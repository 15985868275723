import {UltyDropdown} from '@ulty/ulty-ui';
import {useTranslation} from 'react-i18next';
import useFilters from '../useFilters';
import {useEffect, useState} from 'react';
import {getMerchantsOfCompany} from '../../../../services/Company/Company.service';
import useUser from '../../UserProvider/useUser';

const PosFilter = () => {
    const [t] = useTranslation();
    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const [merchants, setMerchants] = useState([]);
    const { filters, setFilters, debounceFiltersChange } = useFilters();

    useEffect(() => {
        (async function loadPos() {
            setIsLoading(true);
            if (user.provider.type === 'COMPANY') {
                setMerchants((await getMerchantsOfCompany(user.getUserCompany().id)) || []);
            }
            setIsLoading(false);
        })();
    }, [user]);

    const handleSelectedMerchantChange = (selectedMerchantId) => {
        const newFilters = {
            ...filters,
            merchantId: selectedMerchantId,
            brandIds: []
        };

        setFilters(newFilters);
        debounceFiltersChange(newFilters);
    }

    return (
        <UltyDropdown
            label={`${t('global.merchants')}`}
            options={merchants.map(item => ({
                text: item.name,
                key: item.id,
                value: item.id
            }))}
            loading={isLoading}
            multiple={false}
            clearable
            search
            value={filters.merchantId}
            onChange={(e, dp) => handleSelectedMerchantChange(dp.value)}
        />
    )
}

export default PosFilter;
