import {useContext} from 'react';
import {FeatureContext} from './FeatureContext';

export default () => {
  const context = useContext(FeatureContext)
    if (context === undefined) {
        throw new Error(`useFeature must be used within a FeatureProvider`)
    }
    return context
}
