/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState, useRef } from 'react'
import { Modal, Button, Form, Icon, Dropdown, Message, Dimmer} from 'semantic-ui-react'
import { notify } from 'react-notify-toast'
import {API_URL} from '../../../constants';
import {useTranslation} from 'react-i18next';
import useUser from '../../Shared/UserProvider/useUser'
import {uploadFile} from '../../../services/Item/Item.service';
import { isProviderAPos } from '../../../services/Provider/Provider.service';
import useFeature from '../../Shared/FeatureProvider/useFeature';

const FileTemplateUrls = {
    'PRODUCT' : `${API_URL}/templates/product_import.xlsx`,
    'REMOVAL' : `${API_URL}/templates/product_removal.xlsx`,
    'STOCK' : `${API_URL}/templates/product_stock.xlsx`,
    'AVAILABILITY' : `${API_URL}/templates/product_availability.xlsx`,
    'UBEREATS' : `${API_URL}/templates/product_availability.xlsx`,
    'PRICE' : `${API_URL}/templates/product_price_import.xlsx`,
}

const ProductUploadModal = ({ reload }) => {
    const {user} = useUser();
    const {isEnabled} = useFeature();


    const [t] = useTranslation();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const fileRef = useRef()
    const [file, setFile] = useState(null)
    const [filetype, setFiletype] = useState('PRODUCT')

    const handleSubmit = async () => {
        setLoading(true);

        try {
            const bodyFormData = new FormData();
            bodyFormData.append('datafile', file[0]);
            bodyFormData.append('datacontent', filetype);
            const {scheduled=false} = await uploadFile(bodyFormData);
            const displayedMessage = scheduled
                    ? t('product_list.import.scheduled')
                    : t('product_list.import.processed');

            notify.show(displayedMessage, "success");

            if (reload) {
                await reload();
            }
            setOpen(false);
        } catch (e) {
            if (e.message === 'import_already_requested') {
                notify.show(t('product_list.import.import_already_requested'), "error");
            }
            else {
                notify.show(t('product_list.import.error'), "error");
            }
        } finally {
            setLoading(false);
        }
    }

    const getOptions = () => {
        const options = [
            { text: t('product_list.import.metadata'), value: 'PRODUCT', key: 'PRODUCT' },
            { text: t('product_list.import.removals'), value: 'REMOVAL', key: 'REMOVAL' },
        ];

        if(isEnabled('product_import_ubereats')) {
            options.push({ text: t('product_list.import.ubereats'), value: 'UBEREATS', key: 'UBEREATS' });
        }

        if (isProviderAPos(user.provider)) {
            options.push({ text: t('product_list.import.stock'), value: 'STOCK', key: 'STOCK' });
            options.push({ text: t('product_list.import.stock_availability'), value: 'AVAILABILITY', key: 'AVAILABILITY' });
            options.push({ text: t('product_list.import.price'), value: 'PRICE', key: 'PRICE' });
        }

        return options;
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)} closeIcon trigger={<Dropdown.Item onClick={() => setOpen(true)}><Icon name='download' color="teal" />{t('product_list.import.button')}</Dropdown.Item>}>
                <Modal.Header>{t('product_list.import.header')}</Modal.Header>
                <Modal.Content>
                    <Dimmer.Dimmable as={Modal.Description} blurring dimmed={loading}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Select
                                fluid
                                label={t('product_list.import.fileType')}
                                options={getOptions()}
                                defaultValue='STOCK'
                                value={filetype}
                                onChange={(_, { value }) => setFiletype(value)}
                            />
                            {filetype && FileTemplateUrls[filetype] &&
                            <p>
                                <a href={FileTemplateUrls[filetype]}>{t('import.downloadImportTemplateXLSX')}</a>
                            </p>}

                            <Form.Field css={css`margin-top: 20px;`}>
                                <label>{t('product_list.import.file')}</label>
                            </Form.Field>
                            <Form.Field>
                                <input type="file" ref={fileRef} onChange={() => {
                                    setFile(fileRef.current.files)
                                }} />
                            </Form.Field>
                            {filetype === 'REMOVAL' && (
                                <Message color="red"
                                         icon='warning sign'
                                         header={t('product_list.import.warning_removal')}
                                />
                            )}
                            <div style={{ textAlign: 'right' }}>
                                <Button loading={loading} disabled={loading || !file} type="submit" color="teal">{t('product_list.import.import')}</Button>
                            </div>
                        </Form>
                    </Dimmer.Dimmable>
                </Modal.Content>
            </Modal>
    )
}

export default ProductUploadModal
