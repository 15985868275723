/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React from 'react'

import { Segment, Form, Input, Button, Container, Grid, Icon, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'

import {notify} from 'react-notify-toast';
import { checkResetKey, resetPassword } from '../../helpers/api';
import { PASSWORD_REGEX } from '../../constants'
import { useTranslation } from 'react-i18next'

const ResetPassword = ({ location }) => {
  const [t] = useTranslation();

  const [password, setPassword] = useState('')
  const [checkPassword, setCheckPassword] = useState('')
  const [loading, setLoading] = useState(true)
  const [resetKey, setResetKey] = useState('')
  const [step, setStep] = useState('LOADING')

  useEffect(() => {
    let search = location.search;
    let params = new URLSearchParams(search);
    let k = params.get('k');

    checkKey(k)

    setResetKey(k)
  }, [])

  const checkKey = async (k) => {
    let result = await checkResetKey(k)
    if (result.status === 'ERROR') {
      setStep('ERROR')
    } else {
      setStep('FORGOT')
    }
    setLoading(false)
  }

  const handleChange = (_, { name, value }) => {
    if (name === 'password') {
      setPassword(value)
    }
    if (name === 'checkpassword') {
      setCheckPassword(value)
    }
  }

  const handleSubmit = async () => {
    notify.hide();

    if (password === '') {
      notify.show(t('reset_password.missing_password'), 'error')
      return
    }

    if (password !== checkPassword) {
      notify.show(t('reset_password.not_matching_password'), 'error')
      return
    }

    if (!PASSWORD_REGEX.test(password)) {
      notify.show(t('administration.users.invalid_password'), 'error');
      return;
    }

    setLoading(true)

    let res = await resetPassword(resetKey, password)
    if (res.status === 'SUCCESS') {
      setStep('VALID')

      return;
    } else if(res.error === 'INVALID_NEW_PASSWORD') {
      notify.show(t('administration.users.invalid_password'), 'error');
    } else {
      notify.show(t('global.anErrorOccurred'), 'error')
    }
    setLoading(false);
  }

  return (
    <Container css={css`
      margin-top: 6rem !important
    `}>
      <Grid textAlign="center">
        <Grid.Column computer={6} tablet={8} mobile={16}>
          <Segment raised textAlign="left">
            <Form>
              <Grid>
                <Grid.Column width={16} css={css` 
                  background-color: #009c95 !important;
                  padding: 1.5rem !important;
                  color: #ffffff !important;
                `}>
                  <div css={css` font-weight: bold; text-align: center; `}>{t('reset_password.welcome')}</div>
                  <div css={css` text-align: center; `}>{t('reset_password.reset')}</div>
                </Grid.Column>

                { step === 'LOADING' && (
                  <React.Fragment>
                    <Grid.Column width={16} css={css` 
                      padding: 2rem !important;
                    `}>
                      <Loader />
                    </Grid.Column>
                  </React.Fragment>
                )}

                { step === 'FORGOT' && (
                  <React.Fragment>
                    <Grid.Column width={16} css={css` 
                      padding: 2rem !important;
                    `}>
                      <Form.Field
                        control={Input}
                        type="password"
                        name="password"
                        onChange={handleChange}
                        label={t('reset_password.password')}
                        placeholder={t('reset_password.password')}
                      />
                      <Form.Field
                        control={Input}
                        type="password"
                        name="checkpassword"
                        onChange={handleChange}
                        label={t('reset_password.confirmation')}
                        placeholder={t('reset_password.confirmation_password')}
                      />
                    </Grid.Column>

                    <Grid.Column width={16} css={css` 
                      padding: 2rem !important;
                      padding-top: 0 !important;
                    `}>
                      <Button fluid color="teal" type='submit' loading={loading} disabled={loading} onClick={handleSubmit}>{t('reset_password.save')}</Button>
                    </Grid.Column>
                  </React.Fragment>
                )}

                {step === 'VALID' && (
                  <React.Fragment>
                    <Grid.Column width={16} textAlign="center" css={css` 
                      padding: 2rem !important;

                      &>div>div>div {
                        margin: auto;
                      }
                    `}>
                      {t('reset_password.password_success')}
                    </Grid.Column>
                  </React.Fragment>
                )}

                { step === 'ERROR' && (
                  <React.Fragment>
                    <Grid.Column width={16} textAlign="center" css={css` 
                      padding: 2rem !important;

                      &>div>div>div {
                        margin: auto;
                      }
                    `}>
                      {t('reset_password.invalid_key')}
                    </Grid.Column>
                  </React.Fragment>
                )}

                <Grid.Column width={16} textAlign="center" css={css` 
                  padding-top: 0 !important;
                  color: #74788d !important
                `}>
                  <Link to='/auth/login'><Icon name="lock" /> {t('reset_password.connect')}</Link>
                </Grid.Column>
              </Grid>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>

    </Container>
  )
}

export default ResetPassword
