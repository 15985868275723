/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import {Button, Grid, Icon, Table} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import useUser from '../../../Shared/UserProvider/useUser';
import { isProviderAPos } from '../../../../services/Provider/Provider.service';

const itemListMassActions = {
    EDIT: 'edit',
    SET_AVAILABLE: 'setAvailable',
    SET_UNAVAILABLE: 'setUnavailable',
    SET_UNAVAILABLE_UNTIL_TONIGHT: 'setUnavailableUntilTonight',
    DELETE: 'delete'
};

const MassActionConfirm = ({selectedItems, onItemsChange, onMassAction, afterSubmit}) => {
    const [t] = useTranslation();
    const {user} = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(selectedItems);

    function canSetAvailable() {
        return selectedItems.length > 0;
    }

    function canSetUnavailable() {
        return selectedItems.length > 0;
    }

    function canDeleteItems() {
        return selectedItems.length > 0 &&
            selectedItems.every(item => item.provider.id === user.provider.id);
    }

    const massActionGroups = [{
        key: itemListMassActions.DELETE,
        icon: 'delete',
        color: 'red',
        label: t('product_list.mass_actions.delete.delete'),
        allowed: canDeleteItems()
    }, ...(isProviderAPos(user.provider) ? [{
        key: itemListMassActions.SET_AVAILABLE,
        icon: 'check',
        color: 'teal',
        label: t('product_list.mass_actions.set_available.set_available'),
        allowed: canSetAvailable()
    }, {
        key: itemListMassActions.SET_UNAVAILABLE_UNTIL_TONIGHT,
        icon: 'hourglass one',
        color: 'orange',
        label: t('product_list.mass_actions.set_unavailable.set_unavailable_until_tonight'),
        allowed: canSetUnavailable()
    }, {
        key: itemListMassActions.SET_UNAVAILABLE,
        icon: 'hourglass',
        color: 'yellow',
        label: t('product_list.mass_actions.set_unavailable.set_unavailable'),
        allowed: canSetUnavailable()
    }] : []), ];

    const handleRemoveItemFromSelection = (itemToRemove) => {
        const newSelection = items.filter(item => item.id !== itemToRemove.id);
        setItems(newSelection);
        onItemsChange(newSelection);
        if (newSelection.length === 0) {
            afterSubmit();
        }
    }

    const handleRemoveAllItemsFromSelection = () => {
        setItems([]);
        onItemsChange([]);
        afterSubmit();
    }

    return (
        <React.Fragment>
        <div css={css`
            max-height: 400px;
            overflow: auto;
            margin-bottom: 2em;
        `}>
            <Table unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('product_details.product')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('product_details.ean')}</Table.HeaderCell>
                        <Table.HeaderCell/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items.map(item =>
                        <Table.Row key={item.id}>
                            <Table.Cell>{item.object.name}</Table.Cell>
                            <Table.Cell>{item.object.barcode}</Table.Cell>
                            <Table.Cell>
                                <Button size="tiny" color="red" icon onClick={() => handleRemoveItemFromSelection(item)}>
                                    <Icon name='delete' />
                                </Button>
                            </Table.Cell>
                        </Table.Row>)}
                </Table.Body>
            </Table>
            </div>

            <Grid stretched relaxed css={css`
                .column {
                    padding-top: .5rem !important;
                    padding-bottom: .5rem !important;
                }
            `}>
                <Grid.Column computer={8} tablet={8} mobile={16} stretched>
                    <Button onClick={handleRemoveAllItemsFromSelection} loading={isLoading} disabled={isLoading}>
                        {t('product_list.mass_actions.clear_selection')}
                    </Button>
                </Grid.Column>

                {massActionGroups.map(mag => (
                    <Grid.Column computer={8} tablet={8} mobile={16} stretched key={mag.key}>
                        <Button onClick={async () => {
                            setIsLoading(true);
                            await onMassAction(mag.key, items);
                            setIsLoading(false);
                            afterSubmit();
                        }} icon={!!mag.icon} labelPosition="left" color={mag.color} disabled={isLoading || !mag.allowed} loading={isLoading}>
                            {mag.icon && (
                                <Icon name={mag.icon} />
                            )}
                            {mag.label}
                        </Button>
                    </Grid.Column>
                ))}


            </Grid>
        </React.Fragment>
    )
}

export default MassActionConfirm;
