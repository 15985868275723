import React from 'react';

import { Segment } from 'semantic-ui-react';

import Chart from "react-apexcharts";
import {useTranslation} from 'react-i18next';
import {unitFormat} from '../../helpers/money';
import useUser from '../Shared/UserProvider/useUser';
import {customTooltip} from './apexChartUtils';

const HourChart = ({ data }) => {
    const [t] = useTranslation();
    const {user} = useUser();

    return (
        <React.Fragment>
            <Segment style={{
                maxHeight: '300px'
            }}>
                <Chart
                    options={{
                        dataLabels: {
                            enabled: false
                        },
                        chart: {
                            zoom: {
                                enabled: false
                            },
                        },
                        xaxis: {
                            categories: data.map(d => `${("00" + d.h).slice(-2)}h`)
                        },
                        legend: {
                            markers: {
                                fillColors: ['#45b29d', '#334d5c'],
                            }
                        },                  
                        stroke: {
                            colors: ['transparent', '#334d5c'],
                            width: [4, 3],
                        },
                        tooltip: {
                            shared: false,
                            intersect: true,
                            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                                return customTooltip({ series, seriesIndex, dataPointIndex, w, currencyCode: user.getCompanyCurrency(), locale: user.getCompanyLocale() });
                            }
                        },
                        colors: ['#45b29d', '#334d5c'],
                        yaxis: [
                            {
                                title: {
                                    text: t('home.revenues')
                                }
                            },
                            {
                                opposite: true,
                                title: {
                                    text: t('home.order_count')
                                },
                            }
                           
                        ],
                        title: {
                            text: t('home.hourly_activity'),
                            align: 'center'
                        }
                    }}
                    series={[{
                        name: t('home.revenues'),
                        data: data.map(d => unitFormat(d.revenue, user.getCompanyCurrency())),
                        dataType: 'CURRENCY',
                        type: 'bar'
                    },{
                        name: t('home.order_count'),
                        data: data.map(d => d.nbr),
                        dataType: 'NUMERIC',
                        type: 'line'

                    } ]}
                    type="line"
                    height={250}
                />

            </Segment>
        </React.Fragment>
    )
}

export default HourChart
