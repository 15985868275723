/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { Image, Button, Icon, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const OrderNotification = ({ order, stopSound, type }) => {
  const [t] = useTranslation();

  const handleClick = () => {
    stopSound();
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      min-width: 250px;

      .notification-order {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 5px;

        .notification-order-content {
          padding-left: 10px;
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }

      .notification-order-merchant {
        font-weight: bold;
        color: black;
        flex: 2;
        display: flex;
        align-items: center;
      }

      .notification-button {
        width: 100%;
        text-align: right;
      }
    `}>
      <div className="notification-order">
        <div className="notification-order-image">
          <Image size='tiny' src={order.platform.logo} />
        </div>
        <div className="notification-order-content">
          <div css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}>
            <div css={css`
              font-weight: bold;
            `}>{`${order.displayId.indexOf('#') !== -1 ? '' : '# '}${order.displayId}`}</div>
            <div css={css`
              text-align: right;
            `}>
              <Label size="mini" color={type === 'NEW' ? 'green' : 'red'}>{type === 'NEW' ? t('order.notification.new') : t('order.notification.cancelled')}</Label>
            </div>
          </div>

          <div className="notification-order-merchant">{`${order.brand.name}`}</div>
          <div css={css`
              flex: 1;
              display: flex;
              align-items: end;
              justify-content: flex-end;
            `}>
            <Button icon as={Link} color="teal" size="mini" floated='right' to={`/orders/${order.id}`} onClick={handleClick}>
              <Icon name="chevron right" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderNotification
