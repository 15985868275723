/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import OrderItemsRich from '../OrderDetail/OrderItemList/OrderItemsRich/OrderItemsRich';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { Segment, Header, Image, Checkbox } from 'semantic-ui-react';
import useUser from '../../Shared/UserProvider/useUser';
import { UltyMoney } from '@ulty/ulty-ui';
import { MOBILE_THRESHOLD } from '../../../constants';

const OrderSummary = ({ orders }) => {
  const [t] = useTranslation();
  const {user} = useUser();
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

  const [visible, setVisible] = useState(orders.map(o => o.id));

  const getExternalId = (externalId) => {
    return `${externalId.indexOf('#') > -1 ? '' : '#'}${externalId}`;
  }

  const OrderBlock = ({ o }) => {
    return (
      <>
        <Header as='h3' attached='top' key={o.id} block css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px !important;
        `}>
          <div css={css`
            display: flex;
            align-items: center;
            flex: 1;
          `}>
            <Image size="mini" src={o.platform.logo} />
            <div css={css`
              padding-left: 20px; 
              padding-right: 20px;
              display: flex;
              flex-direction: ${isMobile ? 'column' : 'row'};
              align-items: center;
              justify-content: space-between;
              flex: 1;
            `}>
              <span css={css`
                font-size: ${isMobile ? '0.8em' : '1.2em'};
                flex: 3;
              `}>{`${o.brand.name}${isMobile ? '' : ` - ${getExternalId(o.externalId)}`}`}</span>
              <div css={css`
                font-size: 0.8em;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: ${isMobile ? '100%' : 'auto'};
                flex: 1;
                justify-content: ${isMobile ? 'space-between' : 'flex-end'};
              `}>
                { isMobile && (
                  <span>{getExternalId(o.externalId)}</span>
                )}
                <UltyMoney
                    readonly
                    css={css`margin: 0`}
                    currencyCode={user.getCompanyCurrency()}
                    locale={user.getCompanyLocale()}
                    amount={o.price}
                />
              </div>
            </div>
          </div>
          <div>
            <Checkbox label={isMobile ? '' : t('order.treated')} checked={!visible.includes(o.id)} onChange={(evt, { checked }) => setVisible(prev => {
              const l = prev.map(v => v);
              if (!checked) {
                l.push(o.id);
                return l;
              } else {
                return l.filter(ll => ll !== o.id);
              }
            })} />
          </div>
        </Header>
        { visible.includes(o.id) && (
          <Segment attached>
            <OrderItemsRich order={o} />
          </Segment>
        )}
      </>
    );
  };

  return (
    <>
      <LazyLoad height={200} once >
        { orders.map(o => <OrderBlock o={o} />)}
      </LazyLoad>
    </>
  );
}

OrderSummary.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default OrderSummary;
