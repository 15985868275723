import {useContext, useMemo} from 'react';
import {PlatformContext} from './PlatformProvider';


const usePlatform = () => {
  const context = useContext(PlatformContext)
    if (context === undefined) {
        throw new Error(`usePlatforms must be used within a PlatformProvider`)
    }
    return context
}

export default usePlatform

export const Platform = {
  Name : ({id}) => {
    const {get} = usePlatform();
    const name = useMemo(() => get(id || '')?.name, [get,id])
    return <span>{name}</span>
  }
}
