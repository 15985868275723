import { useMemo } from "react";
import useUser from "../../Shared/UserProvider/useUser";
import { getSorter, groupBy } from "../../../helpers/array";

export const  usePricesByPlatform =  (prices) => {
    const {user} = useUser();
    const displayedPrices = useMemo(() => {
        const byPlatform = groupBy(prices || [], d => d.platformId);
        const sortByProvider = getSorter(
            ({providerId}) => (!providerId || providerId === user.provider.id)
                                ? 0
                                : 1
        );

        return Array.from(byPlatform.entries()).map(([key,pricesByPlatform]) => {
            return pricesByPlatform.sort(sortByProvider)[0];
        });
    },[prices,user]); 

    return {displayedPrices};
}