import React, {createContext, useCallback, useEffect, useState} from 'react';
import rules from '../../../rbac-rules';
import { getPlatforms } from '../../../services/Platform/Platform.service'
import useUser from '../UserProvider/useUser';
import { id } from 'date-fns/locale';
const RELOAD_DELAY_IN_MINUTES = 100
const MAX_RETRY_ATTEMPT = 10;
const empty = {
    get(){return undefined},
    list(){ return []}
}

const PlatformContext = createContext(empty);

const createPlatformContext = (map) => {
    return {
        get: (id) => map.get(id ?? ''),
        list: () => Array.from(map).map(([id,value]) => ({id,...value}))
    }
}

const computeRetryDelay = (currentAttempt) => 2 ** (currentAttempt+ 2) ;

const PlatformProvider = ({ children }) =>  {
    const [retryAttemptCount,setRetryAttemptCount] = useState(0);
    const {user} = useUser();
    const [platforms, setPlatforms] = useState(empty);
    const [loaded, hasLoaded] = useState(false);

    const load = async () => {
        try {
            const a = (await getPlatforms()) || [];
            const map = new Map(a.map(b => ([
                b.id,
                {
                    module:b.module,
                    name:b.name,
                    logo:b.logo
                }
            ])));
            setPlatforms(createPlatformContext(map));
            setRetryAttemptCount(0);
            hasLoaded(true);
        }
        catch {
            setRetryAttemptCount(retryAttemptCount++);
            setPlatforms(empty);
        }
    }

    usePeriodicReload();
    useUserModification();
    useRetry();
    useFirstLoad();

    return (
        <PlatformContext.Provider value={platforms}>
            {children}
        </PlatformContext.Provider>
    )

    function useUserModification() {
        useEffect(() => {
            hasLoaded(false);
        }, [user]);
    }

    function useFirstLoad() {
        useEffect(() => {
            (async function loadWrapper() {
                if(!loaded && user.id){
                    await load()
                }
            })()
        },[loaded,user])
    }

    function usePeriodicReload() {
        useEffect(() => {
            const timer = setInterval(async () => await load(), 1000 * 60 * RELOAD_DELAY_IN_MINUTES);
            return () => {
                clearInterval(timer);
            };
        }, []);
    }

    function useRetry() {
        useEffect(() => {
            if (retryAttemptCount === 0 || retryAttemptCount > MAX_RETRY_ATTEMPT) return;

            const timer = setTimeout(async () => await load(), 1000 * computeRetryDelay());
            return () => {
                setTimeout(timer);
            };
        }, [retryAttemptCount]);
    }
}

export {
    PlatformProvider,
    PlatformContext,
    createPlatformContext
};
