import React, { useState } from 'react'

import { Form, Header, Input, Button } from 'semantic-ui-react'
import { notify } from 'react-notify-toast';
import { PASSWORD_REGEX } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { updateSelf } from '../../../services/User/User.service';

const UserForm = () => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false)

  const [oldpassword, setOldpassword] = useState('')
  const [newpassword, setNewpassword] = useState('')
  const [newpassword2, setNewpassword2] = useState('')

  const handleSubmit = async () => {
    if (newpassword !== newpassword2) {
      notify.show(t('administration.users.password_not_matching'), 'error')
      return
    }

    if (!PASSWORD_REGEX.test(newpassword)) {
      notify.show(t('administration.users.invalid_password'), 'error');
      return;
    }

    setLoading(true)

    try {
      await updateSelf({
        oldpassword,
        newpassword
      });

      notify.show(t('global.registerSuccess'), 'success');

      setOldpassword('');
      setNewpassword('');
      setNewpassword2('');
    } catch(e) {
      if(e.message === 'INVALID_PASSWORD') {
        notify.show(t('administration.users.previous_password_incorrect'), 'error')
      } else if(e.message === 'INVALID_NEW_PASSWORD') {
        notify.show(t('administration.users.invalid_password'), 'error');
      } else {
        notify.show(t('global.anErrorOccurred'), 'error')
      }
    }
    setLoading(false)
  }

  return (
    <Form>
      <Header>{t('administration.users.header')}</Header>
      <Form.Field
        control={Input}
        name="oldpassword"
        type="password"
        value={oldpassword}
        onChange={(evt, dt) => setOldpassword(dt.value)}
        label={t('administration.users.current')}
        placeholder={t('administration.users.current')}
      />
      <Form.Field
        control={Input}
        name="newpassword"
        type="password"
        value={newpassword}
        onChange={(evt, dt) => setNewpassword(dt.value)}
        label={t('administration.users.new')}
        placeholder={t('administration.users.new')}
      />
      <Form.Field
        control={Input}
        name="newpassword2"
        type="password"
        value={newpassword2}
        onChange={(evt, dt) => setNewpassword2(dt.value)}
        label={t('administration.users.confirm_new')}
        placeholder={t('administration.users.confirm_new')}
      />

      <div style={{ textAlign: 'right' }}>
        <Button color="teal" type="submit" loading={loading} disabled={loading} onClick={handleSubmit}>{t('global.save')}</Button>
      </div>
    </Form>
  )
}

export default UserForm