/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Icon, Message, MessageContent, MessageHeader, MessageItem, MessageList } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

const FeatureInfo = ({ isVisible, setIsVisible }) => {
    const [t] = useTranslation()

    if (!isVisible) {
        return (
          <Icon
            name="info circle"
            color='blue'
            onClick={() => {
              setIsVisible(true)
            }}
            css={css`
              cursor: pointer;
            `}
          />
        )
    }

    return (
      <Message
        color='blue'
        icon
        onDismiss={() => {
          setIsVisible(false)
      }}>
          <Icon name='info circle' />
          <MessageContent>
              <MessageHeader>{t('reports.performance_configuration.feature_info.header')}</MessageHeader>
              <p>{t('reports.performance_configuration.feature_info.subheader')}</p>
              <MessageList>
                  <MessageItem>{t('reports.performance_configuration.feature_info.item1')}</MessageItem>
                  <MessageItem>{t('reports.performance_configuration.feature_info.item2')}</MessageItem>
                  <MessageItem>{t('reports.performance_configuration.feature_info.item3')}</MessageItem>
              </MessageList>
              <p>{t('reports.performance_configuration.feature_info.footer')}</p>
          </MessageContent>
      </Message>
    )
}

export { FeatureInfo }
