/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useState } from 'react'

import { Button, Icon} from 'semantic-ui-react'

import { notify } from 'react-notify-toast'
import {useTranslation} from 'react-i18next';
import { cancelOrder } from '../../../services/Order/Order.service';

const CancelOrder = ({ order, afterSubmit }) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  const doCancelOrder = async () => {
    setLoading(true);

    try {
      await cancelOrder({ id: order.id })
      afterSubmit();
    } catch(e) {
      notify.show(t('global.anErrorOccurred'), 'error');
    }
    setLoading(false);
  }

  return (
    <div>
      <p>
        {t('order.cancel.confirm_1')}
      </p>
      { order.platform.id !== '3dd792fe-8267-424b-afd0-3d4212b1bd45' && (
        <p>
          {t('order.cancel.confirm_2')}
        </p>
      )}
      <div css={css`
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
      `}>
        <Button color='teal' onClick={() => afterSubmit()} loading={loading} disabled={loading}>
          <Icon name='remove' /> {t('global.cancel')}
        </Button>
        <Button color='red' onClick={doCancelOrder} loading={loading} disabled={loading}>
          <Icon name='checkmark' /> {t('order.cancel.mark_cancelled')}
        </Button>
      </div>
    </div>
  )
}

export default CancelOrder
