import React from 'react'

import { Tab, Divider } from 'semantic-ui-react'

import UserForm from './UserForm'
import PasswordForm from './PasswordForm'

const AdminAccount = () => {
  return (
    <Tab.Pane>
      <UserForm />
      <Divider />
      <PasswordForm />
    </Tab.Pane>
  )
}

export default AdminAccount