import { Switch, Route } from 'react-router-dom';
import CategorySet from './CategorySet';
import CategorySetList from './CategorySetList';
import {CategoryModifiedProvider} from './CategoryModifiedContext';

const Categories = () => {
    return (
        <CategoryModifiedProvider value={false}>
            <Switch>
                <Route path="/categories/:id" component={CategorySetList} />
                <Route path="/categories" component={CategorySet} />
            </Switch>
        </CategoryModifiedProvider>
    )
}

export default Categories;
