import {Label} from 'semantic-ui-react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';


const ScanInfo = ({orderItem,scanValue}) => {
    const [t] = useTranslation()
    return <>
    { scanValue !== undefined && <Label color={scanValue < orderItem.quantity ? 'yellow' : 'green'} size="mini" icon="check" content={t("order.pickup.scanned_label")} detail={`${scanValue}/${orderItem.quantity}`} />}
    </>
}

ScanInfo.propTypes = {
    orderItem: PropTypes.shape({
        quantity: PropTypes.number.isRequired,
    }).isRequired,
    scanValue: PropTypes.number,
};

export {ScanInfo};