import StackTrace from "stacktrace-js"
import { API_URL, AUTH_TOKEN } from '../../constants'

export const sendStackTrace = async (stackFrames, errorMessage) => {
    try {
        const token = localStorage.getItem(AUTH_TOKEN);

        await StackTrace.report(stackFrames, `${API_URL}/rl`, errorMessage, {
            headers: {
                Authorization: `Bearer ${token || ''}`
            }
        })
    } catch (e) {
        console.error(`sendLogs error: ${e}`);
        throw e;
    }
}

export const stackTraceFromError = async (error) => {
    return await StackTrace.fromError(error)
}
