import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useScanning } from "../../../../scanning/scanning.service";
import { OrderDetailContext } from "../OrderDetailContext";
import { UltyModalWrapperContext } from "../../../Shared/UltyModalWrapper/UltyModalWrapperContext";
import OrderPickupWrongItem from "./OrderPickupWrongItem";
import OrderPickupTooMuchItem from "./OrderPickupTooMuchItem";
import OrderPickupMoreItems from "./OrderPickupMoreItems";
import OrderItemReplacement from "../OrderItemList/OrderItemReplacement";
import PropTypes from 'prop-types';
import { usePrevious } from "../../../../helpers/usePrevious";
import OrderPickupItemComplete from "./OrderPickupItemComplete";



const OrderPickingScanContext = createContext({
    scannedItems:[],
    pickingMode : "MANUAL",
    scannedItemsMap: new Map()
});


export const OrderPickingScanProvider  = ({
        order,
        onFulfillmentIssue,
        clearFulfillmentIssue,
        children
    }) => {
    const [pickingMode, setPickingMode] = useState("MANUAL");

    const retrieveScannedFromOrderItems = (orderItems) => {
        return orderItems?.filter(oi => oi.pickingMethod === 'SCAN')?.map(oi => [oi.id,oi.quantity]) ?? [];
    }

    const [scannedItemsMap, setScannedItemsMap] = useState(new Map(retrieveScannedFromOrderItems(order?.orderItems)));
    const [latestScannedItem, setLatestScannedItem] = useState(null);
    const {isModifying,isInReplacement,startReplacement,endReplacement} = useContext(OrderDetailContext);

    const wasModifying = usePrevious(isModifying);

    //Reset picking mode
    useEffect(() => {
        if(wasModifying && !isModifying){
            setPickingMode("MANUAL");
        }
    },[isModifying])

    const defineOrderItemReplacementModal = (orderItem,barcode) => {
        startReplacement();
        const modalSettings = {
            centered: false,
            size: "large",
            onClose: () => {endReplacement();},
            component: <OrderItemReplacement
                preloadedSearch={barcode}
                shallAutoSelectSubstitute
                order={order}
                orderItem={orderItem}
                afterSubmit={() => {
                    handleUltyModalWrapper(false, null);
                }}
                addOrderItemToFulfillmentIssues={(oi, replacedItemQuantity, substitution) => {
                    addScannedItem(oi.id,substitution.quantity);
                    onFulfillmentIssue(oi, replacedItemQuantity, {...substitution,scanned:true});
                }}
            />
        };

        handleUltyModalWrapper(true, modalSettings);
    };

    //Reset scanned when orderItems is updated : Scanned fields should have been updated
    useEffect(() => {
        setScannedItemsMap(new Map(retrieveScannedFromOrderItems(order?.orderItems)));
    },[order.orderItems]);


    const addScannedItem = useCallback((itemId,howManyTime = undefined) => {
        setScannedItemsMap(prev => {
            const nextMap = new Map(prev);
            const current = nextMap.get(itemId) || 0;
            const nextVal = current + (howManyTime ?? 1);
            nextMap.set(itemId, nextVal);
            return nextMap;
        });
        setLatestScannedItem(itemId)
    },[])

    const scannedItems = useMemo(() => {
        return Array.from(scannedItemsMap.entries()).reduce((acc, [itemId, quantity]) => {
            return [...acc, ...Array(quantity).fill(itemId)];
        },[]);
    },[scannedItemsMap]);

    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);
    const alertWrongItem = (barcode) => {
        const replacableOrderItems = order?.orderItems.filter(item =>
            //Should be defined as replaceable
            item.isReplaceable
            // Musn't be already related to a fulfillment issue
            // This should evolves in the future, but current implementation can't handle fulfillment issue modification
            && !item.fulfillmentIssue
            // Wasn't already scanned
            && (!scannedItemsMap?.has(item.id))
        );
        const modalSettings = {
            size: 'tiny',
            component: <OrderPickupWrongItem replacableOrderItems={replacableOrderItems} barcode={barcode} onSubmit={(action,{orderItem}={}) => {
                if(action === "SUBSTITUTE"){
                    defineOrderItemReplacementModal(orderItem,barcode);
                }
            }} />
        };

        handleUltyModalWrapper(true, modalSettings);
    }
    const alertTooMuchItem = (orderItem,barcode) => {
        const modalSettings = {
            size: 'tiny',
            component: <OrderPickupTooMuchItem barcode={barcode} orderItem={orderItem} onSubmit={() => {}} />
        };

        handleUltyModalWrapper(true, modalSettings);
    }
    const alertRemainingItem = (orderItem, currentQuantity, expectedQuantity) => {
        const modalSettings = {
            size: 'tiny',
            component: <OrderPickupMoreItems orderItem={orderItem} currentQuantity={currentQuantity} expectedQuantity={expectedQuantity} onSubmit={({ type }) => {
                if (type === 'UNAVAILABLE') {
                    onFulfillmentIssue(orderItem,currentQuantity);

                }

                if (type === 'VALIDATE') {
                    addScannedItem(orderItem.id, expectedQuantity - currentQuantity);
                    clearFulfillmentIssue(orderItem);
                }
            }} />
        };

        handleUltyModalWrapper(true, modalSettings);
    }
    const alertItemComplete = (orderItem) => {
        const modalSettings = {
            size: 'tiny',
            component: <OrderPickupItemComplete orderItem={orderItem}/>
        };

        handleUltyModalWrapper(true, modalSettings);
    }

    const onScan = useCallback((event) => {
        //Ignore events when already doing a replacement. Scanning is handle directly by the replacement component
        if(isInReplacement){return; }

        if (!order.isModifiable) {
            return;
        }

        setPickingMode("SCANNING");

        const scannedBarcode = event.value;

        const item = order.orderItems.find(i => parseInt(i.object?.barcode || '0') === parseInt(scannedBarcode) || i.object?.barcode === scannedBarcode);
        if (item) {
            const alreadyScanned = scannedItemsMap.get(item.id) || 0;
            const current = alreadyScanned + 1;

            if (item.quantity > current) {
                alertRemainingItem(item, current, item.quantity);
            }
            else if (item.quantity < current) {
                alertTooMuchItem(item,scannedBarcode);
                return;
            }
            else {
                alertItemComplete(item);
            }

            addScannedItem(item.id);

        } else {
            alertWrongItem(scannedBarcode);
        }
    },[order.orderItems, scannedItemsMap,isModifying,isInReplacement]);

    useScanning(onScan);

    const contextValue = useMemo(() => ({
        pickingMode,scannedItems,scannedItemsMap,
        latestScannedItem
    }), [pickingMode,scannedItems,scannedItemsMap,latestScannedItem]);

    return (
        <OrderPickingScanContext.Provider value={contextValue}>
            {children}
        </OrderPickingScanContext.Provider>
    )

}

OrderPickingScanProvider.propTypes = {
    order: PropTypes.object.isRequired,
    onFulfillmentIssue: PropTypes.func.isRequired,
    clearFulfillmentIssue: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export const useOrderPickingScan = () => {
    return useContext(OrderPickingScanContext);
}
