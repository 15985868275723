import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {getPlatforms} from '../../services/Platform/Platform.service';
import {getBrands} from '../../services/Brand/Brand.service';
import {getMerchantsOfCompany} from '../../services/Company/Company.service';
import useUser from '../Shared/UserProvider/useUser';
import { getCategoryLinks, getCategorySets, createCategoryLinks } from '../../services/Category/Category.service';
import { Accordion, Card, Form, Dropdown, Button, Item,Image, Divider, Header, Radio, Label, CardGroup, Icon } from 'semantic-ui-react';
import CategoryLinkItem from './CategoryLinkItem';
import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';

const CategoryLinks = () => {
  const [t] = useTranslation();

  const {user, canCreateCategories} = useUser();
  const [loading, setLoading] = useState(false);

  const [platforms, setPlatforms] = useState([]);
  const [brands, setBrands] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [links, setLinks] = useState([]);
  const [sets, setSets] = useState([]);



  const [form, setForm] = useState({
    platformId: null,
    brandId: null,
    merchants: [],
    setId: null,
  });

  const [customMerchants, setCustomMerchants] = useState(false);

  const unassignedDefaultMenu = useMemo(() => {
    const brandPlatforms = platforms.flatMap(platform => brands.map(brand => ({platform,brand})));

    return brandPlatforms.filter(bp => !links.some(l => l.brand.id === bp.brand.id && l.platform.id === bp.platform.id && l.merchants.length === 0));
  },[platforms,brands,links])

  const availablePlatformsForForm = useMemo(() =>{
    return platforms.filter(p => {
      if(!customMerchants){
      const platformsWithDefaultMenu = links.filter(l => l.brand.id === form.brandId && l.merchants.length === 0).map(l => l.platform.id);
      return !platformsWithDefaultMenu.includes(p.id)
      }
      else {
      const merchantsBeingExplicitlyAssignedTOMenu = links.filter(l => l.brand.id === form.brandId && l.platform.id === p.id && l.merchants.length > 0).flatMap(l => l.merchants);
      return merchantsBeingExplicitlyAssignedTOMenu.length !== merchants.length;
      }
    })
  },[form.brandId,links,platforms,merchants,customMerchants]);

  const formIsValid = useMemo(() => {
    return form.brandId && form.platformId && form.setId && (!customMerchants || form.merchants.length > 0)
  },[form,customMerchants])


  const loadBrandsAndPlaforms = async () => {
    setLoading(true);

    const [platforms, brands, merchants, links, sets] = await Promise.all([
      getPlatforms(),
      getBrands(),
      getMerchantsOfCompany(user.companyId),
      getCategoryLinks(),
      getCategorySets(),
    ]);

    setPlatforms(platforms);
    setBrands(brands);
    setMerchants(merchants);
    setLinks(links);
    setSets(sets);

    setLoading(false);
  }

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await createCategoryLinks({
        ...form
      });
    } catch(e) {
      if (e.message === 'duplicated_merchant') {
        notify.show(`${t(`category_link.${e.message}`)}`, 'error');
      } else {
        notify.show(t('global.anErrorOccurred'), 'error');
      }
    }

    setForm({
      platformId: null,
      brandId: null,
      merchants: [],
      setId: null,
    });

    await loadBrandsAndPlaforms();

    setLoading(false);
  }

  useEffect(() => {
    loadBrandsAndPlaforms();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChecked = useCallback(() => {
    return customMerchants||links.filter(l => l.brand.id === form.brandId && l.platform.id === form.platformId && l.merchants.length > 0).length>0;
  }, [customMerchants, form.platformId, form.brandId, links]);

  return (
    <>
      { canCreateCategories() && (
        <>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t('global.brand')}</label>
              <Dropdown
                fluid
                selection
                loading={loading}
                name="brands"
                onChange={(evt, {value }) => setForm(prev => ({
                  ...prev,
                  brandId: value,
                  platformId: null,
                  setId: null,
                }))}
                value={form.brandId}
                options={brands.map(dm => ({
                  text: dm.name,
                  value: dm.id,
                  key: dm.id
                }))}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('global.platform')}</label>
              <Dropdown
                fluid
                selection
                loading={loading}
                name="platforms"
                disabled={!form.brandId}
                onChange={(evt, {value }) => setForm(prev => ({
                  ...prev,
                  platformId: value,
                  setId: null,
                }))}
                value={form.platformId}
                options={availablePlatformsForForm.map(dm => ({
                  text: dm.name,
                  value: dm.id,
                  key: dm.id
                }))}
              />
            </Form.Field>
            <Form.Field>
              <label>{t('categories.set')}</label>
              <Dropdown
                fluid
                selection
                loading={loading}
                name="sets"
                disabled={!form.platformId}
                onChange={(evt, {value }) => setForm(prev => ({
                  ...prev,
                  setId: value
                }))}
                value={form.setId}
                options={sets.map(dm => {
                  let disabled = false;
                  if (dm.slots.length > 1 && form.platformId !== '3dd792fe-8267-424b-afd0-3d4212b1bd45') {
                    disabled = true;
                  }

                  return {
                    text: disabled ? `${dm.name} (${t('category_link.too_much_slots')})` : dm.name,
                    value: dm.id,
                    key: dm.id,
                    disabled,
                  };
              })}
              />
            </Form.Field>
          </Form.Group>
          { user.provider.type === 'COMPANY' && (
            <>
              <Form.Field>
                <label>{t('global.merchant')}</label>
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('category_link.select_merchants_off')}
                  name='checked'
                  checked={!isChecked()}
                  onChange={() => setCustomMerchants(false)}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('category_link.select_merchants_on')}
                  name='checked'
                  checked={isChecked()}
                  onChange={() => setCustomMerchants(true)}
                />
              </Form.Field>
              { isChecked() && (
                <Form.Field>
                  <Dropdown
                    fluid
                    selection
                    multiple
                    loading={loading}
                    name="merchants"
                    onChange={(evt, {value }) => setForm(prev => ({
                      ...prev,
                      merchants: value
                    }))}
                    value={form.merchants}
                    options={merchants.sort((a, b) => a.name.localeCompare(b.name)).filter(dm => {
                      const existingMerchants = links.filter(l => l.brand.id === form.brandId && l.platform.id === form.platformId && l.merchants.length > 0).flatMap(l => l.merchants);
                      return !existingMerchants.includes(dm.id);
                    }).map(dm => ({
                      text: dm.name,
                      value: dm.id,
                      key: dm.id
                    }))}
                  />
                </Form.Field>
              )}
            </>
          )}
          <div style={{ textAlign: 'right' }}>
            <Button color="teal" type="submit" loading={loading} disabled={loading || !formIsValid} onClick={handleSubmit}>{t('global.add')}</Button>
          </div>

          <Divider />
        </>
      )}
      {unassignedDefaultMenu.length > 0 && (
      <Accordion panels={[{
        key: 'panel-1',
        title: {
          content: <Label color= "orange"><Icon name="warning sign"/>{t('category_link.missing_default_link')}</Label> ,
          icon: <></>
        },
        content: {
          content: (
            <CardGroup itemsPerRow={4}>
              {unassignedDefaultMenu.map(bp =>
                <Card fluid>
                  <Card.Content>
                    <Card.Description textAlign='center'>
                      <Image src={bp.platform.logo} wrapped size="tiny" floated='right' />
                      <strong>{bp.brand.name}</strong>
                    </Card.Description>
                  </Card.Content>
                </Card>)}
            </CardGroup>
            )
        }
      }]}/>)
     }

      <Header>{t('category_link.list_items')}</Header>
      <Item.Group divided>
        {links.map(l => (
          <CategoryLinkItem
            key={l.id+l.merchants.join(",")}
            canCreateLinks={canCreateCategories()}
            logo={platforms.filter(p => p.id === l.platform.id).length > 0 ? platforms.filter(p => p.id === l.platform.id)[0].logo : null}
            link={l}
            categorySet={sets.find(s => s.id === l.categorySet.id)}
            merchants={merchants}  sets={sets} reload={async () => {
              await loadBrandsAndPlaforms();
              setForm({
                platformId: null,
                brandId: null,
                merchants: [],
                setId: null,
              });
            }} />
        ))}
      </Item.Group>
    </>
  );
}

export default CategoryLinks;
