/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {definePlatformItemImage, getPlatforms} from '../../services/Platform/Platform.service';
import {defineItemImage, getItemImages, removeItemImages} from '../../services/Item/Item.service';
import {Button, Icon, Image, Segment} from 'semantic-ui-react';
import {notify} from 'react-notify-toast';
import {useTranslation} from 'react-i18next';
import ItemImagePlatformAssociationItem from './ItemImagePlatformAssociationItem';
import ItemImagePlatformAssociationActions from './ItemImagePlatformAssociationActions';
import {UltyInputText} from '@ulty/ulty-ui';
import {UltyModalWrapperContext} from '../Shared/UltyModalWrapper/UltyModalWrapperContext';
import {UltyModalContext} from '../Shared/UltyModal/UltyModalContext';
import useBreadcrumb from '../NavBar/useBreadcrumb';


const itemStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const squareImg = '/img/image.png'
const squareItemImage = { url: squareImg, isDefault: true };

const ItemImagePlatformsAssociation = () => {
    const [t] = useTranslation();
    const { itemId } = useParams();
    const {paths, setPaths} = useBreadcrumb();
    const [isLoading, setIsLoading] =  useState(false);
    const [defaultItemImage, setDefaultItemImage] = useState(squareItemImage);
    const [platforms, setPlatforms] = useState([]);
    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);
    const { handleUltyModal } = useContext(UltyModalContext);

    useEffect(() => {
        if (paths.length === 0) {
            setPaths([{
                text: t('breadcrumb.home'),
                link: true,
                path: '/'
            }, {
                text: t('breadcrumb.catalog'),
                link: true,
                path: '/products'
            }, {
                text: t('breadcrumb.edition'),
                link: false,
            }]);
        }
    }, [itemId]);

    useEffect(() => {
        setIsLoading(true);
        getItemImages(itemId).then(itemImages => {
            setDefaultItemImage(itemImages.find(itemImage => itemImage.isDefault));
        }).catch(e => {
            console.log(e);
            notify.show(`${t('global.anErrorOccurred')}`, 'error');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [itemId, t]);

    useEffect(() => {
        getPlatforms().then(platforms => {
            setPlatforms(platforms);
        }).catch(e => {
            console.log(e);
            notify.show(`${t('global.anErrorOccurred')}`, 'error');
        });
    }, [t]);

    const setNewItemImage = async (newItemImage) => {
        try {
            setIsLoading(true);
            const defaultItemImage = await defineItemImage(itemId, newItemImage);
            setDefaultItemImage(defaultItemImage);
        } catch (e) {
            console.log(e);
            notify.show(`${t('global.anErrorOccurred')}`, 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const confirmRemoveAllImages = () => {
        const modalSettings = {
            title: `${t('item_image_platform_association.remove_all_images')} ?`,
            content: t('item_image_platform_association.remove_all_images_confirm_message'),
            onValidate: async () => {
                await removeAllImages();
                handleUltyModal(false);
            }
        };

        handleUltyModal(true, modalSettings);
    }

    const removeAllImages = async () => {
        try {
            setIsLoading(true);
            const promises = platforms.map(p => definePlatformItemImage(p, {id: itemId}, null));
            promises.push(removeItemImages(itemId));
            await Promise.all(promises);
            setDefaultItemImage(squareItemImage);
        } catch(e) {
            console.log(e);
            notify.show(`${t('global.anErrorOccurred')}`, 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const handleDefineExpandImageModal = async (imageSrc) => {
        const modalSettings = {
            component: <div css={css`
            display: flex;
            justify-content: center;
`           }>
                <Image
                    src={imageSrc}
                    size='big'
                    bordered
                />
            </div>
        };

        handleUltyModalWrapper(true, modalSettings);
    }

    return (
        <div>
            <div css={css`display: flex; justify-content: flex-end`}>
                <Button
                    color='red'
                    disabled={isLoading}
                    onClick={confirmRemoveAllImages}
                >
                    <Icon name='trash'/>
                    {t('item_image_platform_association.remove_all_images')}
                </Button>
            </div>

            <Segment loading={isLoading} css={itemStyle}>
                <UltyInputText
                    readonly={true}
                    label={t('item_image_platform_association.default_image')}
                    value={
                        <Image
                            css={css`cursor: pointer;`}
                            src={defaultItemImage?.url || squareImg}
                            size='small'
                            bordered
                            onClick={() => handleDefineExpandImageModal(defaultItemImage?.url || squareImg)}
                        />}
                />
                <ItemImagePlatformAssociationActions
                    itemId={itemId}
                    initialImage={defaultItemImage?.url}
                    onAddImage={async (url) => {
                        await setNewItemImage({
                            url,
                            isDefault: true
                        });
                    }}
                    onPickImage={async (pickedImage) => {
                        await setNewItemImage({
                            ...pickedImage,
                            isDefault: true
                        });
                    }}
                />
            </Segment>

            {platforms.map(platform =>
                <ItemImagePlatformAssociationItem
                    key={platform.id}
                    defaultItemImage={defaultItemImage || { url: squareImg, isDefault: true }}
                    platform={platform}
                    itemId={itemId}
                    handleDefineExpandImageModal={handleDefineExpandImageModal}
                    isLoading={isLoading}
                />
            )}
        </div>
    )
}

export default ItemImagePlatformsAssociation;
