import React from 'react';
import PropTypes from 'prop-types';
import {Label} from 'semantic-ui-react';
import capitalize from '../../../helpers/capitalize';
import {useTranslation} from 'react-i18next';


const OrderStatusLabel = ({ status }) => {
    const [t] = useTranslation();

    const colors = {
        ACCEPTED: 'blue',
        CANCELLED: 'black',
        COMPLETED: 'green',
        DENIED: 'orange',
        EXPIRED: 'red',
        PREPARING: 'violet',
        READY: 'olive',
        RECEIVED: 'yellow'
    }

    return (
        <Label size='medium' color={colors[status]}>
            {capitalize(t(`order.status.${status}`))}
        </Label>
    )
}

OrderStatusLabel.propTypes = {
    status: PropTypes.oneOf([
        'RECEIVED',
        'DENIED',
        'ACCEPTED',
        'PREPARING',
        'READY',
        'COMPLETED',
        'CANCELLED',
        'EXPIRED'
    ]).isRequired,
}

export default OrderStatusLabel;
