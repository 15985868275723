import React, { useCallback, useState } from 'react';
import { downloadMetrics,downloadVatOrders } from '../../../services/Order/Order.service';
import PropTypes from 'prop-types';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { saveFile } from '../../../services/File/File.service';
import { useTranslation } from 'react-i18next';

import moment from 'moment'

const getDownloadDateFormat = (start,end) => {
  return `${moment(start).format('YYYY.DD.MM')}-${moment(end).format('YYYY.DD.MM')}`;
}

const Download = ({ state, dateRange, platforms, merchants, brands, status }) => {
  const [statsLoading, setStatsLoading] = useState(false);
  const [t] = useTranslation();

  const handleVatDownload = async () => {
    setStatsLoading(true);
    try {
      const { data } = await downloadVatOrders({
        merchants: typeof merchants === 'string' ? merchants : (merchants||[]).join(','),
        sd: dateRange.sd.valueOf(),
        ed: dateRange.ed.valueOf(),
      });
  
      saveFile(`${t('order.export.vat.export_orders_vat_file_name')}.${getDownloadDateFormat(dateRange.sd,dateRange.ed)}`, data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }
    finally{
      setStatsLoading(false);
    }
  }

  const handleMetrics = async () => {
    setStatsLoading(true);
    try {
      const { data } = await downloadMetrics({
      state,
      platform: (platforms||[]).join(','),
      merchants: typeof merchants === 'string' ? merchants : (merchants||[]).join(','),
      brands: (brands||[]).join(','),
      sd: dateRange.sd.valueOf(),
      ed: dateRange.ed.valueOf(),
      status,
    });

    saveFile(`${t('order.export.export_orders_file_name')}.${getDownloadDateFormat(dateRange.sd,dateRange.ed)}`, data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }
    finally{
      setStatsLoading(false);
    }
  }

  return (
    <Button.Group>
    <Button icon onClick={handleMetrics}  loading={statsLoading} disabled={statsLoading}>
      <Icon name="cloud download" />
    </Button>
      <Dropdown
         disabled={statsLoading}
          className='button icon'
          trigger={<></>}
      >
        <Dropdown.Menu>
        <Dropdown.Item
          key='vat'
          value='vat'
          text={t('order.export.vat.button_label')}
          onClick={handleVatDownload}
        />
</Dropdown.Menu>

      </Dropdown>
  </Button.Group>
    
  );
}

Download.propTypes = {
  state: PropTypes.string.isRequired,
  platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
  merchants: PropTypes.arrayOf(PropTypes.string).isRequired,
  brands: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateRange: PropTypes.shape({
    sd: PropTypes.object.isRequired,
    ed: PropTypes.object.isRequired
  }).isRequired
}

export default Download;