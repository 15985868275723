import {UltyDropdown} from '@ulty/ulty-ui';
import {useTranslation} from 'react-i18next';
import useFilters from '../useFilters';

const OrderStatusFilter = () => {
    const [t] = useTranslation();
    const { filters, setFilters, debounceFiltersChange } = useFilters();
    const status = [{
        text: t('order.status.DENIED'),
        key: 'DENIED',
        value: 'DENIED'
    }, {
        text: t('order.status.CANCELLED'),
        key: 'CANCELLED',
        value: 'CANCELLED'
    }, {
        text: t('order.status.EXPIRED'),
        key: 'EXPIRED',
        value: 'EXPIRED'
    }]

    const handleSelectedOrderStatusChange = (selectedOrderStatus) => {
        const newFilters = {
            ...filters,
            orderStatus: selectedOrderStatus
        };

        setFilters(newFilters);
        debounceFiltersChange(newFilters);
    }

    return (
        <UltyDropdown
            label={t('order.status.status')}
            options={status}
            loading={false}
            search
            multiple
            value={filters.orderStatus}
            noResultsMessage={t('ulty_dropdown.no_result_message')}
            onChange={(e, dp) => handleSelectedOrderStatusChange(dp.value)}
        />
    )
}

export default OrderStatusFilter;
