export const getPropertyValue = (object, property) => {
    return property
        .split('.')
        .reduce((carry, value) => (carry && carry[value]), object);
}

export const wrapValueInObject = (baseObject, propertyPath, value) => {
    const reversedPath = propertyPath.split('.').reverse();
    const wrapPathInObject = ([head, ...tail], object) => {
        if(!head) { return object; }
        const isFirstPath = head === reversedPath[0];
        const newObject = isFirstPath ? {[head]: value} : {[head]: {...object}};
        return { ...baseObject, ...wrapPathInObject(tail, newObject) };
    }
    return wrapPathInObject(reversedPath, baseObject);
}
