/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {Card, Form, Grid, Segment} from 'semantic-ui-react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import {getMissedMetrics} from '../../services/Metrics/Metrics.service';
import HeaderChart from './HeaderChart';
import { useTranslation } from 'react-i18next';
import useBreadcrumb from '../NavBar/useBreadcrumb';
import AdvancedFilters from './AdvancedFilters';
import useUser from '../Shared/UserProvider/useUser';
import PercentChart from './PercentChart';
import FailureHeaderChart from './FailureHeaderChart';
import PosHours from './PosHours';
import { getCancellationSource } from '../../services/http-client';


const Failures = ({ handleFiltersChange, defaultFilters }) => {
  const [t] = useTranslation();
  const { setPaths } = useBreadcrumb();
  const [loading, setLoading] = useState(false);

  const [dataNbOrders, setDataNbOrders] = useState([]);
  const [dataNbOrdersByType, setDataNbOrdersByType] = useState([]);
  const [dataRevenues, setDataRevenues] = useState([]);
  const [dataDaily, setDataDaily] = useState([]);

  const {user} = useUser();

  const filters = useMemo(() => {

    const dateRange = {
      startDate: moment(defaultFilters.dateRange.startDate).utc(),
      endDate: moment(defaultFilters.dateRange.endDate).utc()
    };

    return {
      dateRange :{
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString()
      },
      merchantId: defaultFilters.merchantId,
      brandIds: defaultFilters.brandIds,
      platformIds: defaultFilters.platformIds
    }
},[defaultFilters.dateRange.startDate, defaultFilters.dateRange.endDate, defaultFilters.merchantId, defaultFilters.brandIds, defaultFilters.platformIds])

  const loadMetrics = useCallback(async (cancellationSource) => {
    setLoading(true);

    const data = await getMissedMetrics({
      dateRange: filters.dateRange,
      merchantId: filters.merchantId,
      brandIds: filters.brandIds,
      platformIds: filters.platformIds
    },cancellationSource);

    if(cancellationSource?.isCancelled) return;

    setDataNbOrdersByType(data.nbOrdersByType)
    setDataNbOrders(data.nbOrders)
    setDataRevenues(data.revenues)
    setDataDaily(data.byDate)
    setLoading(false);

  }, [filters]);

  useEffect(() => {
    setPaths([{
      text: t('breadcrumb.home'),
      link: false,
      path: '/'
    }]);
  }, [setPaths, t]);

  useEffect(() => {
    const cancellationSource = getCancellationSource();
    loadMetrics(cancellationSource);
    
    return () => cancellationSource.cancel();
  }, [loadMetrics]);

  const getTotal = () => {
    return +dataRevenues.reduce((total, dataRevenue) => total + dataRevenue.y, 0);
  }

  const chartContainerStyle = css`
    padding-left: 0 !important;
    padding-right: 0 !important;
  `;

  return (
      <React.Fragment>
        <div css={css`
          display: flex;
          justify-content: space-between;
        `}>
          <AdvancedFilters
            defaultFilters={{
              ...defaultFilters,
              dateRange: {
                startDate: moment(defaultFilters.dateRange.startDate).utc(),
                endDate: moment(defaultFilters.dateRange.endDate).utc()
              },
            }}
            onFiltersChange={handleFiltersChange}>
            <AdvancedFilters.DateRangeFilter />
            <Form css={css` display: flex;`}>
              <Form.Group widths="equal">
                {user.provider.type === 'COMPANY' && <AdvancedFilters.PosFilter />}
                <AdvancedFilters.BrandFilter />
                <AdvancedFilters.PlatformFilter />
              </Form.Group>
            </Form>
          </AdvancedFilters>
        </div>
        <Segment loading={loading} basic>
          <Card.Group centered itemsPerRow={2} stackable>
            <FailureHeaderChart textColor="#bc544b"  type="CURRENCY" data={dataRevenues} total={getTotal()} loading={loading} />
            <HeaderChart textColor="#bc544b" color="#bc544b" type="NUMERIC" label={t('home.number_order')} data={dataNbOrdersByType.map(ty => ({
              x: t(`home.status.${ty.status}`),
              y: ty.count
            }))} total={dataNbOrders.reduce((total, dataNbOrder) => total + dataNbOrder.y, 0)} loading={loading} />
          </Card.Group>

          <Grid css={chartContainerStyle}>
            <Grid.Column width={16}>
              <PercentChart loading={loading} data={dataDaily} colors={[ "#45b29d", "#420c09" ]} />
            </Grid.Column>
          </Grid>

          <Grid css={chartContainerStyle}>
            <Grid.Column width={16}>
              <PosHours loading={loading} filters={filters} colors={[ "#45b29d", "#420c09" ]} />
            </Grid.Column>
          </Grid>
        </Segment>
      </React.Fragment>
  )
}

export default Failures;
