/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react'
import i18next from 'i18next';
import { sendStackTrace, stackTraceFromError } from '../services/Logger/Logger.service'

export default class ErrorBoundary extends Component {
    state = {
        error: '',
        errorInfo: '',
        hasError: false,
    };

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    async componentDidCatch(error, errorInfo) {
        try {
            const stackFrames = (await stackTraceFromError(error)).slice(0, 5)
            const errorMessage = {
                type: 'React boundary',
                url: window.location.href,
                userId: window.userId,
                agent: window.navigator.userAgent,
                date: new Date(),
                msg: error.toString()
            }

            await sendStackTrace(stackFrames, errorMessage)
        } catch(e) {}
        try {
            const token = localStorage.getItem('auth-token');
            if (!token) {
                window.location.reload();
            }
        } catch(e) {}
    }

    render() {
        const { hasError } = this.state;
        if (hasError) {
            return (
                <div css={css`
                  height: 100%;
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;

                  * {
                    color: #000 !important;
                  }

                  a {
                    cursor: pointer !important;
                  }
                `}>
                    <div>
                        <Image css={css`
                          img {
                            max-width: 200px;
                          }
                        `} src="/img/logo.png" />

                        <div style={{ textAlign: 'center', color: 'white' }}>
                            <span style={{ fontWeight: 'bold', fontSize: 18 }}>{i18next.t('global.anErrorOccurred')}</span>

                            <div style={{ paddingTop: 40, textAlign: 'center', color: 'white' }}>
                                <a style={{ color: 'white', fontSize: 18 }} onClick={() => {
                                    window.location.reload()
                                }}>{i18next.t('global.click_to_reload')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
