/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';

import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Icon, Message, Image} from 'semantic-ui-react';
import { MOBILE_THRESHOLD } from '../../../constants';

const OrderDelivery = ({ delivery }) => {
  const [t] = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
  const [imageValid, setImageValid] = useState(false);

  return (
    <Message icon compact css={css`
      img {
        display: ${imageValid ? 'block' : 'none'} !important;
        width: 60px !important;
      }
    `}>
      { !isMobile ? <Icon name='shipping fast' /> : <Image src={delivery.pictureUrl} circular size='tiny' onLoad={() => {
        setImageValid(true);
      }} /> }
      <Message.Content css={css`
        display: flex !important;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
      `}>
        <div css={css`
          display: flex;
          flex-direction: ${isMobile ? 'column' : 'row'};
          flex: 1;
        `}>
          <div css={css`
            flex: 1;
          `}>
            <Message.Header css={css`
              text-align: center;
            `}>{t(`order.delivery.${delivery.status}`)}</Message.Header>
            <div css={css`
              font-weight: bold;
              text-align: center;
              font-size: 1.14285714em;
            `}>
              {delivery?.name && delivery.name}
            </div>
            <div css={css`
              font-weight: bold;
              text-align: center;
              font-size: 1em;
            `}>
              {delivery?.phone && (<span>{delivery.phone} ({delivery.phoneCode})</span>)}
            </div>
          </div>
          { !isMobile && <Image src={delivery.pictureUrl} circular size='tiny' onLoad={() => {
            setImageValid(true);
          }} /> }
        </div>
      </Message.Content>
    </Message>
  );
};

OrderDelivery.propTypes = {
  delivery: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    phoneCode: PropTypes.string,
    pictureUrl: PropTypes.string,
    status: PropTypes.string.isRequired,
  }).isRequired,
}

export default OrderDelivery;