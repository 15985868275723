import {AdvancedFiltersContext} from './AdvancedFiltersContext';
import {useContext} from 'react';

const useFilters = () => {
    const context = useContext(AdvancedFiltersContext);
    if (context === undefined) {
        throw new Error(`useFilters must be used within an AdvancedFiltersContext`)
    }
    return context
}

export default useFilters;
