/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useContext, useEffect, useState } from 'react';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import {
  Container,
  Dropdown,
  Image,
  Menu,
  Icon,
} from 'semantic-ui-react'
import { UltyModalWrapperContext } from '../Shared/UltyModalWrapper/UltyModalWrapperContext';
import ProviderChoice from '../Auth/ProviderChoice';
import { Link } from 'react-router-dom'

import Avatar from 'react-avatar';

import 'tippy.js/dist/tippy.css';
import SyncDot from './SyncDot';
import OrderCount from './OrderCount';
import SyncIcon from './SyncIcon';

import { useTranslation } from 'react-i18next';
import { useSocket } from '../../services/socket'
import useUser from '../Shared/UserProvider/useUser';
import {AUTH_TOKEN, MOBILE_THRESHOLD, TABLET_THRESHOLD} from '../../constants';
import { isProviderAPos } from '../../services/Provider/Provider.service';
import { useQueryClient } from '@tanstack/react-query'

const NavBar = ({ history, location }) => {
  const socket = useSocket()
  const {user, setUser, can, landingRoute} = useUser();
  const [t] = useTranslation();
  const isMobileDevice = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
  const isTabletDevice = useMediaQuery(`(max-width: ${TABLET_THRESHOLD}px)`);
  const [hideSecondaryMenu,setHideSecondaryMenu] = useState(true);
  const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);
  const queryClient = useQueryClient()

  useEffect(() => {
    setHideSecondaryMenu(isMobileDevice || (user.isAdmin() && isTabletDevice));

  },[user,isMobileDevice,isTabletDevice])

  const defineProviderChoiceModal = (providers, email) => {
    const modalSettings = {
      centered: false,
      title: t('login.provider_choice'),
      component: <ProviderChoice
          providers={providers}
          email={email}
          afterSubmit={async (data) => {
            await queryClient.clear()
            localStorage.setItem(AUTH_TOKEN, data.token);
            setUser(data.user);

            history.push('/');

            handleUltyModalWrapper(false, null);
          }}
      />
    };

    handleUltyModalWrapper(true, modalSettings);
  };

  const getCatalogLinkQueryParams = () => {
      return isProviderAPos(user.provider) ? '?unavailableUntil=false' : '';
  }

  return (
    <Menu fixed='top' inverted css={css`
      height: 60px;
      z-index: 1000 !important;
    `}>
      <Container>
        { !isMobileDevice && (<Menu.Item as={Link} to={landingRoute()} header>
          <Image size='mini' src='/img/logo.png' style={{ marginRight: '1.5em' }} />
        </Menu.Item>) }
        {can('READ', 'metric') && <Menu.Item as={Link} to='/' active={location.pathname === '/'}>{t('navbar.home')}</Menu.Item>}
        <Menu.Item
            as={Link}
            to={user?.provider?.type === 'COMPANY' ? '/orders/finished' : '/orders/current'}
            active={location.pathname.indexOf('/orders') === 0}>
          {t('navbar.orders')}
        </Menu.Item>
        {!hideSecondaryMenu && can('STATIC', 'categories') && <Menu.Item as={Link} to='/categories' active={location.pathname.indexOf('/categories') === 0}>{t('navbar.categories')}</Menu.Item>}
        {(!isMobileDevice || !can('READ', 'metric')) && can('READ', 'product') &&
        <Menu.Item as={Link} to={`/products/${getCatalogLinkQueryParams()}`} active={location.pathname.indexOf('/products') === 0}>{t('navbar.products')}</Menu.Item>
        }
        { !hideSecondaryMenu && (<Menu.Item as={Link} to='/settings' active={location.pathname.indexOf('/settings') === 0}>{t('navbar.admin')}</Menu.Item>) }

        <Menu.Menu position='right'>
          {user?.provider?.type === 'COMPANY' ? (
            <SyncIcon />
          ) : (
              <>
                <OrderCount />
                <SyncIcon />
                <SyncDot />
              </>
            )}

          <Dropdown item trigger={
            !hideSecondaryMenu ? (
              <span>
                <Avatar name={`${user.firstname} ${user.lastname}`} round size={28} textSizeRatio={1} style={{ width: '28px', height: '28px' }} /> {`${user.firstname || ''} ${user.lastname || ''}`}
              </span>
            ) : (
              <Icon name="bars" />
            )
          } className="navbar-dropdown">
            <Dropdown.Menu>
              { hideSecondaryMenu && can('STATIC', 'categories') && (
                <Dropdown.Item as={Link} to='/categories'>{t('navbar.categories')}</Dropdown.Item>
              ) }
              { (isMobileDevice && can('READ', 'metric')) && can('READ', 'product') && (
                <Dropdown.Item as={Link} to={`/products/${getCatalogLinkQueryParams()}`}>{t('navbar.products')}</Dropdown.Item>
              ) }
              { hideSecondaryMenu && (
                <Dropdown.Item as={Link} to='/settings'>{t('navbar.admin')}</Dropdown.Item>
              ) }
              { (user?.providers||[]).length > 1 && (
                <Dropdown.Item onClick={() => {
                  defineProviderChoiceModal(user.providers, user.email);
                }}>{t('navbar.change_account')}</Dropdown.Item>
              )}
              <Dropdown.Item onClick={async () => {
                await socket.disconnect();
                history.push('/auth/login')
              }}>{t('navbar.disconnect')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Container>
    </Menu >
  )
}

export default NavBar
