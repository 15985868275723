/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React, { useCallback, useState } from 'react';

import { Segment, Dropdown } from 'semantic-ui-react';
import ApexCharts from 'apexcharts';
import moment from 'moment';

import Chart from "react-apexcharts";
import {useTranslation} from 'react-i18next';
import {unitFormat} from '../../helpers/money';
import useUser from '../Shared/UserProvider/useUser';
import {customTooltip} from './apexChartUtils';

const CurrentDayChart = ({ data }) => {
    const [t] = useTranslation();
    const {user} = useUser();
    const [chartType, setChartType] = useState("AGGREGATED");

    const getSeries = useCallback(() => {
        const resultsComparison = Object.fromEntries(data.filter(d => d.dt === 'PAST').map(d => [parseInt(d.hh), d]));
        const results = Object.fromEntries(data.filter(d => d.dt !== 'PAST').map(d => [parseInt(d.hh), d]));

        if (chartType === 'AGGREGATED') {
            [...Array(24).keys()].forEach(idx => {
                const prevComparison = (resultsComparison[idx - 1] || {});
                resultsComparison[idx] = {
                    nborder: parseInt(prevComparison?.nborder||0) + parseInt(resultsComparison[idx]?.nborder || 0),
                    revenues: parseInt(prevComparison?.revenues||0) + parseInt(resultsComparison[idx]?.revenues || 0),
                };

                const prev = (results[idx - 1] || {});
                results[idx] = {
                    nborder: parseInt(prev?.nborder||0) + parseInt(results[idx]?.nborder || 0),
                    revenues: parseInt(prev?.revenues||0) + parseInt(results[idx]?.revenues || 0),
                };
            });
        }

        const maxHour = (new Date()).getHours();
        return [{
            name: `${t('home.revenues')}`,
            data: [...Array(24).keys()].map(d => unitFormat(parseInt(parseFloat(resultsComparison[d]?.revenues || '0')), user.getCompanyCurrency())),
            dataType: 'CURRENCY',
            type: 'bar'
          }, {
            name: `${t('home.revenues')}-prev`,
            data: [...Array(24).keys()].map(d => unitFormat(parseInt(results[d]?.revenues || '0'), user.getCompanyCurrency())).slice(0, maxHour+1),
            dataType: 'CURRENCY',
            type: 'bar'
          }, {
            name: `${t('home.order_count')}`,
            data: [...Array(24).keys()].map(d => parseInt((resultsComparison[d]?.nborder||0))),
            dataType: 'NUMERIC',
            type: 'line'
          }, {
            name: `${t('home.order_count')}-prev`,
            data: [...Array(24).keys()].map(d => parseInt(results[d]?.nborder || 0)).slice(0, maxHour+1),
            dataType: 'NUMERIC',
            type: 'line',
          }];
    }, [data, chartType, user, t]);

    const comparaisonTooltip = (getTitle, w, dataPointIndex, getHtmlValue, disclaimer) => {
        const comparisonSeries = w.config.series.filter(serie => serie.data.length > 0);
        const htmlSeries = [];

        for (let i = 0; i < comparisonSeries.length; i += 2) {
            const markerColor = w.config.colors[i];

            const diff = parseFloat(((comparisonSeries?.[i + 1]?.data?.[dataPointIndex] || 0) - (comparisonSeries?.[i]?.data?.[dataPointIndex] || 0)).toFixed(2));
            const percent = (comparisonSeries?.[i]?.data?.[dataPointIndex] || 0) ? (diff / (comparisonSeries?.[i]?.data?.[dataPointIndex] || 0) * 100.0) : 'N/A';

            htmlSeries.push(`
            <tr>
                <td class="apexcharts-tooltip-series-group apexcharts-active" style="order: ${i}; display: flex">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${markerColor}"></span>
                    <span class="apexcharts-tooltip-text-y-label">${comparisonSeries[i].name}: </span>        
                </td>
                <td style="text-align: right;">
                    <span class="" style="font-weight: normal !important;">${getHtmlValue(comparisonSeries.map(serie => serie.data), i, comparisonSeries[i].dataType, '')}</span>        
                </td>
                <td style="text-align: right;">
                    <span class="apexcharts-tooltip-text-y-value">${getHtmlValue(comparisonSeries.map(serie => serie.data), i + 1, comparisonSeries[i + 1].dataType)}</span>        
                </td>
                <td>
                    <span class="apexcharts-tooltip-text-y-value" style="color: ${diff < 0 ? '#db2828' : '#21ba45'};"><i aria-hidden="true" class="${diff < 0 ? 'red caret down' : 'green caret up'} mini icon" style="top: 4px; font-size: 1.4rem !important;"></i>${isNaN(percent) ? 'N/A' : `${diff >= 0 ? '+' : ''}${percent.toFixed(2)}%`} </span>     
                </td>
            </tr>
            `);
        }

        return (`
            <div class="apexcharts-tooltip-title">${t('home.hour_activity')} - ${getTitle(comparisonSeries[0].data[dataPointIndex]?.[0])}</div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>${t('home.hour_activity_reference')}</th>
                        <th>${t('home.hour_activity_today')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    ${htmlSeries.join('')}
                </tbody>
            </table>
            ${disclaimer ? `<div style="display: flex; flex: 1; flex-direction: row; align-items: flex-start; justify-content: flex-start; padding: .6rem; max-width: 450px; word-wrap: break-word; white-space: normal;"><i aria-hidden="true" class="info circle icon" style="font-size: 1rem !important;"></i><p>${disclaimer}</p></div>` : ''}
        `);
    }

    return (
        <React.Fragment>
            <Segment basic css={css`
                max-height: 300px;

                .ui.dropdown {
                    position: absolute;
                    right: 4em;
                    z-index: 100;
                    top: 1em;
                }
                
                .apexcharts-legend-series {
                    cursor: pointer !important;
                }
                
                div.apexcharts-legend-series[rel="2"], div.apexcharts-legend-series[rel="4"] {
                    display: none;
                }
            `}>
                <Dropdown
                        options={[{
                            text: t('home.hourly'),
                            value: 'HOURLY',
                            key: 'HOURLY'
                        }, {
                            text: t(`home.hourly_aggregated`),
                            value: 'AGGREGATED',
                            key: 'AGGREGATED'
                        }]}
                        value={chartType}
                        onChange={(evt, { value }) => setChartType(value)}
                    />
                <Chart
                    options={{
                        dataLabels: {
                            enabled: false
                        },
                        chart: {
                            zoom: {
                                enabled: false
                            },
                            id: 'chart-today',
                            events: {
                                mounted: function (chartContext, config) {
                                    ApexCharts.exec(`chart-today`, "toggleSeries", `${t('home.order_count')}`);
                                    ApexCharts.exec(`chart-today`, "toggleSeries", `${t('home.order_count')}-prev`);
                                },
                                legendClick: (chartContext, seriesIndex, config) => {
                                    if (seriesIndex === 0) {
                                        ApexCharts.exec(`chart-today`, "toggleSeries", `${t('home.revenues')}`);
                                        ApexCharts.exec(`chart-today`, "toggleSeries", `${t('home.revenues')}-prev`);
                                    } else {
                                        ApexCharts.exec(`chart-today`, "toggleSeries", `${t('home.order_count')}`);
                                        ApexCharts.exec(`chart-today`, "toggleSeries", `${t('home.order_count')}-prev`);
                                    }
                                }
                            }
                        },
                        xaxis: {
                            categories: [...Array(24).keys()].map(d => `${("00" + d).slice(-2)}h`)
                        },
                        stroke: {
                            colors: ['transparent', 'transparent', '#334d5c', '#334d5c'],
                            width: [4, 4, 3, 3],
                            dashArray: [0, 0, 4, 0]
                        },
                        fill: {
                            type: ['pattern', 'color', 'color', 'color'],
                            pattern: {
                                style: 'slantedLines',
                                width: 5,
                                height: 5,
                                strokeWidth: 1
                            }
                        },
                        legend: {
                            show: true,
                            markers: {
                                fillColors: ['#45b29d', '#45b29d', '#334d5c', '#334d5c'],
                                onClick: (chartContext, seriesIndex, config) => {
                                    if (seriesIndex === 0) {
                                        ApexCharts.exec(`chart-today`, "toggleSeries", `${t('home.order_count')}`)
                                        ApexCharts.exec(`chart-today`, "toggleSeries", `${t('home.order_count')}-prev`)
                                    } else {
                                        ApexCharts.exec(`chart-today`, "toggleSeries", `${t('home.revenues')}`)
                                        ApexCharts.exec(`chart-today`, "toggleSeries", `${t('home.revenues')}-prev`)
                                    }
                                }
                            },
                            onItemClick: {
                                toggleDataSeries: false,
                            },
                            onItemHover: {
                                highlightDataSeries: false
                            },
                        },
                        tooltip: {
                            shared: true,
                            intersect: false,
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                return customTooltip({ t, series, seriesIndex, dataPointIndex, w, currencyCode: user.getCompanyCurrency(), locale: user.getCompanyLocale(), isComparison: comparaisonTooltip, disclaimer: t('home.hour_activity_reference_explain') });
                            }
                        },
                        colors: ['#096b58', '#45b29d', '#334d5c', '#334d5c'],
                        yaxis: [
                            {
                                title: {
                                    text: t('home.revenues')
                                }
                            },
                            {
                                seriesName: `${t('home.revenues')}`,
                                show: false,
                            },
                            {
                                opposite: true,
                                labels: {
                                    formatter: function (val) {
                                        return isNaN(val) ? val : val.toFixed(0)
                                    }
                                },
                                title: {
                                    text: t('home.order_count')
                                },
                            },
                            {
                                seriesName: `${t('home.order_count')}`,
                                show: false,
                                opposite: true
                            },
                        ],
                        title: {
                            text: t('home.today_activity'),
                            align: 'center'
                        },
                        subtitle: {
                        text: moment().format(moment.localeData().longDateFormat('L')),
                        align: 'center',
                        offsetY: 20
                        },
                    }}
                    series={getSeries()}
                    type="bar"
                    height={250}
                />

            </Segment>
        </React.Fragment>
    )
}

export default CurrentDayChart;
