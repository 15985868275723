/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import {
  Label,
  Icon,
} from 'semantic-ui-react'

import OrderStore from '../Orders/OrderStore'

import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const OrderCount = () => {
  const orderStore = OrderStore.useContainer()

  useEffect(() => {
    orderStore.reload()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      orderStore.reload()
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div css={css`
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: .6em;
      position: relative;
    `}>
      {orderStore.orders && orderStore.orders.length > 0 ? (
        <Link as="div" css={css` position: relative; padding: 5px; cursor: pointer;`} to='/orders/current'>
          <Icon name='shipping fast' style={{ color: "white" }} />
          <Label color='red' floating circular size="mini">
            {orderStore.orders.length}
          </Label>
        </Link>
      ) : (
          <div css={css` position: relative; padding: 5px; `}>
            <Icon name='shipping fast' style={{ color: "white" }} disabled />
          </div>
        )}
    </div>
  )
}

export default OrderCount
