/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import React from 'react'
import { Segment, Form, Input, Button, Container, Grid, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useState, useRef } from 'react'
import {notify} from 'react-notify-toast';
import { forgotPassword } from '../../helpers/api';
import { RECAPTCHA_KEY } from '../../constants';
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const [t] = useTranslation();
  const recaptcha = useRef()

  const [email, setEmail] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState('FORGOT')

  const handleChange = (_, { name, value }) => {
    if (name === 'email') {
      setEmail(value)
    }
  }

  const handleSubmit = async () => {
    notify.hide()

    if (email === '') {
      notify.show(t('login.missing_email'), 'error')
      return
    }

    setLoading(true)

    let res = await forgotPassword(email, captcha)
    if (res.status === 'SUCCESS') {
      setStep('VALID')

      return
    } else if(res.error === 'CAPTCHA') {
      setLoading(false)
      notify.show(t('login.invalid_captcha'), 'error')
    } else {
      setLoading(false)
      notify.show(t('global.anErrorOccurred'), 'error')
    }

    recaptcha.current.reset()
  }

  return (
    <Container css={css`
      margin-top: 6rem !important
    `}>
      <Grid textAlign="center">
        <Grid.Column computer={6} tablet={8} mobile={16}>
          <Segment raised textAlign="left">
            <Form>
              <Grid>
                <Grid.Column width={16} css={css` 
                  background-color: #009c95 !important;
                  padding: 1.5rem !important;
                  color: #ffffff !important;
                `}>
                  <div css={css` font-weight: bold; text-align: center; `}>{t('login.welcome')}</div>
                  <div css={css` text-align: center; `}>{t('login.message')}</div>
                </Grid.Column>

                { step === 'FORGOT' && (
                  <React.Fragment>
                    <Grid.Column width={16} css={css` 
                      padding: 2rem !important;
                    `}>
                      <Form.Field
                        control={Input}
                        name="email"
                        value={email}
                        onChange={handleChange}
                        label={t('login.email')}
                        placeholder={t('login.email')}
                      />
                    </Grid.Column>

                    <Grid.Column width={16} textAlign="center" css={css` 
                      padding: 2rem !important;
                      padding-top: 0 !important;

                      &>div>div>div {
                        margin: auto;
                      }
                    `}>
                      <ReCAPTCHA
                        ref={recaptcha}
                        sitekey={RECAPTCHA_KEY}
                        onChange={(value) => setCaptcha(value)}
                      />
                    </Grid.Column>
                    <Grid.Column width={16} css={css` 
                      padding: 2rem !important;
                      padding-top: 0 !important;
                    `}>
                      <Button fluid color="teal" type='submit' loading={loading} disabled={loading} onClick={handleSubmit}>{t('login.forgot_email')}</Button>
                    </Grid.Column>
                  </React.Fragment>
                )}

                { step === 'VALID' && (
                  <React.Fragment>
                    <Grid.Column width={16} textAlign="center" css={css` 
                      padding: 2rem !important;

                      &>div>div>div {
                        margin: auto;
                      }
                    `}>
                      {t('login.forgot_confirm')}
                    </Grid.Column>
                  </React.Fragment>
                )}

                <Grid.Column width={16} textAlign="center" css={css` 
                  padding-top: 0 !important;
                  color: #74788d !important
                `}>
                  <Link to='/auth/login'><Icon name="lock" /> {t('login.login')}</Link>
                </Grid.Column>
              </Grid>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>

    </Container>
  )
}

export default ForgotPassword
