import React, {useState, useMemo, useEffect} from 'react';

import { Segment, Button, Dropdown, Icon, Placeholder, PlaceholderImage,Label } from 'semantic-ui-react';

import Chart from "react-apexcharts";
import {useTranslation} from 'react-i18next';
import useUser from '../Shared/UserProvider/useUser';
import {customTooltip} from './apexChartUtils';
import {getMissedMetricsAnalysis} from '../../services/Metrics/Metrics.service';
import { CSVLink } from 'react-csv';
import {format} from 'date-fns'
import { getCancellationSource } from '../../services/http-client';

const getLabelFromCompletionRate = (completionRate) => {

    const prettyValue = `${Math.round(completionRate*100)}%`;
    const color = completionRate < 0.7 ? "red"
                    : completionRate < 0.9 ? "orange"
                    : completionRate < 0.97 ? "yellow"
                    : "green";

    return {color,content:prettyValue}

}

const formatHourDecimalToStr = (hour) => (`${("00" + hour).slice(-2)}h`)


const PosMissingChart = ({posData, dateRange}) => {

    const [t] = useTranslation();
    const {user} = useUser();
    const label = getLabelFromCompletionRate(posData.completionRate)

    return (
    <Segment key={posData.id}
    style={{
        maxHeight: '300px'
    }}>
        <Label as='a' color={label.color} ribbon>
          {label.content}
        </Label>
        <Chart
            options={{
                dataLabels: {
                    enabled: false
                },
                chart: {
                    type:"line",
                    height: 350,
                    stacked: true,
                    zoom: {
                        enabled: false
                    },
                },
                xaxis: {
                    categories: posData.hours.map(d => formatHourDecimalToStr(d.h))
                },
               
                tooltip: {
                    shared: true,
                    intersect: false,
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return customTooltip({ series, seriesIndex, dataPointIndex, w, locale: user.getCompanyLocale() });
                    }
                },
                stroke: {
                    colors: ['transparent', 'transparent', '#334d5c'],
                    width: [4, 4, 3],
                    dashArray: [0, 0, 4],
                  },  
                  fill: {
                    type: ['pattern', 'pattern', 'color'],
                    pattern: {
                      style: 'slantedLines',
                      width: 5,
                      height: 5,
                      strokeWidth: 1
                    }
                  }, 
                colors: [ 
                    //'#45b29d', //Completed
                    "#c91313", // Expired
                    '#c96e13', // Denied
                    // '#c9c913', // Canceled
                    '#000'],
                yaxis: [
                    {
                        min: 0,
                        max: Math.max(...posData.hours.map(t => t.cExpired+t.cDenied)),
                        title: {
                            text: t('home.missed_analysis.missed')
                        }
                    },
                    // {
                    //     seriesName: t('home.missed_analysis.missed'),
                    //     show: false,
                    // },
                    {
                        seriesName: t('home.missed_analysis.missed'),
                        show: false,
                    },
                    // {
                    //     seriesName: t('home.missed_analysis.missed'),
                    //     show: false,
                    // },
                    {
                        opposite: true,
                        forceNiceScale: true,
                        min: 0,
                        max: Math.max(...posData.hours.map(t => t.orderCpt)),
                        title: {
                            text: t('home.missed_analysis.total')
                        }
                    }
                   
                ],
                title: {
                    text: posData.name,
                    align: 'center'
                },
                subtitle: {
                    text: t('home.missed_analysis.completion_rate_on_period',{
                        completionRate: getLabelFromCompletionRate(posData.completionRate).content ,
                        from: format(new Date(dateRange.startDate),"dd-MM-YYY"),
                         to: format(new Date(dateRange.endDate),"dd-MM-YYY")}),
                    align: 'center'
                }
            }}
            series={[ 
                /*
                {
                name: 'Completed',
                data: posData.hours.map(da => da.cCompleted),
                type:"bar",
                dataType: 'NUMERIC'

            },*/
            {
                name: t('home.status.EXPIRED'),
                data: posData.hours.map(da => da.cExpired),
                type:"bar",
                dataType: 'NUMERIC'
            }, {
                name: t('home.status.DENIED'),
                data: posData.hours.map(da => da.cDenied),
                type:"bar",
                dataType: 'NUMERIC'

            },
            // {
            //     name: 'Canceled',
            //     data: posData.hours.map(da => da.cCanceled),
            //     type:"bar",
            //     dataType: 'NUMERIC'

            // },
             {
                name: t('home.missed_analysis.total'),
                data: posData.hours.map(da => da.orderCpt),
                dataType: 'NUMERIC',
                type:"line"

            }]}
            type="line"
            height={250}
        />
    </Segment>
)
}


const PosMissedAnalysisExport = ({posData,isLoading}) => {
    const [dataToExport,setDataToExport] = useState([]);
    const [t] = useTranslation();

    const headers = ["Store", t('home.missed_analysis.completion_rate')].concat([...Array(24)].flatMap((_,hour) => {
            const hourStr = formatHourDecimalToStr(hour);
            return [
                `${hourStr} ${t("home.status.EXPIRED")}`,
                `${hourStr} ${t("home.status.DENIED")}`,
                `${hourStr} ${t("home.missed_analysis.total")}`,
            ]
        }));

    useEffect(() => {
        const data = posData.map(pos => [pos.name,pos.completionRate.toFixed(2)].concat(pos.hours.flatMap(h => [
                h.cExpired,
                h.cDenied,
                h.orderCpt
            ])));
        
            setDataToExport([headers,...data]);
    },[posData])

    return   (
    <CSVLink
       className="csv-btn"
       enclosingCharacter={'"'}
       separator=";"
       data={dataToExport}
       filename="export-deployments.csv"
     >
       <Button icon  loading={isLoading}>
         <Icon name="cloud download" />
       </Button>
     </CSVLink>)
   

}

const PosHours = ({ filters }) => {
    const numberDisplayed = 5;
    
    const [t] = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [currentPage,setCurrentPage] = useState(0);
    const [data,setData] = useState([]);
    const [availablePos,setAvailablePos] = useState([]);
    const [posDisplayFilter,setPosDisplayFilter] = useState([]);

    const [displayDataCache,setDisplayDataCache] = useState(new Map());


    useEffect(() => {

        const cancellationSource = getCancellationSource();

        const loader = async () => {
            setLoading(true);
            setCurrentPage(0)
            
            const res = await getMissedMetricsAnalysis({
                dateRange: filters.dateRange,
                merchantId: filters.merchantId,
                brandsIds: filters.brandIds,
                platformIds:filters.platformIds
            },cancellationSource);
    
            const posData = res?.posData ?? [];
    
            setAvailablePos(posData.map(pos => ({
                key: pos.id,
                value: pos.id,
                label: getLabelFromCompletionRate(pos.completionRate),
                text: pos.name
            })));

            const cache = new Map();
            for(const pos of posData){
                cache.set(pos.id,(<PosMissingChart key={pos.id} posData={pos} dateRange={filters.dateRange}></PosMissingChart>))
            };

            setData(posData);

            setDisplayDataCache(cache);
    
            setLoading(false);
        };
        loader();

    },[filters])
   
    const displayedData = useMemo(() => {
        
        let eligiblePos = posDisplayFilter.length ? availablePos.filter(pos => posDisplayFilter.includes(pos.key)) : availablePos;

        if(eligiblePos?.length > numberDisplayed){
            eligiblePos = eligiblePos.slice(currentPage*numberDisplayed,((currentPage+1)*numberDisplayed)-1);
        }

        return eligiblePos.map(pos => displayDataCache.get(pos.key));
    },[displayDataCache,currentPage,posDisplayFilter,availablePos]);


    return (
        <>
        {!filters?.merchantId && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button loading={isLoading} disabled={!currentPage || posDisplayFilter.length > 0} onClick={() => setCurrentPage((cur)=>--cur)}  >
            {t('home.missed_analysis.previous')}
            </Button>
            <Dropdown
                style={{ margin: '0 10px' }}
                clearable
                fluid
                selection
                multiple
                search
                placeholder={t('home.missed_analysis.search_placeholder')}
                loading={isLoading}
                options={availablePos}
                closeOnChange
                lazyLoad
                scrolling
                onChange={(e, { value }) => setPosDisplayFilter(value) }
            ></Dropdown>
            <Button loading={isLoading} disabled={((currentPage+1)*numberDisplayed > availablePos.length)  || posDisplayFilter.length > 0} onClick={() => setCurrentPage((cur)=>++cur)}  >
                {t('home.missed_analysis.next')}
            </Button>
        
            <PosMissedAnalysisExport isLoading={isLoading} posData={data}/>
            </div>)
        }
        
         <Segment loading={displayedData.length > 0 && isLoading}>
         {displayedData.length > 0 && displayedData}
         {displayedData.length === 0 && (
         <Placeholder fluid>
            <PlaceholderImage rectangular />
         </Placeholder>)}
         </Segment>
           

        </>
       
    )
}

export default PosHours
