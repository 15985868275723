import React, { useState } from 'react'
import { downloadMetrics } from '../../services/Metrics/Metrics.service';
import {notify} from 'react-notify-toast';

import { Button, Icon } from 'semantic-ui-react'
import { saveFile } from '../../services/File/File.service';
import { useTranslation } from 'react-i18next';

const DownloadMetrics = ({ dateRange, brands, merchantId, platformIds, type, ...props }) => {
  const [t] = useTranslation();
  const [statsLoading, setStatsLoading] = useState(false)

  const handleMetrics = async () => {
    try {
      setStatsLoading(true)
        const {data} = await downloadMetrics(dateRange, merchantId, brands, platformIds, type);
        saveFile(t('home.export'), data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    } catch (e) {
        console.log(e);
        notify.show(t('global.anErrorOccurred'), 'error');
    }
    setStatsLoading(false)
};

  return (
    <Button className={props.className} icon onClick={handleMetrics} loading={statsLoading} disabled={statsLoading}>
      <Icon name="cloud download" />
    </Button>
  )
}

export default DownloadMetrics
