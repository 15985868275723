import {createContext, useMemo, useState} from 'react';
import {debounce} from 'lodash';

const AdvancedFiltersContext = createContext({});
const AdvancedFiltersProvider  = ({children, defaultFilters, onFiltersChange}) => {
    const [filters, setFilters] = useState(defaultFilters);

    const debounceFiltersChange = useMemo(() =>
            debounce((filters) => onFiltersChange(filters), 600),
        [onFiltersChange]
    );

    return (
        <AdvancedFiltersContext.Provider value={{filters, setFilters, debounceFiltersChange}}>
            {children}
        </AdvancedFiltersContext.Provider>
    )
}

export { AdvancedFiltersContext, AdvancedFiltersProvider };
