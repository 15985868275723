import React, { useEffect, useState } from 'react';

import { Form, Tab, Dropdown, Segment } from 'semantic-ui-react';
import { getBrands } from '../../../services/Brand/Brand.service';
import { useTranslation } from 'react-i18next';
import BrandForm from './BrandForm';

const AdminBrands = () => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  useEffect(() => {
    loadBrands();
  }, []);

  const loadBrands = async () => {
    setLoading(true);
    getBrands().then((data) => {
      setBrands(data);
      setSelectedBrand(data?.[0]?.id || null);
      setLoading(false);
    });
  };

  return (
    <>
      <Tab.Pane>
        <Form>
          <Form.Field>
            <label>{t('global.brand')}</label>
            <Dropdown
              fluid
              selection
              loading={loading}
              name="brands"
              value={selectedBrand}
              onChange={(evt, { value }) => setSelectedBrand(value)}
              options={brands.map(dm => ({
                text: dm.name,
                value: dm.id,
                key: dm.id
              }))}
            />
          </Form.Field>

          { selectedBrand && (
            <BrandForm key={selectedBrand} brand={brands.find(b => b.id === selectedBrand)} reloadBrands={loadBrands} />
          )}
        </Form>
      </Tab.Pane>
      <Segment>
        <Form>
          <Form.Field>
            <label>{t('administration.brands.addABrand')}</label>
          </Form.Field>
          <BrandForm key={"new"} brand={null} reloadBrands={loadBrands} />
        </Form>
      </Segment>
    </>
  )
}

export default AdminBrands;
