/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {UltyInputText, UltyMoney} from '@ulty/ulty-ui';
import {useTranslation} from 'react-i18next';
import {Checkbox, Dimmer, Divider, Form, Header, Icon, Loader} from 'semantic-ui-react'
import React, {useEffect, useState} from 'react';
import {isQuantityEnabled} from '../../../services/Provider/Provider.service';
import PropTypes from 'prop-types';
import useUser from '../../Shared/UserProvider/useUser';
import { MOBILE_THRESHOLD } from '../../../constants';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import PlatformPricesEdit from '../PlatformPrices/PlatformPricesEdit';


const InventoryEdit = ({type, inventory, isUncountable, isLoading, onChange, basePrice=null,pricesByPlatform=null}) => {
    const [t] = useTranslation();
    const {user} = useUser();
    const [customPrice, setCustomPrice] = useState(!!(inventory?.sellPrice));
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

    useEffect(() => {
        setCustomPrice(!!(inventory?.sellPrice));
    }, [inventory?.sellPrice]);

    return (
        <>
            <Dimmer.Dimmable dimmed={isLoading}>
                <Dimmer inverted active={isLoading} />
                <Loader active={isLoading} />
                <Divider horizontal><Header as='h4' color='teal'><Icon name='warehouse' />{t('item_edit.inventory.inventory')}</Header></Divider>

                {(type === 'PRODUCT' || type === 'MENU') &&
                    <>
                        <Form.Field>
                            <Checkbox
                                toggle
                                checked={!!customPrice}
                                label={t('item_edit.inventory.sellPriceToggle')}
                                onChange={(evt, {checked}) => {
                                    setCustomPrice(checked);
                                    onChange({name: 'sellPrice', value: null})
                                }}
                            />
                        </Form.Field>

                        {customPrice && (
                            <UltyMoney
                                name="sellPrice"
                                label={t('item_edit.inventory.sellPrice')}
                                amount={+inventory?.sellPrice}
                                onMoneyAmountChange={(e, amt) => onChange({name: "sellPrice", value: amt})}
                                currencyCode={user.getCompanyCurrency()}
                                locale={user.getCompanyLocale()}
                            />
                        )}

                        <PlatformPricesEdit
                                isLoading={isLoading}
                                basePrice={basePrice}
                                pricesByPlatform={pricesByPlatform}
                                onChange={onChange.bind(this)}
                            />
                    </>
                }

                {(type === 'PRODUCT' || type === 'EXTRA') &&
                    <span css={css`
                  display: flex;
                  align-items: center;
                  column-gap: 1rem;

                  ${isMobile && `
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;
                    margin-bottom: 1em;
                  `}
                `}>

                {isQuantityEnabled(inventory?.unavailableUntil, isUncountable) &&
                    <UltyInputText
                        type="number"
                        name="quantity"
                        value={inventory?.quantity !== null ? inventory?.quantity : ''}
                        onChange={e => onChange(e.target)}
                        label={t('item_edit.inventory.quantity')}
                        placeholder={t('item_edit.inventory.quantity')}
                    />}
            </span>
                }

                <UltyInputText
                    name="externalId"
                    value={inventory?.externalId}
                    onChange={e => onChange(e.target)}
                    label={t('item_edit.inventory.externalId')}
                    placeholder={t('item_edit.inventory.externalId')}
                />

            </Dimmer.Dimmable>
        </>
    )
}

InventoryEdit.propTypes = {
    type: PropTypes.oneOf(['PRODUCT', 'MENU', 'EXTRA']),
    inventory: PropTypes.object,
    onChange: PropTypes.func
}

export default InventoryEdit;
