/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback } from 'react';

import { Breadcrumb, Label } from 'semantic-ui-react';

import { Link } from 'react-router-dom'
import useUser from '../Shared/UserProvider/useUser'
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import useBreadcrumb from './useBreadcrumb';
import { MOBILE_THRESHOLD } from '../../constants';

const NavBreadcrumb = ({ location }) => {
  const { user } = useUser();
  const { paths } = useBreadcrumb();
  const [t] = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

  const ProviderString = useCallback(() => {
    if (!user) {
      return (<span/>);
    }

    const [provider] = (user?.providers || []).filter(p => p.id === user?.provider?.id);
    if (provider) {
      return (
        <React.Fragment>
          <span css={css`margin-right: 10px;`}>
            {`${provider.object.name}`}
          </span>
          <Label
            size="tiny" color={provider.type === 'COMPANY' ? 'blue' : 'green'}>{t(`provider.type_${provider.type}`)}</Label>
        </React.Fragment>
      );
    }
    return (<span/>);
  }, [user.provider, user.providers, t]);

  return (
    <div css={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}>
      {!isMobile && (
        <React.Fragment>
          <div>
            <Breadcrumb>
              {paths.map(p => ({
                path: p.path,
                link: p.link,
                text: p.text
              })).map((b, i) => {
                return (
                  <span key={`${location.pathname}-${i}`}>
                    {b.path !== '/' ? (<Breadcrumb.Divider>/</Breadcrumb.Divider>) : ''}
                    <Breadcrumb.Section link={b.link} as={b.link ? Link : 'div'} to={b.path}>{b.text}</Breadcrumb.Section>
                  </span>
                )
              })}
            </Breadcrumb>
          </div>
          <div css={css`
            display: flex;
            align-items: center;
          `}>
            <ProviderString />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default NavBreadcrumb
