const rules = {
  power_user: {
    static: [
      'administration',
      'categories',
      "admin:cancelOrders",
    ],
    dynamic: {}
  },
  order_preparer: {
    static: [],
    dynamic: {}
  },
};

export default rules;
