import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'platform';

export const getPlatforms = async () => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX);
    } catch (e) {
        console.error(`getPlatforms error: ${e}`);
        throw e;
    }
}

export const definePlatformItemImage = async (platform, item, itemImage) => {
    return await call(METHODS.PUT, ROUTE_PREFIX, `${platform.id}/items/${item.id}/images`, {itemImage});
}

export const getPlatformItemImage = async (platform, itemId) => {
    return await call(METHODS.GET, ROUTE_PREFIX, `${platform}/items/${itemId}/images`);
}
