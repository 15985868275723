import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'auth';

export const login = async (email, password, captcha) => {
    try {
        return await call(METHODS.POST, ROUTE_PREFIX, `login`, { email, password, captcha });
    } catch (e) {
        console.error(`login error: ${e}`);
        throw e;
    }
}

export const loginToProvider = async (providerId, email) => {
    try {
        return await call(METHODS.POST, ROUTE_PREFIX, `login/provider`, { email, providerId });
    } catch (e) {
        console.error(`loginToProvider error: ${e}`);
        throw e;
    }
}