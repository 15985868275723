/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';

import {CalendarDay, SingleDatePicker} from 'react-dates';
import moment from 'moment';
import isBeforeDay from '../../../../helpers/isBeforeDay';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import useFilters from '../useFilters';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { MOBILE_THRESHOLD } from '../../../../constants';

const ComparedDateRangeFilter = () => {
    const [t] = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
    const { filters, setFilters, debounceFiltersChange } = useFilters();

    const range = filters.dateRange.endDate.diff(filters.dateRange.startDate, 'days');

    const [focused, setFocused] = useState(null);
    const [dates, setDates] = useState([])

    const handleOnDatesChange = ({ date }) => {
      const newFilters = {
        ...filters,
        dateComparison: {
          startDate: date ? moment.utc(date).startOf('day') : null,
          endDate: date ? moment.utc(date).add(range, 'days').endOf('day') : null
        }
      };

      setFilters(newFilters);
      debounceFiltersChange(newFilters);
    }

    return (
            <div
              css={css`
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                font-size: .9rem !important;
                * {
                  font-size: .9rem !important;
                }

                .SingleDatePicker_picker {
                  z-index: 99;
                }

                .SingleDatePickerInput, .DateInput_input__focused, .SingleDatePickerInput, .DateInput, .DateInput_input {
                  background-color: none !important;
                  background: none !important;
                }
                
                .DateInput__small {
                  width: 13em !important;
                }

                .CalendarDay__highlighted_calendar {
                  background: #ffe8bc !important;
                  color: #484848 !important;
                }

                ${dates.map(d => `.date-${d}`).join(',')} {
                  background: #66e2da;
                  border: 1px solid #66e2da;
                  color: inherit;
                }

                ${dates.map(d => `.date-${d}`).join(',')} {
                  background: #66e2da;
                  border: 1px solid #66e2da;
                  color: inherit;
                }
              `}>
              {t('home.compare_to')} <SingleDatePicker date={(filters?.dateComparison?.startDate?._isValid && filters.dateComparison.startDate) || null}
              onDateChange={date => handleOnDatesChange({date})}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              small
              placeholder={t('home.compare_nothing')}
              showClearDate
              noBorder
              block={false}
              displayFormat={filters.dateComparison.startDate ? `${filters.dateComparison.startDate.format(moment.localeData().longDateFormat('L'))} - ${filters.dateComparison.endDate.format(moment.localeData().longDateFormat('L'))}` : ''}
              showDefaultInputIcon={false}
              onClose={() => {}}
              hideKeyboardShortcutsPanel
              numberOfMonths={isMobile ? 1 : 2}
              enableOutsideDays={false}
              isOutsideRange={(d) => !isBeforeDay(d, moment().add(-1*range, 'day'))}
              initialVisibleMonth={() => moment().add(-1, 'month')}
              isDayHighlighted={(day) => {
                return day.utc().isBetween(filters.dateRange.startDate, filters.dateRange.endDate, undefined, '[]');
              }}
              renderCalendarDay={(props) => {
                return (
                    <CalendarDay
                      {...props}
                      renderDayContents={(day, modifiers) => (
                        <div
                          css={css`
                            height: 100%;
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `}
                          className={`date-${props.day && props.day.format('YYYYMMDD')}`}>{day.format('D')}</div>
                      )}
                      onDayMouseEnter={(a) => {
                        if (!isBeforeDay(a, moment().add(-1*range, 'day'))) {
                          setDates([]);
                          return;
                        }

                        const dt = [];
                        for(let i = 1; i <= range; i++) {
                          dt.push(a.clone().add(i, 'days').format('YYYYMMDD'));
                        }
                        setDates(dt);
                      }}
                    />
              )}}
              />
            </div>
    )
}

export default ComparedDateRangeFilter;
