/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {Card} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import useUser from '../Shared/UserProvider/useUser';
import {UltyMoney} from '@ulty/ulty-ui';

const crossedOutStyle = css`text-decoration: line-through; color: red !important;`;

const MenuCardContentExtra = ({ basePrice, inventory, color }) => {
    const {user} = useUser();

    return (
        <Card.Content extra css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 1rem;
          color: ${color};
        `}>
            <UltyMoney
                css={css`
                  margin: 0;
                  ${inventory?.sellPrice && crossedOutStyle}
                `}
                readonly={true}
                currencyCode={user.getCompanyCurrency()}
                locale={user.getCompanyLocale()}
                amount={basePrice}
            />

            {inventory?.sellPrice && (
                <UltyMoney
                    css={css`margin: 0;`}
                    readonly={true}
                    currencyCode={user.getCompanyCurrency()}
                    locale={user.getCompanyLocale()}
                    amount={inventory.sellPrice}
                />
            )}
        </Card.Content>
    )
}

MenuCardContentExtra.propTypes = {
    basePrice: PropTypes.number.isRequired,
    inventory: PropTypes.object,
    color: PropTypes.string
}

export default MenuCardContentExtra;
