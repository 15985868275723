/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {useEffect, useState} from 'react';
import {Dropdown, Checkbox} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {notify} from 'react-notify-toast';
import { getCategorySets } from '../../../services/Category/Category.service';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import { MOBILE_THRESHOLD } from '../../../constants';

const AdvancedMenuFilters = ({ filters, setFilters }) => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

    const [categoriesFilterOptions, setCategoriesFilterOptions] = useState([]);

    useEffect(() => {
        (async function loadCategories() {
            try {
                setIsLoading(true);
                const categories = await getCategorySets();
                setCategoriesFilterOptions(categories.map(set => ({
                    label: set.name,
                    value: set.id,
                    slots: set.slots.map(slot => ({
                        label: slot.name,
                        value: slot.id,
                        categories: slot.categories.map(category => ({
                            label: category.name,
                            value: category.id,
                        }))
                    })),
                })));
            } catch (e) {
                console.log(e);
                notify.show(`${t('global.anErrorOccurred')}`, 'error');
            } finally {
                setIsLoading(false);
            }
        })();
    }, [t]);

    return (
        <div
            css={css`
              display: flex;
              flex-direction: column;
              row-gap: .4rem;

              label {
                  font-weight: bold;
              }

              .ui.selection.dropdown {
                  line-height: 1.1em;
              }

              .ui.form.tiny {
                  min-width: 14em;
              }

              .text {
                  font-size: .78571429rem;
              }

              .dropdown {
                  ${!isMobile && 'max-width: 14em;'}
              
                  .divider.text {
                      display: block;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                  }
              }

              .toggle.checkbox label {
                  font-weight: normal !important;
              }
          `}
        >
            <label>{t('product_list.advanced_filters.menu.menu')}</label>
            <Checkbox size="mini" toggle checked={filters.excludeCategories} onChange={(evt, { checked }) => {
                setFilters(filters => {
                    const newFilters = { ...filters };

                    delete newFilters.categorySet;
                    delete newFilters.categorySlot;
                    delete newFilters.category;
                    delete newFilters.excludeCategories;

                    if (checked) {
                        newFilters.excludeCategories = true;
                    }
                    return newFilters;
                });
            }} label={t('product_list.advanced_filters.menu.items_without_category')} />
            {!filters.excludeCategories && (
                <React.Fragment>
                    <div className='ui form mini'>
                        <Dropdown
                            disabled={filters.excludeCategories}
                            loading={isLoading}
                            selection
                            clearable
                            fluid={isMobile}
                            selectOnBlur={false}
                            placeholder={t('product_list.advanced_filters.menu.menu')}
                            options={categoriesFilterOptions.map(set => ({
                                value: set.value,
                                text: set.label,
                                key: set.label,
                            }))}
                            value={filters.categorySet}
                            onChange={(evt, { value }) => {
                                setFilters(filters => {
                                    const newFilters = { ...filters };

                                    delete newFilters.categorySet;
                                    delete newFilters.categorySlot;
                                    delete newFilters.category;
                                    delete newFilters.excludeCategories;

                                    if (value) {
                                        newFilters.categorySet = value;
                                    }
                                    return newFilters;
                                });
                            }}
                        />
                    </div>

                    <div className='ui form mini'>
                        {filters.categorySet && categoriesFilterOptions.filter(set => set.value === filters.categorySet).flatMap(set => set.slots).length > 1 && (
                            <Dropdown
                                disabled={filters.excludeCategories}
                                loading={isLoading}
                                selection
                                clearable
                                fluid={isMobile}
                                selectOnBlur={false}
                                placeholder={t('product_list.advanced_filters.menu.submenu')}
                                options={categoriesFilterOptions.filter(set => set.value === filters.categorySet).flatMap(set => set.slots).map(slot => ({
                                    value: slot.value,
                                    text: slot.label,
                                    key: slot.label,
                                }))}
                                value={filters.categorySlot}
                                onChange={(evt, { value }) => {
                                    setFilters(filters => {
                                        const newFilters = { ...filters };

                                        delete newFilters.categorySlot;
                                        delete newFilters.category;
                                        delete newFilters.excludeCategories;

                                        if (value) {
                                            newFilters.categorySlot = value;
                                        }
                                        return newFilters;
                                    });
                                }}
                            />
                        )}
                    </div>

                    <div className='ui form mini'>
                        {filters.categorySet && (filters.categorySlot || categoriesFilterOptions.filter(set => set.value === filters.categorySet).flatMap(set => set.slots).length === 1) && (
                            <Dropdown
                                disabled={filters.excludeCategories}
                                loading={isLoading}
                                selection
                                clearable
                                selectOnBlur={false}
                                fluid={isMobile}
                                placeholder={t('product_list.advanced_filters.menu.category')}
                                options={categoriesFilterOptions.filter(set => set.value === filters.categorySet).flatMap(set => set.slots).filter(slot => slot.value === filters.categorySlot || (categoriesFilterOptions.filter(set => set.value === filters.categorySet).flatMap(set => set.slots).length === 1)).flatMap(slot => slot.categories).map(cat => ({
                                    value: cat.value,
                                    text: cat.label,
                                    key: cat.label,
                                }))}
                                value={filters.category}
                                onChange={(evt, { value }) => {
                                    setFilters(filters => {
                                        const newFilters = { ...filters };

                                        delete newFilters.category;
                                        delete newFilters.excludeCategories;

                                        if (value) {
                                            newFilters.category = value;
                                        }
                                        return newFilters;
                                    });
                                }}
                            />
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

AdvancedMenuFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
}

export default AdvancedMenuFilters;
