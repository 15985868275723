import React from 'react';
import PropTypes from 'prop-types';
import {Message} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';

const OrderDenyReasonMessage = ({ order }) => {
    const [t] = useTranslation();
    const resolveOrderItemNameFromItemId = (order, itemId) => {
        const orderItem = order.orderItems?.find(orderItem => orderItem.itemId === itemId);

        if (!orderItem) {
            return '';
        }

        return orderItem.object.name || orderItem.name;
    }

    return (
        <Message color={'yellow'}>
            <Message.Header>{t('order.this_order_has_been_denied')}</Message.Header>
            <p>{t(`order.denyReason.types.${order.denyReason?.type.name}`)}</p>
            <p>{order.denyReason?.details?.notes}</p>
            {order.denyReason?.type.name === 'ITEMS_OUT_OF_STOCK' &&
            <ul>
                {(order.denyReason?.details?.outOfStockItems||[]).map(outOfStockItem => (
                    <li key={outOfStockItem}>{resolveOrderItemNameFromItemId(order, outOfStockItem)}</li>
                ))}
            </ul>}
        </Message>
    );
}

export default OrderDenyReasonMessage;

OrderDenyReasonMessage.propTypes = {
    order: PropTypes.object.isRequired,
}
