import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import useFeature from '../components/Shared/FeatureProvider/useFeature';

const useCamera = (callback, id) => {
    const queryClient = useQueryClient()

    const {isEnabled} = useFeature();

    useEffect(() => {
        const handlePhotoTakenChunkReceived = (chunk) => {
            queryClient.setQueryData([`android.ulty.photo-taken-chunk-${id}`], prev => [...prev ||[], chunk.value])
        }
        window.addEventListener(`android.ulty.photo-taken-chunk-${id}`, handlePhotoTakenChunkReceived)
        return () => {
            window.removeEventListener(`android.ulty.photo-taken-chunk-${id}`, handlePhotoTakenChunkReceived)
        }
    }, [id, queryClient])

    useEffect(() => {
        const handlePhotoTakenComplete = () => {
            const chunks = queryClient.getQueryData([`android.ulty.photo-taken-chunk-${id}`])
            if (!chunks) {
                return
            }
            const base64Image = `data:image/jpeg;base64,${chunks.join('')}`
            queryClient.removeQueries({ queryKey: [`android.ulty.photo-taken-chunk-${id}`], exact: true })
            callback(base64Image)
        }

        window.addEventListener(`android.ulty.photo-taken-complete-${id}`, handlePhotoTakenComplete)
        return () => {
            window.removeEventListener(`android.ulty.photo-taken-complete-${id}`, handlePhotoTakenComplete)
        }
    }, [callback, id, queryClient])

    const isCameraAvailable = () => {
        return !!window.Android?.takePhoto && isEnabled('picking-proof')
    }

    const takePhoto = () => {
        if(!isCameraAvailable()) {
            return
        }

        window.Android?.takePhoto(id)
    }

    return {
        takePhoto,
        isCameraAvailable
    }
}

export { useCamera }
