/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import cronstrue from 'cronstrue/i18n'
import { Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import useUser from '../../Shared/UserProvider/useUser'
import { FrequencyPicker } from './FrequencyPicker/FrequencyPicker'
import { RecipientsEditor } from './RecipientEditor/RecipientsEditor'
import { POSPicker } from './POSPicker/POSPicker'

const PerformanceReportConfigurationForm = ({performanceReportConfiguration, onChange}) => {
    const [t] = useTranslation()
    const {user} = useUser()

    return (
      <>
          <div css={css`
              display: flex;
              justify-content: space-between;
          `}>
              <Header>
                  {cronstrue.toString(performanceReportConfiguration.cronExpression, {
                      locale: localStorage.getItem('ulty-lng').split('-')[0],
                  })}
              </Header>

              <i>{performanceReportConfiguration.lastSentAt && t('reports.performance_configuration.lastSentAt')}
                  {performanceReportConfiguration.lastSentAt ? new Date(performanceReportConfiguration.lastSentAt).toLocaleDateString('fr-FR', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                  }) : t('reports.performance_configuration.neverSent')}</i>
          </div>


          <div css={css`
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
          `}>
              <FrequencyPicker
                cronExpression={performanceReportConfiguration.cronExpression}
                onChange={(cronExpression) => {
                    onChange({
                        ...performanceReportConfiguration,
                        cronExpression
                    })
                }} />

              <RecipientsEditor
                recipients={performanceReportConfiguration.recipients}
                onChange={(recipients) => {
                    onChange({
                        ...performanceReportConfiguration,
                        recipients
                    })
                }} />

              {user.provider.type === 'COMPANY' &&
                <POSPicker
                  posIds={performanceReportConfiguration.posIds}
                  onChange={(posIds => {
                    onChange({
                        ...performanceReportConfiguration,
                        posIds,
                    })
                })} />
              }
          </div>
      </>
    )
}

export { PerformanceReportConfigurationForm }
