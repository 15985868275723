import { UltyMoney } from "@ulty/ulty-ui";
import { useTranslation } from "react-i18next";
import useUser from "../../Shared/UserProvider/useUser";
import usePlatforms from "../../Shared/PlatformProvider/usePlatforms";
import { usePricesByPlatform } from "../../Prices/PricesByPlatform/usePricesByPlatform";

export const ProductPrices = ({product}) => {
    const [t] = useTranslation();
    const {user} = useUser();
    const {get:getPlatform} = usePlatforms();

    const {displayedPrices} = usePricesByPlatform(product?.pricesByPlatform);

    return (
    <>
                        <UltyMoney
                            noMargin
                            readonly
                            label={`${t('item_edit.basePrice')} :`}
                            amount={product.basePrice}
                            currencyCode={user.getCompanyCurrency()}
                            locale={user.getCompanyLocale()}
                        />
                        {product.inventory?.sellPrice && (
                            <UltyMoney
                                noMargin
                                readonly
                                label={`${t('item_edit.inventory.sellPrice')}:`}
                                amount={product.inventory.sellPrice}
                                currencyCode={user.getCompanyCurrency()}
                                locale={user.getCompanyLocale()}
                            />
                        )}
                        {displayedPrices?.length > 0 && displayedPrices.map(p => (
                            <UltyMoney
                                noMargin
                                readonly
                                label={`${t('item_edit.inventory.sellPrice')} ${getPlatform(p.platformId ||'')?.name}:`}
                                amount={p.price}
                                currencyCode={user.getCompanyCurrency()}
                                locale={user.getCompanyLocale()}
                            />
                        ))}
    </>
)}