import { PickingProofEventItemType } from './PickingProofEventItemType'

const EventItemType = ({ orderId, event }) => {
    if (event.type === 'PICKING_PROOF_ADDED') {
        return <PickingProofEventItemType orderId={orderId} event={event} />
    }

    return event.type
}

export { EventItemType }
