/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Item from '../Item/Item'
import { gridComponents } from '../Item/SortableItem'
import { VirtuosoGrid } from 'react-virtuoso'
import PropTypes from 'prop-types'
import { DraggableList } from './DraggableList'

const ItemList = ({isParent, items, onDeleteItem, onSortItems}) => {
    return (
      <div css={css`
          margin-top: 1rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          gap: 1rem;
      `}>
          {isParent ?
            <VirtuosoGrid
              useWindowScroll
              style={{
                  width: '100%',
              }}
              components={gridComponents}
              data={items}
              itemContent={(_, item) => (
                <Item
                  key={item.id}
                  item={item}
                  onDeleteItem={onDeleteItem}
                  canDelete={false}
                />
              )}
            />
            :
            <DraggableList
              items={items}
              isParent={isParent}
              onDeleteItem={onDeleteItem}
              onSortItems={onSortItems}
            />}
      </div>
    )
}

ItemList.prototype = {
    items: PropTypes.array.isRequired,
}

export { ItemList }
