import { useCallback } from "react";
import { usePrinter } from "../../services/printer.service"
import {
    Button
} from 'semantic-ui-react';

const PrintOrderButton = ({order,...buttonProps}) => {

    const {printerInfo,printOrder,printingOptions} = usePrinter();

    const handleClick = useCallback(() => {
        printOrder(order,true);
    },[order,printOrder]);

    return (<>
    {(printerInfo.isDeviceCompatible
        && <Button
            {...buttonProps}
            basic
            icon="print"
            color={printerInfo.isConnected ? undefined : 'red'}
            onClick={handleClick}
            >
            </Button>
    )}
    </>
    
    )

}

export {PrintOrderButton}