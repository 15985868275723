/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {AdvancedFiltersProvider} from '../Shared/AdvancedFilters/AdvancedFiltersContext';
import DateRangeFilter from '../Shared/AdvancedFilters/Controls/DateRangeFilter';
import PosFilter from '../Shared/AdvancedFilters/Controls/PosFilter';
import BrandFilter from '../Shared/AdvancedFilters/Controls/BrandFilter';
import PlatformFilter from '../Shared/AdvancedFilters/Controls/PlatformFilter';
import OrderStatusFilter from '../Shared/AdvancedFilters/Controls/OrderStatusFilter';
import DisplayIdFilter from '../Shared/AdvancedFilters/Controls/DisplayIdFilter'


const AdvancedFilters = ({defaultFilters, onFiltersChange, children, ...props}) => {
    return (
        <div className={props.className}>
            <div css={css`
              display: flex;
              flex-wrap: wrap;
              z-index: 999;
              position: relative;
            `}>
                <AdvancedFiltersProvider defaultFilters={defaultFilters} onFiltersChange={onFiltersChange}>
                    {children}
                </AdvancedFiltersProvider>
            </div>
        </div>
    );
}

AdvancedFilters.propTypes = {
    defaultFilters: PropTypes.shape({
        dateRange: PropTypes.shape({
            startDate: PropTypes.instanceOf(moment),
            endDate: PropTypes.instanceOf(moment),
        }),
        displayId: PropTypes.arrayOf(PropTypes.string),
        dateComparison: PropTypes.shape({
            startDate: PropTypes.instanceOf(moment),
            endDate: PropTypes.instanceOf(moment),
        }),
        merchantId: PropTypes.string,
        brandIds: PropTypes.arrayOf(PropTypes.string),
        platformIds: PropTypes.arrayOf(PropTypes.string),
        orderStatus: PropTypes.arrayOf(PropTypes.string),
    }),
    onFiltersChange: PropTypes.func
}

AdvancedFilters.DateRangeFilter = DateRangeFilter;
AdvancedFilters.DisplayIdFilter = DisplayIdFilter;
AdvancedFilters.PosFilter = PosFilter;
AdvancedFilters.BrandFilter = BrandFilter;
AdvancedFilters.PlatformFilter = PlatformFilter;
AdvancedFilters.OrderStatusFilter = OrderStatusFilter;

export default AdvancedFilters;
