import {useTranslation} from 'react-i18next';
import {Icon, Label} from 'semantic-ui-react';

const OrderItemFulfillmentActionLabel = ({ fulfillmentAction, className }) => {
    const [t] = useTranslation();

    return (<></>);
    
    return (
        <Label color='teal' title={t('order.fulfillment_action.shopper_preference')} className={className}>
            <Icon name='user' />{t(`order.fulfillment_action.${fulfillmentAction}`)}
        </Label>
    );
}

export default OrderItemFulfillmentActionLabel;
