import { UltyDropdown } from '@ulty/ulty-ui'
import { useTranslation } from 'react-i18next'

const days = Array.from({length: 31}, (_, d) => d + 1)

const MonthdayPicker = ({day, onChange}) => {
    const [t] = useTranslation()

    return (
      <UltyDropdown
        fluid
        label={t('reports.performance_configuration.frequency_picker.month')}
        loading={false}
        options={days.map(day => ({
            text: day,
            key: day,
            value: day,
        }))}
        onChange={(e, {value}) => {
            onChange({ day: value })
        }}
        value={day}
        multiple={false}
      />
    )
}

export { MonthdayPicker }
