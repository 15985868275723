import React from 'react';
import useUser from '../UserProvider/useUser';
import {Route, Redirect} from 'react-router-dom';


const PrivateRoute = ({ children, staticResource, ...rest }) => {
    const {can, landingRoute} = useUser();

    return(
        <Route
            {...rest}
            render={({location}) =>
                can('STATIC', staticResource) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: landingRoute(),
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    )
}

export default PrivateRoute;
