import {
    Button,
    Loader,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import { getProviderUsers, isProviderAPos } from '../../../../services/Provider/Provider.service'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import useUser from '../../../Shared/UserProvider/useUser'
import UserEdit from '../UserEdit'
import { UltyModalWrapperContext } from '../../../Shared/UltyModalWrapper/UltyModalWrapperContext'
import { UserSearch } from './UserSearch'
import { useQuerySearchParams } from '../../../../services/Filter/filter.service'
import { useParams } from 'react-router-dom'

const UserList = ({ color }) => {
    const [t] = useTranslation()
    const {user} = useUser()
    const { providerId } = useParams()
    const {handleUltyModalWrapper} = useContext(UltyModalWrapperContext)
    const queryParams = useQuerySearchParams()
    const userSearch = queryParams.get('user-search')

    const defineUserModal = (user) => {
        const modalSettings = {
            title: user ? `${user.firstname} ${user.lastname}` : t('user.edit.new_user'),
            component: <UserEdit
              userId={user?.id}
              afterSubmit={() => {
                  handleUltyModalWrapper(false, null)
              }}
            />,
        }

        handleUltyModalWrapper(true, modalSettings)
    }

    const {data: users, isLoading} = useQuery({
        queryFn: () => getProviderUsers(providerId, userSearch),
        queryKey: ['providers', `${providerId}`, 'users', ...(userSearch ? [`?user-search=${userSearch}`] : [])],
    })

    return (
      <div>
          <UserSearch />

          {isLoading ?
            <Loader active /> :
            <Table celled compact color={color}>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>{t('administration.users.field_name')}</TableHeaderCell>
                        <TableHeaderCell>{t('administration.users.field_email')}</TableHeaderCell>
                        <TableHeaderCell>{t('role.role')}</TableHeaderCell>
                        <TableHeaderCell>{t('administration.users.field_actions')}</TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {users.map(u => (
                      <TableRow key={u.id}>
                          <TableCell><strong>{u.lastname.toUpperCase()}</strong> {u.firstname}</TableCell>
                          <TableCell>{u.email}</TableCell>
                          <TableCell>
                              {t(`role.${u.providers[0]?.role?.name || 'UNKNOWN'}`)}
                          </TableCell>
                          <TableCell textAlign="center">
                              {!(isProviderAPos(user.provider) && u.providers.some(p => p.type === 'COMPANY')) &&
                                <Button
                                  icon="pencil"
                                  onClick={() => defineUserModal(u)}
                                />
                              }
                          </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
            </Table>
          }
      </div>
    )
}

export { UserList }
