import React, { createContext } from 'react';
import useUltyModalWrapper from './useUltyModalWrapper';
import UltyModalWrapper from './UltyModalWrapper';


let UltyModalWrapperContext;
const { Provider } = (UltyModalWrapperContext = createContext(null));

const UltyModalWrapperProvider = ({ children }) => {
    const { isOpen, handleUltyModalWrapper, modalSettings } = useUltyModalWrapper();
    return (
        <Provider value={{ isOpen, handleUltyModalWrapper, modalSettings }}>
            <UltyModalWrapper />
            {children}
        </Provider>
    )
};

export { UltyModalWrapperContext, UltyModalWrapperProvider };
