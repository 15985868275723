/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import TimePeriod from './TimePeriod';
import {useTranslation} from 'react-i18next';
import { useEffect, useState } from 'react';



const DayTimePeriods = ({ label, timePeriods, onChange, onError, readonly, replicate }) => {
    const [t] = useTranslation();



    const handleTimePeriodChange = (timePeriodIndex, timePeriod) => {
        if(!timePeriod){
            setNumberOfTimePeriods(nb => --nb);
        }
        onChange(timePeriodIndex, timePeriod);
    }

    const handleOnTimePeriodError = (timePeriodIndex, error) => {
        onError(timePeriodIndex, error)
    }

    const [numberOfTimePeriods,setNumberOfTimePeriods] = useState(2);
    
    
    const AddAnotherSlot = ({slotIndex}) => {
        const MAX_NUMBER_OF_SLOT = 4;
        if(readonly || (slotIndex + 1 + 1 > MAX_NUMBER_OF_SLOT || (slotIndex+1 !== numberOfTimePeriods))) return <></>

        return <span css={css`
        cursor: pointer;
        color: #4183c4;
    `}  onClick={()=> setNumberOfTimePeriods(v => ++v)}>{t('administration.hours.add_slot')}</span>
    }

    
    useEffect(() => {
        setNumberOfTimePeriods(Math.max(timePeriods?.length ?? 0,2))
    },[timePeriods])


    return (
        <div css={css`
          display: flex;
          row-gap: 1rem;
          flex-direction: column;
          margin-bottom: 40px;

        `}>
            <label css={css`font-weight: bold`}>{label}</label>

            <TimePeriod
                label={t('administration.hours.slot_x',{x:1})}
                timePeriod={timePeriods[0]}
                onChange={(timePeriod) => handleTimePeriodChange(0,timePeriod)}
                onError={(error) => handleOnTimePeriodError(0, error)}
                readonly={readonly}
            />

            <TimePeriod
                label={t('administration.hours.slot_x',{x:2})}
                timePeriod={timePeriods[1]}
                onChange={(timePeriod) => handleTimePeriodChange(1, timePeriod)}
                onError={(error) => handleOnTimePeriodError(1, error)}
                readonly={readonly}
            /> 

            <AddAnotherSlot slotIndex={1}/>

            {numberOfTimePeriods > 2 && [...Array(numberOfTimePeriods-2).keys()].map((i) => (
                <>
                    <TimePeriod
                        label={t('administration.hours.slot_x',{x:i+2+1})}
                        timePeriod={timePeriods[i+2] ?? {}}
                        onChange={(timePeriod) => handleTimePeriodChange(i+2, timePeriod)}
                        onError={(error) => handleOnTimePeriodError(i+2, error)}
                        readonly={readonly}
                        canDelete={i+2+1 === numberOfTimePeriods}
                    />
                    <AddAnotherSlot slotIndex={i+2}/>
                </>
                ))}

            { replicate && (
                <span css={css`
                    cursor: pointer;
                    color: #4183c4;
                `} onClick={replicate}>{t('administration.hours.replicate')}</span>
            )}
        </div>
    )
}

export default DayTimePeriods;
