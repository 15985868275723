import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Dimmer, Form, Image, Loader} from 'semantic-ui-react';
import {uploadImage} from '../../services/Upload/Upload.service';
import {useTranslation} from 'react-i18next';
import {notify} from 'react-notify-toast';


const squareImg = '/img/image.png'

const ImageUpload = ({ onSrcChange, initial, withConversion = false, disabled = false }) => {
  const [t] = useTranslation();
  const [uploading, setUploading] = useState(false);
  const [src, setSrc] = useState(squareImg);

  const [uploadingState, setUploadingState] = useState('');
  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    setSrc(initial || squareImg)
  }, [initial]);

  const uploadImageCallback = useCallback(
      async (file) => await uploadImage(file, withConversion, setCurrentProgress),[]
  );

  const fileInputRef = useRef(null);

  const handleImageLoad = async (url) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.onerror = (e) => {
        setUploading(false);
        fileInputRef.current.value = '';
        setSrc(squareImg);
        reject(e);
      };
      img.onload = () => {
        setCurrentProgress(0);
        setUploading(true);
        setUploadingState('UPLOAD');
        resolve(img);
      };

      img.src = url;
    });
  }

  const inputFileChange = async () => {
    if (fileInputRef.current.files.length === 0) {
      return;
    }

    const file = fileInputRef.current.files[0];
    setUploading(true);
    try {
      setUploadingState('UPLOAD');
      const url = await uploadImageCallback(file);
      setUploadingState('');
      await handleImageLoad(url);
      onSrcChange(url);
      setSrc(url);
    } catch (e) {
      setUploading(false);
      notify.show(`${t(`global.${e.message}`)}`, 'error');
    } finally {
      setUploading(false);
      setUploadingState('');
    }
  }

  const getLoaderMessage = () => {
    switch(uploadingState) {
      case 'UPLOAD':
        return `${t('uploader.uploading')} (${currentProgress}%)`;
      default:
        return t('uploader.loading');
    }
  }

  return (
    <Dimmer.Dimmable as={Form.Field} dimmed={uploading} style={{ margin: 'auto', maxWidth: 300 }}>
        <Dimmer active={uploading}>
          <Loader indeterminate inline="centered">{getLoaderMessage()}</Loader>
        </Dimmer>

        <div>
          <Image
            src={src}
            size='medium'
            style={{ cursor: 'pointer' }}
            onClick={() => fileInputRef.current.click()}
            rounded />
          { src && src !== '' && src !== squareImg && !disabled && (
            <Button icon="times" style={{
              position: 'absolute',
              top: '5px',
              right: '5px'
            }} circular onClick={() => {
              fileInputRef.current.value = '';
              setSrc(squareImg)
              setUploading(false)
              onSrcChange('')
            }} />
          )}
          <input
            ref={fileInputRef}
            type="file"
            hidden
            onChange={inputFileChange}
            disabled={disabled}
          />
        </div>
      </Dimmer.Dimmable>
  )
}

export default ImageUpload
