import React, { useEffect, useState } from 'react'
import { Tab, Dropdown, Form, Loader } from 'semantic-ui-react'
import useUser from '../../Shared/UserProvider/useUser';
import {getMerchantsOfCompany} from '../../../services/Company/Company.service';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query'
import { isProviderACompany } from '../../../services/Provider/Provider.service'
import { POSBrands } from './POSBrands'

const AdminPlatforms = () => {
    const [t] = useTranslation()
    const {user} = useUser()
    const [selectedPos, setSelectedPos] = useState(user.getUserPos())

    const { data: pointOfSales, isLoading: arePointOfSalesLoading, isSuccess: getPointOfSalesIsSuccess } = useQuery({
        queryFn: () => getMerchantsOfCompany(user.provider.object.id),
        queryKey:['companies', user.provider.object.id, 'pointofsales'],
        enabled: isProviderACompany(user.provider)
    })

    useEffect(() => {
        if (getPointOfSalesIsSuccess) {
            const firstPos = pointOfSales?.length > 0 ? pointOfSales[0] : null
            setSelectedPos(firstPos)
        }
    }, [getPointOfSalesIsSuccess, pointOfSales])

    const posId = isProviderACompany(user.provider) ? selectedPos?.id : user.provider.object.id
    const handlePosChange = (e, { value }) => {
        setSelectedPos(pointOfSales.find(pos => pos.id === value));
    }

    if (isProviderACompany(user.provider) && !pointOfSales) {
        return <Loader active />
    }

    return (
        <Tab.Pane>
            <Form>
                <Form.Group widths="equal">
                    {isProviderACompany(user.provider) &&
                        <Form.Field>
                            <label>{t('global.merchant')}</label>
                            <Dropdown
                                fluid
                                selection
                                loading={arePointOfSalesLoading}
                                value={selectedPos?.id}
                                name="pointOfSales"
                                onChange={handlePosChange}
                                options={(pointOfSales || []).map(dm => ({
                                    text: dm.name,
                                    value: dm.id,
                                    key: dm.id
                                }))}
                            />
                        </Form.Field>
                    }
                </Form.Group>
            </Form>
            {posId && <POSBrands posId={posId} />}
        </Tab.Pane>
    )
}

export default AdminPlatforms
