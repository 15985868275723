import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button, Table, Icon} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { UltyMoney } from '@ulty/ulty-ui';
import useUser from '../../Shared/UserProvider/useUser';


const ItemElementItem = ({ item, extraCost, onExtraCostChange, onDelete }) => {
    const [t] = useTranslation();
    const {user} = useUser();
    const { id, object } = item;
    const { name } = object;

    const handleChangeExtraCost = (value) => {
        onExtraCostChange(id, value);
    };

    const isReadOnly = useMemo(() => !onExtraCostChange, [onExtraCostChange]);

    return (
        <>
            <Table.Cell className="handle">
                {onExtraCostChange && <Button icon><Icon name="arrows alternate"/></Button>}
            </Table.Cell>

            <Table.Cell>
                {name}
            </Table.Cell>

            <Table.Cell>
                <UltyMoney
                    readonly={isReadOnly}
                    name="extra_cost"
                    amount={extraCost}
                    onMoneyAmountChange={(e, amt) => handleChangeExtraCost(amt)}
                    currencyCode={user.getCompanyCurrency()}
                    locale={user.getCompanyLocale()}
                />
            </Table.Cell>

            <Table.Cell textAlign="right">
                {onDelete &&
                <Button
                    icon='minus'
                    onClick={() => onDelete(id)}
                />}
            </Table.Cell>
        </>
    )
}

ItemElementItem.propTypes = {
    item: PropTypes.object,
    extraCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onExtraCostChange: PropTypes.func,
    onDelete: PropTypes.func,
}

export default ItemElementItem;
