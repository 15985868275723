/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import alert1 from '../../assets/mp3/alert1.mp3';

const SoundPlayer = ({ playing }) => {
  const [t] = useTranslation();

  const player = useRef();

  const [show, setShow] = useState(true);


  useEffect(() => {
    if (player.current) {
      try {
        if (playing) {
          player.current.volume = 1.0;
          player.current.muted = false;
          player.current.play();
          if (window.Android?.stopNotifications) {
            window.Android?.stopNotifications();
            
          }
        } else {
          player.current.pause();
        }

      } catch(e) {}
    }
  }, [playing])

  return (
    <React.Fragment>
      { show && (!window.Android?.getVersion && !window.webkit?.messageHandlers) && (
        <div css={css` 
          width: 100%; 
          height: 100%; 
          position: fixed; 
          top: 0; 
          left: 0; 
          background-color: #000; 
          opacity: 0.8; 
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 99999999;
        `}>
          <Button color="red" onClick={() => {
            player.current.play()
            setTimeout(() => {
              player.current.pause()
            }, 1)
            setShow(false)
          }}>{t('global.sound_on')}</Button>
        </div>

      )}
      <audio ref={player} src={alert1} loop controls={false}/>
    </React.Fragment>
  )
}

SoundPlayer.propTypes = {
  playing: PropTypes.bool.isRequired
};

export default SoundPlayer;
