import { Button, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { NavLink } from 'react-router-dom'

const NavigateToPickingProofsGalleryButton = ({order}) => {
    const [t] = useTranslation()
    return (
      <Button
        icon
        labelPosition='left'
        as={NavLink}
        to={`/orders/${order.id}/picking-proofs`}
        color="teal"
      >
          <Icon name='image outline' />
          {t('order.picking_proof.download_x_picking_proofs', { count: order.pickingProofs.length })}
      </Button>
    )
}

export { NavigateToPickingProofsGalleryButton }
