import {call, getFileContent, METHODS} from '../http-client';

const ROUTE_PREFIX = 'product';

export const getProducts = async (includeInactive) => {
    return await call(METHODS.GET, ROUTE_PREFIX,`?inactive=${includeInactive?1:0}`);
}

export const getProduct = async (id) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${id}`);
    } catch (e) {
        console.error(`getProduct error: ${e}`);
        throw e;
    }
}

export const createProduct = async (obj) => {
    try {
        return await call(METHODS.POST, ROUTE_PREFIX, '', { ...obj });
    } catch (e) {
        console.error(`createProduct error: ${e}`);
        throw e;
    }
}

export const updateProduct = async (id, obj) => {
    try {
        return await call(METHODS.PUT, ROUTE_PREFIX, `${id}`, { ...obj });
    } catch (e) {
        console.error(`updateProduct error: ${e}`);
        throw e;
    }
}

export const saveProduct = async (product) => {
    try {
        if (!product.id) {
            return await call(METHODS.POST, ROUTE_PREFIX, '', product);
        } else {
            return await call(METHODS.PUT, ROUTE_PREFIX, product.id, product);
        }
    } catch (e) {
        console.error(`saveProduct error: ${e.message}`);
        throw e;
    }
}

export const saveItemPlatformPrices = async(itemId,prices) => {
    try {
        return await call(METHODS.PUT, ROUTE_PREFIX, `${itemId}/prices`, {prices});
    } catch (e) {
        console.error(`saveProduct error: ${e.message}`);
        throw e;
    }
}

export const saveProductElements = async (id, elements) => {
    try {
        return await call(METHODS.PUT, ROUTE_PREFIX, `${id}/modifiers`, elements);
    } catch (e) {
        console.error(`saveProductElements error: ${e.message}`);
        throw e;
    }
}

export const getItemElements = async (itemId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${itemId}/modifiers`);
    } catch (e) {
        console.error(`getProductElements error: ${e.message}`);
        throw e;
    }
}

export const exportProducts = async () => {
    try {
        return await getFileContent(`${ROUTE_PREFIX}/export`, {
            responseType: 'arraybuffer'
        });
    } catch (e) {
        console.error(`exportProducts error: ${e.message}`);
        throw e;
    }
}
