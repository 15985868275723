import { useEffect, useState } from 'react'
import { Grid, Form, Input, Button, Icon, Checkbox, Loader, Dimmer } from 'semantic-ui-react'
import { notify } from 'react-notify-toast'
import useUser from '../../Shared/UserProvider/useUser'
import { useTranslation } from 'react-i18next'
import { saveUser } from '../../../services/User/User.service'
import RoleSelector from '../../Role/RoleSelector'
import { getUserOfAMerchant, isProviderACompany, isProviderAPos } from '../../../services/Provider/Provider.service'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { RolesAccesses } from './RoleAccesses/RolesAccesses'

const UserEdit = ({userId, afterSubmit}) => {
    const [t] = useTranslation()
    const {user: currentUser} = useUser()
    const [form, setForm] = useState({
        firstname: '',
        lastname: '',
        email: '',
        providers: [],
        active: false,
        addToCompany: false,
        companyRole: null,
    })
    const queryClient = useQueryClient()

    const {data: user, isLoading, status} = useQuery({
        queryFn: () => getUserOfAMerchant(currentUser.provider.id, userId),
        queryKey: ['providers', currentUser.provider.id, 'users', userId],
        enabled: !!userId,
    })

    useEffect(() => {
        if (status === 'success') {
            setForm(user)

            const [companyProvider] = user.providers.filter(p => p.type === 'COMPANY')
            if (companyProvider) {
                setForm(prev => ({
                    ...prev,
                    addToCompany: true,
                    companyRole: companyProvider.role,
                }))
            }
        }
    }, [currentUser.provider, status, user])

    const saveUserMutation = useMutation({
        mutationFn: ({ form }) => saveUser(form),
        onSuccess: () => {
            if (form?.id) {
                notify.show(t('administration.users.user_updated'), 'success')
            } else {
                notify.show(t('administration.users.user_validated'), 'success')
            }

            handleClose()
        },
        onError: (e) => {
            if (e.message === 'email_already_exists') {
                notify.show(t('administration.users.user_already_exists'), 'error')
            } else {
                notify.show(t('global.anErrorOccurred'), 'error')
            }
        },
    })

    if (userId && isLoading) {
        return (
          <Dimmer active inverted>
              <Loader />
          </Dimmer>
        )
    }

    const handleChange = (evt, {name, value, checked}) => {
        if (['active', 'addToCompany'].includes(name)) {
            value = checked
        }
        setForm(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleSubmit = async () => {
        if (form.email === '' || form.firstname === '' || form.lastname === '') {
            notify.show(t('administration.users.all_fields_mandatory'), 'error')
            return
        }

        if (form.providers.length === 0 && (!form.addToCompany || isProviderAPos(currentUser.provider))) {
            notify.show(t('administration.users.provider_mandatory_if_not_company'), 'error')
            return
        }

        if (form.addToCompany && !form.companyRole) {
            notify.show(t('administration.users.company_role_mandatory'), 'error')
            return
        }

        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(form.email)) {
            notify.show(t('administration.users.invalid_email'), 'error')
            return
        }

        saveUserMutation.mutate({form})

        if (userId) {
            await queryClient.invalidateQueries({queryKey: ['providers', currentUser.provider.id, 'users', userId]})
        }
    }

    const handleClose = () => {
        afterSubmit()
    }

    const addProviderUser = ({pos, role}) => {
        setForm(prev => ({
            ...prev,
            providers: prev.providers.concat(...[{
                id: pos.providerId,
                type: 'POINT_OF_SALE',
                object: {
                    id: pos.id,
                    name: pos.name,
                },
                role,
            }]),
        }))
    }

    const removeProviderUser = ({pos, role}) => {
        const providers = form.providers.filter(provider =>
          !(provider.object.id === pos.id && provider.role.name === role.name),
        )

        setForm(prev => ({
            ...prev,
            providers,
        }))
    }

    const upsertRoleForPos = ({pos, role}) => {
        const providerIndex = form.providers.findIndex(provider => provider.id === pos.providerId)
        if (providerIndex === -1) {
            return addProviderUser({pos, role})
        }

        setForm(prev => ({
            ...prev,
            providers: prev.providers.map((provider, index) =>
              index === providerIndex ? {...provider, role} : provider,
            ),
        }))
    }

    return (
      <Form>
          <Form.Field style={{display: form.id ? 'block' : 'none'}}>
              <Checkbox toggle label={t('administration.users.active')}
                        onChange={handleChange}
                        checked={form.active}
                        name="active"
                        disabled={form.id === currentUser.id}
              />
          </Form.Field>

          <Form.Field
            control={Input}
            name="firstname"
            value={form.firstname}
            onChange={handleChange}
            label={t('user.edit.firstname')}
            placeholder={t('user.edit.firstname')}
          />
          <Form.Field
            control={Input}
            name="lastname"
            value={form.lastname}
            onChange={handleChange}
            label={t('user.edit.lastname')}
            placeholder={t('user.edit.lastname')}
          />
          <Form.Field
            control={Input}
            name="email"
            value={form.email}
            onChange={handleChange}
            label={t('user.edit.email')}
            placeholder={t('user.edit.email')}
          />

          {isProviderACompany(currentUser.provider) && (
            <>
                <Form.Field>
                    <Checkbox toggle label={t('user.edit.add_company_access')} onChange={handleChange}
                              name="addToCompany" checked={form.addToCompany}/>
                </Form.Field>

                {form.addToCompany && (
                  <Form.Field>
                      <RoleSelector
                        selectedRole={form.companyRole}
                        onRoleChange={(role) => setForm(prev => ({
                            ...prev,
                            companyRole: role,
                        }))}/>
                  </Form.Field>
                )}
            </>
          )}

          <>
              <Form.Field>
                  <label style={{fontWeight: 'bold'}}>{t('user.edit.authorize_access_to_pos')}</label>
              </Form.Field>

              <RolesAccesses
                providersRole={form.providers}
                removeProviderUser={removeProviderUser}
                upsertRoleForPos={upsertRoleForPos}
              />
          </>

          <Grid>
              <Grid.Row>
                  <Grid.Column width={16}>
                      <div style={{textAlign: 'right', marginTop: '10px'}}>
                          <Button loading={saveUserMutation.isPending} disabled={saveUserMutation.isPending} color="red" onClick={handleClose}>
                              <Icon name="remove"/>
                              {t('global.cancel')}
                          </Button>
                          <Button loading={saveUserMutation.isPending} disabled={saveUserMutation.isPending} color="teal" onClick={handleSubmit}>
                              <Icon name="checkmark"/>
                              {t('global.validate')}
                          </Button>
                      </div>
                  </Grid.Column>
              </Grid.Row>
          </Grid>
      </Form>
    )
}

export default UserEdit
