/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Input, Radio, Form, Item, Icon } from 'semantic-ui-react';
import { loginToProvider } from '../../services/Auth/Auth.service';
import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';
import { isProviderAPos } from '../../services/Provider/Provider.service';

const ProviderChoice = ({ providers, email, afterSubmit }) => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [providerType, setProviderType] = useState('POINT_OF_SALE');

  useEffect(() => {
    if (providers.length === 1) {
      setProviderType(providers[0].type);
    }
  }, [providers]);

  const getProviders = useCallback(() => {
    return providers.filter(p => {
      return (p.type === providerType || (providerType === 'POINT_OF_SALE' && isProviderAPos(p))) && (search === '' || p.object.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
    });
  }, [providers, search, providerType]);

  const handleSelect = async (providerId) => {
    setLoading(true);

    try {
      const data = await loginToProvider(providerId, email);

      setLoading(false);
      afterSubmit(data);
    } catch(e) {
      notify.show(t('global.anErrorOccurred'), 'error')
    }

    setLoading(false);
  }

  return (
    <>
      <Grid>

        <Grid.Column computer={8} tablet={8} mobile={16}>
          <Input fluid={true} icon='search' placeholder={t('product_list.filters.filter_placeholder')} value={search} onChange={(evt, { value }) => setSearch(value)} />
        </Grid.Column>
        <Grid.Column computer={8} tablet={8} mobile={16} css={css`
          display: flex !important;
          flex-direction: row !important;
          align-items: center !important;
          justify-content: space-evenly !important;
        `}>
          <Form.Field>
            <Radio
              label={t('global.merchant')}
              name='checked'
              disabled={providers.length === 1}
              checked={providerType === 'POINT_OF_SALE'}
              onChange={() => {
                setProviderType('POINT_OF_SALE');
              }}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label={t('global.company')}
              name='checked'
              disabled={providers.length === 1}
              checked={providerType === 'COMPANY'}
              onChange={() => {
                setProviderType('COMPANY');
              }}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
      <Item.Group divided>
        {getProviders().sort((a, b) => a.object.name.localeCompare(b.object.name)).map(provider => (
          <Item key={provider.id} color={provider.type === 'COMPANY' ? 'blue' : 'green'}>
            <Item.Content>
              <Item.Header>{provider.object.name}</Item.Header>
              <Item.Meta>{t(`provider.type_${provider.type}`)}</Item.Meta>
            </Item.Content>
            <Button basic color='teal' icon loading={loading} disabled={loading} onClick={() => handleSelect(provider.id)}>
              <Icon name="arrow right" />
            </Button>
          </Item>
        ))}
      </Item.Group>
    </>
  );
};

ProviderChoice.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.object).isRequired,
  email: PropTypes.string.isRequired,
  afterSubmit: PropTypes.func.isRequired,
};

export default ProviderChoice;
