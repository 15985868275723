import React from "react";
import {Button, Modal} from 'semantic-ui-react';
import { createRoot } from 'react-dom/client'


const UltyPrompt = (isOpen, setIsOpen, message, callback, i18n) => {
    const htmlDivElement = document.createElement('div')
    htmlDivElement.setAttribute('ulty-prompt', '')
    const root = createRoot(htmlDivElement)

    const handleConfirm = (callbackState) => {
        root.unmount();
        callback(callbackState);
        setIsOpen(false);
    }

    const handleCancel = () => {
        root.unmount();
        callback();
        setIsOpen(false);
    }

    document.body.appendChild(htmlDivElement)

    root.render(
        <Modal
            open={isOpen}
            closeOnEscape={true}
            closeOnDimmerClick={true}
        >
            <Modal.Content>
                {message}
            </Modal.Content>

            <Modal.Actions>
                <Button basic color="teal" onClick={handleCancel} >{i18n.t('global.dont_continue')}</Button>
                <Button color="teal" onClick={handleConfirm}>{i18n.t('global.continue')}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default UltyPrompt;
