/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {useState, useEffect, useContext, useCallback} from 'react';
import {Segment, Icon, Button, Popup, Form, Dimmer, Loader} from 'semantic-ui-react';
import { getOrders } from '../../../services/Order/Order.service';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment-timezone';

import 'moment/locale/fr';
import Download from './Download';
import PropTypes from 'prop-types';
import OrderList from './OrderList';
import OrderStore from '../OrderStore';
import { useTranslation } from 'react-i18next';
import { UltyModalWrapperContext } from '../../Shared/UltyModalWrapper/UltyModalWrapperContext';
import OrderSummary from './OrderSummary';
import useBreadcrumb from '../../NavBar/useBreadcrumb';
import AdvancedFilters from '../../Home/AdvancedFilters';
import {useHistory} from 'react-router-dom';
import useUser from '../../Shared/UserProvider/useUser';
import {useQuerySearchParams} from '../../../services/Filter/filter.service';
import { isProviderAPos } from '../../../services/Provider/Provider.service';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { MOBILE_THRESHOLD } from '../../../constants'

const defaultFilters = {
    dateRange: {
        startDate: moment().tz('Europe/Paris').startOf('day'),
        endDate: moment().tz('Europe/Paris').endOf('day')
    },
    merchantId: null,
    brandIds: [],
    platformIds: [],
    orderStatus: []
};

const OrderElement = ({ emptyText, getStatusText, seeText, state, showSummary = false }) => {
    const store = OrderStore.useContainer();
    const {setPaths} = useBreadcrumb();
    const [t] = useTranslation();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState(defaultFilters);
    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);
    const {user} = useUser();
    const history = useHistory();
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

    const query = useQuerySearchParams();
    const queryFilterFrom = query.get('from');
    const queryFilterTo = query.get('to');
    const queryFilterDisplayId = query.get('displayId');
    const queryFilterMerchant = query.get('merchant');
    const queryFilterBrands = query.get('brands');
    const queryFilterPlatforms = query.get('platforms');
    const queryFilterOrderStatus = query.get('status');

    const [pagination, setPagination] = useState({
        totalItems: 0,
        totalPages: 1,
        page: JSON.parse(query.get('page')) || 1,
        pageSize: JSON.parse(query.get('size')) || 30
    });

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/'
        }, {
            text: t('breadcrumb.orders'),
            link: false,
        }]);
    }, [orders, setPaths, t]);

    const defineOrderSummaryModal = (orders) => {
        const modalSettings = {
            centered: false,
            size: "large",
            component: <OrderSummary
                orders={orders}
                afterSubmit={() => {
                    handleUltyModalWrapper(false, null);
                }}
            />
        };

        handleUltyModalWrapper(true, modalSettings);
    };

    const handleOnPageChange = (e, paginationProps) => {
        setPagination({
            ...pagination,
            page: paginationProps.activePage
        });
        replaceHistory(filters, {...pagination, page: paginationProps.activePage});
    }

    const loadOrders = useCallback(async () => {
        setLoading(true);

        getOrders(state, {
            merchant: queryFilterMerchant || defaultFilters.merchantId,
            platforms: queryFilterPlatforms || defaultFilters.platformIds,
            brands: queryFilterBrands || defaultFilters.brandIds,
            status: queryFilterOrderStatus || defaultFilters.orderStatus,
            sd: queryFilterFrom || defaultFilters.dateRange.startDate.toISOString(),
            ed: queryFilterTo || defaultFilters.dateRange.endDate.toISOString(),
            displayId: queryFilterDisplayId || defaultFilters.displayId,
            page: pagination.page,
            size: pagination.pageSize
        }).then((orders => {
            setOrders(orders?.orders || []);
            setPagination(prev => ({...prev, ...(orders?.pagination || {
                totalItems: 0,
                totalPages: 1,
                page: 1,
                pageSize: 30
            })}));
            setLoading(false);
        }));
    }, [state, queryFilterMerchant, queryFilterPlatforms, queryFilterBrands, queryFilterOrderStatus, queryFilterFrom, queryFilterTo, queryFilterDisplayId, pagination.page, pagination.pageSize]);

    useEffect(() => {
        loadOrders();
    }, [loadOrders]);

    const replaceHistory = useCallback((filters, pagination) => {
        const filtersToQueryString = (filters) => {
            const strings = {
                merchant: filters.merchantId,
                brands: filters.brandIds,
                platforms: filters.platformIds,
                status: filters.orderStatus,
                page: filters.page,
                size: filters.size,
                from: filters.dateRange.startDate.toISOString(),
                to: filters.dateRange.endDate.toISOString(),
                displayId: filters.displayId
            };

            const queryStrings = Object.keys(strings).map(key => {
                let value = strings[key];

                if (!value) {
                    return '';
                }

                if (Array.isArray(value)) {
                    if(value.length > 0) {
                        value=value.join();
                    } else if (value.length === 0) {
                        return ''
                    }
                }

                return `&${key}=${value}`
            });

            const queryString = queryStrings.join('');
            return queryString.replace('&', '?');
        }
        history.replace({
            pathname: `/orders/${state}`,
            search: filtersToQueryString({
                ...filters,
                page: pagination.page,
                size: pagination.pageSize
            })
        });
    }, [history, state]);

    const handleFiltersChange = (filters) => {
        setFilters(filters);

        replaceHistory(filters, pagination);

        store.setDateRange(() => ({
                sd: filters.dateRange.startDate,
                ed: filters.dateRange.endDate
            }
        ))
    }

    return (
        <>
            { state !== 'current' && (
                <Segment>
                    <AdvancedFilters
                        defaultFilters={{
                            ...filters,
                            merchantId: queryFilterMerchant || (isProviderAPos(user.provider) ? user.provider.object.id : defaultFilters.merchantId),
                            brandIds: queryFilterBrands ? queryFilterBrands.split(/,/) : defaultFilters.brandIds,
                            platformIds: queryFilterPlatforms ? queryFilterPlatforms.split(/,/) : defaultFilters.platformIds,
                            orderStatus: queryFilterOrderStatus ? queryFilterOrderStatus.split(/,/) : defaultFilters.orderStatus,
                            dateRange: {
                                startDate: queryFilterFrom ? moment(queryFilterFrom).utc() : filters.dateRange.startDate,
                                endDate: queryFilterTo ? moment(queryFilterTo).utc() : filters.dateRange.endDate
                            },
                            displayId: queryFilterDisplayId || defaultFilters.displayId,
                        }}
                        onFiltersChange={handleFiltersChange}
                    >
                        <Form css={css` display: flex; flex-direction: column;`}>
                            <Form.Group widths={isMobile ? 'equal' : '16'}>
                                <AdvancedFilters.DateRangeFilter />
                                <AdvancedFilters.DisplayIdFilter />
                                {state === 'untreated' && (
                                  <AdvancedFilters.OrderStatusFilter />
                                )}
                            </Form.Group>
                            <Form.Group widths='equal'>
                                {user.provider.type === 'COMPANY' && <AdvancedFilters.PosFilter />}
                                <AdvancedFilters.BrandFilter />
                                <AdvancedFilters.PlatformFilter />
                            </Form.Group>
                        </Form>
                    </AdvancedFilters>
                    <div css={css`
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    `}>
                        {`${pagination.totalOrders || 0} ${t('order.orders_count')}`}
                        <div>
                            { showSummary && (
                                <>
                                    { store.dateRange.ed.valueOf() - store.dateRange.sd.valueOf() > 86400000 ? (
                                        <Popup content={t('order.summary_only_available_for_1_day')} trigger={<span><Button icon loading={loading} disabled>
                          <Icon name="cart arrow down"  />
                        </Button></span>} />
                                    ) : (
                                        <Button icon loading={loading} onClick={() => {
                                            defineOrderSummaryModal(orders);
                                        }}>
                                            <Icon name="cart arrow down"  />
                                        </Button>
                                    )}
                                </>
                            )}
                            <Download
                                state={state}
                                platforms={queryFilterPlatforms ? queryFilterPlatforms.split(/,/) : defaultFilters.platformIds}
                                brands={queryFilterBrands ? queryFilterBrands.split(/,/) : defaultFilters.brandIds}
                                merchants={queryFilterMerchant || (isProviderAPos(user.provider) ? user.provider.object.id : defaultFilters.merchantId)}
                                status={queryFilterOrderStatus ? queryFilterOrderStatus.split(/,/) : defaultFilters.orderStatus}
                                dateRange={{
                                    sd: queryFilterFrom || defaultFilters.dateRange.startDate.toISOString(),
                                    ed: queryFilterTo || defaultFilters.dateRange.endDate.toISOString()
                                }}
                            />
                        </div>
                    </div>
                </Segment>
            )}

            <Dimmer.Dimmable>
                <Dimmer active={loading} css={css`
                    z-index: 1 !important;
                `} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                <OrderList emptyText={emptyText} getStatusText={getStatusText} seeText={seeText} loading={loading} orders={orders||[]} pagination={pagination} handleOnPageChange={handleOnPageChange} />
            </Dimmer.Dimmable>
        </>
    )
}

OrderElement.propTypes = {
    emptyText: PropTypes.string.isRequired,
    getStatusText: PropTypes.func.isRequired,
    seeText: PropTypes.string.isRequired,
    showSummary: PropTypes.bool,
    state: PropTypes.oneOf(["finished", "current", "untreated"])
}

export default OrderElement;
