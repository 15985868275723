import useLocalStorage from 'beautiful-react-hooks/useLocalStorage'


const usePrintingOptions = () => {
    return useLocalStorage('ulty-printing-option', {
        isDisabled: false,
        printerType: 'escpos',
        connectionType:'bluetooth',
        printOnNewOrder: false,
        endMessage: "",
        ticketSizeInMillimeter: undefined,
        dpi: 203
    })
}

export {usePrintingOptions}
