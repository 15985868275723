/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {Fragment, useEffect,useState} from 'react';
import {Button, ButtonGroup, Checkbox, Icon, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import { MOBILE_THRESHOLD } from '../../../constants';


const MassActionBar = ({ selectionCount = 0, onViewSelection, onClearSelection, onSelectAll,canStillSelectItems=true }) => {
    const [t] = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

    const [isChecked,setChecked] = useState(!canStillSelectItems)

    const handleViewSelection = () => {
        if (selectionCount === 0) {
            return;
        }

        onViewSelection();
    }

    useEffect(() => {
        if(isChecked != !canStillSelectItems){
            setChecked(!canStillSelectItems);
        }
    },[canStillSelectItems])


    const debouncedCallback = useDebouncedCallback(value => {
        if(value){
            onSelectAll()
        }
        else{
            onClearSelection()
        }
    },[onSelectAll,onClearSelection],175)



    return (
        <div>
            <Button.Group>
                <Button color="teal"
                    onClick={() => setChecked((current) => {
                        const v = !current;
                        debouncedCallback(v);
                        return v;
                    })}
                    icon={isChecked ?'check circle outline' : 'circle outline' } />
              
                {!isMobile ? (
                    <>
                      <Button icon='eye'
                    basic color='teal' onClick={handleViewSelection}
                        content={!isMobile ? t('mass_action_bar.current_selection') : undefined}
                />
                    <Button color='teal' animated='vertical'
                        title={t('product_list.mass_actions.clear_selection')}
                        onClick={onClearSelection}
                    >
                        <Button.Content visible>
                            {selectionCount}
                        </Button.Content>
                        <Button.Content hidden>
                            <Icon name='remove' />
                        </Button.Content>
                    </Button>
                    </>
                    
                    ) : (
                    <Button color='teal'
                        basic
                        title={t('product_list.mass_actions.clear_selection')}
                        onClick={handleViewSelection}
                    >
                        <b>{selectionCount}</b>
                        
                    </Button>
                    )}

            </Button.Group>
        </div>
    )
}

MassActionBar.propTypes = {
    selectionCount: PropTypes.number,
    onViewSelection: PropTypes.func,
    onClearSelection: PropTypes.func,
    onSelectAll: PropTypes.func,
}

export default MassActionBar;
