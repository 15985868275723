/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Form, TextArea, Dropdown, Grid, Checkbox, Segment, Icon } from 'semantic-ui-react'
import { getProduct, saveItemPlatformPrices, saveProduct } from '../../../services/Product/Product.service';
import { notify } from 'react-notify-toast';
import Uploader from '../../Uploader'
import ModalExtraFields from './ModalExtraFields';
import useUser from '../../Shared/UserProvider/useUser';
import { useTranslation } from 'react-i18next';
import { getPropertyValue, wrapValueInObject } from '../../../helpers/object';
import { isProviderAPos, setProviderItemInventory } from '../../../services/Provider/Provider.service';
import { isItemProvidedByUserProvider } from '../../../services/Item/Item.service';
import { Link, useParams } from 'react-router-dom';
import { UltyInputText, UltyMoney } from '@ulty/ulty-ui'
import useBreadcrumb from '../../NavBar/useBreadcrumb';
import ItemAvailabilitySetter from '../../Items/ItemAvailabilitySetter';
import InventoryEdit from '../../Items/Inventory/InventoryEdit';
import { getCountryTaxRates } from '../../../services/Country/Country.service';
import PlatformPricesEdit from '../../Items/PlatformPrices/PlatformPricesEdit';


const ProductEdit = ({ history }) => {
    const { id } = useParams();
    const [t] = useTranslation();
    const { user, can } = useUser();
    const { setPaths } = useBreadcrumb();
    const [loading, setLoading] = useState(false);
    const [canEditItem, setCanEditItem] = useState(false);
    const [taxRates, setTaxRates] = useState([]);
    const [pristineProduct, setPristineProduct] = useState({});
    const [formState, setFormState] = useState({});

    const [form, setForm] = useState({
        type: 'PRODUCT',
        basePrice: 0,
        taxRate: null,
        image: null,
        containsAlcohol: false,
        isUncountable: false,
        externalId: '',
        maxOrderableQuantity: null,
        providerIds: [],
        inventory: {
            quantity: 0,
            sellPrice: null,
            unavailableUntil: null,
            externalId: ''
        },
        object: {
            name: '',
            description: '',
            barcode: '',
            promotionalCategory: null,
        },
        platforms: [],
    });

    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        (async function getTaxRates() {
            setTaxRates(await getCountryTaxRates(user.getUserCompany().country.id));
        })();
    }, [user]);

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/'
        }, {
            text: t('breadcrumb.catalog'),
            link: true,
            path: '/products'
        }, {
            text: form?.object?.name || t('global.loading'),
            link: true,
            path: `/products/${id}`
        }, {
            text: t('breadcrumb.edition'),
            link: false,
        }]);
    }, [form, id, t]);

    useEffect(() => {
        if (id) {
            setLoading(true);

            getProduct(id).then(product => {
                const keys = Object.keys(product.extraFields || {}), extra = {}
                keys.forEach(k => {
                    if (k.indexOf('extra_') === 0) {
                        extra[k] = product.extraFields[k]
                    }
                });

                setPristineProduct(product);

                setForm(prev => ({
                    ...prev,
                    id: product.id,
                    type: product.type,
                    providerIds: product.providers.map(p => p.id),
                    basePrice: product.basePrice,
                    taxRate: product.taxRate,
                    image: product.images.find(image => image.isDefault),
                    extraFields: extra,
                    containsAlcohol: typeof product.containsAlcohol !== 'undefined' ? product.containsAlcohol : true,
                    isUncountable: product.isUncountable,
                    externalId: product.externalId,
                    maxOrderableQuantity: product.maxOrderableQuantity,
                    pricesByPlatform: product.pricesByPlatform,
                    inventory: {
                        quantity: product.inventory?.quantity,
                        sellPrice: product.inventory?.sellPrice || null,
                        unavailableUntil: product.inventory?.unavailableUntil || null,
                        externalId: product.inventory?.externalId || '',
                    },
                    object: {
                        name: product.object.name,
                        barcode: product.object.barcode,
                        description: product.object.description,
                    }
                }));

                setCanEditItem(isItemProvidedByUserProvider(product, user));
            }).catch(e => {
                notify.show(`${t(`global.${e.message}`)}`, 'error');
                history.push('/products');
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setCanEditItem(true);
        }
    }, [history, id, t, user]);

    const handleChangeItem = ({ name, value, checked, type }) => {
        setForm(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }))

        setFormErrors(prev => ({
            ...prev,
            [name]: false,
        }))
    }

    const handleItemPlatformPriceChange = useCallback(({ name, value, type }) => {

        const valueToSet = type === 'RESET' ? pristineProduct[name] : value;
        setForm(prev => ({
            ...prev,
            [name]: valueToSet
        }))

        setFormState(prev =>({
            ...prev,
            [name]: type === 'RESET' ? undefined : 'touched',
        }));

        setFormErrors(prev => ({
            ...prev,
            [name]: false,
        }));
    }, [setForm, setFormErrors, pristineProduct])

    const handleChangeObject = (_evt, { name, value, checked, type }) => {
        setForm(prev => ({
            ...prev,
            object: {
                ...prev.object,
                [name]: type === 'checkbox' ? checked : value
            }
        }));

        setFormErrors(prev => ({
            ...prev,
            object: {
                ...prev.object,
                [name]: false,
            }
        }));
    }

    const handleChangeInventory = ({ name, value, checked, type }) => {
        setForm(prev => ({
            ...prev,
            inventory: {
                ...prev.inventory,
                [name]: type === 'checkbox' ? checked : value
            }
        }))
    }

    const handleChangeUncountable = ({ name, value, checked, type }) => {
        handleChangeItem({ name, value, checked, type })

        setForm(prev => ({
            ...prev,
            inventory: {
                ...prev.inventory,
                quantity: checked ? null : 0
            }
        }))
    }

    const handleChangeExtraFields = (_evt, { name, value }) => {
        setForm(prev => ({
            ...prev,
            extraFields: {
                ...prev.object.extraFields,
                [name]: value
            }
        }));
    }


    const checkErrors = () => {
        const mandatoryFields = ['basePrice', 'taxRate', 'object.name', 'object.barcode'];

        let hasErrors = false, errorObject = {};
        mandatoryFields.forEach(mandatoryField => {
            const fieldValue = getPropertyValue(form, mandatoryField);
            if (!fieldValue || `${fieldValue}`.trim() === '') {
                errorObject = {
                    ...wrapValueInObject(formErrors, mandatoryField, true),
                    ...errorObject,
                }

                hasErrors = true;
            }
        });
        setFormErrors(errorObject);
        return hasErrors;
    }

    const handleSubmit = async () => {
        setLoading(true)

        const isError = checkErrors()
        if (isError) {
            notify.show(t('product_edit.missing_fields'), 'error')
            setLoading(false)
            return;
        }

        try {
            let savedProduct = form;
            if (can('UPDATE', 'product')) {
                savedProduct = await saveProduct(form);
                setForm(savedProduct);
            }

            let inventory = null;
            if (isProviderAPos(user.provider)) {
                inventory = await setProviderItemInventory(user.provider.id, savedProduct.id, {
                    sellPrice: form.inventory.sellPrice,
                    quantity: form.inventory.quantity,
                    externalId: form.inventory.externalId
                })

                savedProduct.inventory = inventory;
            }
            if(formState.pricesByPlatform){
               let pricesByPlatform = await saveItemPlatformPrices(savedProduct.id,form.pricesByPlatform);
               savedProduct.pricesByPlatform = pricesByPlatform;
            }

            notify.show(t('global.registerSuccess'), 'success');
            history.push(`/products/${savedProduct.id}`);
        } catch (e) {
            if (e.message === 'ean_already_exists') {
                notify.show(`${t(`product_edit.${e.message}`)}`, 'error');
            } else {
                notify.show(t('global.anErrorOccurred'), 'error');
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Segment>
            <Form>
                <Grid css={css`
                  .column { padding-top: .5rem !important; padding-bottom: .5rem !important; }
                `}>
                    <Grid.Column width={16}>
                        <div style={{ textAlign: 'right', marginTop: '10px' }}>
                            <Button type="submit" color="teal" loading={loading} disabled={loading} onClick={handleSubmit}>{t('global.save')}</Button>
                        </div>
                    </Grid.Column>

                    <Grid.Column width={16}>
                        <Form.Field
                            control={Input}
                            name="name"
                            value={form.object.name}
                            disabled={!canEditItem}
                            onChange={handleChangeObject}
                            error={formErrors?.object?.name || false}
                            label={t('item_edit.name')}
                            placeholder={t('item_edit.name')}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form.Field
                            control={Input}
                            name="barcode"
                            value={form.object.barcode}
                            disabled={!canEditItem}
                            onChange={handleChangeObject}
                            error={formErrors?.object?.barcode || false}
                            label={t('item_edit.ean')}
                            placeholder={t('item_edit.ean')}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <UltyInputText
                            name="externalId"
                            readonly={!canEditItem}
                            value={form.externalId}
                            onChange={({ target }) => handleChangeItem({ name: target.name, value: target.value })}
                            label={t('item_edit.externalId')}
                            placeholder={t('item_edit.externalId')}
                        />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Form.Field
                            control={TextArea}
                            name="description"
                            value={form.object.description}
                            disabled={!canEditItem}
                            error={formErrors?.object?.description || false}
                            onChange={handleChangeObject}
                            label={t('item_edit.description')}
                            placeholder={t('item_edit.description')}
                        />
                    </Grid.Column>
                    <Grid.Column tablet={8} mobile={16} computer={8} css={css`
                      display: flex !important;
                      align-items: center !important;
                      flex-direction: column !important;
                    `}>
                        <Form.Field css={css` width: 100%; `}>
                            <label>{t('item_edit.image')}</label>
                            <Uploader
                                disabled={!canEditItem}
                                initial={form.image?.url || ""}
                                withConversion={true}
                                onSrcChange={(url) => {
                                    const image = url ? { url, isDefault: true } : null;
                                    handleChangeItem({ name: 'image', value: image })
                                }}
                            />
                        </Form.Field>
                        {form.id && canEditItem &&
                            <Grid.Row>
                                <Grid.Column textAlign='right'>
                                    <Link to={`/items/${form.id}/platforms`}><Icon name='settings' />{t('item_edit.manageImages')}</Link>
                                </Grid.Column>
                            </Grid.Row>}
                    </Grid.Column>
                    <Grid.Column tablet={8} mobile={16} computer={8}>
                        <Form.Group widths={2}>
                            <Form.Field>
                                <UltyMoney
                                    readonly={!canEditItem}
                                    name="basePrice"
                                    label={t('item_edit.basePrice')}
                                    amount={form.basePrice}
                                    onMoneyAmountChange={(e, amt) => handleChangeItem({ name: e.target.name, value: amt })}
                                    currencyCode={user.getCompanyCurrency()}
                                    locale={user.getCompanyLocale()}
                                />
                            </Form.Field>

                            <Form.Field>
                                <label>{t('item_edit.taxRate')}</label>
                                <Dropdown
                                    placeholder={t('item_edit.taxRate')}
                                    fluid
                                    selection
                                    name="taxRate"
                                    value={form.taxRate}
                                    disabled={!canEditItem}
                                    error={formErrors.taxRate || false}
                                    onChange={(e, { name, value }) => handleChangeItem({ name, value })}
                                    options={taxRates.map(taxRate => ({
                                        text: `${taxRate}%`,
                                        value: taxRate,
                                        key: taxRate
                                    }))}
                                />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths={2}>
                            <Form.Field>
                                <UltyInputText
                                    type="number"
                                    name="maxOrderableQuantity"
                                    min={0}
                                    value={form.maxOrderableQuantity}
                                    onChange={e => handleChangeItem({ name: e.target.name, value: e.target.value })}
                                    label={t('item_edit.maxOrderableQuantity')}
                                />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths={2}>
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Checkbox
                                    label={t('item_edit.contains_alcohol')}
                                    disabled={!canEditItem}
                                    checked={form.containsAlcohol}
                                    onChange={(e, { name, value, type, checked }) =>
                                        handleChangeItem({ name, value, type, checked })}
                                    name="containsAlcohol"
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Checkbox
                                    label={t('item_edit.is_uncountable')}
                                    disabled={!canEditItem}
                                    checked={form.isUncountable}
                                    onChange={(_e, { name, value, type, checked }) => {
                                        handleChangeUncountable({ name, value, checked, type })
                                    }}
                                    name="isUncountable"
                                />
                            </Form.Field>
                        </Form.Group>
                        {!isProviderAPos(user.provider) && (
                            <>
                                <PlatformPricesEdit
                                    isLoading={loading}
                                    basePrice={form.basePrice}
                                    pricesByPlatform={form.pricesByPlatform}
                                    onChange={handleItemPlatformPriceChange.bind(this)}
                                />
                            </>
                        )}

                        {isProviderAPos(user.provider) &&
                            <>
                                <InventoryEdit
                                    type={form.type}
                                    inventory={form.inventory}
                                    isUncountable={form.isUncountable}
                                    isLoading={loading}
                                    basePrice={form.basePrice}
                                    pricesByPlatform={form.pricesByPlatform}
                                    onChange={({ name, value, type }) => name === 'pricesByPlatform' ? handleItemPlatformPriceChange({ name, value, type }) : handleChangeInventory({ name, value })}
                                />

                                {id &&
                                    <ItemAvailabilitySetter
                                        css={css` margin-top: 1rem; `}
                                        itemId={id}
                                        onChange={(unavailableUntil) => handleChangeInventory({ name: "unavailableUntil", value: unavailableUntil })} />
                                }
                            </>
                        }
                    </Grid.Column>
                    {isProviderAPos(user.provider) &&
                        <ModalExtraFields extraFields={form.extraFields} handleChange={handleChangeExtraFields} />}
                    <Grid.Column width={16}>
                        <div style={{ textAlign: 'right', marginTop: '10px' }}>
                            <Button type="submit" color="teal" loading={loading} disabled={loading} onClick={handleSubmit}>{t('global.save')}</Button>
                        </div>
                    </Grid.Column>
                </Grid>
            </Form>
        </Segment>
    )
}

ProductEdit.propTypes = {
    merchant: PropTypes.object,
    history: PropTypes.object.isRequired,
};

export default ProductEdit;
