import React, { useState } from "react";

const BreadcrumbContext = React.createContext();

export const BreadcrumbContextProvider = ({ children }) => {
  const [paths, setPaths] = useState([]);

  return (
    <BreadcrumbContext.Provider
      value={{ paths, setPaths }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export default BreadcrumbContext;