import produce from 'immer';


const itemElementsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ITEM_ELEMENTS':
            return produce(state, draft => {
                draft.itemElements = action.payload;
            });
        case 'SET_ITEM_ELEMENT_ITEMS':
            return produce(state, draft => {
                const itemElementIdx = state.itemElements.findIndex(i => i.id === action.payload.itemElementId);
                draft.itemElements[itemElementIdx].items = action.payload.items;
            });
        case 'SET_ITEM_ELEMENT_POSITION': {
            return produce(state, draft => {
                const itemElementIdx = state.itemElements.findIndex(i => i.id === action.payload.itemElementId);
                draft.itemElements.splice(action.payload.to, 0, draft.itemElements.splice(itemElementIdx, 1)[0]);
            });
        }
        case 'ADD_ITEM_ELEMENT':
            return produce(state, draft => {
                draft.itemElements.push(action.payload);
            });
        case 'REMOVE_ITEM_ELEMENT': {
            return produce(state, draft => {
                draft.itemElements = state.itemElements.filter(productElement => productElement.id !== action.payload);
            });
        }
        case 'SET_ITEM_ELEMENT_FIELD': {
            return produce(state, draft => {
                const updatedItemElementIdx = state.itemElements.findIndex(m => m.id === action.payload.itemElementId);
                const updatedItemElement = state.itemElements[updatedItemElementIdx];
                draft.itemElements[updatedItemElementIdx] = { ...updatedItemElement, [action.payload.field]: action.payload.value };
            });
        }
        default:
            throw Error(`Action of itemElementsReducer: '${action.type}' not found`);
    }
};

export default itemElementsReducer;
