import {useEffect, useState} from 'react';
import { Form, Header, Button, Checkbox, Radio, Input } from 'semantic-ui-react'
import { notify } from 'react-notify-toast';
import {patchMerchant} from '../../../services/PointOfSale/PointOfSale.service';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useUser from '../../Shared/UserProvider/useUser';

const OptionsForm = ({ pointOfSale, reload }) => {
  const [t] = useTranslation();
  const {user} = useUser();

  const [canCreateItems, setCanCreateItems] = useState(false);
  const [canCreateCategories, setCanCreateCategories] = useState(false);
  const [canExtendCategories, setCanExtendCategories] = useState(false);
  const [stockUnavailableThreshold, setStockUnavailableThreshold] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCanCreateItems(pointOfSale.canCreateItems);
    setCanCreateCategories(pointOfSale.canCreateCategories);
    setCanExtendCategories(pointOfSale.canExtendCategories);
    setStockUnavailableThreshold(pointOfSale.stockUnavailableThreshold);
  }, [pointOfSale]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true)

    try {
      await patchMerchant(pointOfSale.id, {
        canCreateItems, canCreateCategories, canExtendCategories, stockUnavailableThreshold
      });
      notify.show(t('global.registerSuccess'), 'success')
    } catch (err) {
      notify.show(t('global.anErrorOccurred'), 'error')
    }

    await reload();

    setLoading(false)
  }

  return (
    <Form>
      <Header>{t('administration.merchants.manage_merchant')}</Header>

      <Form.Field
        control={Input}
        name="unavailable_threshold"
        value={stockUnavailableThreshold}
        onChange={(evt, dt) => setStockUnavailableThreshold(dt.value)}
        label={t('administration.merchants.unavailable_threshold')}
        placeholder={t('administration.merchants.unavailable_threshold_placeholder')}
      />

      {user.provider.type === 'COMPANY' && (
        <>
          <Form.Field>
            <label>{t('administration.merchants.manage_items')}</label>
          </Form.Field>
          <Form.Field>
            <Checkbox toggle checked={canCreateItems} onChange={(evt, { checked }) => setCanCreateItems(checked)} label={t('administration.merchants.authorize_products')} />
          </Form.Field>

          <Form.Field>
            <label>{t('administration.merchants.manage_sets')}</label>
          </Form.Field>
          <Form.Field>
            <Radio
              label={t('administration.merchants.sets_read_only')}
              name='checked'
              checked={!canCreateCategories && !canExtendCategories}
              onChange={() => {
                setCanCreateCategories(false);
                setCanExtendCategories(false);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label={t('administration.merchants.sets_extend')}
              name='checked'
              checked={!canCreateCategories && canExtendCategories}
              onChange={() => {
                setCanCreateCategories(false);
                setCanExtendCategories(true);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label={t('administration.merchants.sets_create')}
              name='checked'
              checked={canCreateCategories}
              onChange={() => {
                setCanCreateCategories(true);
                setCanExtendCategories(true);
              }}
            />
          </Form.Field>
        </>
      )}

      <div style={{ textAlign: 'right' }}>
        <Button color="teal" loading={loading} disabled={loading} onClick={handleSubmit}>{t('global.save')}</Button>
      </div>
    </Form>
  );
}

OptionsForm.propTypes = {
    pointOfSale: PropTypes.object.isRequired
}

export default OptionsForm;
