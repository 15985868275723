/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Button, Divider, Icon, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { addItemCategory, getItemCategories, removeItemCategory } from '../../services/Item/Item.service';
import { getCategorySets } from '../../services/Category/Category.service';
import useUser from '../Shared/UserProvider/useUser';

const ItemCategories = ({ itemId, canEdit }) => {
  const {user} = useUser();
  const [t] = useTranslation();
  const [categories, setCategories] = useState([]);
  const [categorySets, setCategorySets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [categorySet, setCategorySet] = useState(null);
  const [categorySlot, setCategorySlot] = useState(null);
  const [category, setCategory] = useState(null);

  const loadCategories = useCallback(async () => {
    setLoading(true);

    const [categories, sets] = await Promise.all([
      getItemCategories(itemId),
      getCategorySets(),
    ]);

    setCategories(categories);
    setCategorySets(sets);

    setLoading(false);
  }, [itemId]);

  useEffect(() => {
    setCategoriesOptions(categorySets.filter(set => {
      return set.provider.type === user.provider.type;
    }).map(set => ({
      label: set.name,
      value: set.id,
      slots: set.slots.map(slot => ({
        label: slot.name,
        value: slot.id,
        categories: slot.categories.filter(c => !categories.map(cc => cc.id).includes(c.id)).map(category => ({
          label: category.name,
          value: category.id,
        }))
      })),
    })));
  }, [categorySets, categories, user?.provider]);

  const handleItemCategoryRemoval = async (categoryId) => {
    setLoading(true);

    const categories = await removeItemCategory(itemId, categoryId);
    setCategories(categories);

    setLoading(false);
  }

  const handleAddItemToCategory = async () => {
    setLoading(true);

    const categories = await addItemCategory(itemId, {
      categorySetId: categorySet,
      categorySlotId: categorySlot || categoriesOptions.filter(set => set.value === categorySet).flatMap(set => set.slots)[0],
      categoryId: category,
    });
    setCategories(categories);

    setCategorySet(null);
    setCategorySlot(null);
    setCategory(null);

    setLoading(false);
  }

  useEffect(() => {
    if (itemId) {
      loadCategories();
    }
  }, [itemId, user.provider.id, loadCategories]);

  return (
    <>
      {categorySets && categorySets.length > 0 && categories.map(category => {
        const set = categorySets.find(cs => cs.id === category.categorySlot.categorySet.id);
        if (!set) return (<></>)

        return (
          <div key={category.id}>
            <div css={css`
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            `}>
              <Breadcrumb>
                <Breadcrumb.Section link to={`/categories/${category.categorySlot.categorySet.id}`}>{category.categorySlot.categorySet.name}</Breadcrumb.Section>
                <Breadcrumb.Divider icon='right chevron' />
                { set.slots.length > 1 && (
                  <>
                    <Breadcrumb.Section >{category.categorySlot.name}</Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right chevron' />
                  </>
                )}
                <Breadcrumb.Section active>{category.name}</Breadcrumb.Section>
              </Breadcrumb>
              { canEdit && set.provider.type === user.provider.type && (
              <Button icon loading={loading} disabled={loading} color="red" size="mini" onClick={() => handleItemCategoryRemoval(category.id)}>
                <Icon name="times" />
              </Button>
              )}
            </div>
            <Divider />
          </div>
        );
      })}

      { canEdit && (
        <div
          css={css`
                display: flex;
                flex-direction: column;
                row-gap: .4rem;

                .menu-container {
                  display: flex;
                  flex-direction: row;
                  column-gap: 1em;
                  align-items: center;
                }

                label {
                    font-weight: bold;
                }

                .ui.selection.dropdown {
                    line-height: 1.1em;
                }

                .ui.form.tiny {
                    min-width: 14em;
                }

                .text {
                    font-size: .78571429rem;
                }

                .dropdown {
                    .divider.text {
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            `}
        >
          <label>{t('item_categories.add_category')}</label>

          <div className='menu-container'>
            <div className='ui form mini'>
              <Dropdown
                loading={loading}
                selection
                clearable
                selectOnBlur={false}
                placeholder={t('item_categories.menu')}
                options={categoriesOptions.map(set => ({
                  value: set.value,
                  text: set.label,
                  key: set.label,
                }))}
                value={categorySet}
                onChange={(evt, { value }) => {
                  setCategorySet(value);
                  setCategorySlot(null);
                  setCategory(null);
                }}
              />
            </div>

            <div className='ui form mini'>
              {categorySet && categoriesOptions.filter(set => set.value === categorySet).flatMap(set => set.slots).length > 1 && (
                <>
                  <Icon name='right chevron' />
                  <Dropdown
                    css={css`margin-left: 1em;`}
                    loading={loading}
                    selection
                    clearable
                    selectOnBlur={false}
                    placeholder={t('item_categories.submenu')}
                    options={categoriesOptions.filter(set => set.value === categorySet).flatMap(set => set.slots).map(slot => ({
                      value: slot.value,
                      text: slot.label,
                      key: slot.label,
                    }))}
                    value={categorySlot}
                    onChange={(evt, { value }) => {
                      setCategorySlot(value);
                      setCategory(null);
                    }}
                  />
                </>

              )}
            </div>

            <div className='ui form mini'>
              {categorySet && (categorySlot || categoriesOptions.filter(set => set.value === categorySet).flatMap(set => set.slots).length === 1) && (
                <>
                  <Icon name='right chevron' />
                  <Dropdown
                    css={css`margin-left: 1em;`}
                    loading={loading}
                    selection
                    clearable
                    selectOnBlur={false}
                    placeholder={t('item_categories.category')}
                    options={categoriesOptions.filter(set => set.value === categorySet).flatMap(set => set.slots).filter(slot => slot.value === categorySlot || (categoriesOptions.filter(set => set.value === categorySet).flatMap(set => set.slots).length === 1)).flatMap(slot => slot.categories).map(cat => ({
                      value: cat.value,
                      text: cat.label,
                      key: cat.label,
                    }))}
                    value={category}
                    onChange={(evt, { value }) => {
                      setCategory(value);
                    }}
                  />
                </>
              )}
            </div>

            <div className='ui form mini'>
              { category && (
                <Button icon color="teal" size="mini" loading={loading} disabled={loading} onClick={handleAddItemToCategory}>
                  <Icon name="add" />
                  {t('item_categories.add')}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

ItemCategories.propTypes = {
  itemId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default ItemCategories;
