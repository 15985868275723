import React, { useEffect, useState } from 'react'

import { Tab, Form, Button, Header, Message, Grid,Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { notify } from 'react-notify-toast';
import { usePrinter } from '../../../printing/services/printer.service';
import { AdminPrintingOptions } from '../../../printing/components/AdminPrintingOptions/AdminPrintingOptions';
import { registerWithPolling, registerWithPush } from '../../../devices/device.provider';

const AdminDevice = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [hasRegistered, setRegistered] = useState(false);

  const {printerInfo} = usePrinter()

  useEffect(() => {
    const token = localStorage.getItem("PUSH-TOKEN");
    if(token){
      try {
        const parsed = JSON.parse(token);
        setRegistered(parsed?.id);
      }catch{
        localStorage.removeItem("PUSH-TOKEN");
        localStorage.removeItem("TOKEN_SYNC");
      }
    }
  }, [])

  useEffect(() => {
    const handle = async (evt) => {
      setLoading(true);
      const token = evt.token;
      await registerWithPush(token, true);

      notify.show(t('administration.device.success'), 'success');

      setRegistered(true);
      setLoading(false);
    }
    window.addEventListener("FirebaseToken", handle, false);
    return () => window.removeEventListener("FirebaseToken", handle);
  }, [t]);

  const handleConnect = async () => {
    setLoading(true);

    try {
      if (window.webkit?.messageHandlers) {
        window.webkit.messageHandlers.getToken.postMessage('');
        return;
      }

      const token = window.Android.getToken();
      await registerWithPush(token, true);

      notify.show(t('administration.device.success'), 'success');

      setRegistered(true);
    } catch (e) {
      notify.show(t('global.anErrorOccurred'), 'error');
    }

    setLoading(false);
  }

  const handleAuthConnect = async () => {
    setLoading(true);

    try {
      await registerWithPolling();

      notify.show(t('administration.device.success'), 'success');

      setRegistered(true);
    } catch (e) {
      notify.show(t('global.anErrorOccurred'), 'error');
    }

    setLoading(false);
  }

  return (
    <Tab.Pane>
      <Segment basic>
      <Form>
        <Header>{t('administration.device.header')}</Header>

        <Grid>
          <Grid.Column width={16}>
            {hasRegistered && (
              <Message color="teal">{t('administration.device.already_linked')}</Message>
            )}
            <Button disabled={loading} loading={loading} color="teal" size="tiny" onClick={handleConnect}>{t('administration.device.link_to_account')}</Button>
          </Grid.Column>

          { window.Android?.storeAuthToken && window.Android?.acknowledgeOrder && (
            <Grid.Column width={16}>
              <Button disabled={loading} loading={loading} color="teal" size="tiny" onClick={handleAuthConnect}>{t('administration.device.link_to_auth_account')}</Button>
            </Grid.Column>
          )}

          { window.Android?.quit && (
            <Grid.Column width={16}>
              <Button disabled={loading} loading={loading} color="teal" size="tiny" onClick={() => {
                window.Android.quit();
              }}>{t('administration.device.quit')}</Button>
            </Grid.Column>
          )}
          { window.Android?.resetToken && (
            <Grid.Column width={16}>
              <Button disabled={loading} loading={loading} color="teal" size="tiny" onClick={() => {
                window.Android.resetToken();
              }}>{t('administration.device.reset')}</Button>
            </Grid.Column>
          )}
        </Grid>
      </Form>
      </Segment>

      {printerInfo.isDeviceCompatible && (<AdminPrintingOptions></AdminPrintingOptions>)}
    </Tab.Pane>
  )
}

export default AdminDevice
