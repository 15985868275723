/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {useEffect, useState} from 'react';
import {Button, Divider, Form, Header, Icon} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {getItemTypes} from '../../../services/ItemType/ItemType.service';
import {notify} from 'react-notify-toast';
import UltyIndeterminateCheckboxGroup from '../../Shared/UltyIndeterminateCheckboxGroup/UltyIndeterminateCheckboxGroup';
import useUser from '../../Shared/UserProvider/useUser';
import {UltyInputText} from '@ulty/ulty-ui';
import AdvancedMenuFilters from './AdvancedMenuFilters';
import { isProviderAPos } from '../../../services/Provider/Provider.service';

const categoryStyles = css`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column;

    &>div {
        padding: 1em;
    }
  }
`

const AdvancedFilters = ({defaultFilters, onFiltersChange}) => {
    const {user} = useUser();
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [itemTypesFilterOptions, setItemTypesFilterOptions] = useState([]);
    const [containsAlcoholFilterOptions, setContainsAlcoholFilterOptions] = useState([
        {label: t('product_list.advanced_filters.general.alcohol.with'), checked: defaultFilters.containsAlcohol !== undefined ? defaultFilters.containsAlcohol : true, value: true},
        {label: t('product_list.advanced_filters.general.alcohol.without'), checked: defaultFilters.containsAlcohol !== undefined ? !defaultFilters.containsAlcohol : true, value: false}
    ]);
    const [imageFilterOptions, setImageFilterOptions] = useState([
        {label: t('product_list.advanced_filters.general.image.with'), checked: defaultFilters.withImage !== undefined ? defaultFilters.withImage : true, value: true},
        {label: t('product_list.advanced_filters.general.image.without'), checked: defaultFilters.withImage !== undefined ? !defaultFilters.withImage : true, value: false}
    ]);
    const [unavailableFilterOptions, setUnavailableFilterOptions] = useState([
        {label: t('product_list.advanced_filters.inventory.availability.available'), checked: defaultFilters.unavailableUntil !== undefined ? !defaultFilters.unavailableUntil : true, value: false},
        {label: t('product_list.advanced_filters.inventory.availability.unavailable'), checked: defaultFilters.unavailableUntil !== undefined ? defaultFilters.unavailableUntil : true, value: true}
    ]);

    const [filters, setFilters] = useState(defaultFilters);

    useEffect(() => {
        (async function loadItemTypes() {
            try {
                setIsLoading(true);
                const types = await getItemTypes(['PRODUCT', 'MENU', 'EXTRA']);
                setItemTypesFilterOptions(types.map(type => ({
                    label: t(`global.enums.itemTypes.${type.name}`),
                    checked: defaultFilters.types !== undefined ? defaultFilters.types.includes(type.name) : true,
                    value: type.name
                })));
            } catch (e) {
                console.log(e);
                notify.show(`${t('global.anErrorOccurred')}`, 'error');
            } finally {
                setIsLoading(false);
            }
        })();
    }, [defaultFilters.types, t]);

    const handleIndeterminateInclusiveValuesCheckboxGroupChange = (selectedValues, filterOptionsValues) => {
        const newFilters = { ...filters };
        const noneSelected = selectedValues.length === 0;

        if (noneSelected) {
            delete newFilters.types;
        } else {
            newFilters.types = selectedValues;
        }

        setFilters(newFilters);
    }

    const handleIndeterminateExclusiveValuesCheckboxGroupChange = (filterName, selectedValues) => {
        const newFilters = { ...filters };
        const trueValueSelected = selectedValues.includes(true);
        const falseValueSelected = selectedValues.includes(false);

        const allSelected = trueValueSelected && falseValueSelected;
        const noneSelected = !trueValueSelected && !falseValueSelected;

        if (allSelected || noneSelected) {
            delete newFilters[filterName];
        } else {
            newFilters[filterName] = !!selectedValues[0];
        }

        setFilters(newFilters);
    }

    const defineMaxQuantityFilter = (maxQuantityValue) => {
        const newFilters = { ...filters };

        if (maxQuantityValue === '') {
            const { maxQuantity, ...filtersWithoutMaxQuantity } = newFilters;
            setFilters(filtersWithoutMaxQuantity);
        } else {
            newFilters.maxQuantity = maxQuantityValue;
            setFilters(newFilters);
        }
    }

    const handleMaxQuantityChange = (e) => {
        defineMaxQuantityFilter(e.target.value);
    }

    const handleSetMaxQuantityToPopMinimumThreshold = () => {
        defineMaxQuantityFilter(user.provider.object.stockUnavailableThreshold);
    }

    const handleFilters = () => {
        onFiltersChange(filters);
    };

    return(
        <Form css={css`
          display: flex;
          flex-direction: column;
        `}>
            <Divider horizontal>
                <Header as='h4'>
                    <Icon name='globe' color='teal' />
                    {t('product_list.advanced_filters.general.general')}
                </Header>
            </Divider>

            <div
                css={categoryStyles}>
                <UltyIndeterminateCheckboxGroup
                    parentLabel={t('product_list.advanced_filters.general.item_types')}
                    loading={isLoading}
                    options={itemTypesFilterOptions}
                    onChange={({checkedValues}) => {
                        setItemTypesFilterOptions(prev => (
                            prev.map(p => {
                                p.checked = checkedValues.includes(p.value)
                                return p
                            })
                        ));
                        handleIndeterminateInclusiveValuesCheckboxGroupChange(checkedValues, itemTypesFilterOptions.map(filterOption => filterOption.value));
                    }}
                />

                <UltyIndeterminateCheckboxGroup
                    parentLabel={t('product_list.advanced_filters.general.alcohol.alcohol')}
                    options={containsAlcoholFilterOptions}
                    onChange={({checkedValues}) => {
                        setContainsAlcoholFilterOptions(prev => (
                            prev.map(p => {
                                p.checked = checkedValues.includes(p.value)
                                return p
                            })
                        ));
                        handleIndeterminateExclusiveValuesCheckboxGroupChange('containsAlcohol', checkedValues);
                    }}
                />

                <UltyIndeterminateCheckboxGroup
                    parentLabel={t('product_list.advanced_filters.general.image.image')}
                    options={imageFilterOptions}
                    onChange={({checkedValues}) => {
                        setImageFilterOptions(prev => (
                            prev.map(p => {
                                p.checked = checkedValues.includes(p.value)
                                return p
                            })
                        ));
                        handleIndeterminateExclusiveValuesCheckboxGroupChange('withImage', checkedValues);
                    }}
                />

                <AdvancedMenuFilters filters={filters} setFilters={setFilters} />
            </div>

            {isProviderAPos(user.provider) &&
            <>
                <Divider horizontal>
                    <Header as='h4'>
                        <Icon name='warehouse' color='teal' />
                        {t('product_list.advanced_filters.inventory.inventory')}
                    </Header>
                </Divider>

                <div css={categoryStyles}>
                    <UltyIndeterminateCheckboxGroup
                        parentLabel={t('product_list.advanced_filters.inventory.availability.availability')}
                        options={unavailableFilterOptions}
                        onChange={({checkedValues}) => {
                            setUnavailableFilterOptions(prev => (
                                prev.map(p => {
                                    p.checked = checkedValues.includes(p.value)
                                    return p
                                })
                            ));
                            handleIndeterminateExclusiveValuesCheckboxGroupChange('unavailableUntil', checkedValues);
                        }}
                    />


                    <div
                        css={css`
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                        `}
                    >
                        <UltyInputText
                            label={t('product_list.advanced_filters.inventory.quantity.available_quantity')}
                            type='number'
                            min={0}
                            value={filters.maxQuantity ?? ''}
                            onChange={handleMaxQuantityChange}
                        />
                        <Button
                            type='button'
                            color='teal'
                            basic
                            onClick={handleSetMaxQuantityToPopMinimumThreshold}
                        >
                            {`${t('product_list.advanced_filters.inventory.quantity.pos_minimum_threshold')}: ${user.provider.object.stockUnavailableThreshold}`}
                        </Button>
                    </div>

                </div>
            </>
            }

            <div css={css`
              display: flex;
              margin-top: 1rem;
              justify-content: flex-end;
            `}>
                <Button
                    color='teal'
                    onClick={handleFilters}
                >
                    {t('global.validate')}
                </Button>
            </div>
        </Form>
    )
}

export default AdvancedFilters;
