/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React, { useState } from 'react'

import { Table, Segment, Header, Dropdown } from 'semantic-ui-react'
import DownloadMetrics from './DownloadMetrics';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UltyMoney } from '@ulty/ulty-ui';
import useUser from '../Shared/UserProvider/useUser';

const TopProducts = ({ dataTop, filters }) => {
  const [t] = useTranslation();
  const {user} = useUser();

  const [type, setType] = useState('CA');

  return (
    <React.Fragment>
      <Segment color="teal">
        <div css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 25px !important; 
        `}>
          <Header size="small" css={css` margin-bottom: 0 !important;  `}>{t('home.top_products_header_' + type)}</Header>
          <div css={css`
          `}>
            <Dropdown
              options={[{
                text: t('home.ca'),
                value: 'CA',
                key: 'CA'
              }, {
                text: t('home.volume'),
                value: 'VOLUME',
                key: 'VOLUME'
              }]}
              value={type}
              onChange={(evt, { value }) => setType(value)}
            />
            <DownloadMetrics
              css={css`
                  align-self: center;
                  margin-left: 1rem !important;
                `}
              dateRange={filters.dateRange}
              merchantId={filters.merchantId}
              brands={filters.brandIds}
              platformIds={filters.platformIds}
              type={"TOP"}
            />
          </div>
        </div>

        <Table basic='very' fixed singleLine celled css={css`
          a {
            color: #000 !important;
          }

          width: 100%;
        `}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={type === 'CA' ? 4 : 2}>{type === 'CA' ? t('home.ca') : '#'}</Table.HeaderCell>
              <Table.HeaderCell width={type === 'CA' ? 12 : 14}>{t('home.product')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {((type === 'CA' ? dataTop?.topCA : dataTop?.top)||[]).map(dt => {
              return (
                <Table.Row key={dt.item_id}>
                  <Table.Cell>
                    { type === 'CA' ? (
                    <UltyMoney
                        readonly
                        currencyCode={user.getCompanyCurrency()}
                        locale={user.getCompanyLocale()}
                        amount={parseInt(dt.sum)}
                    />
                    ) : dt.sum }
                  </Table.Cell>
                  <Table.Cell><Link to={`/products/${dt.item_id}`}>{dt.name}</Link></Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Segment>
    </React.Fragment>
  )
}

export default TopProducts
