import { registerDevice } from "../services/Device/Device.service";

export const registerWithPush = async (token, manual = false) => {
    const {device} = getDeviceInfoFromStorage()
    const result = await registerDevice(token, manual,device?.id ?? undefined);
    localStorage.setItem("TOKEN_SYNC", `${(new Date()).getTime()}`);
    localStorage.setItem("PUSH-TOKEN", JSON.stringify({...result, type:"PUSH"}));
};

export const registerWithPolling = async () => {
    const {device} = getDeviceInfoFromStorage();
    const result = await registerDevice("", false,device?.id ?? undefined);
    localStorage.setItem("TOKEN_SYNC", `${(new Date()).getTime()}`);
    localStorage.setItem("PUSH-TOKEN", JSON.stringify({...result, type:"POLLING"}));

    if(window.Android && window.Android.storeAuthToken){
        window.Android.storeAuthToken(result.authToken);
    }
}

export const retriggerRegistration =  async () => {
    const notificationType = retrieveRegistrationType();
    if (window.Android.storeAuthToken && (!notificationType || notificationType === "POLLING")) {
        await registerWithPolling();
      } else if(window.Android.getToken && (!notificationType || notificationType === "PUSH")) {
        const token = window.Android.getToken();
        await registerWithPush(token,false);
      }
}
export const retrieveLastSync = () => {
    const {lastSynced} = getDeviceInfoFromStorage();
    return lastSynced
}
export const retrieveRegistrationType = () => {
    const {device} = getDeviceInfoFromStorage();
    return device?.type;
}

const getDeviceInfoFromStorage = () => {
    const lastSyncedStr = localStorage.getItem("TOKEN_SYNC");
    const deviceStr = localStorage.getItem("PUSH-TOKEN");
    const lastSynced = lastSyncedStr ? parseInt(lastSyncedStr) : null;
    const device = deviceStr ? tryParse(deviceStr) : null;
    return { lastSynced, device };
}

const tryParse = (str) => {
    try{
        return JSON.parse(str);
    }
    catch(e){
        return null;
    }
}
