/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { DndContext, DragOverlay, PointerSensor, rectIntersection, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { VirtuosoGrid } from 'react-virtuoso'
import { gridComponents, SortableItem } from '../Item/SortableItem'
import { createPortal } from 'react-dom'
import Item from '../Item/Item'
import { useState } from 'react'

const DraggableList = ({ items, isParent, onDeleteItem, onSortItems }) => {
    const [activeId, setActiveId] = useState(null)

    const sensors = useSensors(
      useSensor(PointerSensor, {
          // Explicitly declare an activationConstraint with distance 0 is
          // necessary to interact with an item (eg: click on delete button)
          // without triggering the drag event instead.
          // Drag event is only triggered after moving the item
          // https://docs.dndkit.com/api-documentation/sensors
          activationConstraint: {
              distance: 0,
          },
      }),
    )

    const getItemIndex = (id) => items.findIndex(item => item.id === id)
    const activeIndex = activeId ? getItemIndex(activeId) : -1

    const handleOnDragEnd = (event) => {
        const {active, over} = event

        if (!over) {
            return
        }

        if (active.id !== over.id) {
            const oldIndex = items.findIndex(item => item.id === active.id)
            const newIndex = items.findIndex(item => item.id === over.id)
            const sortedItems = arrayMove(items, oldIndex, newIndex)
              .map((item, idx) => ({...item, position: idx}))
            onSortItems(sortedItems)
        }

        setActiveId(null)
    }


    return (
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragStart={({active}) => {
            setActiveId(active.id)
        }}
        onDragEnd={handleOnDragEnd}
        onDragCancel={() => setActiveId(null)}
      >
          <div css={css`
                    width: 100%;
                    height: 100%;
                `}>
              <SortableContext
                items={items}
                strategy={rectSortingStrategy}
              >
                  <VirtuosoGrid
                    useWindowScroll
                    style={{
                        width: '100%',
                    }}
                    components={gridComponents}
                    data={items}
                    itemContent={(_, item) => (
                      <SortableItem
                        key={item.id}
                        item={item}
                        onDeleteItem={onDeleteItem}
                        canDelete={!isParent}
                      />
                    )}
                  />
              </SortableContext>
          </div>
          {createPortal(
            <DragOverlay adjustScale={false}>
                {activeId ? (
                  <Item
                    item={items[activeIndex]}
                    canDelete={false}
                  />
                ) : null}
            </DragOverlay>,
            document.body,
          )}
      </DndContext>
    )
}

export { DraggableList }
