import React, { useState, useEffect } from 'react'
import Notifications from 'react-notify-toast'
import 'semantic-ui-css/semantic.min.css'
import Index from './components/Index'
import 'moment/locale/fr'
import moment from 'moment'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n/i18n'
import {
    BrowserRouter, Switch, Route,
} from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'

import UserStore from './components/Auth/store'
import Login from './components/Auth/Login'
import ForgotPassword from './components/Auth/ForgotPassword'
import ResetPassword from './components/Auth/ResetPassword'
import { UltyModalProvider } from './components/Shared/UltyModal/UltyModalContext'
import { UltyModalWrapperProvider } from './components/Shared/UltyModalWrapper/UltyModalWrapperContext'
import { UserProvider } from './components/Shared/UserProvider/UserContext'
import ErrorBoundary from './components/ErrorBoundary'
import UltyPrompt from './components/Shared/UltyPrompt/UltyPrompt'
import { FeatureProvider } from './components/Shared/FeatureProvider/FeatureContext'
import { PlatformProvider } from './components/Shared/PlatformProvider/PlatformProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const locale = localStorage.getItem('ulty-lng')?.split('-')[0] || 'fr'
moment.locale(locale)

const NoMatch = () => (
  <h3>Forbidden</h3>
)

const queryClient = new QueryClient()

const App = () => {
    const [promptOpen, setPromptOpen] = useState(true)
    const storageHandler = (e) => {
        if (
          e.key === 'auth-token' &&
          (e.oldValue !== e.newValue || !e.newValue) &&
          !window.location.pathname.endsWith('/auth/login')
        ) {
            window.location.reload()
        }
    }
    useEffect(() => {
        window.addEventListener('storage', storageHandler)
        return () => {
            window.removeEventListener('storage', storageHandler)
        }
    }, [])

    return (
      <I18nextProvider i18n={i18n}>
          <ErrorBoundary>
              <QueryClientProvider client={queryClient}>
                  <BrowserRouter getUserConfirmation={(message, callback) => {
                      return UltyPrompt(
                        promptOpen,
                        setPromptOpen,
                        message,
                        callback,
                        i18n,
                      )
                  }}>
                      <CompatRouter>
                          <Notifications/>
                          <UserStore.Provider>
                              <UserProvider>
                                  <FeatureProvider>
                                      <PlatformProvider>
                                          <UltyModalWrapperProvider>
                                              <UltyModalProvider>
                                                  <Switch>
                                                      <Route path="/auth/login" component={Login}/>
                                                      <Route path="/auth/forgot" component={ForgotPassword}/>
                                                      <Route path="/auth/reset-password" component={ResetPassword}/>
                                                      <Route path="/auth" component={NoMatch}/>
                                                      <Route path="/" component={Index}/>
                                                  </Switch>
                                              </UltyModalProvider>
                                          </UltyModalWrapperProvider>
                                      </PlatformProvider>
                                  </FeatureProvider>
                              </UserProvider>
                          </UserStore.Provider>
                      </CompatRouter>
                  </BrowserRouter>
                  <ReactQueryDevtools initialIsOpen={false}/>
              </QueryClientProvider>
          </ErrorBoundary>
      </I18nextProvider>
    )
}

export default App
