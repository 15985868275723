/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Header } from 'semantic-ui-react'
import { notify } from 'react-notify-toast';

import { useTranslation } from 'react-i18next';
import { removeCategorySet } from '../../services/Category/Category.service';

const CategoryRemoveSet = ({ set, afterSubmit }) => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true)

    try {
      await removeCategorySet(set.id);

      afterSubmit();
      notify.show(t('global.registerSuccess'), 'success');
    } catch (e) {
      if (e.message === 'not_empty_set') {
        notify.show(`${t(`category_set.${e.message}`)}`, 'error');
      } else {
        notify.show(t('global.anErrorOccurred'), 'error');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form>
      <Grid css={css`
                .column { padding-top: .5rem !important; padding-bottom: .5rem !important; }
              `}>
        <Grid.Column width={16}>
          <Header>{t('category_set.remove_header', { name: set.name})}</Header>
        </Grid.Column>
        <Grid.Column width={16}>
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <Button type="submit" color="red" loading={loading} disabled={loading} onClick={handleSubmit}>{t('global.delete')}</Button>
          </div>
        </Grid.Column>
      </Grid>
    </Form>
  )
}

CategoryRemoveSet.propTypes = {
  set: PropTypes.object.isRequired,
  afterSubmit: PropTypes.func.isRequired,
};

export default withRouter(CategoryRemoveSet);
