/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React from 'react';

import { Icon } from 'semantic-ui-react';

import OrderStore from '../Orders/OrderStore';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { MOBILE_THRESHOLD } from '../../constants';

const SyncIcon = () => {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
  const store = OrderStore.useContainer();

  if (isMobile) {
    return (<React.Fragment/>);
  }

  return (
    <div css={css`
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      width: 20px;
      padding: .92857143em 1.14285714em;
      margin-left: 10px;
      margin-right: 18px;

      .icon-container {
        position: relative;
      }
      
      .sync-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin: -10px 0 0 -10px;
        color: white;
      }

      .rotate {
        -webkit-animation:spin 4s linear infinite;
        -moz-animation:spin 4s linear infinite;
        animation:spin 4s linear infinite;
      }

      @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
      @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
      @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
    `}>
      <div className="icon-container">
        <Icon name="sync" className={`sync-icon ${store.sync ? `rotate` : `no-rotate`}`} disabled={!store.sync} />
      </div>
    </div>
  )
}

export default SyncIcon
