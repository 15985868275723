/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { UltyInputText } from '@ulty/ulty-ui'
import { RecipientRemover } from './RecipientRemover'
import { RecipientAdder } from './RecipientAdder'
import { Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

const RecipientsEditor = ({recipients, onChange}) => {
    const [t] = useTranslation()

    return (<div css={css`
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    `}>
        <UltyInputText
          readonly
        >
           <Icon name='users'/>
            <b>{t('reports.performance_configuration.recipients')}</b>
        </UltyInputText>

        <div css={css`
            display: flex;
            row-gap: 1rem;
            flex-direction: column;
        `}>
            {recipients.map(recipient => (
              <RecipientRemover
                key={recipient}
                recipient={recipient}
                onRemove={(value) => {
                    onChange(recipients.filter(recipient => recipient !== value))
                }}/>
            ))}

            {recipients.length < 5 &&
              <RecipientAdder
                onAdd={(value) => {
                    onChange([...recipients, value])
                }}
              />
            }
        </div>
    </div>)
}

export { RecipientsEditor }
