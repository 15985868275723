import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'countries';

export const getCountryTaxRates = async (countryCode) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${countryCode}/taxRates`);
    } catch (e) {
        console.error(`getCountryTaxRates error: ${e}`);
        throw e;
    }
}
