import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Button} from 'semantic-ui-react'
import { notify } from 'react-notify-toast';
import {patchMerchant} from '../../../services/PointOfSale/PointOfSale.service';
import {useTranslation} from 'react-i18next';
import DayTimePeriods from './DayTimePeriods';
import {getTimePeriodsOfADayFromAvailabilities} from '../../../services/TimePeriod/timePeriod.service';

const HoursForm = ({ merchant,reload }) => {
    const [t] = useTranslation();

    const DAYS = [
        { value: 'monday', text: t('days.monday') },
        { value: 'tuesday', text: t('days.tuesday') },
        { value: 'wednesday', text: t('days.wednesday') },
        { value: 'thursday', text: t('days.thursday') },
        { value: 'friday', text: t('days.friday') },
        { value: 'saturday', text: t('days.saturday') },
        { value: 'sunday', text: t('days.sunday') },
    ];

    const [availabilities, setAvailabilities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        setAvailabilities(Array.isArray(merchant.serviceAvailability) && merchant.serviceAvailability||[]);
    }, [merchant.serviceAvailability]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)

        let hours = []

        let isError = []
        availabilities.forEach((a) => {
            let times = []
            a.time_periods.forEach(h => {
                if (h.start_time === '' && h.end_time === '') {
                    return
                }

                if (h.start_time === '' || h.end_time === '' || !h.start_time.match(/[0-9][0-9]:[0-9][0-9]/) || !h.end_time.match(/[0-9][0-9]:[0-9][0-9]/)) {
                    isError.push(a.day_of_week)
                    return
                }

                times.push(h)
            })

            a.time_periods = times

            hours.push(a)
        })

        if (isError.length > 0) {
            setErrors(isError)
            notify.show(t('administration.hours.error'), 'error')
            setLoading(false)

            return
        }

        try {
            const patched = await patchMerchant(merchant.id, { serviceAvailability: hours });
            setAvailabilities(Array.isArray(patched.serviceAvailability) && patched.serviceAvailability||[]);
            reload();
            notify.show(t('global.registerSuccess'), 'success')
        } catch (e) {
            notify.show(t('global.anErrorOccurred'), 'error')
        } finally {
            setLoading(false)
        }
    }

    const handleAvailabilityChange = (day, timePeriodIndex, timePeriod) => {
        const curAvailabilities = availabilities.map(a => a);

        let availabilityToUpdate = curAvailabilities.find(availability => availability.day_of_week === day);
        if (!availabilityToUpdate) {
            availabilityToUpdate = {
                day_of_week: day,
                time_periods: []
            };
            curAvailabilities.push(availabilityToUpdate);
        }
        if(timePeriod){
            availabilityToUpdate.time_periods[timePeriodIndex] = timePeriod
        }
        else if(availabilityToUpdate.time_periods[timePeriodIndex]){
            availabilityToUpdate.time_periods.splice(timePeriodIndex,1);
        }
      

        setAvailabilities(curAvailabilities);
    }

    const handleReplicateDay = (day) => {
        const availabilityToReplicate = availabilities.find(availability => availability.day_of_week === day.value);
        if (availabilityToReplicate) {
            setAvailabilities(DAYS.map(d => ({
                day_of_week: d.value,
                time_periods: availabilityToReplicate.time_periods.map(a => a),
            })));
        }
    }

    const handleOnError = (day, timePeriodIndex, error) => {
        const prefix = `${day}-${timePeriodIndex}-`;
        const errorCode = `${prefix}${error}`;

        if (error) {
            setErrors(prev => [...prev, errorCode]);
        } else {
            setErrors(prev => prev.filter(err => !err.includes(prefix)));
        }
    }

    return (
        <>
            {DAYS.map((day, idx) => (
                <DayTimePeriods
                    key={day.value}
                    label={day.text}
                    timePeriods={getTimePeriodsOfADayFromAvailabilities(day, availabilities)}
                    onChange={(timePeriodIndex, timePeriod) => handleAvailabilityChange(day.value, timePeriodIndex, timePeriod)}
                    onError={(timePeriodIndex, error) => handleOnError(day.value, timePeriodIndex, error)}
                    replicate={idx === 0 ? () => handleReplicateDay(day) : null}
                />
            ))}

            <div style={{ textAlign: 'right' }}>
                <Button color="teal" loading={loading} disabled={loading || errors.length > 0} onClick={handleSubmit}>{t('global.save')}</Button>
            </div>
        </>
    )
}

HoursForm.propTypes = {
    reload: PropTypes.func,
    merchant: PropTypes.shape({
        id: PropTypes.string.isRequired,
        serviceAvailability: PropTypes.arrayOf(PropTypes.shape({
            day_of_week: PropTypes.oneOf([
                    'monday',
                    'tuesday',
                    'wednesday',
                    'thursday',
                    'friday',
                    'saturday',
                    'sunday'
                ]).isRequired,
            time_periods:PropTypes.arrayOf(PropTypes.shape({
                start_time:PropTypes.string,
                end_time:PropTypes.string
            }))
        }))
    }).isRequired
};

HoursForm.defaultProps = {
    reload: () => Promise.resolve(),
};

export default HoursForm
