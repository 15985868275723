import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ItemsList from './ItemsList'
import ProductDetails from './ProductDetails'
import ProductEdit from './ProductAddModal/ProductEdit';

const Products = () => {
    return (
        <Switch>
            <Route path="/products/edit/:id" component={ProductEdit} />
            <Route path="/products/edit" component={ProductEdit} />
            <Route path="/products/:id" component={ProductDetails} />
            <Route path="/products" component={ItemsList} />
        </Switch>
    )
}

export default Products
