
export const getClientName = (client) => {
    try {
        const m = (client||'').match(/^([^()]*) \((.*)\)$/);
        if (m) {
            return m[1];
        }
    } catch {}

    return client||'N/C';
}