/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {useEffect, useState, Fragment} from 'react';
import {getItemImages} from '../../services/Item/Item.service';
import {Button, Image} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';


const ImageLibrary = ({ itemId, initialImage, onPickImage, afterSubmit }) => {
    const [t] = useTranslation();
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        getItemImages(itemId).then(images => {
            setImages(images);
            setSelectedImage(images.find(image => image.url === initialImage || image.isDefault) || null);
        }).catch(e => {
            console.log(e);
        })
    }, [initialImage, itemId]);

    const pickSelectedImage = () => {
        onPickImage(selectedImage);
        afterSubmit();
    }

    const getDefaultLabel = (image) => {
        const label = {
            ribbon: 'right',
            content: t('item_image_platform_association.default_image')
        };

        return image.isDefault ? label : null;
    }

    return (
        <Fragment>
            <div css={css`
        display: flex;
        justify-content: space-around;
        align-content: space-around;
        flex-wrap: wrap;
        `}>
                {images.map(image =>
                    <Image
                        css={css`
                    margin: .5rem 0;
                    cursor: pointer;
                    border: ${selectedImage?.id === image.id ?
                            '8px solid teal;' :
                            '1px solid grey;'}
                    object-fit: contain;
                    &:hover { opacity: .6; }
                    `}
                        key={image.id}
                        size={'small'}
                        alt={image.url}
                        src={image.url}
                        label={getDefaultLabel(image)}
                        onClick={() => { setSelectedImage(image); }}
                    />
                )}
            </div>

            <div css={css`
            text-align: right;
            margin: 1rem;
            `}>
                <Button
                    onClick={afterSubmit}
                >{t('global.cancel')}</Button>
                <Button
                    onClick={pickSelectedImage}
                    color='teal'
                >{t('itemImages.pick')}</Button>
            </div>
        </Fragment>
    )
}

ImageLibrary.propTypes = {
    itemId: PropTypes.string.isRequired,
    initialImage: PropTypes.string,
    onPickImage: PropTypes.func.isRequired,
    afterSubmit: PropTypes.func,
}

export default ImageLibrary
