export function groupBy(arr, key,valGetter)  {
    return arr.reduce((map, item) => setOrUpdate(map,key(item), valGetter ?valGetter(item): item), new Map());
}

export function setOrUpdate(map, id, value) {
    return  map.set(id, [
      ...(map.get(id)|| []),
      value
     ]);
}

export function getSorter(propGetter,direction = "asc") {
    return (u1,u2) => {
        const directionModifier = direction === 'asc' ? 1 : -1;
        const pU1 = propGetter(u1);
        const pU2 = propGetter(u2) 
        if(pU1 > pU2 ) return 1*directionModifier;
        if(pU1 < pU2) return -1*directionModifier;
        return 0;
      }
  }
  
  export function sortAsc(arr,propGetter) {
    return [...arr].sort(getSorter(propGetter,'asc'));
   }
  
export function sortDesc(arr,propGetter) {
    return [...arr].sort(getSorter(propGetter,'desc'));
}