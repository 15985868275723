/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import {memo} from 'react';
import {Card, Checkbox, Image, Label} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import Thumbnail from '../../assets/default-thumbnail.png';
import ProductCardContentExtra from '../Products/ItemsList/ProductCardContentExtra';
import ExtraCardContentExtra from '../Extra/ExtraCardContentExtra';
import MenuCardContentExtra from '../Menu/MenuCardContentExtra';
import useUser from '../Shared/UserProvider/useUser';
import moment from 'moment';
import { useWebPSupportCheck } from "react-use-webp-support-check";
import { isProviderAPos } from '../../services/Provider/Provider.service';

const ItemCardProviderTags = ({provider}) => {
    return (
        <div css={css`
          display: flex;
          flex-direction: column;
          margin-right: 14px;
        `}>
            <Label color={provider.type === 'COMPANY' ? 'blue' : 'green'} ribbon='right' css={css`width: 50%;`}>
                {provider.object.name}
            </Label>
        </div>
    );
}

const ItemCard = ({ item, isSelected, onSelect }) => {
    const {user} = useUser();
    const itemInventory = item.inventories
        .find(inventory => inventory.providerId === user.provider.id);
    const isItemExtra = item.type === 'EXTRA';
    const isItemMenu = item.type === 'MENU';
    const isItemProduct = item.type === 'PRODUCT';
    const supportsWebP = useWebPSupportCheck();

    const getCardLink = () => {
        if (isItemExtra) {
            return `/extras/edit/${item.id}`;
        }

        if (isItemMenu) {
            return `/menus/edit/${item.id}`;
        }

        if (isItemProduct) {
            return `/products/${item.id}`;
        }

        return '';
    }

    const handleSelectionChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onSelect(item);
    }

    const getInventoryUnavailabilityUntilColor = () => {
        const isAvailableUntilADate = moment(itemInventory.unavailableUntil).isValid();
        if(isAvailableUntilADate) {
            return 'orange';
        }

        if (itemInventory.unavailableUntil === Infinity) {
            return 'red';
        }

        return 'teal';
    }

    const getItemImage = (item) => {
        const defaultImage = (item.images || []).find(image => image.isDefault);

        if (!defaultImage?.url) {
            return Thumbnail;
        }

        if (defaultImage.url.indexOf('images.ulty.fr') === -1) {
            return defaultImage.url;
        }

        const path = defaultImage.url.replace(/http[s]?:\/\/images.ulty.fr\//, '');
        if (supportsWebP) {
            return `https://thumbnails.ulty.fr/200x200/webp/${path}`;
        }
        return `https://thumbnails.ulty.fr/200x200/png/${path}`;
    }

    return (
        <Card
            {...(isSelected && {color: itemInventory ? getInventoryUnavailabilityUntilColor() : 'teal'})}
            as={Link}
            to={getCardLink}
            css={css`
              .checkbox {
                width: 100%;
                height: 100%;
                padding-left: 12.5%;
                padding-top: 12.5%;
              }

              .label.corner.basic {
                border-bottom: none;
                border-right: none;
                background: none transparent !important;
              }
            `}
        >
            <Label color={itemInventory ? getInventoryUnavailabilityUntilColor() : 'teal'} basic as="div" size="large" corner="left" content={
                <Checkbox checked={isSelected}
                          onChange={handleSelectionChange} />
            } />
            <ItemCardProviderTags provider={item.provider} />

            <Image
                src={getItemImage(item)}
                wrapped ui={false}
                css={css`
                  img {
                    max-height: 200px;
                    height: 200px !important;
                    object-fit: contain;
                    width: auto !important;
                    margin: auto;
                    max-width: 100%;
                  }



                  height: 200px;
                  background: #fff !important;`
                } />

            <Card.Content>
                <>
                    <Card.Header>{item.object.name}</Card.Header>
                    <Card.Description css={css`
                      display: -webkit-box;
                      -webkit-line-clamp: 4;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                    `}>{item.object.description}</Card.Description>
                </>
            </Card.Content>

            <Card.Content
                extra
                onClick={handleSelectionChange}
            >
                <div css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `} >
                    { isItemProduct && isProviderAPos(user.provider) && itemInventory && (
                        <ProductCardContentExtra
                            item={item}
                            basePrice={item.basePrice}
                            inventory={itemInventory}
                            color={isSelected ? getInventoryUnavailabilityUntilColor() : ''}
                        />
                    )}

                    { isItemExtra && isProviderAPos(user.provider) && itemInventory && (
                        <ExtraCardContentExtra
                            inventory={itemInventory}
                            color={isSelected ? getInventoryUnavailabilityUntilColor() : ''}
                        />
                    )}

                    { isItemMenu && isProviderAPos(user.provider) && itemInventory && (
                        <MenuCardContentExtra
                            basePrice={item.basePrice}
                            inventory={itemInventory}
                            color={isSelected ? getInventoryUnavailabilityUntilColor() : ''}
                        />
                    ) }
                </div>
            </Card.Content>
        </Card>
    );
};

ItemCard.propTypes = {
    item: PropTypes.object.isRequired
};

export default memo(ItemCard);
