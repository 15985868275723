import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'itemTypes';

export const getItemTypes = async (types) => {
    let queryParams = '';
    if (types?.length > 0) {
         queryParams = `?types=${types}`;
    }

    return call(METHODS.GET, ROUTE_PREFIX, queryParams);
}
