/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect,useMemo } from 'react'

import { Item, Icon, Segment, Header, Button, Pagination,Popup,Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import PropTypes from 'prop-types';
import OrderStatusLabel from '../OrderStatusLabel/OrderStatusLabel';
import useUser from '../../Shared/UserProvider/useUser';
import {UltyInputText, UltyMoney} from '@ulty/ulty-ui';
import { MOBILE_HIGH_THRESHOLD } from '../../../constants';

import humanizeDuration from '../../../helpers/humanizeDuration'
import {addMinutes, addSeconds} from 'date-fns'


const canExpire = (o) => {
  if(o.status !== 'RECEIVED') return false;

  const expirationDate = getExpirationDate(o);
  if(!expirationDate) return false;

  return !isInPast(expirationDate);
}

const getExpirationDate = (o) => {

  if(o.receivedOrderLifetime){
    return addSeconds(new Date(o.receivedAt),o.receivedOrderLifetime);
  }
  if(o.platform.module === 'ubereats'){
    return addMinutes(new Date(o.receivedAt),6)
  }
  return null;
}

const isInPast = (date) => {
    const pickupDate = date
    const now = new Date();
    return pickupDate < now;
}

const getHumanRemaningTime = (time) => humanizeDuration(new Date(),time,{round: true, units: ['d', 'h', 'm'],largest:1 })


const ExpirationTime = ({order}) => {

  const expirationDate = useMemo(() => getExpirationDate(order),[order]);
  const remainingSeconds = expirationDate && ((expirationDate - new Date()) / 1000)
  const color = useMemo(() => {
    if(remainingSeconds > (60*15)) return "green"
    if(remainingSeconds > (60*10)) return "yellow"
    if(remainingSeconds > (60*6)) return "orange"
    return  "red"
  },[remainingSeconds])

  return <>
     {canExpire(order) && expirationDate && (
      <Label attached='top left' ribbon color={color} size='tiny' icon="clock outline" detail={getHumanRemaningTime(expirationDate)}/>
  )}
  </>
}

const OrderList = ({ emptyText, getStatusText, seeText, orders, loading, pagination, handleOnPageChange, displayDelivery }) => {
  const [t] = useTranslation();
  const {user} = useUser();
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_HIGH_THRESHOLD}px)`);

  const [mustRefresh,setRefresh] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRefresh(true);
    }, 1000*60);

    return () => clearTimeout(timer);
  });


  const isScheduledLater = (o) => {
    if(!o.pickupAt) return false;

    const pickupDate = new Date(o.pickupAt);
    const inOneHour = new Date()
    return pickupDate > inOneHour;
  }

  const isPastSchedule = (o) => {
    if(!o.pickupAt) return false;

    const pickupDate = new Date(o.pickupAt);
    return isInPast(pickupDate);
  }

  const getHumanPickupTime = (pickupAt) => humanizeDuration(pickupAt,new Date(),{round: true, units: ['d', 'h', 'm'],largest:1 })
  const formatScheduledHour = (o) => {
    return  t('order.scheduled_pickup_in', { dt:getHumanPickupTime(o.pickupAt)});
  }

    return (
        <>
            <Item.Group divided>
                {orders.length > 0 && orders.map(order => {
                    return (
                        <Item key={order.id}>
                            { isMobile ? (
                                  <Segment>
                                    <div css={css`
                                      display: flex;
                                    `}>
                                        <Item.Image size='tiny' src={order.platform.logo} />
                                        <div css={css`
                                          display: flex;
                                          flex-direction: column;
                                          padding-left: .8rem;
                                          width: 100%;
                                        `}>
                                            <div css={css`
                                              display: flex;
                                              align-items: center;
                                              justify-content: space-between;
                                              flex-direction: row;
                                            `}>
                        <span css={css`
                          font-weight: bold;
                          font-size: 1.2em;
                        `}>{order.displayId}</span>
                                                <div>
                                                    <OrderStatusLabel status={order.status} />
                                                </div>
                                            </div>
                                            <div css={css`
                                              display: flex;
                                              margin-top: 1rem;
                                              align-items: center;
                                              flex-direction: row;
                                              justify-content: space-between;

                                              .bold {
                                                font-weight: bold;
                                              }
                                            `}>
                                              <span className='bold'>
                                                {order.brand.name}</span>
                                                <div className={isScheduledLater(order) ? '' : 'bold'}>
                                                  {!canExpire(order) && order.pickupAt && !isPastSchedule(order) &&
                                                  ( <Popup
                                                    trigger={<span>
                                                      <Icon name="shipping fast" ></Icon>
                                                      {getHumanPickupTime(order.pickupAt)}
                                                      </span>
                                                      }
                                                    content={`${t('order.pickup_at')} ${new Date(order.pickupAt).toLocaleString()}`}
                                                    on={['hover', 'click']}
                                                />)
                                                }
                                                <ExpirationTime order={order} />

                                              </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Item.Content css={css`
                                    `}>
                                        <Item.Meta css={css`
                                          display: flex;
                                          justify-content: space-between;
                                          align-items: center;
                                        `}>
                                            <UltyMoney
                                                readonly
                                                currencyCode={user.getCompanyCurrency()}
                                                locale={user.getCompanyLocale()}
                                                amount={order.price}
                                            />
                                            <span className='stay'><Icon name="" />{t('order.num_products', { n: `${order.nbProducts}`})}</span>
                                            <span className='stay'><Icon name="" />{order.orderType ? t(`order.type_${order.orderType}`) : 'N/C'}</span>
                                            <Button as={Link} color="teal" size="mini" to={`/orders/${order.id}`}>
                                                {seeText}
                                                <Icon name='right chevron' />
                                            </Button>
                                        </Item.Meta>
                                    </Item.Content>
                              </Segment>
                            ) : (
                                <>
                                    <Item.Image size='tiny' src={order.platform.logo} />

                                    <Item.Content>
                                        <Item.Header style={{ position: 'relative', width: '100%' }}>
                                            {`${order.brand.name} - ${order.platform.name} - ${order.displayId}`}
                                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                                <OrderStatusLabel status={order.status} />
                                            </div>
                                        </Item.Header>

                          <Item.Meta css={css`
                            display: flex;
                            align-items: center;
                            & > *:not(:last-child) {
                              margin-right: 1rem !important;
                            }
                          `}>
                            <UltyMoney
                                readonly
                                currencyCode={user.getCompanyCurrency()}
                                locale={user.getCompanyLocale()}
                                amount={order.price}
                            />
                            <UltyInputText
                                readonly
                                value={t('order.num_products', { n: `${order.nbProducts}`})}
                            />
                            <UltyInputText
                                readonly
                                value={order.orderType ? t(`order.type_${order.orderType}`) : 'N/C'}
                            />
                          </Item.Meta>
                          <Item.Description style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                            {mustRefresh && isScheduledLater(order) &&  ( <Popup
                              trigger={<b>{formatScheduledHour(order)}</b>}
                              content={`${t('order.pickup_at')} ${new Date(order.pickupAt).toLocaleString()}`}
                              on={['hover', 'click']}
                          />)}
                            {mustRefresh && getStatusText(order)}
                            {displayDelivery && order.delivery?.status && (<div css={css`
                              color: rgba(0,0,0,.6);
                              margin: 0.5em 0 0 0;
                            `}>
                              <Icon name="shipping fast" />&nbsp;&nbsp;&nbsp;{t(`order.delivery.${order.delivery.status}`)}
                            </div>)}
                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                              <Button as={Link} color="teal" floated='right' to={`/orders/${order.id}`}>
                                {seeText}
                                <Icon name='right chevron' />
                              </Button>
                            </div>
                          </Item.Description>
                          <Item.Extra>
                          </Item.Extra>
                        </Item.Content>
                      </>
                  )}
                </Item>
            )
          })}
          {!loading && orders.length === 0 && (
              <Segment placeholder>
                <Header icon>
                  <Icon name="trophy" />
                  {emptyText}
                </Header>
              </Segment>
          )}
        </Item.Group>
        { pagination && (
        <Segment basic textAlign="center">
          <Pagination firstItem={null}
                      lastItem={null}
                      pointing
                      siblingRange={isMobile ? 0 : 1}
                      secondary
                      activePage={pagination.page}
                      totalPages={pagination.totalPages}
                      onPageChange={handleOnPageChange} />
        </Segment>
        )}
      </>
  );
}

OrderList.propTypes = {
    emptyText: PropTypes.string.isRequired,
    getStatusText: PropTypes.func.isRequired,
    seeText: PropTypes.string.isRequired,
    orders: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool.isRequired,
    displayDelivery: PropTypes.bool
}

export default OrderList;
