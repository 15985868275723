import { useState } from "react"
import { createContainer } from "unstated-next"
import { getCurrentOrders } from "../../helpers/api"

import moment from 'moment';
import 'moment/locale/fr';

const OrderStore = () => {
  const [orders, setOrders] = useState([])

  const [opened, setOpened] = useState(true);
  const [sync, setSync] = useState(false);
  const [busyPrepTime, setBusyPrepTime] = useState(60);
  const [inError, setInError] = useState(false);
  
  const [toasts, setToasts] = useState([]);

  const [dateRange, setDateRange] = useState({
    sd: moment().utc().add(-1, 'day').startOf('day'),
    ed: moment().utc().endOf('day')
  });
  const [orderPage, setOrderPage] = useState('');
  const [orderPagination, setOrderPagination] = useState(1);
  const [selectedPlatform, setSelectedPlatform] = useState([]);

  const reload = async () => {
    try {
      const res = await getCurrentOrders();

      if (res.status === "SUCCESS") {
        setOrders(res.data)
      }
    } catch(e) {
      console.log(e);
    }
  }

  const addToast = (id) => {
    setToasts(prev => [...prev, id]);
  }

  const getToast = (id) => {
    return toasts.filter(o => o.order.id === id);
  }

  return {
    orders, setOrders,

    opened, setOpened,
    sync, setSync,
    busyPrepTime, setBusyPrepTime,
    inError, setInError,

    dateRange, setDateRange,

    orderPage, setOrderPage,
    orderPagination, setOrderPagination,
    selectedPlatform, setSelectedPlatform,

    toasts, addToast, setToasts, getToast,

    reload,
  }
}

export default createContainer(OrderStore)
