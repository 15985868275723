/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import OrderItemsTable from './OrderItemsTable/OrderItemsTable';
import OrderItemsRich from './OrderItemsRich/OrderItemsRich';
import {Button} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {createRef, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {convertOrderItemsToFulfillmentIssues, setFulfillmentIssues, setPickingMethod} from '../../../../services/Order/Order.service';
import {notify} from 'react-notify-toast';
import { OrderPickingScanProvider, useOrderPickingScan } from '../OrderScanPicking';
import OrderPickupIncomplete from '../OrderScanPicking/OrderPickupIncomplete';

import Barcoder from 'barcoder';
import { UltyModalWrapperContext } from '../../../Shared/UltyModalWrapper/UltyModalWrapperContext';


const orderItemsListDisplayMode = {
    LINES: 1,
    PRODUCTS: 2
};

const OrderModifyingActions =  ({
    isLoading,
    onReset,
    onSave,
    orderItemsWithFulfillmentIssue,
    orderItems,
    order
}) => {

    const [t] = useTranslation();
    const actionRef = createRef();
    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext)

    const { scannedItemsMap, pickingMode } = useOrderPickingScan();

    const scannableItemsInfo = useMemo(() => {
        return orderItems
            .filter(item => item.object?.barcode && Barcoder.validate(item.object?.barcode))
            .map(oi => {
                    const fulfillmentIssue = orderItemsWithFulfillmentIssue.find(oiwfi => oiwfi.id === oi.id);
                    const scannedItems = scannedItemsMap.get(oi.id) || 0;
                    return {
                        ...oi,
                        scannedItems,
                        fulfillmentIssue,
                        expectedQuantity: fulfillmentIssue ? fulfillmentIssue.quantity : oi.quantity
                    }
            });

    },[orderItemsWithFulfillmentIssue,orderItems,scannedItemsMap]);

    useEffect(() => {
        if(scannableItemsInfo.every(s => s.expectedQuantity === s.scannedItems)){
            setTimeout(()=>actionRef?.current?.scrollIntoView({behavior: 'smooth'}));
        }
    },[scannableItemsInfo]);

    const triggerSave = async (orderItemsToCheck) => {

        try {

            if(orderItemsToCheck.length > 0) {
                await setPickingMethod(order,"SCAN",orderItemsToCheck.map(oi => oi.id))
            }
        }catch{
            notify.show(t('global.anErrorOccurred'), 'error')
        }
        return onSave();
    }

    const handleSave = async () => {
        if (pickingMode === 'SCANNING') {
            const orderItemsToCheck = scannableItemsInfo.filter(item => item.expectedQuantity !== 0);

            const orderItemsErrors = orderItemsToCheck.map((item) => {
                const {scannedItems,expectedQuantity} = item;
                if(scannedItems === expectedQuantity) return;

                return {
                        item,
                        expected: expectedQuantity,
                        found: scannedItems
                }
            }).filter(t => !!t);

            if (orderItemsErrors.length > 0) {
                const orderItemsWithoutError = orderItemsToCheck.filter(t => t.scannedItems === t.expectedQuantity)
                const modalSettings = {
                    size: 'tiny',
                    component: <OrderPickupIncomplete items={orderItemsErrors} onSubmit={async () => {
                        await triggerSave(orderItemsWithoutError);
                    }} />
                };

                handleUltyModalWrapper(true, modalSettings);
                return;
            }

            return await triggerSave(orderItemsToCheck);
        }
        return onSave();
    }


    return <div ref={actionRef} css={css`
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}>
      <Button
          color='red'
          loading={isLoading}
          disabled={isLoading}
          onClick={onReset}
      >
          {t('global.cancel')}
      </Button>
      <Button
          color='blue'
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSave}
      >
          {t('global.save')}
      </Button>
  </div>



}

const OrderItemsList = ({displayMode, order, isModifying, onChange, onSave, onCancel}) => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [orderItemsWithFulfillmentIssues, setOrderItemsWithFulfillmentIssues] = useState([]);
    const [orderItemsReplacements, setOrderItemsReplacements] = useState([]);

    const reset = useCallback(() => {
        const filterFulfillmentIssueWithNoParent = order.orderItems.filter(oi => oi.fulfillmentIssue && !oi.fulfillmentIssueParentId)
        setOrderItemsWithFulfillmentIssues(filterFulfillmentIssueWithNoParent)
        let filterFulfilmentIssueWithParent = order.orderItems.filter(oi => oi.fulfillmentIssue && oi.fulfillmentIssueParentId)
        setOrderItemsReplacements(filterFulfilmentIssueWithParent)

        const fulfillmentIssues = convertOrderItemsToFulfillmentIssues(filterFulfillmentIssueWithNoParent, filterFulfilmentIssueWithParent)
        onChange(fulfillmentIssues)
    }, [order])

    useEffect(() => {
        reset();
    }, [reset]);


    const clearOrderItemFulfillmentIssue = (orderItem) => {
        const getOrderItemsWithFulfillmentIssues = (prev) => {
            return prev.filter(p => p.id !== orderItem.id)
        }
        const newOrderItemWithFulfillmentIssue = getOrderItemsWithFulfillmentIssues()
        setOrderItemsWithFulfillmentIssues(newOrderItemWithFulfillmentIssue)

        const fulfillmentIssues = convertOrderItemsToFulfillmentIssues(newOrderItemWithFulfillmentIssue, orderItemsReplacements)
        onChange(fulfillmentIssues)
    }

    const addOrderItemToFulfillmentIssues = (oi, newQuantity, substitution = null) => {
        const getNewOrderFulfillmentIssues = () => {
            const otherItems = orderItemsWithFulfillmentIssues.filter(p => p.id !== oi.id);
            const orderItemToUpdate = {...order.orderItems.find(orderItem => orderItem.id === oi.id)};
            orderItemToUpdate.quantity = newQuantity;
            return otherItems.concat([orderItemToUpdate]);
        }

        const newOrderFulfillmentIssues = getNewOrderFulfillmentIssues()

        setOrderItemsWithFulfillmentIssues(newOrderFulfillmentIssues)

        let fulfillmentIssues = convertOrderItemsToFulfillmentIssues(newOrderFulfillmentIssues, orderItemsReplacements)
        if (substitution) {
            const getOrderItemsReplacements = () => {
                const otherItems = orderItemsReplacements.filter(p => p.fulfillmentIssueParentId !== oi.id)
                const orderItemToUpdate = {...order.orderItems.find(orderItem => orderItem.id === oi.id)};

                const orderItemToCreate = {
                    fulfillmentIssueParentId: orderItemToUpdate.id,
                    name: substitution.name,
                    unitPrice: substitution.unitPrice,
                    quantity: substitution.quantity,
                    itemId: substitution.itemId,
                    substitutionId: substitution.substitutionId,
                    scanned: substitution.scanned
                }

                return otherItems.concat([orderItemToCreate]);
            }
            const newOrderItemsReplacements = getOrderItemsReplacements()
            setOrderItemsReplacements(newOrderItemsReplacements)
            fulfillmentIssues = convertOrderItemsToFulfillmentIssues(newOrderFulfillmentIssues, newOrderItemsReplacements)
        }

        onChange(fulfillmentIssues)
    }

    const handleSaveOrderFulfillmentIssues = async () => {
        try {
            setIsLoading(true);
            const fulfillmentIssues = convertOrderItemsToFulfillmentIssues(orderItemsWithFulfillmentIssues, orderItemsReplacements);
            const {status} = await setFulfillmentIssues(order, fulfillmentIssues);
            await onSave(status);
        } catch (e) {
            notify.show(t('global.anErrorOccurred'), 'error')
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <OrderPickingScanProvider
            order={order}
            onFulfillmentIssue={addOrderItemToFulfillmentIssues}
            clearFulfillmentIssue={clearOrderItemFulfillmentIssue}
            >

            {displayMode === orderItemsListDisplayMode.PRODUCTS ?
                <div css={css`margin-top: 10px;`}>
                    <OrderItemsRich
                        order={order}
                        orderItemsWithFulfillmentIssue={orderItemsWithFulfillmentIssues}
                        orderItemsReplacements={orderItemsReplacements}
                        onAddOrderItemToFulfillmentIssues={addOrderItemToFulfillmentIssues}
                        isModifying={isModifying}
                        hideable
                    />
                </div>
                :
                <OrderItemsTable
                    order={order}
                    orderItemsWithFulfillmentIssue={orderItemsWithFulfillmentIssues}
                    orderItemsReplacements={orderItemsReplacements}
                    onAddOrderItemToFulfillmentIssues={addOrderItemToFulfillmentIssues}
                    isModifying={isModifying}
                />}

            {isModifying && <OrderModifyingActions
                isLoading={isLoading}
                onReset={() => {
                    reset();
                    onCancel();
                }}
                onSave={handleSaveOrderFulfillmentIssues}
                orderItems={order?.orderItems ?? []}
                orderItemsWithFulfillmentIssue={orderItemsWithFulfillmentIssues ?? []}
                order={order}
            ></OrderModifyingActions>}


        </OrderPickingScanProvider>
    )
}

export { OrderItemsList, orderItemsListDisplayMode };
