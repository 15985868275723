import React, {createContext, useCallback, useEffect, useState} from 'react';
import rules from '../../../rbac-rules';
import { getFeatures } from '../../../services/Features/Features.service'
import useUser from '../UserProvider/useUser';
const RELOAD_DELAY_IN_MINUTES = 10
const MAX_RETRY_ATTEMPT = 10;
const FeatureContext = createContext({});
const empty = [];

const computeRetryDelay = (currentAttempt) => 2 ** (currentAttempt+ 2) ;

const FeatureProvider = ({ children }) =>  {
    const [retryAttemptCount,setRetryAttemptCount] = useState(0);
    const {user} = useUser();
    const [features, setFeatures] = useState(empty);
    const [loaded, hasLoaded] = useState(false);

    const isEnabled = useCallback((feature) => {
        return features?.includes(feature) === true;
    },[features]);

    const loadFeatures = async () => {
        try {
            setFeatures(await getFeatures());
            setRetryAttemptCount(0);
            hasLoaded(true);
        }
        catch {
            setRetryAttemptCount(prev => prev++);
            setFeatures(empty);
        }
    }

    usePeriodicReload();
    useUserModification();
    useRetry();
    useFirstLoad();

    return (
        <FeatureContext.Provider value={{isEnabled }}>
            {children}
        </FeatureContext.Provider>
    )

    function useUserModification() {
        useEffect(() => {
            hasLoaded(false);
        }, [user]);
    }

    function useFirstLoad() {
        useEffect(() => {
            (async function loadFeatureWrapper() {
                if(!loaded && user.id){
                    await loadFeatures()
                }
            })()
        },[loaded,user])
    }

    function usePeriodicReload() {
        useEffect(() => {
            const timer = setInterval(async () => await loadFeatures(), 1000 * 60 * RELOAD_DELAY_IN_MINUTES);
            return () => {
                clearInterval(timer);
            };
        }, []);
    }

    function useRetry() {
        useEffect(() => {
            if (retryAttemptCount === 0 || retryAttemptCount > MAX_RETRY_ATTEMPT) return;

            const timer = setTimeout(async () => await loadFeatures(), 1000 * computeRetryDelay());
            return () => {
                setTimeout(timer);
            };
        }, [retryAttemptCount]);
    }
}

export { FeatureProvider, FeatureContext };
