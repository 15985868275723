/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, Header, Loader, MenuItem, Segment, Tab } from 'semantic-ui-react'
import { UltyModalWrapperContext } from '../../Shared/UltyModalWrapper/UltyModalWrapperContext'
import UserEdit from './UserEdit'
import useUser from '../../Shared/UserProvider/useUser'
import { useTranslation } from 'react-i18next'
import { getMerchantsOfCompany } from '../../../services/Company/Company.service'
import { useQuery } from '@tanstack/react-query'
import { UserList } from './UserList/UserList'
import { isProviderACompany, isProviderAPos } from '../../../services/Provider/Provider.service'
import { useQuerySearchParams } from '../../../services/Filter/filter.service'
import { TIME } from '../../../services/date.service'
import { ProviderSearch } from './ProviderSearch'
import { useHistory, useParams } from 'react-router-dom'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { MOBILE_THRESHOLD } from '../../../constants'
import { debounce } from 'lodash'
import { active } from 'sortablejs'

const AdminUsers = () => {
    const [t] = useTranslation()
    const {user} = useUser()
    const history = useHistory()
    const queryParams = useQuerySearchParams()
    const { providerId } = useParams()
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
    const {handleUltyModalWrapper} = useContext(UltyModalWrapperContext)
    const defineUserModal = (user) => {
        const modalSettings = {
            title: user ? `${user.firstname} ${user.lastname}` : t('user.edit.new_user'),
            component: <UserEdit
              userId={user?.id}
              afterSubmit={async () => {
                  handleUltyModalWrapper(false, null)
              }}
            />,
        }

        handleUltyModalWrapper(true, modalSettings)
    }

    const { data: pointOfSales, isLoading } = useQuery({
        queryFn: () => getMerchantsOfCompany(isProviderACompany(user.provider) ? user.provider.object.id : user.provider.object.company.id),
        queryKey: ['companies', user.provider.object.id,  'pointofsales'],
        staleTime: TIME.HOUR_IN_MS,
    })

    const allProviders = useMemo(() => {
        if(!pointOfSales) return [];
        return [
        ...(isProviderACompany(user.provider) ? [user.provider] : []),
        ...pointOfSales
          .map(pos => ({
              id: pos.providerId,
              object: pos,
              type: 'POINT_OF_SALE',
          })),
    ]}, [user,pointOfSales]);

    const posSearch = queryParams.get('pos-search') || ''


    const filteredProviders = useMemo(() => {
        return allProviders.filter(pos => pos.object.name.toLowerCase().includes(posSearch.toLowerCase()))
;
    },[posSearch,allProviders])

    const activeIndex = useMemo(() => {
        return filteredProviders.findIndex(p => p.id === providerId);
    },[providerId,filteredProviders]);

    const currentProvider = allProviders.find(provider => provider.id === providerId) || user.provider;

    const setCurrentProvider = (provider) => {
        if(!provider){
            provider = user.provider;
        }
        history.push({
            pathname: `/settings/${provider.id}/users`,
            search: queryParams.toString(),
        })
    }

    const updateHistory = useCallback(debounce((activeIndex) => {
        if (activeIndex === -1) {
            setCurrentProvider(filteredProviders?.[0] || user.provider);
        }
    }, 500),[filteredProviders])

    useEffect(() => {
        updateHistory(activeIndex);
    }, [activeIndex])

    if (isLoading) {
        return <Loader active />
    }

    return (
      <Tab.Pane loading={isLoading}>
          <Header>{t('administration.users.list')}</Header>

          <Segment css={css`
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              gap: 1rem;
          `}>
              {(!!(isMobile || isProviderACompany(user.provider)) && (
                  <ProviderSearch providers={filteredProviders} />
                )
              )}

              <Button
                icon="plus"
                onClick={() => defineUserModal(null)}
              >
                  {t('administration.users.add_user')}
              </Button>
          </Segment>

          {(isMobile || isProviderAPos(user.provider)) ? (
            <UserList
              color={isProviderACompany(currentProvider) ? 'blue' : 'green' }
            />
          ) : (
              <Tab
                activeIndex={activeIndex}
                onTabChange={(event, data) => {
                    window.scrollTo({top: 150, behavior: 'smooth'})
                    const provider = filteredProviders[data.activeIndex]
                    setCurrentProvider(provider);
                }}
                menu={{fluid: true, vertical: true, tabular: true}}
                panes={filteredProviders.map(p => ({
                    menuItem: (
                      <MenuItem
                        key={p.id}
                        css={css`
                            border-left: 3px solid ${isProviderACompany(p) ? '#2185d0' : '#21ba45'} !important;
                        `}
                      >
                          {p.object.name}
                      </MenuItem>
                    ),
                    render: () => <UserList
                      color={isProviderACompany(p) ? 'blue' : 'green' }
                    />,
                }))}
              />
          )}
      </Tab.Pane>
    )
}

export default AdminUsers
