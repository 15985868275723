import { useQuery } from '@tanstack/react-query'
import { getPlatformsOfPosBrand } from '../../../services/PointOfSale/PointOfSale.service'
import PlatformItem from './PlatformItem'
import { Item, Loader } from 'semantic-ui-react'
import React from 'react'

const PosBrandPlatforms = ({posId, brandId}) => {
    const { data: posBrandPlatforms } = useQuery({
        queryFn: () => getPlatformsOfPosBrand(posId, brandId),
        queryKey: ['pointofsales', posId, 'brands', brandId, 'platforms'],
    })

    if (!posBrandPlatforms) {
        return <Loader active />
    }

    return (
      <Item.Group divided>
          {posBrandPlatforms.platforms.map(platform => (
              <PlatformItem
                key={platform.id}
                posId={posId}
                brandId={brandId}
                platform={platform}/>
            ),
          )}
      </Item.Group>
    )
}

export { PosBrandPlatforms }
