import {useTranslation} from 'react-i18next';
import {Tab} from 'semantic-ui-react';
import OrderElement from '../OrderElement';
import formatDate from '../../../helpers/formatDate'
import React from 'react';

const OrdersUntreated = () => {
    const [t] = useTranslation();

    return (
        <Tab.Pane as="div">
            <OrderElement
                seeText={t('global.see')}
                emptyText={t('order.no_untreated')}
                state="untreated"
                getStatusText={(o) => t('order.received_at_date', { dt: `${formatDate(o.receivedAt)}`})} />
        </Tab.Pane>
    )
}

export default OrdersUntreated;
