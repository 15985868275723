import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, TextArea, Input, Grid } from 'semantic-ui-react';
import Uploader from '../../Uploader';
import { notify } from 'react-notify-toast';
import { addBrand, patchBrand } from '../../../services/Brand/Brand.service';

const BrandForm = ({ brand, reloadBrands }) => {
  const [t] = useTranslation();

  const [form, setForm] = useState({
    name: '',
    image: '',
    description: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (brand) {
      setForm({
        name: brand.name,
        image: brand.image,
        description: brand.description,
      });
    }
  }, [brand]);

  const handleSubmit = async () => {
    if(!form.name || form.name === '') {
      notify.show(t('administration.brands.nameRequired'), 'error');
      return;
    }

    setLoading(true);

    try {
      const updatedBrand = brand?.id ? await patchBrand(brand.id, {
        image: form.image,
        name: form.name,
        description: form.description,
      }) : await addBrand({
        image: form.image,
        name: form.name,
        description: form.description,
      });

      setForm({
        image: updatedBrand.image,
        name: updatedBrand.name,
        description: updatedBrand.description,
        autoAcceptOrder: updatedBrand.autoAcceptOrder
      });

      setForm({
        name: '',
        image: '',
        description: '',
      });
      reloadBrands();

      notify.show(t('global.registerSuccess'), 'success');
    } catch {
      notify.show(t('global.anErrorOccurred'), 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Form.Field name="image">
              <Uploader
                initial={form.image}
                onSrcChange={(src) => setForm(prev => ({ ...prev, image: src }))}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field
              control={Input}
              name="name"
              value={form.name}
              onChange={(evt, dt) => setForm(prev => ({ ...prev, name: dt.value }))}
              label={t('administration.brands.name')}
              placeholder={t('administration.brands.name')}
            />

            <Form.Field
              control={TextArea}
              name="description"
              value={form.description}
              onChange={(evt, dt) => setForm(prev => ({ ...prev, description: dt.value }))}
              label={t('administration.brands.description')}
              placeholder={t('administration.brands.description')}
            />
            <div style={{ textAlign: 'right' }}>
              <Button color="teal" type="submit" loading={loading} disabled={loading} onClick={handleSubmit}>{brand ? t('global.save') : t('global.add')}</Button>
            </div>
          </Grid.Column>
        </Grid.Row>

      </Grid>
    </>
  );
};

export default BrandForm;
