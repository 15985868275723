import { UltyDropdown } from '@ulty/ulty-ui'
import { useTranslation } from 'react-i18next'

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const WeekdayPicker = ({ weekday, onChange }) => {
    const [t] = useTranslation()

    return (
      <UltyDropdown
        label={'Jour'}
        loading={false}
        options={weekdays.map(weekday => ({
            text: t(`days.${weekday}`),
            key: weekday,
            value: weekday,
        }))}
        onChange={(e, {value}) => {
            onChange({ weekday: weekdays.indexOf(value) + 1 })
        }}
        value={weekdays[weekday-1]}
        multiple={false}
      />
    )
}

export { WeekdayPicker }
