/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import isBeforeDay from '../../../../helpers/isBeforeDay'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import useFilters from '../useFilters'
import { Button } from 'semantic-ui-react'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { MOBILE_THRESHOLD } from '../../../../constants'
import ComparedDateRangeFilter from './ComparedDateRangeFilter'

const DateRangeFilter = ({includeComparaison = false}) => {
    const [t] = useTranslation()
    const {filters, setFilters, debounceFiltersChange} = useFilters()
    const [focusedInput, setFocusedInput] = useState(null)
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`)

    const handleOnDatesChange = ({startDate, endDate}) => {
        if (moment.utc(startDate).startOf('day') === filters.dateRange.startDate) {
            return
        }
        const newFilters = {
            ...filters,
            dateRange: {
                startDate: moment.utc(startDate).startOf('day'),
                endDate: moment.utc(endDate).endOf('day'),
            },
        }

        if (filters.dateComparison?.startDate) {
            const range = newFilters.dateRange.endDate.diff(newFilters.dateRange.startDate, 'days')
            newFilters.dateComparison = {
                startDate: newFilters.dateRange.startDate.clone().add(-1 * range - 1, 'days').startOf('day'),
                endDate: newFilters.dateRange.startDate.clone().add(-1, 'days').endOf('day'),
            }
        }

        setFilters(newFilters)
        debounceFiltersChange(newFilters)
    }

    const handleButtonClick = (sd, ed) => {
        handleOnDatesChange({
            startDate: sd,
            endDate: ed,
        })
        setFocusedInput(false)
    }

    return (
      <div className="field" css={css`
          margin: 0 1rem 1rem 0;

          .DateRangePickerInput__withBorder {
              border: 1px solid #dbdbdb;
              border-radius: 2px;
          }

          .DateRangePickerInput > div.DateInput > .DateInput_input  {
              padding: 7px 7px 5px;
              line-height: 18px;
              border: none;

              &.DateInput_input__focused {
                  border-radius: 0;
                  background: #fff;
                  border: 0;
                  border-bottom: 2px solid #008489;
                  outline: 0;
              }
          }

          .DateRangePickerInput_calendarIcon {
              margin-left: 0;
          }

          .DateInput_input.DateInput_input_1.DateInput_input__small.DateInput_input__small_2 {
              font-size: 14px;
          }

          .CalendarMonth_table {
              margin-top: 5px !important;
          }

          .calendar-info {
              padding: 10px;
              display: flex;
              align-items: center;
              justify-content: space-around;
          }
      `}>
          <label
            css={css`
                font-weight: bolder;
                display: block;
                margin: 0 0 .28571429rem 0;
                color: rgba(0, 0, 0, .87);
                font-size: .92857143em;
            `}
          >{t('global.period')}</label>
          <DateRangePicker
            small
            minimumNights={0}
            showDefaultInputIcon={true}
            startDate={filters.dateRange.startDate}
            startDateId="metrics-advanced-filters-startDate"
            endDate={filters.dateRange.endDate}
            endDateId="metrics-advanced-filters-endDate"
            onDatesChange={handleOnDatesChange}
            readOnly
            onClose={handleOnDatesChange}
            focusedInput={focusedInput}
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
            maxDate={moment()}
            hideKeyboardShortcutsPanel
            numberOfMonths={isMobile ? 1 : 2}
            enableOutsideDays={false}
            isOutsideRange={(d) => !isBeforeDay(d, moment().add(1, 'day'))}
            initialVisibleMonth={() => moment().add(-1, 'month')}
            renderCalendarInfo={() => (
              <div className="calendar-info">
                  <Button
                    basic
                    size="tiny"
                    onClick={() => {
                        handleButtonClick(moment().utc().startOf('day').add(-7, 'days'), moment().utc().add(-1, 'days').endOf('day'))
                    }}
                  >
                      {t('calendar.7days')}
                  </Button>
                  <Button
                    basic
                    size="tiny"
                    onClick={() => {
                        handleButtonClick(moment().utc().startOf('day').add(-28, 'days'), moment().utc().add(-1, 'days').endOf('day'))
                    }}
                  >
                      {t('calendar.28days')}
                  </Button>
                  {!isMobile && (
                    <>
                        <Button
                          basic
                          size="tiny"
                          onClick={() => {
                              handleButtonClick(moment().utc().startOf('month'), moment().utc().endOf('day'))
                          }}
                        >
                            {t('calendar.current_month')}
                        </Button>
                        <Button
                          basic
                          size="tiny"
                          onClick={() => {
                              handleButtonClick(moment().utc().startOf('month').add(-1, 'days')
                                .startOf('month'), moment().utc().startOf('month').add(-1, 'days')
                                .endOf('month'))
                          }}
                        >
                            {t('calendar.prev_month')}
                        </Button>
                    </>
                  )}
              </div>
            )}
          />

          {includeComparaison && <ComparedDateRangeFilter/>}
      </div>
    )
}

export default DateRangeFilter
