import {dinero, toFormat, toSnapshot, toUnit} from 'dinero.js';
import * as currencies from '@dinero.js/currencies';


export const intlFormat = (amount, currencyCode, locale, options = {}) => {
    try {
        const transformer = ({ amount, currency }) => {
            return amount.toLocaleString(locale, {
                ...options,
                style: 'currency',
                currency: currency.code,
            });
        }

        const dineroObject = dinero({ amount, currency: currencies[currencyCode]});
        return toFormat(dineroObject, transformer);
    } catch (e) {
        console.log(e);
    }
}

export const moneyFromFloat = (floatAmount, currencyCode, scale = 0) => {
    try {
        floatAmount = `${floatAmount}`.replace(/,/g, '.');
        const currency = currencies[currencyCode];
        const factor = currency.base ** currency.exponent || scale;
        const amount = Math.round(floatAmount * factor);
        const d = dinero({ amount, currency, scale: currency.exponent || scale });

        return toSnapshot(d);
    } catch (e) {
        console.log(e);
    }
}

export const getCurrency = (currencyCode) => {
    return currencies[currencyCode]
}

export const unitFormat = (amount, currencyCode) => {
    try {
        const dineroObject = dinero({ amount, currency: currencies[currencyCode]});
        return toUnit(dineroObject);
    } catch (e) {
        console.log(e);
    }
}

export const createMoney = (amount, currencyCode) => {
    try {
        return toSnapshot(dinero({amount, currency: currencies[currencyCode]}));
    } catch (e) {
        console.log(e);
    }
}
