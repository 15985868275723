/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { sortAsc } from '../../../helpers/array'
import { memo } from 'react'
  
const arrayOfDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
];


const dayIndex = new Map(arrayOfDays.map((a,i) => ([a,i])));

const getConnectedDayPeriods = (listOfDays) => {
    const sorted = sortAsc(listOfDays, day => arrayOfDays.indexOf(day));
    let grouped = sorted.reduce((agg,curr) => {
        if(agg.length === 0) return [{from:curr,to:curr}];

        const currIndex = dayIndex.get(curr);
        const currGroup = agg[agg.length -1];
        const currGroupEndIndex = dayIndex.get(currGroup.to);
        if(currIndex === currGroupEndIndex + 1){
            currGroup.to = curr;
            return agg;
        }
        return [
            ...agg,
            {from:curr,to:curr}
        ]
    },[]);

    const first = grouped[0];
    const last = grouped[grouped.length - 1];
    if(first !== last && first.from === 'monday' && last.to === 'sunday'){
        first.from = last.from;
        grouped.splice(grouped.length - 1,1);
    }

    return grouped;
}

const DayWeekDisplay = memo(({daysOfWeek,locale = 'fr-FR',hours = [
   {start_time: "09:00", end_time: "18:00"},
   {start_time: "19:00", end_time: "23:00"}
]}) => {

    const [t] = useTranslation()


    const displayedGroups = useMemo(() => {
        const groups = getConnectedDayPeriods(daysOfWeek || []);
        return groups;
    },[daysOfWeek])

    return (
        <>
        <div css={css`
            display: grid; 
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
            grid-template-rows: 1fr; 
            gap: 0px 0px; 
            grid-template-areas: 
              ". . . . . . ."; 

            .item {
                border-top: 1px #aaa solid ;
                border-bottom: 1px #aaa solid ;
                width: 100%;
                text-align: center;
            }

            .on {
                background-color: #2185d0;
                color: #eee
            }
        `}>
        {arrayOfDays.map((day,index) =>  (
            <span class={`item ${daysOfWeek.find(d => d === day) ? 'on': ''}`}>{`${t(`days.${day}`)[0].toUpperCase()}`}</span>
            ))
        }
        </div>
        <div css={css`
            font-size:0.7em;
            display: grid; 
            grid-template-columns: 1fr 1fr; 
            grid-template-rows: 1fr; 
            gap: 0px 0px; 
        `}>
            <div>
                {
                displayedGroups.map(({from,to}) => {
                    return (
                    <p css={css`margin-bottom:0!important`}>
                        {from !== to
                            ? t("weekdays_display.from_to",{from:t(`days.${from}`),to:t(`days.${to}`)})
                            : t("weekdays_display.from_day",{from:t(`days.${from}`)})
                        }
                    </p>
                )
                })
                }
            </div>
            <div css={css`
                text-align:right;
            `}>
                {
                hours?.length > 0 && hours.map(({start_time,end_time}) => {
                    return (
                    <p css={css`margin-bottom:0!important`}>
                        {t("hours_display.from_to",{from:start_time,to:end_time})}
                    </p>
                )
                })
                }
                {
                (hours?.length === 0) && (
                    <p css={css`margin-bottom:0!important`}>
                        {t("markup.add.whole_day")}
                    </p>
                )
                }
            </div>

        </div>
       
        </>
        
    )
})

export {
    DayWeekDisplay
}