import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const AdminBillingInvoices = ({ invoices }) => {
  const [t] = useTranslation();

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('administration.billing.invoice_date')}</Table.HeaderCell>
          <Table.HeaderCell>{t('administration.billing.invoice_amount')}</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        { invoices.map(i => (
          <Table.Row>
            <Table.Cell>{(new Date(i.created * 1000)).toLocaleDateString()}</Table.Cell>
            <Table.Cell>{(parseFloat(i.total)/100.0).toLocaleString('fr-FR', { style: 'currency', currency: i.currency })}</Table.Cell>
            <Table.Cell textAlign='right'>
              <a href={i.hosted_invoice_url} target="_blank" rel="noopener noreferrer">{t('administration.billing.invoice_download')}</a>
            </Table.Cell>
          </Table.Row>
        ))}

        { invoices.length === 0 && (
          <Table.Row>
            <Table.Cell textAlign="center" colSpan={3}>{t('administration.billing.invoice_none')}</Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}

AdminBillingInvoices.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AdminBillingInvoices;