import { useState, useEffect } from 'react';
import { Form, Header, Input, Button } from 'semantic-ui-react'
import { notify } from 'react-notify-toast';
import useUser from '../../Shared/UserProvider/useUser';
import { useTranslation } from 'react-i18next';
import { updateSelf } from '../../../services/User/User.service';

const UserForm = () => {
    const [t] = useTranslation();

    const {user, setUser} = useUser();
    const [loading, setLoading] = useState(false)

    const [firstname, setFirstname] = useState(user.firstname)
    const [lastname, setLastname] = useState(user.lastname)

    useEffect(() => {
        setFirstname(user.firstname)
        setLastname(user.lastname)
    }, [user])

    const handleSubmit = async () => {
        setLoading(true)

        try {
            const { updatedUser } = await updateSelf({
                firstname,
                lastname
            });

            notify.show(t('global.registerSuccess'), 'success');

            setUser(prev => ({
              ...prev,
              firstname: user.firstname,
              lastname: user.lastname
            }));
        } catch(e) {
            notify.show(t('global.anErrorOccurred'), 'error');
        }

        setLoading(false)
    }

    return (
        <Form>
            <Header>{t('administration.users.user_header')}</Header>
            <Form.Field
                control={Input}
                name="firstname"
                value={firstname}
                onChange={(evt, dt) => setFirstname(dt.value)}
                label={t('administration.users.firstname')}
                placeholder={t('administration.users.firstname')}
            />
            <Form.Field
                control={Input}
                name="lastname"
                value={lastname}
                onChange={(evt, dt) => setLastname(dt.value)}
                label={t('administration.users.lastname')}
                placeholder={t('administration.users.lastname')}
            />

            <div style={{ textAlign: 'right' }}>
                <Button color="teal" type="submit" loading={loading} disabled={loading} onClick={handleSubmit}>{t('global.save')}</Button>
            </div>
        </Form>
    )
}

export default UserForm
