import { useTranslation } from "react-i18next";
import {Header,Segment,Form,Checkbox,Label,Button,TextArea,Accordion, Input,Dropdown } from 'semantic-ui-react'
import { usePrinter } from "../../services/printer.service";
import { useCallback, useMemo, useState } from "react";


const baseTestOrder = {
    platform: {name: "Test Impression"},
    displayId: "4562E1",
    orderItems :[
        {quantity: 12, name: "Produit 1", modifiers: [{name: "Option 1",quantity: 1}]},
        {quantity: 1, name: "Produit 2"},
        {quantity: 4, name: "Produit 5"},
    ]
}

const PrinterTypeButton = ({printerType,currentType,onClick,children,...props}) => {
    const active = useMemo(() => {
        return printerType === currentType;
    },[printerType,currentType]);

    const color = useMemo(() => {
        return active ? 'green' : 'grey';
    },[active]);

    const clickHandler = useCallback(() => {
        if(!active){
            onClick(printerType);
        }
    },[active,printerType,onClick]);

    return <Button
    {...props} 
    active={active} 
    size="big" 
    color={color} 
    onClick={clickHandler}>
        {children}</Button>
}


const defaultOptions = {
    'star' : {
        ticketSizeInMillimeter: 73
    },
    'escpos': {
        ticketSizeInMillimeter: 80,
    }
}


const AdminPrintingOptions = ({}) => {

    const [t] = useTranslation();
    const {printerInfo,printOrder,printingOptions,setPrintingOptions} = usePrinter();

    const [displayAdvanced,setDisplayAdvanced] = useState(false);
    const handleAdvancedToggle = useCallback(() => {
        setDisplayAdvanced(i => !i);
    },[]);



    const handleUpdate = useCallback((prop, value) => {
        setPrintingOptions(v => ({
            ...v,
            [prop]: value,
            ...(prop === 'printerType' ? defaultOptions[value] : {})
        }));
        printerInfo.reload();
    }, [setPrintingOptions]);

    const triggerTestTicket = useCallback(() => {
        printOrder({
            id:'aze',
            ...baseTestOrder,
            receivedAt: new Date()
        },true)
    },[printOrder])

    return (
    <Segment clearing>
       
       {printerInfo.isConnected && (<Label  icon="remove circle" onClick={() => printerInfo.disconnect()} size="medium"  attached="top right" color="green" content={t('printer.connected_at',{name:printerInfo.deviceName})}/>)}
        {! printerInfo.isConnected && (<Label icon="redo" onClick={() => printerInfo.reload()} size="medium"  attached="top right" content={t('printer.not_connected')}/>)}

        <Header>
        {t('printer.admin.header')}
        </Header>
        <Form >
            <Form.Field>
            <Checkbox toggle onChange={(e,{checked}) => handleUpdate('printOnNewOrder',checked)} checked={printingOptions.printOnNewOrder} label={t('printer.admin.printOnNewOrder_label')}/>
            </Form.Field>
            <Form.Field>
            <label>{t('printer.admin.printer_type_label')}</label>
            <Button.Group>
                <PrinterTypeButton printerType="star"
                    currentType={printingOptions.printerType}
                    onClick={(e)=> handleUpdate('printerType',e)} >
                        Star
                </PrinterTypeButton>
                <Button.Or text={t('global.or')}/>
                <PrinterTypeButton printerType="escpos"
                    currentType={printingOptions.printerType}
                    onClick={(e)=> handleUpdate('printerType',e)} >
                        Epson ESC/POS
                </PrinterTypeButton>
            </Button.Group>
            </Form.Field>
            {printingOptions.printerType === 'escpos' && (
<>
            <Form.Field>
                <label>{t('printer.admin.connection_type_label')}</label>
                <Dropdown 
                    selection
                    options={[{
                        "text":t('printer.admin.connection_type.bluetooth'),
                        "value":"bluetooth"
                    },
                    {
                        "text":t('printer.admin.connection_type.network'),
                        "value":"network"
                    }
                    ]}
                    value={`${printingOptions.connectionType || 'bluetooth'}`}
                    onChange={(e,{value}) => handleUpdate('connectionType',value)} />
            </Form.Field>
            {(printingOptions.connectionType === 'network' && (
                 <Form.Field>
                 <label>{t('printer.admin.network.connection_address_label')}</label>
                 <Input
                     value={printingOptions.connectionAddress}
                     onChange={({target}) => handleUpdate('connectionAddress',target.value)}
                 ></Input>
             </Form.Field>
            ) )}
            </>
        )}
            <Form.Field>
                <label>{t('printer.admin.endMessage_label')}</label>
                <TextArea
                    row={2}
                    value={printingOptions.endMessage}
                    onChange={({target}) => handleUpdate('endMessage',target.value)}
                ></TextArea>
            </Form.Field>
            <Accordion>
                <Accordion.Title 
                    active={displayAdvanced}
                    onClick={handleAdvancedToggle}
                    icon={`caret ${displayAdvanced ? 'down':'right'}`}
                    content={t('printer.admin.advanced_configuration')}
                    >
                    
                </Accordion.Title>
                <Accordion.Content active={displayAdvanced}>
                    <Form.Field>
                        <label>{t('printer.admin.ticketSizeInMillimeter_label')}</label>
                    <Dropdown
                        options={[
                            {
                                "text":"57",
                                "value":"57"
                            },
                            {
                                "text":"73",
                                "value":"73"
                            },    
                        {
                            "text":"80",
                            "value":"80"
                        },
                        {
                            "text":"48",
                            "value":"48"
                        }
                    ]}
                        value={`${printingOptions.ticketSizeInMillimeter}`}
                        onChange={(e,{value}) => handleUpdate('ticketSizeInMillimeter',value?.replace(/[^0-9]/,''))}
                        selection
                    ></Dropdown>
                    </Form.Field>

                    {(printingOptions.connectionType === 'network' && (
                    <Form.Field>
                        <label>{t('printer.admin.network.connection_port_label')}</label>
                        <Input
                            value={`${printingOptions.connectionPort || 9100}`}
                            onChange={(e,{value}) => handleUpdate('connectionPort',value?.replace(/[^0-9]/,''))}
                            selection
                        ></Input>
                    </Form.Field>
                    ))}
                </Accordion.Content>
            </Accordion>
            <Button icon="print" floated="right" type="button" onClick={triggerTestTicket}
                content={t('printer.admin.test_button_label')}
            />
        </Form>
    </Segment>)
}

export {AdminPrintingOptions}