/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Input, Form, Grid, Checkbox, Radio } from 'semantic-ui-react'
import { notify } from 'react-notify-toast';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { saveCategorySet } from '../../services/Category/Category.service';
import { API_URL } from '../../constants';

const CategoryAddSet = ({ afterSubmit }) => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const fileRef = useRef();
  const [file, setFile] = useState(null);
  const [useFile, setUseFile] = useState(false);
  const [fileType, setFileType] = useState("SUBMENU");

  const handleSubmit = async () => {
    setLoading(true);

    if (name === '' || !name) {
      notify.show(t('category_set.add_name_mandatory'), 'error');
      setNameError(true);
      setLoading(false);
      return;
    }

    try {
      const bodyFormData = new FormData();
      if (useFile && file && file.length > 0) {
        bodyFormData.append('datafile', file[0]);
      }
      bodyFormData.append('name', name)

      await saveCategorySet(bodyFormData);

      afterSubmit();
      notify.show(t('global.registerSuccess'), 'success');
    } catch (e) {
      console.error(e);
      if (e.message === 'invalid_file') {
        notify.show(`${t(`category_set.${e.message}`)}`, 'error');
      } else if (e.message === 'name_already_exists') {
        notify.show(`${t(`category_set.${e.message}`)}`, 'error');
      } else {
        notify.show(t('global.anErrorOccurred'), 'error');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form>
      <Grid css={css`
        .column { padding-top: .5rem !important; padding-bottom: .5rem !important; }
      `}>
        <Grid.Column width={16}>
          <Form.Field
            control={Input}
            name="name"
            value={name}
            onChange={(evt, { value }) => {
              setNameError(false);
              setName(value);
            }}
            error={nameError || false}
            label={t('category_set.add_name')}
            placeholder={t('category_set.add_name')}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <Form.Field>
            <Checkbox toggle label={t('category_set.import_file')} checked={useFile} onChange={(evt, { checked }) => setUseFile(checked)} />
          </Form.Field>
          {useFile && (
            <React.Fragment>
              <Form.Field>
                <Radio
                  label={t('category_set.file_with_submenu')}
                  name='checked'
                  checked={fileType === 'SUBMENU'}
                  onChange={() => {
                    setFileType('SUBMENU');
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={t('category_set.file_without_submenu')}
                  name='checked'
                  checked={fileType === 'CATEGORY'}
                  onChange={() => {
                    setFileType('CATEGORY');
                  }}
                />
              </Form.Field>
              <p>
                <a href={`${API_URL}/templates/category_import_${fileType === 'SUBMENU' ? 'submenu' : 'nosubmenu'}.xlsx`}>{t('import.downloadImportTemplateXLSX')}</a>
              </p>
              <Form.Field css={css`margin-top: 20px;`}>
                <label>{t('product_list.import.file')}</label>
              </Form.Field>
              <Form.Field>
                <input type="file" ref={fileRef} onChange={() => {
                  setFile(fileRef.current.files)
                }} />
              </Form.Field>
            </React.Fragment>
          )}
        </Grid.Column>
        <Grid.Column width={16}>
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <Button type="submit" color="teal" loading={loading} disabled={loading} onClick={handleSubmit}>{t('global.save')}</Button>
          </div>
        </Grid.Column>
      </Grid>
    </Form>
  )
}

CategoryAddSet.propTypes = {
  afterSubmit: PropTypes.func.isRequired
};

export default withRouter(CategoryAddSet);
