import React, { useMemo } from "react"
import { Breadcrumb } from "semantic-ui-react"

const isMonoSlotSet = (categorySet,categorySlot) => categorySlot?.name === 'Sous-Menu 1';


const CategoryName = ({categorySet,categorySlot = null,category = null, vertical=false}) => {

    const sections = useMemo(() => {
        //HACK. Hide slot for mono-slot sets based on default name
        categorySlot = isMonoSlotSet(categorySet,categorySlot) ? null : categorySlot;

        const {displayCategorySet,displayCategorySlot,displayCategory} =  {
            displayCategory : {
                name: '',
                ...category
            },
            displayCategorySlot: {
                name: '',
                ...categorySlot,
            },
            displayCategorySet: {
                    name: '',
                    ...categorySet,
                }
        }

        return [
            ...(categorySet ? [<Breadcrumb.Section link to={`/categories/${displayCategorySet.id}`}>{displayCategorySet.name}</Breadcrumb.Section>]: []),
            ...(categorySlot ? [ <Breadcrumb.Section >{displayCategorySlot.name}</Breadcrumb.Section>]: []),
            ...(category ? [ <Breadcrumb.Section active>{displayCategory.name}</Breadcrumb.Section>] : [])
        ].reduce((prev,curr,index) => {
            return [
                ...( prev.length > 0 ? [
                    ...prev,
                    (vertical && (<><br/> {Array.from(Array(index*4).keys()).map(()=> (<span>&nbsp;</span>))} </>)),
                    <Breadcrumb.Divider icon='right chevron' />,
                ] : []),
                curr
            ]
        }, [])
    },[category,categorySlot,categorySet,vertical]);


    return (
        <Breadcrumb>
        {sections}
        </Breadcrumb>
    )
}

export {CategoryName,isMonoSlotSet}