import React from 'react';
import {Button} from 'semantic-ui-react';
import ImageLibrary from '../ImageLibrary/ImageLibrary';
import {UltyModalWrapperContext} from '../Shared/UltyModalWrapper/UltyModalWrapperContext';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import ImageEditor from '../ImageEditor/ImageEditor';
import {base64ToFile} from '../../services/File/File.service';
import {uploadImage} from '../../services/Upload/Upload.service';


const ItemImagePlatformAssociationActions = ({ itemId, initialImage, onAddImage, onPickImage }) => {
    const [t] = useTranslation();
    const { handleUltyModalWrapper } = React.useContext(UltyModalWrapperContext);

    const defineImageEditorModal = () => {
        const modalSettings = {
            title: t('imageEditor.retouchImage'),
            component: <ImageEditor
                initialImage={initialImage}
                onImageChange={async (image) => {
                    const file = await base64ToFile(image.data, `item-image`, image.type);
                    const url = await uploadImage(file, true, () => {});
                    onAddImage(url)
                }}
                afterSubmit={() => {
                    handleUltyModalWrapper(false, null);
                }}
            />,
        }
        handleUltyModalWrapper(true, modalSettings);
    };

    const definePlatformItemImageLibraryModal = () => {
        const modalSettings = {
            title: t('itemImages.imageLibrary'),
            component: <ImageLibrary
                itemId={itemId}
                initialImage={initialImage}
                onPickImage={(pickedImage) => onPickImage(pickedImage)}
                afterSubmit={() => {
                    handleUltyModalWrapper(false, null);
                }}
            />,
        }
        handleUltyModalWrapper(true, modalSettings);
    };

    return (
        <div>
            <Button
                color='teal'
                size='massive'
                icon='add square'
                onClick={defineImageEditorModal}
            />
            <Button
                color='teal'
                size='massive'
                icon='folder open'
                onClick={definePlatformItemImageLibraryModal}
            />
        </div>
    )
}

ItemImagePlatformAssociationActions.propTypes = {
    itemId: PropTypes.string.isRequired,
    initialImage: PropTypes.string,
    onAddImage: PropTypes.func.isRequired,
    onPickImage: PropTypes.func.isRequired,
}

export default ItemImagePlatformAssociationActions;
