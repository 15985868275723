import { css } from '@emotion/react'
import { UltyInputText } from '@ulty/ulty-ui'
import { debounce } from 'lodash'
import { Form } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useQuerySearchParams } from '../../../../services/Filter/filter.service'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { MOBILE_THRESHOLD } from '../../../../constants'

const UserSearch = () => {
    const [t] = useTranslation()
    const history = useHistory()
    const { search } = useLocation()
    const queryParams = useQuerySearchParams()
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

    return (
      <Form>
          <UltyInputText
            css={css`
                width: ${!isMobile ? 'fit-content' : 'inherit'};
            `}
            placeholder={t('administration.users.search_user_placeholder')}
            icon="search"
            value={queryParams.get('user-search') || ''}
            onChange={debounce(e => {
                const searchValue = e.target.value
                const parsedQuery = queryString.parse(search)
                const searchQuery = queryString.stringify({
                    ...parsedQuery,
                    'user-search': searchValue || undefined,
                })

                history.push({ search: searchQuery })
            }, 400)}
          />
      </Form>
    )

}

export { UserSearch }
