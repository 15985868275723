/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useState } from 'react'

import { Button, Icon } from 'semantic-ui-react'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';

import { notify } from 'react-notify-toast'
import {useTranslation} from 'react-i18next';
import { updateOrderCouriers } from '../../../services/Order/Order.service';
import { MOBILE_THRESHOLD } from '../../../constants';

const RequestCourier = ({ order, afterSubmit }) => {
  const [t] = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

  const [couriers, setCouriers] = useState(order.couriers||1);
  const [loading, setLoading] = useState(false);

  const validate = async () => {
    setLoading(true);

    try {
      await updateOrderCouriers(order, couriers);
      afterSubmit();
    } catch(e) {
      if (e.message === 'UnsupportedOperationError') {
        notify.show(t('order.couriers.unsupported'), 'error');
      } else {
        notify.show(t('global.anErrorOccurred'), 'error');
      }
      setCouriers(order.couriers);
    }
    setLoading(false);
  }

  return (
    <div css={css`padding: 0;`}>
      <div css={css`
        padding-bottom: 20px;
      `}>{t('order.couriers.explanation')}</div>
      <div css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        button {
          min-width: 120px;
        }
      `}>
        <Button.Group vertical size="big">

          <Button loading={loading} disabled={loading || order.couriers > 1} onClick={() => setCouriers(1)} active={couriers === 1} color={couriers === 1 ? "olive" : "teal"}>
          <label>
              <Icon name="user" size=""/>
            </label>
            </Button>
          <Button loading={loading} disabled={loading || order.couriers > 2} onClick={() => setCouriers(2)} active={couriers === 2} color={couriers === 2 ? "olive" : "teal"}>
        
            <label>
              <Icon name="user" size=""/>
              <Icon name="user" size=""/>
            </label>
            
            </Button>
          <Button loading={loading} disabled={loading || order.couriers > 3} onClick={() => setCouriers(3)} active={couriers === 3} color={couriers === 3 ? "olive" : "teal"}>
          <label>
              <Icon name="user" size=""/>
              <Icon name="user" size=""/>
              <Icon name="user" size=""/>
            </label>

          </Button>
          <Button loading={loading} disabled={loading || order.couriers === 4} onClick={() => setCouriers(4)} active={couriers === 4} color={couriers === 4 ? "olive" : "teal"}>
          <label>
              <Icon name="user" size=""/>
              <Icon name="user" size=""/>
              <Icon name="user" size=""/>
              <Icon name="user" size=""/>
            </label>
          </Button>
        </Button.Group>
      </div>
      <div css={css`
        padding: 2rem 0 0;
        text-align: right;
      `}>
        <Button color="teal" size="large" fluid={isMobile} onClick={validate}>{t('order.couriers.validate')}</Button>
      </div>
    </div>
  );
}

export default RequestCourier;
