import { Divider, Header, Icon, Loader, Segment } from 'semantic-ui-react'
import { PosBrandPlatforms } from './PosBrandPlatforms'
import { useQuery } from '@tanstack/react-query'
import { getBrandsOfAPos } from '../../../services/PointOfSale/PointOfSale.service'
import React from 'react'
import { useTranslation } from 'react-i18next'

const POSBrands = ({ posId }) => {
    const [t] = useTranslation()
    const { data: posBrands } = useQuery({
        queryFn: () => getBrandsOfAPos(posId),
        queryKey: ['pointofsales', posId, 'brands'],
    })

    if (!posBrands) {
        return <Loader active />
    }

    if (posBrands.length === 0) {
        return (
          <Segment placeholder>
              <Header icon>
                  <Icon name="search" />
                  {t('administration.platforms.no_brands_configured_for_this_pos')}
              </Header>
          </Segment>
        )
    }

    return (
      <>
          {posBrands.map(brand => (
            <div key={`${posId}-${brand.id}`}>
                <Divider horizontal>{brand.name}</Divider>
                <PosBrandPlatforms posId={posId} brandId={brand.id} />
            </div>
          ))}
      </>
    )
}

export { POSBrands }
