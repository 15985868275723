import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { getItemAvailability, patchItemAvailability } from '../../services/Provider/Provider.service';
import toast from 'react-hot-toast';
import useUser from '../Shared/UserProvider/useUser';
import UltyPopup from '../Shared/UltyPopup/UltyPopup';
import moment from 'moment';

const ItemAvailabilitySetter = ({ itemId, onChange, ...props }) => {
    const {user} = useUser();
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState(null);

    useEffect(() => {
        setLoading(true);
        getItemAvailability(user.provider.id, itemId)
            .then((item) => {
                setLoading(false);
                setItem(item);
            });
    }, [itemId, user.provider.id]);

    const handleUnavailable = async (unavailableUntil) => {
        setLoading(true);

        try {
            const item = await patchItemAvailability(user.provider.id, itemId, unavailableUntil);
            setItem(prev => ({
                ...prev,
                unavailableUntil: item.unavailableUntil
            }));

            if (onChange) {
                onChange(unavailableUntil);
            }

        } catch (e) {
            toast.error(t('global.anErrorOccurred'));
        }

        setLoading(false);
    }

    const getAvailabilityLabel = (item) => {
        const now = moment(new Date());
        const until = moment(item.unavailableUntil);
        const dayCount = Math.ceil(until.diff(now, 'days', true));

        if (!item.unavailableUntil) {
            return '';
        }

        return item.unavailableUntil === Infinity
            ? t('item_edit.inventory.unavailable_until.currently_unavailable')
            : t('item_edit.inventory.unavailable_until.will_become_available_in_days', { dayCount });
    }

    if (!item) {
        return <></>
    }

    return (
        <div className={props.className}>
            {item.unavailableUntil !== null &&
            <Button
                color="teal"
                loading={loading}
                icon={'stopwatch'}
                labelPosition="left"
                label={{ basic: true, pointing: 'right', content: getAvailabilityLabel(item) }}
                content={t('item_edit.inventory.make_available')}
                onClick={async () => await handleUnavailable(null)}
            />}

            {item.unavailableUntil === null &&
            <UltyPopup
                label={t('item_edit.inventory.make_unavailable')}
                icon={'stopwatch'}
                color='teal'
                isLoading={loading}
                items={[{
                    icon: 'hourglass one',
                    color: 'orange',
                    label: t('item_edit.inventory.unavailable_until.until_tomorrow'),
                    onClick: async () => {
                        const tomorrow = new Date();
                        tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
                        tomorrow.setUTCHours(0,0,0,0);
                        await handleUnavailable(tomorrow);
                    },
                    active: true
                }, {
                    icon: 'hourglass',
                    color: 'red',
                    label: t('item_edit.inventory.unavailable_until.indefinitely'),
                    onClick: () => handleUnavailable('Infinity'),
                    active: true
                }]}
            />

            }
        </div>
    );
}

ItemAvailabilitySetter.propTypes = {
    itemId: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default ItemAvailabilitySetter;
