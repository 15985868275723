/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {debounce} from 'lodash';

import { useMemo } from 'react';


import {UltyTimePicker} from '@ulty/ulty-ui';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { MOBILE_THRESHOLD } from '../../../constants';
import { Button } from 'semantic-ui-react';

const TimePeriod = ({ label, timePeriod, onChange, onError, readonly,canDelete=false }) => {
    const [t] = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

    const debounceFieldChange = useMemo(() => (
        debounce((actionType, payload) => actionType === "start" ? handleStartTimeChange(payload) :handleEndTimeChange(payload), 700)
    ), [onChange]);
 

    const dateTimeFromTimeString = (timeString) => {
        if (!timeString) {
            return;
        }

        const [hours, minutes] = timeString.split(':');
        return moment().set('hour', hours).set('minute', minutes);
    }

    const timeStringFromDateTime = (dateTime) => {
        if (!dateTime || !dateTime.isValid()) {
            return '';
        }

        const hours = `${dateTime.hours()}`.padStart(2, '0');
        const minutes = `${dateTime.minutes()}`.padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    const handleStartTimeChange = (dateTime) => {
        onChange({
            start_time: timeStringFromDateTime(dateTime),
            end_time: timePeriod?.end_time || ''
        });
    }

    const handleEndTimeChange = (dateTime) => {
        onChange({
            start_time: timePeriod?.start_time || '',
            end_time: timeStringFromDateTime(dateTime)
        });
    }

    const handleDelete = () => {
        onChange(null);
    }

    const handleTimePeriodError = (error) => {
        onError(error)
    }


    return (
        <div css={css`
          display: flex;
          align-items: center;
          column-gap: 1rem;
          flex-direction: ${isMobile ? 'column' : 'row'};

          .MuiFormControl-root {
              max-width: 150px;
          }
        `}>
            <div css={css`
                display: flex;
                width: ${isMobile ? '100%' : 'auto'};
                justify-content: space-between;
                align-items: center;
                ${isMobile ? 'margin-bottom: 10px;' : ''}

                label {
                    min-width: 100px;
                }
            `}>
                <label>{label}:</label>
                <UltyTimePicker
                    label={t('administration.hours.start_hour')}
                    dateTime={timePeriod ? dateTimeFromTimeString(timePeriod.start_time) : undefined}
                    maxDateTime={timePeriod?.maxStartTime}
                    onChange={debounceFieldChange.bind(this,'start')}
                    onError={handleTimePeriodError}
                    readonly={readonly}
                />
            </div>

            <div css={css`
                display: flex;
                width: ${isMobile ? '100%' : 'auto'};
                justify-content: space-between;
                align-items: center;

                label {
                    min-width: 20px;
                }
            `}>
                <label>{t('administration.hours.to')}</label>

                <UltyTimePicker
                    label={t('administration.hours.end_hour')}
                    dateTime={timePeriod ? dateTimeFromTimeString(timePeriod.end_time) : undefined}
                    minDateTime={timePeriod?.minEndTime}
                    onChange={debounceFieldChange.bind(this,'end')}
                    onError={handleTimePeriodError}
                    readonly={readonly}
                />
            </div>
            {canDelete && (
                <Button circular icon="delete" size="mini" onClick={handleDelete}></Button>
            )}
        </div>
    )
}

export default TimePeriod;
