/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {Header, Icon, Segment, Table, Button} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react'
import {getOrderEvents} from '../../../services/Order/Order.service'
import {notify} from 'react-notify-toast'
import { EventItem } from './EventItem'


const OrderEvents = ({orderId, afterSubmit }) => {
    const [t] = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [events, setEvents] = useState([])

    useEffect(() => {
        (async function loadOrderEvents() {
            try {
                setIsLoading(true)
                setEvents(await getOrderEvents(orderId))
            } catch (e) {
                console.log(e)
                notify.show(t('global.anErrorOccurred'), 'error')
            } finally {
                setIsLoading(false)
            }
        })()
    }, [orderId, t])

    return (
        <Segment loading={isLoading}>
            <Header>{t('order.history.header')}</Header>

            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('order.history.date')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('order.history.event')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('order.history.user')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {events?.length > 0 ? events.map(event => (
                      <EventItem key={event.id} orderId={orderId} event={event} />
                    )): (
                      <Table.Row warning>
                          <Table.Cell textAlign="center" colSpan={6}>
                              <Icon name='attention'/>
                              {t('order.history.none')}
                          </Table.Cell>
                      </Table.Row>
                    )}
                </Table.Body>
            </Table>

            <div css={css`
              width: 100%;
              text-align: right;
            `}>
                <Button size="small" color="teal" onClick={afterSubmit}>
                    {t('global.validate')}
                </Button>
            </div>
        </Segment>
    )
}

export default OrderEvents;
