import React, {createContext, useCallback, useEffect, useState} from 'react';
import { usePrintedCache } from '../PrintedCache/usePrintedCache';
import { usePrintingOptions } from '../PrintingOptions/usePrintingOptions';
const RELOAD_DELAY_IN_MINUTES = 1

const CLEAR_OLD_CACHE_DELAY_IN_MINUTES = 60

const empty = {
    isConnected: false,
    deviceName: null,
}
const PrinterInfoContext = createContext({
    ...empty,
    isDeviceCompatible : false,
});


const PrinterInfoProvider = ({ children }) =>  {
    const [printerInfos, setPrinterInfos] = useState(empty);
    const [printingOptions,setPrintingOptions] = usePrintingOptions();

    const [trigger,reloadTrigger] = useState({});

    const triggerReload = useCallback(() => {
        reloadTrigger({});
    },[reloadTrigger])

    const [loaded, hasLoaded] = useState(false);


    const {clearOld} = usePrintedCache();

    const isDeviceCompatible = Boolean(window.Android && window.Android.getPrinterInfo);

    const loadPrinterInfos = useCallback(() => {
       if(!isDeviceCompatible) return;

       try {
            const infoStr = window.Android.getPrinterInfo(JSON.stringify(printingOptions));
            const info = JSON.parse(infoStr);
            setPrinterInfos({...info});
            hasLoaded(true);
       }catch {
            
        setPrinterInfos({...empty});
       }
    },[printingOptions])

    
    useEffect(() => {
        const debounceTimer = setTimeout(loadPrinterInfos,200);
        return () => {
            clearTimeout(debounceTimer);
        }
    },[trigger])

    const reload = useCallback(() => {
        setPrintingOptions(options => ({...options,isDisabled:false}));
        triggerReload();
    },[setPrintingOptions,triggerReload]);
    const disconnect = useCallback(() => {
        setPrintingOptions(options => ({...options,isDisabled:true}));
        triggerReload();
    },[setPrintingOptions,triggerReload])
    
    usePeriodicReload();
    useRefreshOnPrinterEvent();
    useFirstLoad();
    usePeriodicClearOldCache();

    return (
        <PrinterInfoContext.Provider value={
            {isDeviceCompatible,...printerInfos,reload,disconnect, printingOptions,setPrintingOptions }}>
            {children}
        </PrinterInfoContext.Provider>
    )

    function useFirstLoad() {
        useEffect( () => {
            if(!isDeviceCompatible) return;
            if(!loaded){
                triggerReload()
            }
        },[loaded,triggerReload])
    }

    function usePeriodicReload() {

        useEffect(() => {
            if(!isDeviceCompatible) return;
        
            const timer = setInterval( () => triggerReload(), 1000 * 60 * RELOAD_DELAY_IN_MINUTES);
            return () => {
                clearInterval(timer);
            };
        }, []);
    }

    function usePeriodicClearOldCache() {
        useEffect(() => {
            if(!isDeviceCompatible) return;
        
            const timer = setInterval( () => clearOld(), 1000 * 60 * CLEAR_OLD_CACHE_DELAY_IN_MINUTES);
            return () => {
                clearInterval(timer);
            };
        }, []);
        
    }

    function useRefreshOnPrinterEvent() {
        useEffect(() => {
            if(!isDeviceCompatible) return;
            window.addEventListener('android.ulty.printer-updated', triggerReload);

            return () => {
                window.removeEventListener('android.ulty.printer-updated', triggerReload);
            };
        },[])
    
    }

}

export { PrinterInfoProvider, PrinterInfoContext };
