/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import MarkupSetupAdd from './MarkupSetupAdd'
import { useCallback, useEffect, useState } from 'react'
import { getMarkupSetup } from '../../../services/Category/Category.service'
import { Dimmer, DimmerDimmable, Header, Icon, Loader, Label, Message, Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import MarkupSetupItem from './MarkupSetupItem'
import { getPosBrandPlatform } from '../../../services/PointOfSale/PointOfSale.service'
import useUser from '../../Shared/UserProvider/useUser'
import MarkupPriceChecker from './MarkupPriceChecker'

const getMarkupRank = function (markup, categorySet) {
    const slotId = markup.slot?.id
    const categoryId = markup.category?.id
    const matchedSlot = categorySet.slots.find(s => s.id === slotId)
    if (!matchedSlot) return -1
    const matchedCategory = matchedSlot.categories.find(c => c.id === categoryId)
    if (!matchedCategory) return matchedSlot.position * 1000

    return matchedSlot.position * 1000 + matchedCategory.position
}

const sortMarkupByRange = function (markups, categorySet) {
    return [...markups].sort((m1, m2) => {
        const m1Rank = getMarkupRank(m1, categorySet)
        const m2Rank = getMarkupRank(m2, categorySet)

        return m1Rank - m2Rank
    })
}


const MarkupSetupEdit = ({categorySetLink, categorySet}) => {
    const [t] = useTranslation()
    const {user} = useUser()
    const [store, setStore] = useState(null)
    const [isStoreLoading, setIsStoreLoading] = useState(false)
    const [markupSetups, setMarkupSetups] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [displayAdd, setDisplayAdd] = useState(false)

    const [displayedMarkupSetups, setDisplayedMarkupSetups] = useState([])

    useEffect(() => setDisplayedMarkupSetups(sortMarkupByRange(markupSetups, categorySet)), [markupSetups, categorySet])

    const mightHaveGlobalMarkup = user.provider.type !== 'COMPANY'

    const loadMarkupSetup = useCallback(async () => {
        setDisplayAdd(false)
        setIsLoading(true)
        setMarkupSetups(await getMarkupSetup(categorySetLink.id))
        setIsLoading(false)
    }, [categorySetLink])

    useEffect(() => {
        loadMarkupSetup()
    }, [])

    useEffect(() => {
        (async function loadPosBrandPlatform() {
            if (mightHaveGlobalMarkup) {
                setIsStoreLoading(true)
                setStore(await getPosBrandPlatform(user.getUserPos().id, categorySetLink.brand.id, categorySetLink.platform.id))
                setIsStoreLoading(false)
            }
        })()
    }, [categorySetLink.brand.id, categorySetLink.platform.id, mightHaveGlobalMarkup, user])

    return (
      <div css={css`
          .pointer {
              cursor: pointer;
          }
      `}>

          <div>
              <Message color={'blue'} compact css={css`white-space: pre-wrap;`}>
                  <Icon name="info circle"/>
                  {t('markup.markup_explain')}
              </Message>

          </div>

          <Dimmer.Dimmable dimmed={true}>
              <Dimmer active={isLoading} inverted>
                  <Loader/>
              </Dimmer>
              {(displayedMarkupSetups.length > 0 || mightHaveGlobalMarkup) && (
                <Header>{t('markup.markups')}</Header>

              )}
              {mightHaveGlobalMarkup && (
                <Segment>
                    {store !== null && (<>
                        <Label horizontal size="large">
                            {store.markup} %
                        </Label>
                        <strong>{t('markup.base_markup_on')} {categorySetLink.platform.name}</strong>
                    </>)}


                    <Dimmer active={isStoreLoading} inverted>
                        <Loader/>
                    </Dimmer>
                </Segment>
              )}
              {displayedMarkupSetups.length > 0 && displayedMarkupSetups
                .map(markupSetup => (
                  <MarkupSetupItem
                    key={`${markupSetup.id}`}
                    categorySetLinkId={categorySetLink.id}
                    categorySetId={categorySet.id}
                    markupSetup={markupSetup}
                    displaySim
                    onDelete={loadMarkupSetup}
                  />
                ))}
          </Dimmer.Dimmable>
          <DimmerDimmable blurring as={Segment} dimmed={!displayAdd}>
              <MarkupSetupAdd categorySetLink={categorySetLink} categorySet={categorySet} onAdd={loadMarkupSetup}/>

              <Dimmer className="pointer" inverted active={!displayAdd} onClick={() => setDisplayAdd(true)}>
                  <Header size="mini" icon color="blue">
                      <Icon size="mini" name="plus"/>
                      {t('markup.add_markup')}
                  </Header>
              </Dimmer>
          </DimmerDimmable>
      </div>
    )
}

export default MarkupSetupEdit
