/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useCallback, useState } from 'react';

import { Button, Segment, Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import Chart from "react-apexcharts";
import { unitFormat } from '../../helpers/money';
import useUser from '../Shared/UserProvider/useUser';
import { customTooltip } from './apexChartUtils';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { MOBILE_THRESHOLD } from '../../constants';
import moment from 'moment';

const PercentChart = ({ loading, data, colors }) => {
  const [t] = useTranslation();
  const { user } = useUser();

  const [timebucket, setTimebucket] = useState("DAY");
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
  const [type, setType] = useState("VOLUME");

  const getData = useCallback(() => {
    let datas = [];
    if (timebucket !== "DAY") {
      const aggData = {};
      data.forEach((d) => {
        const dt = new Date(d.dt);

        if (timebucket === "MONTH") {
          dt.setUTCDate(1);
        } else {
          const distance = (7 - ((8 - dt.getUTCDay()) % 7)) % 7;
          dt.setUTCDate(dt.getUTCDate() - distance);
        }

        aggData[dt.getTime()] = [
          (aggData[dt.getTime()] || [0, 0])[0] + (type === 'VOLUME' ? d.nborder : d.revenues),
          (aggData[dt.getTime()] || [0, 0])[1] + (type === 'VOLUME' ? d.missedorder : d.revenuesMissed),
        ];
      });

      datas = Object.entries(aggData).map((d) => [parseInt(d[0]), d[1]]);
    } else {
      datas = data.map(d => [
        (new Date(d.dt)).getTime(),
        [(type === 'VOLUME' ? d.nborder : d.revenues), (type === 'VOLUME' ? d.missedorder : d.revenuesMissed)]]);
    }
    datas = datas.sort((a, b) => b[0] - a[0]);

    if (datas.length === 0) {
      return [];
    }

    let min = datas[datas.length - 1][0];
    const max = datas[0][0], results = [];

    while (min <= max) {
      const locMin = min;
      const [dt] = datas.filter(d => d[0] === locMin);

      if (!dt) {
        results.push([locMin, [0, 0.0]]);
      } else {
        results.push(dt);
      }

      if (timebucket === 'DAY') {
        min += 86400000;
      }
      if (timebucket === 'WEEK') {
        min += 86400000 * 7;
      }
      if (timebucket === 'MONTH') {
        const mt = (new Date(min));
        mt.setUTCMonth(mt.getUTCMonth()+1);
        min = mt.getTime();
      }
    }

    return results;
  }, [data, timebucket, type]);

  const isPeriodComplete = (timebucket, sd, ed) => {
    switch(timebucket) {
      case 'DAY':
        return true;

      case 'WEEK':
        return sd.isoWeekday() === 1 && ed.isoWeekday() === 7;

      case 'MONTH':
        const endofmonth = ed.clone().endOf('month').date();
        return sd.date() === 1 && ed.date() === endofmonth;

      default:
        return true;
    }
  }

  const getDisclaimer = () => {
    let disclaimer = null, min, max;
    if (data && data.length > 0) {
      max = moment(data[0].dt);
      min = moment(data[data.length - 1].dt);
      disclaimer = isPeriodComplete(timebucket, min, max) ? null : t(`home.range_disclaimer_${timebucket.toLowerCase()}`); 
    }
    return disclaimer;
  }

  return (
    <>
      <Segment style={{
        maxHeight: '300px',
        minHeight: '295px',
      }}  css={css`
      .ui.dropdown {
          position: absolute;
          right: 40px;
          z-index: 100;
          top: 2px;
      }
    `}>
        {!loading && data && data.length > 0 && (
          <>
            <Button.Group
              toggle
              className="time-toggle"
              style={{
                [isMobile ? "bottom" : "top"]: isMobile ? "0" : "1rem",
                width: isMobile ? "100%" : "auto",
                position: isMobile ? "relative" : "absolute",
                zIndex: 100
              }}
              size="mini"
              compact
            >
              <Button
                size="mini"
                onClick={() => setTimebucket("DAY")}
                toggle
                color={timebucket === "DAY" && "teal"}
                className="btn-time"
              >
                {t("home.analytics.day")}
              </Button>
              <Button
                size="mini"
                onClick={() => setTimebucket("WEEK")}
                toggle
                color={timebucket === "WEEK" && "teal"}
                className="btn-time"
              >
                {t("home.analytics.week")}
              </Button>
              <Button
                size="mini"
                onClick={() => setTimebucket("MONTH")}
                toggle
                color={timebucket === "MONTH" && "teal"}
                className="btn-time"
              >
                {t("home.analytics.month")}
              </Button>
            </Button.Group>
            <div css={css`
                    position: relative;
                `}>
              <Dropdown
                options={[{
                  text: t('home.failure.ca'),
                  value: 'CA',
                  key: 'CA'
                }, {
                  text: t('home.failure.volume'),
                  value: 'VOLUME',
                  key: 'VOLUME'
                }]}
                value={type}
                onChange={(evt, { value }) => setType(value)}
              />

              <Chart
                key={type}
                options={{
                  dataLabels: {
                    enabled: true
                  },
                  chart: {
                    zoom: {
                      enabled: false
                    },
                    stacked: true,
                    stackType: '100%'
                  },
                  legend: {
                    show: true,
                    onItemClick: {
                      toggleDataSeries: false,
                    },
                    onItemHover: {
                      highlightDataSeries: false
                    },
                  },
                  xaxis: {
                    type: 'datetime'
                  },
                  stroke: {
                    colors: ['transparent'],
                    width: 4,
                  },
                  tooltip: {
                    shared: true,
                    intersect: false,
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                      return customTooltip({ t, series, seriesIndex, dataPointIndex, w, currencyCode: user.getCompanyCurrency(), locale: user.getCompanyLocale(), disclaimer: getDisclaimer() });
                    }
                  },
                  colors: colors || ['#45b29d', '#334d5c'],
                  yaxis: [
                    {
                      title: {
                        text: t('home.percent_revenues')
                      },
                    },
                  ],
                  title: {
                    text: t('home.percent_completed'),
                    align: 'center'
                  }
                }}
                series={type === 'VOLUME' ? [{
                  name: t('home.acquired_orders'),
                  data: getData().map(d => [d[0], d[1][0]]),
                  dataType: 'NUMERIC'
                }, {
                name: t('home.missed_orders'),
                  data: getData().map(d => [d[0], d[1][1]]),
                  dataType: 'NUMERIC'
                }] : [{
                  name: t('home.acquired_revenues'),
                  data: getData().map(d => [d[0], unitFormat(d[1][0], user.getCompanyCurrency())]),
                  dataType: 'CURRENCY'
                }, {
                name: t('home.missed_revenues'),
                  data: getData().map(d => [d[0], unitFormat(d[1][1], user.getCompanyCurrency())]),
                  dataType: 'CURRENCY'
                }]}
                type="bar"
                stacked
                height={250}
              />
            </div>
          </>
        )}
      </Segment>
    </>
  )
}

export default PercentChart
