 import axios from 'axios'
 import axiosRetry, {isNetworkOrIdempotentRequestError,exponentialDelay} from 'axios-retry'

import { AUTH_TOKEN, API_URL } from '../constants'

const isNetworkErrorOrIsIdempotent = (error) => isNetworkOrIdempotentRequestError(error) || !!error.config.headers["Idempotency-Key"];

export const getCancellationSource = () => {
    let isCancelled = false; 
    const source = axios.CancelToken.source();
    return {
        patchAxiosOptions: function(options) {
            return {
                ...options,
                cancelToken:source.token
            }
        },
        cancel: function(){
            isCancelled = true;
            source.cancel();
        },
        get isCancelled(){
            return isCancelled
        },
    }
}


export const withIdempotencyKey = (key=null) => (
    {
      headers: {
        "Idempotency-Key": key ||new Date()
      }
    });

const ultyApi = axios.create({
    baseURL: API_URL,
    timeout: 300000,
    headers: {
        "x-ulty-version": process.env.REACT_APP_VERSION,
        "x-ulty-app-version": (window.Android?.getVersion && window.Android.getVersion()) || ''
    },
});

axiosRetry(ultyApi,{
    retries: 10,
    shouldResetTimeout: true,
    retryDelay: exponentialDelay,
    retryCondition: isNetworkErrorOrIsIdempotent
})

ultyApi.interceptors.request.use(async (config) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) {
        config.headers.Authorization = `Bearer ${token || ''}`;
    }
    return config;
});

export const call = async (method, routePrefix, path, body, options={}) => {
    try {
        const { status, data } = await rawCall(method, routePrefix, path, body, options);
        if (status === 200) {
            // Legacy endpoints wraps their response in a data property.
            return data.data || data;
        }

        return null;
    } catch(e) {
        return handleHttpErrors(e);
    }
}


const isFromUserCheckEndpoint = (error) => {
    return ((error?.response?.request?.responseURL||'').indexOf('/user/check') > 0)
}

const handleHttpErrors = async (error) => {
    const isClientError = (httpCode) => {
        const errors4xx = new RegExp('(^4)');
        return errors4xx.test(httpCode);
    }


    const isCancelled = axios.isCancel(error);

    if(isCancelled) return;

    if(isFromUserCheckEndpoint(error)){
        localStorage.removeItem(AUTH_TOKEN);
        window.location.reload();
    }

    if (isClientError(error.response?.status)) {
        if (error.response?.status === 401) {
            await call(METHODS.GET, '/user', 'check');
        } else {
            throw Error(error.response?.data?.code);
        }
    }

    const isServerError = (httpCode) => {
        const errors5xx = new RegExp('(^5)');
        return errors5xx.test(httpCode);
    }

    if (isServerError(error?.response?.status)) {
        throw Error('server_error');
    }
}

export const rawCall = async (method, routePrefix, path, body, options={}) => {
    try {
        const route = `${routePrefix}${path ? `/${path}` : ''}`;
        return (await ultyApi[method.toLowerCase()](route, body, options));
    } catch(e) {
        throw e;
    }
}

export const getFileContent = async (route, options) => {
    try {
        return (ultyApi.get(route, options));
    } catch(e) {
        throw e;
    }
}

export const METHODS = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete'
}
