import React from 'react'

import { Tab } from 'semantic-ui-react'

import OrderElement from '../OrderElement'
import formatDate from '../../../helpers/formatDate'
import { useTranslation } from 'react-i18next'

const OrdersFinished = () => {
  const [t] = useTranslation();

  return (
    <Tab.Pane as="div">
      <OrderElement 
        state="finished"
        seeText={t('global.see')} 
        emptyText={t('order.no_handled')}
        showSummary={true}
        getStatusText={(o) => t('order.finished_at', { dt: `${formatDate(o.completedAt)}`})} />
    </Tab.Pane>
  )
}

export default OrdersFinished