import { useContext } from 'react';
import { PrinterInfoContext } from './PrinterInfo/PrinterInfo.provider';
import { usePrintedCache } from './PrintedCache/usePrintedCache';
import { getClientName } from '../../helpers/order';

const usePrinter =  () => {
    const {
        printingOptions,setPrintingOptions,
        ...printerInfo
     } = useContext(PrinterInfoContext);

    const {addIfNotExists} = usePrintedCache();


    function printOrder(order, bypassCache= false) {
        if(window.Android?.tryPrint){
            if(bypassCache || addIfNotExists(order.id)) {
                window.Android?.tryPrint(
                    JSON.stringify({
                        ...order,
                        customerName: getClientName(order.customer)
                    }),
                    JSON.stringify(printingOptions)
                );
            }
           
        }
    }

    return {printOrder,printerInfo,printingOptions,setPrintingOptions}
}

export {usePrinter}