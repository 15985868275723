/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import PropTypes from 'prop-types';
import {Button, Icon, Image, Segment} from 'semantic-ui-react';
import {UltyInputText} from '@ulty/ulty-ui';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import ItemImagePlatformAssociationActions from './ItemImagePlatformAssociationActions';
import {definePlatformItemImage, getPlatformItemImage} from '../../services/Platform/Platform.service';
import {notify} from 'react-notify-toast';

const itemStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const squareImg = '/img/image.png'

const ItemImagePlatformAssociationItem = ({ platform, itemId, defaultItemImage, handleDefineExpandImageModal, isLoading: isParentLoading }) => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(isParentLoading);
    const [isLoaded, setIsLoaded] = useState(false);
    const [platformItemImage, setPlatformItemImage] = useState(squareImg);

    useEffect(() => {
        setIsLoading(true);
        getPlatformItemImage(platform.id, itemId).then(platformItemImage => {
            setPlatformItemImage(platformItemImage);
        }).catch(e => {
            console.log(e);
            notify.show(`${t('global.anErrorOccurred')}`, 'error');
        }).finally(() => {
            setIsLoading(isParentLoading || false);
            setIsLoaded(true);
        });
    }, [isParentLoading, itemId, platform.id, t]);

    const getPlatformImageUrlToDisplay = () => {
        if (!isLoaded) {
            return squareImg;
        }

        if (platformItemImage?.url) {
            return platformItemImage.url
        }

        return defaultItemImage.url || squareImg;
    }

    const getDefaultLabel = () => {
        const label = {
            ribbon: 'right',
            content: t('item_image_platform_association.default_image')
        };

        return (!platformItemImage || platformItemImage?.isDefault) ? label : null;
    }

    const setNewPlatformItemImage = async (newPlatformItemImage) => {
        try {
            const platformItemImage = await definePlatformItemImage(platform, {id: itemId}, newPlatformItemImage);
            setPlatformItemImage(platformItemImage);
        } catch (e) {
            console.log(e);
            notify.show(`${t('global.anErrorOccurred')}`, 'error');
        }
    }

    const resetPlatformItemImageToDefaultItemImage = async () => {
        try {
            await setNewPlatformItemImage(null);
            setPlatformItemImage(defaultItemImage);
        } catch (e) {
            console.log(e);
            notify.show(`${t('global.anErrorOccurred')}`, 'error');
        }
    }

    return (
        <Segment loading={isLoading} css={itemStyle}>
            <UltyInputText
                label={t('item_image_platform_association.platform')}
                value={platform.name}
                readonly={true}
            />
            <Image src={platform.logo} size='small' />
            <Image
                css={css`cursor: pointer`}
                src={getPlatformImageUrlToDisplay()}
                size='small'
                title={t('itemImages.preview')}
                bordered
                label={getDefaultLabel()}
                onClick={() => handleDefineExpandImageModal(getPlatformImageUrlToDisplay(getPlatformImageUrlToDisplay()))}
            />

            <Button
                disabled={!platformItemImage?.id || platformItemImage?.id === defaultItemImage?.id}
                onClick={resetPlatformItemImageToDefaultItemImage}
            >
                <Icon
                    name='undo'
                />
                {t('item_image_platform_association.reset_to_default_item_image')}
            </Button>

            <ItemImagePlatformAssociationActions
                itemId={itemId}
                initialImage={platformItemImage?.url}
                onAddImage={async (url) => {
                    await setNewPlatformItemImage({
                        url,
                        isDefault: false
                    });
                }}
                onPickImage={async (pickedImage) => {
                    await setNewPlatformItemImage(pickedImage);
                }}
            />
        </Segment>
    )
}

ItemImagePlatformAssociationItem.propTypes = {
    defaultItemImage: PropTypes.object.isRequired,
    platform: PropTypes.object.isRequired,
    itemId: PropTypes.string.isRequired,
}

export default ItemImagePlatformAssociationItem;
