import { UltyInputText } from '@ulty/ulty-ui'
import useFilters from '../useFilters'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DisplayIdFilter = () => {
    const [t] = useTranslation();
    const { filters, setFilters, debounceFiltersChange } = useFilters();

    const handleDisplayIdChange = (displayId) => {
        const newFilters = {
            ...filters,
            dateRange: displayId ? {
                startDate: moment().utc().subtract(7, 'd'),
                endDate: moment().utc()
              } : filters.dateRange,
            displayId
        }

        setFilters(newFilters);
        debounceFiltersChange(newFilters)
    }

    return (
      <UltyInputText
        name="displayId"
        label={t('order.display_id')}
        placeholder='9E7AB'
        value={filters.displayId}
        onChange={e => handleDisplayIdChange(e.target.value)}
      />
    )
}

export default DisplayIdFilter
