/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Item from './Item'

const gridComponents = {
    List: React.forwardRef(({style, children, ...props}, ref) => (
      <div
        ref={ref}
        {...props}
        style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1rem',
            justifyContent: 'space-evenly',
            ...style,
        }}
      >
          {children}
      </div>
    )),
    Item: ({children, ...props}) => (
      <div
        {...props}
      >
          {children}
      </div>
    ),
}

const SortableItem = ({item, onDeleteItem, canDelete}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: item.id})

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        touchAction: 'manipulation',
        width: '100%',
    }

    return (
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        css={css`
            cursor: grab;
        `}>
          <Item
            item={item}
            onDeleteItem={onDeleteItem}
            canDelete={canDelete}
          />
      </div>
    )
}

export { gridComponents, SortableItem }
