/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect } from 'react'

import { Dimmer, Loader, Tab,Icon,Button } from 'semantic-ui-react'

import OrderList from '../OrderElement/OrderList'
import OrderStore from '../OrderStore'

import humanizeDuration from '../../../helpers/humanizeDuration'
import { useTranslation } from 'react-i18next'
import useBreadcrumb from '../../NavBar/useBreadcrumb'
import useLocalStorage from 'beautiful-react-hooks/useLocalStorage'
import { TogglePrintOnNewOrder } from '../../../printing/components/TogglePrintOnNewOrder/TogglePrintOnNewOrder';
const isScheduledLater = (o) => {
  if(!o.pickupAt) return false;
  
  const inOneHour = new Date((new Date()).setHours(new Date().getHours()+1));
  console.log(inOneHour,new Date(o.pickupAt))
  return new Date(o.pickupAt) > inOneHour;
}

const sortByPickupDescThenReceivedDateDesc = (o1,o2) => {
    const now = new Date();
    
    if (o1.status === 'RECEIVED' && o1.status !== o2.status && !isScheduledLater(o1)) return -1;
    if (o2.status === 'RECEIVED' && o1.status !== o2.status && !isScheduledLater(o2)) return 1;


    const compare1 = o1.pickupAt || o1.receivedAt;
    const compare2 = o2.pickupAt || o2.receivedAt;

    if(compare1 < now && compare2 > now) return 1
    if(compare1 > now && compare2 < now) return -1

    return (new Date(compare1)).getTime() - (new Date(compare2).getTime());
}

const DisplayMode = {
  BY_RECEIVED_DATE : "BY_RECEIVED_DATE",
  BY_NEXT_ACTION_DATE : "BY_NEXT_ACTION_DATE"
}


const OrdersCurrent = () => {
  const [t] = useTranslation();
  const {setPaths} = useBreadcrumb();

  const store = OrderStore.useContainer();

  const [displayedOrder,setDisplayedOrder] = useState(store.orders);
  const [displayMode,setDisplayMode] = useLocalStorage('OrdersCurrent.displayMode',DisplayMode.BY_RECEIVED_DATE);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const sorted =
      displayMode === DisplayMode.BY_NEXT_ACTION_DATE
        ? [...store.orders].sort(sortByPickupDescThenReceivedDateDesc)
        : store.orders;

    setDisplayedOrder(sorted)
  },[store.orders,displayMode])

  const loadCurrentOrders = async () => {
    setLoading(true);
    await store.reload();
    setLoading(false);
  }

  useEffect(() => {
    setPaths([{
      text: t('breadcrumb.home'),
      link: true,
      path: '/'
    }, {
      text: t('breadcrumb.orders'),
      link: false,
    }]);
  }, []);

  useEffect(() => {
    if (window.Android && window.Android.stopNotifications) {
      window.Android.stopNotifications();
    }
  }, []);

  useEffect(() => {
    const handler = () => {
      loadCurrentOrders();
    }
    window.addEventListener("AndroidResume", handler, false);

    return () => {
      window.removeEventListener('AndroidResume', handler);
    };
  }, []);

  useEffect(() => {
    if (store.orderPage !== '') {
      store.setOrderPagination(1);
    }
    store.setOrderPage('');
    loadCurrentOrders();
  }, []);

  return (
    <Tab.Pane  css={css`
      .cleared:after {
        content: '';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
      }
    `}>
      <div className='cleared'>

        <Button.Group floated='left' >
        <Button icon 
          active={displayMode === DisplayMode.BY_RECEIVED_DATE}
          onClick={() => setDisplayMode(DisplayMode.BY_RECEIVED_DATE)}>
            <Icon name='th list' />
        </Button>
        <Button icon
          active={displayMode === DisplayMode.BY_NEXT_ACTION_DATE}
          onClick={() => setDisplayMode(DisplayMode.BY_NEXT_ACTION_DATE)}>
            <Icon name='sort amount down' />
        </Button>
        </Button.Group>
        <Button.Group floated='right'>
          <TogglePrintOnNewOrder />
        </Button.Group>
        </div>

        <OrderList 
          seeText={t('order.notification.handle')} 
          emptyText={t('order.all_handled')} 
          loading={loading}
          orders={displayedOrder}
          displayDelivery
          getStatusText={(o) => t('order.received_at', { dt: `${humanizeDuration(new Date(),o.receivedAt, {round: true, units: ['d', 'h', 'm', 's'] })}`})}
        />
        <Dimmer active={loading} inverted css={css`
          .ui.loader {
            top: 60px !important;
          }
        `}>
          <Loader inverted>{t('global.loading')}</Loader>
        </Dimmer>
    </Tab.Pane>
  )
}

export default OrdersCurrent
