import React, {useEffect, useReducer, useState} from 'react';
import {
    Button,
    Form,
    Grid,
    Icon,
} from 'semantic-ui-react';
import {notify} from 'react-notify-toast';
import {canSaveMenu, deleteMenu, getMenu, saveMenu} from '../../../services/Menu/Menu.service';
import produce from 'immer';
import MenuSummary from './MenuSummary';
import {UltyModalContext} from '../../Shared/UltyModal/UltyModalContext';
import ItemElements from '../../Items/ItemElements/ItemElements';
import itemElementsReducer from '../../Items/ItemElements/ItemElementsReducer';
import useUser from '../../Shared/UserProvider/useUser';
import {isProviderAPos, setProviderItemInventory} from '../../../services/Provider/Provider.service';
import {useTranslation} from 'react-i18next';
import {isItemProvidedByUserProvider} from '../../../services/Item/Item.service';
import useBreadcrumb from '../../NavBar/useBreadcrumb';
import {useHistory, useParams} from 'react-router-dom';

const initialState = {
    id: 0,
    type: 'MENU',
    basePrice: 0,
    taxRate: 0,
    maxOrderableQuantity: '',
    providerIds: [],
    inventory: {
        sellPrice: null,
        unavailableUntil: null,
        externalId: ''
    },
    image: null,
    object: {
        name: '',
        description: '',
    },
    itemElements: [],
};

const menuEditReducer = (state, action) => {
    switch (action.type) {
        case 'SET_MENU':
            return produce(state, draft => {
                draft.id = action.payload.id;
                draft.type = action.payload.type;
                draft.basePrice = action.payload.basePrice;
                draft.taxRate = action.payload.taxRate;
                draft.maxOrderableQuantity = action.payload.maxOrderableQuantity;
                draft.image = action.payload.images.find(image => image.isDefault);
                draft.containsAlcohol = action.payload.containsAlcohol;
                draft.externalId = action.payload.externalId;
                draft.inventory.externalId = action.payload.inventory?.externalId || '';
                draft.inventory.sellPrice = action.payload.inventory?.sellPrice;
                draft.inventory.unavailableUntil = action.payload.inventory?.unavailableUntil || null;
                draft.object = {
                    name: action.payload.object.name,
                    description: action.payload.object.description,
                };
                draft.providerIds = action.payload.providers.map(p => p.id);
            });
        case 'SET_INVENTORY':
            return produce(state, draft => {
                draft.inventory.externalId = action.payload.externalId || '';
                draft.inventory.sellPrice = action.payload.sellPrice || '';
            });
        case 'SET_ITEM_FIELD':
            return produce(state, draft => {
                draft[action.payload.field] = action.payload.value;
            });
        case 'SET_OBJECT_FIELD':
            return produce(state, draft => {
                draft.object[action.payload.field] = action.payload.value;
            });
        case 'SET_INVENTORY_FIELD':
            return produce(state, draft => {
                draft.inventory[action.payload.field] = action.payload.value;
            });
        default:
            throw Error(`Action of menuEditReducer: '${action.type}' not found`);
    }
};

const MenuEdit = () => {
    const {id} = useParams();
    const history = useHistory();
    const [t] = useTranslation();
    const {user, can} = useUser();
    const {setPaths} = useBreadcrumb();
    const [stateMenu, dispatchMenu] = useReducer(menuEditReducer, initialState);
    const [state, dispatch] = useReducer(itemElementsReducer, initialState);
    const [loading, setIsLoading] = useState(false);
    const { handleUltyModal } = React.useContext(UltyModalContext);
    const [canEditItem, setCanEditItem] = useState(true);
    const [canDeleteItem, setCanDeleteItem] = useState(true);

    useEffect(() => {
        if (id) {
            loadMenu(id);
        } else {
            setCanEditItem(true);
        }
    }, [id]);

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/'
        }, {
            text: t('breadcrumb.catalog'),
            link: true,
            path: '/products'
        }, {
            text: stateMenu?.object?.name || t('global.loading'),
            link: true,
            path: `/menus/edit/${id}`
        }, {
            text: t('breadcrumb.edition'),
            link: false,
        }]);
    }, [stateMenu, id]);

    const handleDefineDeleteMenuModal = () => {
        const modalSettings = {
            title: t('menu_edit.remove', { menu: stateMenu.object.name }),
            content: <>
                <p>{t('menu_edit.remove_confirm_1')}</p>
                <p>{t('menu_edit.remove_confirm_2')}</p>
            </>,
            onValidate: async () => {
                await handleDeleteMenu(stateMenu.id);
                handleUltyModal(false);
                navigateToMenuList();
            }
        };
        handleUltyModal(true, modalSettings);
    }

    const loadMenu = async (menuId) => {
        setIsLoading(true);
        const menuItem = await getMenu(menuId);
        dispatchMenu({ type: 'SET_MENU', payload: menuItem });
        dispatch({ type: 'SET_ITEM_ELEMENTS', payload: menuItem.itemElements })

        const userOwnItem = isItemProvidedByUserProvider(menuItem, user);
        setCanEditItem(userOwnItem && can('UPDATE', 'menu'));
        setCanDeleteItem(userOwnItem && can('UPDATE', 'menu'));
        setIsLoading(false);
    };

    const handleDeleteMenu = async (menuItemId) => {
        setIsLoading(true);
        await deleteMenu(menuItemId);
        setIsLoading(false);
    }

    function navigateToMenuList() {
        history.push('/menus');
    }

    const handleSaveMenu = async () => {
        setIsLoading(true);
        try {
            let savedMenu = {
                id: stateMenu.id,
                type: stateMenu.type,
                basePrice: stateMenu.basePrice,
                taxRate: stateMenu.taxRate,
                maxOrderableQuantity: stateMenu.maxOrderableQuantity,
                image: stateMenu.image,
                containsAlcohol: stateMenu.containsAlcohol,
                externalId: stateMenu.externalId,
                object: {
                    name: stateMenu.object.name,
                    description: stateMenu.object.description,
                },
                itemElements: state.itemElements.map((item, idx) => ({
                    ...item,
                    position: idx,
                    items: item.items.map((item, idx) => ({
                        ...item,
                        object: {
                            ...item.object,
                            position: idx
                        }
                    }))
                })),
                providerIds: stateMenu.providerIds
            };
            if (can('UPDATE', 'menu')) {
                savedMenu = await saveMenu(savedMenu);
                dispatchMenu({ type: 'SET_MENU', payload: savedMenu });
                dispatch({ type: 'SET_ITEM_ELEMENTS', payload: savedMenu.itemElements });
            }

            let inventory = null;
            if (isProviderAPos(user.provider)) {
                inventory = await setProviderItemInventory(user.provider.id, savedMenu.id, {
                    quantity: null,
                    sellPrice: stateMenu.inventory.sellPrice,
                    externalId: stateMenu.inventory.externalId
                });

                dispatchMenu({ type: 'SET_INVENTORY', payload: inventory });
            }

            notify.show(t('global.registerSuccess'), 'success');
            history.push(`/menus/edit/${savedMenu.id}`);
        } catch(e) {
            console.error(e);
            notify.show(t('global.anErrorOccurred'), 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const handleFieldChange = (type, payload) => {
        dispatchMenu({ type, payload });
    }

    return (
        <>
            <Form loading={loading}>
                <Grid>
                    <Grid.Row style={{marginTop: '10px'}}>
                        <Grid.Column width={16} textAlign="right">
                            <Button
                                type="submit"
                                color="teal"
                                onClick={handleSaveMenu}
                                loading={loading}
                                disabled={!canSaveMenu(stateMenu)}
                            >
                                {t('global.save')}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <MenuSummary
                    id={stateMenu.id}
                    type={stateMenu.type}
                    object={stateMenu.object}
                    basePrice={stateMenu.basePrice}
                    taxRate={stateMenu.taxRate}
                    maxOrderableQuantity={stateMenu.maxOrderableQuantity}
                    image={stateMenu.image}
                    containsAlcohol={stateMenu.containsAlcohol}
                    externalId={stateMenu.externalId}
                    providerIds={stateMenu.providerIds}
                    inventory={stateMenu.inventory}
                    canEditItem={canEditItem}
                    onFieldChange={(actionType, field) => handleFieldChange(actionType, field)}
                />

                <ItemElements
                    itemTypeContext="MENU"
                    itemElements={state.itemElements}
                    onChangeItemElementField={canEditItem ? (itemElementId, field, value) => dispatch({ type: 'SET_ITEM_ELEMENT_FIELD', payload: { itemElementId, field, value } }) : null}
                    onChangeItemElementItems={canEditItem ? (itemElementId, items) => dispatch({ type: 'SET_ITEM_ELEMENT_ITEMS', payload: { itemElementId, items } }) : null}
                    onChangeItemElementPosition={(itemElementId, value) => dispatch({ type: 'SET_ITEM_ELEMENT_POSITION', payload: { itemElementId, to: value } })}
                    onAdd={canEditItem ? (itemElement) => dispatch({ type: 'ADD_ITEM_ELEMENT', payload: {
                            id: `new-${state.itemElements.length + 1}`, ...itemElement
                        }}): null}
                    onDelete={canEditItem ? (itemElementId) => dispatch({ type: 'REMOVE_ITEM_ELEMENT', payload: itemElementId }) : null}
                />

                <Grid>
                    <Grid.Row style={{marginTop: '10px'}}>
                        <Grid.Column width={8}>
                            { canDeleteItem &&
                            <Button
                                color="red"
                                onClick={handleDefineDeleteMenuModal}
                                loading={loading}
                            >
                                <Icon name={'delete'}/>
                                {t('global.delete')}
                            </Button>
                            }
                        </Grid.Column>
                        <Grid.Column width={8} textAlign="right">
                            <Button
                                type="submit"
                                color="teal"
                                onClick={handleSaveMenu}
                                loading={loading}
                                disabled={!canSaveMenu(stateMenu)}
                            >
                                {t('global.save')}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </>
    )
}

export default MenuEdit;
