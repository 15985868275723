/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useQuery } from '@tanstack/react-query'
import { getOrder, getOrderPickingProofsUrls } from '../../../services/Order/Order.service'
import { Loader, Image, Button, Icon, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { NavLink, useParams } from 'react-router-dom'

const PickingProofsGallery = () => {
    const [t] = useTranslation()
    const { id } = useParams()
    const { data: order } = useQuery({
        queryFn: () => getOrder(id),
        queryKey: [`orders/${id}`]
    })

    const { data: pickingProofs, isLoading } = useQuery({
        queryFn: () => getOrderPickingProofsUrls(id),
        queryKey: [`orders/${id}/picking-proofs`]
    })

    if (!pickingProofs) {
        return <Loader active/>
    }

    return (
      <>
          <div
            css={css`
                display: flex;
                align-items: baseline;
                column-gap: 1rem;
            `}
          >
              <Button icon as={NavLink} to={`/orders/${id}`}>
                  <Icon name="arrow left" />
              </Button>
              {order && <Header>{order.platform.name} - {order.displayId}</Header>}
          </div>
          <div
            css={css`
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            `}>
              {pickingProofs.map((pickingProof, index) => (
                <div
                  key={pickingProof}
                  css={css`
                      display: flex;
                      flex-direction: column;
                      margin: .5rem;
                      align-items: center;
                  `}
                >
                    <Image
                      css={css`
                          border: 4px solid teal;
                          min-width: 300px;
                          object-fit: contain;
                          height: auto;
                      `}
                      size={'small'}
                      src={pickingProof}
                      alt={t('order.picking_proof.proof_number', {count: index + 1})}
                    />
                    <Button
                      icon
                      labelPosition="left"
                      loading={isLoading}
                      color='teal'
                      onClick={() => window.open(pickingProof)}
                    >
                        <Icon name="download"/>
                        {t('order.picking_proof.download')}
                    </Button>
                </div>
              ))}
          </div>
      </>
    )
}

export { PickingProofsGallery }
