/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {Button, Icon, Table} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import React, {useState, useContext, Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {UltyInputText, UltyMoney} from '@ulty/ulty-ui';
import useUser from '../../../Shared/UserProvider/useUser';
import { UltyModalWrapperContext } from '../../../Shared/UltyModalWrapper/UltyModalWrapperContext';
import OrderItemReplacement from './OrderItemReplacement';
import OrderItemFulfillmentActionLabel from './OrderItemFulfillmentActionLabel';
import { OrderDetailContext } from '../OrderDetailContext';
import { useOrderPickingScan } from '../OrderScanPicking';
import { ScanInfo } from '../OrderScanPicking/ScanInfo';



const OrderItemRow = ({
    displayOrderItemName = true,
    order,
    orderItem,
    onOrderItemDetailsPreview,
    isModifying,
    addOrderItemToFulfillmentIssues,
    hasFulfillmentIssues,
    fulfillmentReplacement,
    currentQuantity = undefined
}) => {
    const [t] = useTranslation();
    const {user} = useUser();
    const [quantity, setQuantity] = useState(currentQuantity ?? orderItem.quantity);
    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);
    const {startReplacement,endReplacement} = useContext(OrderDetailContext);

    const {scannedItemsMap} = useOrderPickingScan();


    if(currentQuantity !== undefined && currentQuantity !== quantity) {
        setQuantity(currentQuantity);
    }

    const defineOrderItemReplacementModal = (orderItem) => {
        startReplacement();
        const modalSettings = {
            centered: false,
            size: "large",
            onClose: () => {endReplacement();},
            component: <OrderItemReplacement
                order={order}
                orderItem={orderItem}
                afterSubmit={() => {
                    handleUltyModalWrapper(false, null);
                }}
                addOrderItemToFulfillmentIssues={(oi, newQuantity, substitution) => {
                    setQuantity(newQuantity);
                    addOrderItemToFulfillmentIssues(oi, newQuantity, substitution);
                }}
            />
        };

        handleUltyModalWrapper(true, modalSettings);
    };

    const buildModifiers = (modifiers, prefix = 0) => {
        return modifiers.map((m, idx) => {
            let Pre = () => (<Fragment/>);
            if (m.modifiers && m.modifiers.length > 0) {
                Pre = () => buildModifiers(m.modifiers, prefix + 1);
            }

            return (
                <Fragment key={idx}>
                    <div style={{paddingLeft: `${prefix * 10}px`}}>{`- ${m.name} (${m.quantity}x)`}</div>
                    <Pre/>
                </Fragment>
            )
        })
    }

    const changeOrderItemQuantity = (oi, newQuantity) => {
        setQuantity(newQuantity);

        addOrderItemToFulfillmentIssues(oi, newQuantity);
    }

    const handleQuantityChange = (e, oi) => {
        e.persist();
        changeOrderItemQuantity(oi, e.target.value);
    }

    const handleSetOrderItemQuantityToZero = (oi) => {
        changeOrderItemQuantity(oi, 0);
    }

    const OrderItemNameCell = () => {
        if (displayOrderItemName) {
            return <Table.Cell css={css`
              display: flex;
              flex-direction: column;
              font-style: italic;
              a {
                color: rgb(0, 0, 0);
              }

              a:hover {
                color: #1e70bf;
              }
            `}>
                <ScanInfo orderItem={orderItem} scanValue={scannedItemsMap.get(orderItem?.id)}/>
                <a
                    href="#"
                    onClick={onOrderItemDetailsPreview}
                    style={{textDecoration: parseInt(quantity) === 0 ? 'line-through' : 'none'}}
                >{orderItem.name}</a>
                {orderItem.modifiers && buildModifiers(orderItem.modifiers)}
            </Table.Cell>
        } else {
            return <Table.Cell />
        }
    }
    

    return (
        <>
            <Table.Row>
                <OrderItemNameCell />
                {(hasFulfillmentIssues || isModifying) && (
                    <Table.Cell>
                        {orderItem.fulfillmentIssue &&
                            t(`order.fulfillment_issue.${orderItem.fulfillmentIssue}`)
                        }

                        {orderItem.fulfillmentAction &&
                            <OrderItemFulfillmentActionLabel
                                css={css`align-self: flex-start`}
                                fulfillmentAction={orderItem.fulfillmentAction} />}
                    </Table.Cell>
                )}
                <Table.Cell
                    textAlign="right"
                    className={parseFloat(orderItem.quantity || 0) > 1 ? "multi-items" : ""}>
                    <div css={css`
                      display: flex;
                      justify-content: flex-end;
                    `}>
                        {isModifying && !orderItem.fulfillmentIssueParentId ?
                            <>
                                <Button
                                    icon
                                    disabled={quantity <= 0}
                                    onClick={() => handleQuantityChange({
                                        persist: () => {},
                                        target: {
                                            value: quantity-1
                                        }
                                    }, orderItem)}
                                >
                                    <Icon name="minus" />
                                </Button>
                                <div css={css`
                                  padding-left: 20px;
                                  padding-right: 20px;
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;

                                  div {
                                    font-weight: bold;
                                  }
                                `}>
                                    {quantity}
                                </div>
                                <Button
                                    icon
                                    disabled={orderItem.quantity <= quantity || fulfillmentReplacement}
                                    onClick={() => handleQuantityChange({
                                        persist: () => {},
                                        target: {
                                            value: quantity+1
                                        }
                                    }, orderItem)}
                                >
                                    <Icon name="plus" />
                                </Button>
                                <div css={css`flex: 1;`}/>
                                { (! orderItem.fulfillmentAction ||orderItem.fulfillmentAction === 'CANCEL_ORDER') && (<Button
                                    color="red"
                                    title={`${t('order.fulfillment_action.shopper_preference')} : ${t('order.fulfillment_action.CANCEL_ORDER')}`}
                                    icon
                                    disabled={quantity === 0}
                                    onClick={() => handleSetOrderItemQuantityToZero(orderItem)}>
                                    {t('order.deny')}
                                </Button>
                                )}   
                                { (orderItem.fulfillmentAction && orderItem.fulfillmentAction !== 'CANCEL_ORDER') && (
                                 <>
                                    <Button
                                        color="red"
                                        icon
                                        disabled={quantity === 0}
                                        onClick={() => handleSetOrderItemQuantityToZero(orderItem)}>
                                        <Icon name="trash"/>
                                    </Button>
                                    
                                    { (order.platform.supportsSubstitution && orderItem.object) && (
                                        <Button
                                            color="teal"
                                            icon
                                            disabled={quantity === 0 || !orderItem.isReplaceable}
                                            onClick={() => defineOrderItemReplacementModal(orderItem)}>
                                            <Icon name="retweet"/>
                                        </Button>
                                    )}
                                </>
                                )} 
                            </>
                            :
                            <UltyInputText
                                
                                readonly
                            >
                                {quantity}x
                            </UltyInputText>
                        }
                    </div>
                </Table.Cell>
                <Table.Cell textAlign="right">
                    <UltyMoney
                        readonly
                        amount={quantity * orderItem.unitPrice}
                        currencyCode={user.getCompanyCurrency()}
                        locale={user.getCompanyLocale()}
                    />
                </Table.Cell>
            </Table.Row>
            { fulfillmentReplacement && (
                <Table.Row>
                    <Table.Cell css={css`
                        display: flex;
                        flex-direction: column;
                        font-style: italic;
                        a {
                            color: rgb(0, 0, 0);
                        }

                        a:hover {
                            color: #1e70bf;
                        }
                        `}>
                <ScanInfo orderItem={fulfillmentReplacement} scanValue={scannedItemsMap.get(fulfillmentReplacement?.id)}/>
                        <a
                            href="#"
                            onClick={onOrderItemDetailsPreview}
                        >{fulfillmentReplacement.name}</a>
                    </Table.Cell>
                    <Table.Cell>
                        {t(`order.fulfillment_issue.replacement_product`)}
                    </Table.Cell>
                    <Table.Cell
                        textAlign="right"
                        className={parseFloat(fulfillmentReplacement.quantity || 0) > 1 ? "multi-items" : ""}>
                        <div css={css`
                          display: flex;
                          justify-content: flex-end;
                        `}>
                            <UltyInputText
                                readonly
                            >
                                {fulfillmentReplacement.quantity}x
                            </UltyInputText>
                        </div>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        <UltyMoney
                            readonly
                            amount={fulfillmentReplacement.quantity * fulfillmentReplacement.unitPrice}
                            currencyCode={user.getCompanyCurrency()}
                            locale={user.getCompanyLocale()}
                        />
                    </Table.Cell>
                </Table.Row>
            )}
        </>
    )
}

OrderItemRow.propTypes = {
    order: PropTypes.object.isRequired,
    orderItem: PropTypes.object.isRequired,
    onOrderItemDetailsPreview: PropTypes.func,
    isModifying: PropTypes.bool.isRequired,
    hasFulfillmentIssues: PropTypes.bool.isRequired,
    addOrderItemToFulfillmentIssues: PropTypes.func,
    fulfillmentReplacement: PropTypes.object
}

export default OrderItemRow;
