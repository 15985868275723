import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'user';

export const checkUser = async () => {
  try {
      return await call(METHODS.GET, ROUTE_PREFIX, 'check');
  } catch (e) {
      console.error(`checkUser error: ${e}`);
      throw e;
  }
}

export const saveUser = async (user) => {
    try {
        if (!user?.id) {
            return await call(METHODS.POST, ROUTE_PREFIX, '', user);
        } else {
            return await call(METHODS.PUT, ROUTE_PREFIX, user.id, user);
        }
    } catch (e) {
        console.error(`saveUser error: ${e.message}`);
        throw e;
    }
}

export const createUser = async (user) => {
  try {
      return await call(METHODS.POST, ROUTE_PREFIX, '', user);
  } catch (e) {
      console.error(`createUser error: ${e}`);
      throw e;
  }
}

export const updateUser = async (id, user) => {
  try {
      return await call(METHODS.PUT, ROUTE_PREFIX, `${id}`, user);
  } catch (e) {
      console.error(`updateUser error: ${e}`);
      throw e;
  }
}

export const updateSelf = async (user) => {
    try {
        return await call(METHODS.PUT, ROUTE_PREFIX, `self`, user);
    } catch (e) {
        console.error(`updateSelf error: ${e}`);
        throw e;
    }
  }
