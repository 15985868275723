import { useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { UltyModalContext } from './UltyModalContext'
import {Button, Header, Icon, Modal} from "semantic-ui-react";

const UltyModal = () => {
    const { isOpen, handleUltyModal, modalSettings } = useContext(UltyModalContext);
    const [isLoading, setIsLoading] = useState(false);

    return createPortal(
        <Modal
            basic
            open={isOpen}
        >
            <Header icon>
                <Icon name='times' />
                {modalSettings && modalSettings.title}
            </Header>
            <Modal.Content>
                {modalSettings && modalSettings.content}
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='red'
                        inverted
                        onClick={() => handleUltyModal(false)}
                        loading={isLoading}
                        disabled={isLoading}
                >
                    <Icon name='remove' /> Annuler
                </Button>
                <Button color='red'
                        inverted
                        onClick={async () => {
                            setIsLoading(true);
                             modalSettings && await modalSettings.onValidate();
                            setIsLoading(false);
                        }}
                        loading={isLoading}
                        disabled={isLoading}
                >
                    <Icon name='checkmark' /> Supprimer
                </Button>
            </Modal.Actions>
        </Modal>,
        document.querySelector('#ulty-modal-root')
    );
}

export default UltyModal;
