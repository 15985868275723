import { call, METHODS } from '../http-client';

const ROUTE_PREFIX = 'features';

export const getFeatures = async () => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX,null,null,{'axios-retry': {
            retries: 0
          }});
    } catch (e) {
        throw e;
    }
}