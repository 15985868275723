/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {Checkbox, Dropdown, Form, Grid, Header, Icon, Input, Segment, TextArea} from 'semantic-ui-react';
import Uploader from '../../Uploader';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';
import produce from 'immer';
import {useTranslation} from 'react-i18next';
import useUser from '../../Shared/UserProvider/useUser';
import {Link} from 'react-router-dom';
import {UltyInputText, UltyMoney} from '@ulty/ulty-ui'
import ItemAvailabilitySetter from '../../Items/ItemAvailabilitySetter';
import InventoryEdit from '../../Items/Inventory/InventoryEdit';
import {getCountryTaxRates} from '../../../services/Country/Country.service';
import { isProviderAPos } from '../../../services/Provider/Provider.service';

const debounceDelay = 400;

const initialState = {
    id: '',
    type: 'MENU',
    providerIds: [],
    basePrice: 0,
    taxRate: 20,
    maxOrderableQuantity: '',
    image: null,
    containsAlcohol: false,
    isUncountable: false,
    externalId: '',
    inventory: {
        externalId: '',
        sellPrice: null,
        unavailableUntil: null
    },
    object: {
        name: '',
        description: '',
    }
};

const menuSummaryReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SUMMARY':
            return produce(state, draft => {
                return {
                    ...draft,
                    id: action.payload.id,
                    type: action.payload.type,
                    providerIds: action.payload.providerIds,
                    basePrice: action.payload.basePrice,
                    taxRate: action.payload.taxRate,
                    maxOrderableQuantity: action.payload.maxOrderableQuantity,
                    image: action.payload.image,
                    containsAlcohol: action.payload.containsAlcohol,
                    externalId: action.payload.externalId,
                    inventory: {
                        externalId: action.payload.inventory?.externalId || '',
                        sellPrice: action.payload.inventory?.sellPrice || null,
                        unavailableUntil: action.payload.inventory?.unavailableUntil || null,
                    },
                    object: action.payload.object
                }
            });
        case 'SET_ITEM_FIELD':
            return produce(state, draft => {
                draft[action.payload.field] = action.payload.value;
            });
        case 'SET_OBJECT_FIELD':
            return produce(state, draft => {
                draft.object[action.payload.field] = action.payload.value;
            });
        case 'SET_INVENTORY_FIELD':
            return produce(state, draft => {
                draft.inventory[action.payload.field] = action.payload.value;
            });
        default:
            throw Error(`Action of menuSummaryReducer: '${action.type}' not found`);
    }
};

const MenuSummary = ({ id, type, object, basePrice, taxRate, maxOrderableQuantity, image, containsAlcohol, externalId, providerIds, inventory, canEditItem, onFieldChange }) => {
    const [t] = useTranslation();
    const {user} = useUser();
    const [taxRates, setTaxRates] = useState([]);
    const [state, dispatch] = useReducer(menuSummaryReducer, initialState);

    useEffect(() => {
        (async function getTaxRates() {
            setTaxRates(await getCountryTaxRates(user.getUserCompany().country.id));
        })();
    }, [user]);

    useEffect(() => {
        dispatch({ type: 'SET_SUMMARY', payload: {
                id,
                type,
                providerIds,
                basePrice,
                taxRate,
                maxOrderableQuantity,
                image,
                containsAlcohol,
                externalId,
                inventory,
                object
            }});
    }, [id, object, basePrice, image, containsAlcohol, externalId, taxRate,maxOrderableQuantity, providerIds, inventory, type]);

    const debounceFieldChange = useMemo(() => (
        debounce((actionType, payload) => onFieldChange(actionType, payload), debounceDelay)
    ), [onFieldChange]);

    const handleFieldChange = (actionType, {name, value}) => {
        dispatch({ type: actionType, payload: { field: name, value } });
        debounceFieldChange(actionType, { field: name, value });
    }

    return (
        <Segment>
            <Header as='h3'>{t('menu_edit.menu')}</Header>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column tablet={8} mobile={16} computer={8} css={css`
                      display: flex !important;
                      flex-direction: column !important;
                      align-items: center !important;
                    `}>
                        <Form.Field css={css` width: 100%; `} name="image">
                            <Uploader
                                disabled={!canEditItem}
                                initial={state.image?.url || ""}
                                withConversion={true}
                                onSrcChange={(url) => {
                                    const image = url ? {url, isDefault: true} : null;
                                    handleFieldChange('SET_ITEM_FIELD', { name: 'image', value: image })
                                }}
                            />
                        </Form.Field>
                        {!!state.id && canEditItem &&
                        <Grid.Row>
                            <Grid.Column />
                            <Grid.Column textAlign='right'>
                                <Link to={`/items/${state.id}/platforms`}><Icon name='settings' />{t('item_edit.manageImages')}</Link>
                            </Grid.Column>
                        </Grid.Row>}
                    </Grid.Column>
                    <Grid.Column tablet={8} mobile={16} computer={8}>
                        <Form.Field
                            control={Input}
                            onChange={(e, {name, value}) => handleFieldChange('SET_OBJECT_FIELD', { name, value })}
                            value={state.object.name}
                            disabled={!canEditItem}
                            name="name"
                            label={t('menu_edit.name')}
                            placeholder={t('menu_edit.name')}
                        />
                        <UltyInputText
                            name="externalId"
                            readonly={!canEditItem}
                            value={state.externalId}
                            onChange={({target}) => handleFieldChange('SET_ITEM_FIELD',{name: target.name, value: target.value })}
                            label={t('item_edit.externalId')}
                            placeholder={t('item_edit.externalId')}
                        />
                        <Form.Group widths={2}>
                            <UltyMoney
                                readonly={!canEditItem}
                                name="basePrice"
                                label={t('item_edit.basePrice')}
                                placeholder={t('item_edit.basePrice')}
                                amount={state.basePrice}
                                onMoneyAmountChange={(e, amt) => handleFieldChange('SET_ITEM_FIELD', {name: e.target.name, value: amt})}
                                currencyCode={user.getCompanyCurrency()}
                                locale={user.getCompanyLocale()}
                            />

                            <Form.Field>
                                <label>{t('item_edit.taxRate')}</label>
                                <Dropdown
                                    fluid
                                    selection
                                    value={state.taxRate}
                                    name="taxRate"
                                    disabled={!canEditItem}
                                    onChange={(e, {name, value}) => handleFieldChange('SET_ITEM_FIELD', { name, value })}
                                    options={taxRates.map(taxRate => ({
                                        text: `${taxRate}%`,
                                        value: taxRate,
                                        key: taxRate
                                    }))}
                                />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group  widths={2}>
                            <UltyInputText
                                type="number"
                                name="maxOrderableQuantity"
                                min={0}
                                value={state.maxOrderableQuantity}
                                onChange={(e) => handleFieldChange('SET_ITEM_FIELD', { name: e.target.name, value: e.target.value })}
                                label={t('item_edit.maxOrderableQuantity')}
                            />
                        </Form.Group>

                        <Form.Field>
                            <label>&nbsp;</label>
                            <Checkbox label={t('item_edit.contains_alcohol')}
                                      checked={state.containsAlcohol}
                                      onChange={(_e, {name, checked}) => handleFieldChange('SET_ITEM_FIELD',{name, value: checked })}
                                      name="containsAlcohol"
                                      disabled={!canEditItem}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Form.Field
                            control={TextArea}
                            onChange={(e, {name, value}) => handleFieldChange('SET_OBJECT_FIELD', { name, value })}
                            value={state.object.description}
                            disabled={!canEditItem}
                            name="description"
                            label={t('menu_edit.description')}
                            placeholder={t('menu_edit.description')}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={16}>
                        {isProviderAPos(user.provider) &&
                            <>
                                <InventoryEdit
                                    type={state.type}
                                    inventory={state.inventory}
                                    isUncountable={false}
                                    isLoading={false}
                                    onChange={({name, value}) => handleFieldChange('SET_INVENTORY_FIELD', { name, value })}
                                />

                                {!!id &&
                                    <ItemAvailabilitySetter
                                        css={css` margin-top: 1rem; `}
                                        itemId={id}
                                        onChange={(unavailableUntil) => handleFieldChange('SET_INVENTORY_FIELD', { name: "unavailableUntil", value: unavailableUntil })}
                                    />}
                            </>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

MenuSummary.propTypes = {
    object: PropTypes.object,
    inventory: PropTypes.object,
    basePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    taxRate: PropTypes.number,
    onFieldChange: PropTypes.func.isRequired,
}

export default MenuSummary;
