/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {Card, Icon, Image, Label, } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ProductCardContentExtra from '../../../Products/ItemsList/ProductCardContentExtra';
import MenuCardContentExtra from '../../../Menu/MenuCardContentExtra';
import { useWebPSupportCheck } from "react-use-webp-support-check";

const squareImg = '/img/image.png'

const Item = ({ item, onDeleteItem, canDelete = true }) => {
    const supportsWebP = useWebPSupportCheck();

    const handleDelete = () => {
        onDeleteItem(item);
    }

    const getItemImage = (item) => {
        if (!item.image) {
            return squareImg;
        }

        if (item.image.indexOf('images.ulty.fr') === -1) {
            return item.image;
        }

        const path = item.image.replace(/https?:\/\/images.ulty.fr\//, '');
        if (supportsWebP) {
            return `https://thumbnails.ulty.fr/200x200/webp/${path}`;
        }
        return `https://thumbnails.ulty.fr/200x200/png/${path}`;
    }

    return (
    <Card css={css`
        padding: 0 !important;
        `}>
            <Card.Content>
                {canDelete && (
                  <Label as='a' color='red' corner={'right'} onClick={handleDelete}>
                      <Icon name={'delete'} css={css`cursor: pointer !important;`} />
                  </Label>
                )}
                <Image
                  css={css`
                    max-height: 80px;
                    height: 80px !important;
                    object-fit: contain;
                    width: auto;
                    max-width: 80px;
                    user-select: none;
                    -moz-user-select: none;
                    -webkit-user-drag: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                `}
                  floated='right'
                  size='tiny'
                  alt={item.object.name}
                  loading="lazy"
                  src={getItemImage(item)}
                />
                <div css={css`
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            `}/>

                <Card.Header css={css`font-size: 1em !important;`}>{item.object.name}</Card.Header>
            </Card.Content>

            <Card.Content
              extra
            >
                {item.type === 'PRODUCT' &&
                  <ProductCardContentExtra
                    basePrice={item.basePrice}
                    inventory={item.inventory}
                    linkTo={`/products/${item.id}`}
                  />
                }

            {item.type === 'MENU' &&
            <MenuCardContentExtra
                basePrice={item.basePrice}
                inventory={item.inventory}
                linkTo={`/menus/${item.id}`}
            />
            }
        </Card.Content>
    </Card>
    )
}

Item.propTypes = {
    item: PropTypes.object.isRequired,
    canDelete: PropTypes.bool.isRequired,
    onDeleteItem: PropTypes.func
}

export default Item;
