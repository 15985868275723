import moment from 'moment';

export const getTimePeriodsOfADayFromAvailabilities = (day, availabilities) => {
    return availabilities
        .find(availability => availability.day_of_week === day.value)?.time_periods
        .map(timePeriod => {
            const [startHours, startMinutes] = timePeriod.start_time.split(':');
            const startDateTime = moment().set('hour', +startHours).set('minute', +startMinutes);

            const [endHours, endMinutes] = timePeriod.end_time.split(':');
            const endDateTime = moment().set('hour', +endHours).set('minute', +endMinutes);

            return {
                ...timePeriod,
                maxStartTime: moment(endDateTime).subtract(60, 'minutes'),
                minEndTime: moment(startDateTime).add(60, 'minutes'),
            }
        }) || []
}
