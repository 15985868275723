/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { differenceInMilliseconds } from 'date-fns'

const timerStyles = css`
  line-height: 5rem;
  font-size: 5rem;
`;

const halfTimeStyles = css`
  color: orange;
`;

const nearEndStyles = css`
  color: red;
  font-weight: bolder;
  animation: blink 1s linear infinite;

  @keyframes blink {
    50% {
      opacity: 0;
    }
`;

const padWithZero = (timeLeft) => {
    Object.keys(timeLeft).forEach(key => {
        timeLeft[key] = `${timeLeft[key]}`.padStart(2, '0');
    });
}

const formattedTimeLeft = (timeLeft) => {
    const formatted = {
        minutes: Math.floor((timeLeft / 1000 / 60) % 60),
        seconds: Math.floor((timeLeft / 1000) % 60),
    }

    padWithZero(formatted)
    return formatted
}

const calculateTimeLeft = (endDate) => {
    const now = new Date()
    const timeLeft = differenceInMilliseconds(endDate, now)
    return timeLeft >= 0 ? timeLeft : 0
}

const Timer = ({endDate, onTimerEnd}) => {
    const calculatedTimeLeft = calculateTimeLeft(endDate)
    const [timeLeft, setTimeLeft] = useState(calculatedTimeLeft)
    const [initialTimeLeft] = useState(calculatedTimeLeft)

    useEffect(() => {
        const tick = 1000;
        const interval = setInterval(() => {
            const updatedTimeLeft = calculateTimeLeft(endDate)
            if (updatedTimeLeft > 0) {
                setTimeLeft(updatedTimeLeft)
            }

            if (updatedTimeLeft === 0) {
                clearInterval(interval)
                onTimerEnd()
            }
        }, tick)

        return () => clearInterval(interval);
    }, [endDate, onTimerEnd, timeLeft]);

    function getTimerStyles() {
        const halfTime = initialTimeLeft / 2;
        const twoMinutes = 120000;

        if (timeLeft <= twoMinutes) {
            return nearEndStyles;
        }

        if (timeLeft <= halfTime) {
            return halfTimeStyles;
        }

        return css``;
    }

    return (
        <div
            css={css`
              ${timerStyles}
              ${getTimerStyles()}
            `}>
            {formattedTimeLeft(timeLeft).minutes}:{formattedTimeLeft(timeLeft).seconds}
        </div>
    )
}

Timer.propTypes = {
    endDate: PropTypes.instanceOf(Date),
    onTimerEnd: PropTypes.func,
}

export default Timer;
