import {getRoles} from '../../services/Role/role.service';
import {Dropdown} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query'
import { TIME } from '../../services/date.service'

const RoleSelector = ({selectedRole, onRoleChange}) => {
    const [t] = useTranslation();
    const { data: roles, isLoading } = useQuery({
        queryFn: getRoles,
        queryKey: ['roles'],
        staleTime: TIME.HOUR_IN_MS,
    })

    const getRolesOptions = () => {
        return (roles || []).map(role => ({
            text: t(`role.${role.name}`),
            value: role.name,
            key: role.id
        }))
    }

    const handleSelectedRoleChange = (value) => {
        onRoleChange(roles.find(role => role.name === value));
    }

    return (
        <Dropdown
            clearable
            fluid
            search
            selection
            loading={isLoading}
            disabled={isLoading}
            value={selectedRole?.name || null}
            onChange={(e, { value }) => handleSelectedRoleChange(value)}
            options={getRolesOptions()}
        />
    )
}

RoleSelector.propTypes = {
    selectedRole: PropTypes.object,
    onRoleChange: PropTypes.func
}

export default RoleSelector;
