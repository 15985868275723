/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import {
  Button, Message
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { notify } from 'react-notify-toast';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';

import OrderStore from './OrderStore';
import { useState } from 'react';
import { setMerchantStatus } from '../../services/PointOfSale/PointOfSale.service';
import { useTranslation } from 'react-i18next'
import { MOBILE_THRESHOLD } from '../../constants';

const CloseNow = ({ readOnly = false }) => {
  const [t] = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

  const orderStore = OrderStore.useContainer();

  const [loading, setLoading] = useState(false);

  const handleClose = async () => {
    setLoading(true)

    try {
      await setMerchantStatus(!orderStore.opened);
      orderStore.setOpened(!orderStore.opened);
    } catch(e) {
      notify.show(t('global.anErrorOccurred'), 'error');
    }

    setLoading(false);
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: ${isMobile ? 'column' : 'row'};
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;
    `}>
      <div css={css`
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: ${isMobile ? '10px' : 0};
      `}>
        {!orderStore.opened && (
          <Message color="yellow" compact>
            <strong>{t('navbar.busy', {
              minutes: orderStore.busyPrepTime
            })}</strong>
          </Message>
        )}
      </div>
      { !readOnly && (
        <div css={css`
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
        `}>
          <Button size="tiny" fluid={isMobile} color={orderStore.opened ? "orange" : "teal"} onClick={handleClose} disabled={loading} loading={loading}>
            {orderStore.opened ? t('order.busy') : t('order.busy_end')}
          </Button>
        </div>
      )}
    </div>
  )
}

CloseNow.propTypes = {
  readOnly: PropTypes.bool,
};

export default CloseNow;
