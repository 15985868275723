/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

export const pulsing = css`


  .pulse.ok {
    --c1: rgba(0,255,0,0.2);
    --c2: rgba(0,255,0,0);
  }

  
  .pulse.ko {
    --c1: rgba(255,0,0,0.4);
    --c2: rgba(255,0,0,0.1);
  }

  .pulse {
    position:relative;
    --c1:rgba(0,255,0,0.6);
    --c2:rgba(0,255,0,0.2);
    --blur: 5px;
    --spread: 2px;

    animation: pulse-animation 2s ease-in-out infinite alternate-reverse both;
  }

  
  @keyframes pulse-animation {
    from
    0% {
      box-shadow: 0 0 var(--blur) var(--spread) var(--c1);
    }
    100% {
      box-shadow: 0 0 var(--blur) var(--spread) var(--c2);
    }
  }
`