import React from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'

import MenuEdit from './MenuEdit/MenuEdit';

const Menus = () => {
    return (
        <Switch>
            <Route path="/menus/edit/:id" component={MenuEdit} />
            <Route path="/menus/edit" component={MenuEdit} />
            <Route path="/menus" exact component={() => (<Redirect to="/products" />)} />
        </Switch>
    )
}

export default Menus
