/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { UltyDropdown, UltyInputText } from '@ulty/ulty-ui'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { notify } from 'react-notify-toast'
import { Button, Checkbox, Form, Header, Segment, Select } from 'semantic-ui-react'
import { saveMarkupSetup } from '../../../services/Category/Category.service'

const centered = css`
display: flex;
align-items: center;
justify-content: center;
`;

const weekdays = ['monday','tuesday','wednesday','thursday','friday'];
const weekend = ['saturday','sunday'];

const hours_list = [
    '00:00',    '01:00',    '02:00',    '03:00',    '04:00',    '05:00',    '06:00',    '07:00',    '08:00',    '09:00',
    '10:00',    '11:00',    '12:00',    '13:00',    '14:00',    '15:00',    '16:00',    '17:00',    '18:00',    '19:00',
    '20:00',    '21:00',    '22:00',    '23:00',    '23:59'
];

const HourSelector = ({currentFrom,currentTo,onUpdate,canDelete = false,minValue=undefined}) => {

    const [t] = useTranslation();

    const setHour = useCallback(({start_time=undefined,end_time=undefined}) => {
        if(start_time){
            const invalidTo = !currentTo || start_time >= currentTo;
            if(invalidTo){
                const firstValid = available_hours(start_time)[0];
                onUpdate({start_time,end_time:firstValid});
            } else {
                onUpdate({start_time,end_time:currentTo});
            }
        }
        else{
            onUpdate({start_time:currentFrom,end_time});
        }
    },[currentFrom,currentTo]);

    return (
        <Form.Group css={css` font-size: smaller`}>
                   
                <Form.Field control={Select} size="tiny" compact  value={currentFrom} options={listToDropdown(available_hours(minValue))} onChange={(_e, {value}) =>{
                        setHour({start_time:value});
                    } }
                    />
                    <span css={centered}>{t('hours_display.to')}</span>
                    <Form.Field control={Select} compact value={currentTo} options={listToDropdown(available_hours(currentFrom))} onChange={(_e, {value}) =>{
                        setHour({end_time:value});
                    }}>
                    </Form.Field>
                    <Form.Field width="1"  css={centered} >
                        {canDelete && (<Button onClick={() => onUpdate(null)} basic floated='left'  size='tiny' inverted negative compact icon="delete"/>)}
                    </Form.Field>
            </Form.Group>
    )
}

const listToDropdown  = (list) => list.map(v => ({key:v,text:v,value:v}));

const available_hours = (minHour=null) => {
    return minHour ? hours_list.filter(h => h > minHour) : hours_list;
};

const marginLeft = css`
    margin-left:0.5em
`
const AddAnotherSlot = ({slotIndex,currentHours,onAdd}) => {
    const [t] = useTranslation();
    const MAX_NUMBER_OF_SLOT = 4;
    const alreadyMaxNumberOfSlot = slotIndex + 1 + 1 > MAX_NUMBER_OF_SLOT;
    const notLastSlot = (slotIndex+1 !== currentHours.length);
    const noMoreSlotLeft = currentHours.length > 0 && available_hours(currentHours[currentHours.length -1].end_time).length <= 1

    if( ( alreadyMaxNumberOfSlot|| notLastSlot || noMoreSlotLeft)) return <></>

    return <span css={css`
    cursor: pointer;
    color: #4183c4;
`}  onClick={()=> onAdd()}>{t('administration.hours.add_slot')}</span>
}

const MarkupSetupAdd = ({ categorySetLink,categorySet, onAdd }) => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [categorySlots, setCategorySlots] = useState(categorySet?.slots)
    const [selectedCategorySlot, setSelectedCategorySlot] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedDays, setSelectedDays] = useState([])
    const [markup, setMarkup] = useState('')
    
    const [ displayHours, setDisplayHours] = useState(false)
    const [ hours, setHours] = useState(null)


    const DAYS = useMemo(() => [
        { value: 'monday', text: t('days.monday') },
        { value: 'tuesday', text: t('days.tuesday') },
        { value: 'wednesday', text: t('days.wednesday') },
        { value: 'thursday', text: t('days.thursday') },
        { value: 'friday', text: t('days.friday') },
        { value: 'saturday', text: t('days.saturday') },
        { value: 'sunday', text: t('days.sunday') },
    ],[t]);

   
    useEffect(() => {
    
        if(!categorySet) return;

        setIsLoading(true)
        setCategorySlots(categorySet.slots)
        if(categorySet.slots?.length == 1){
            setSelectedCategorySlot(categorySet.slots[0])
        }
        setIsLoading(false)
    }, [categorySet]);

    const displayCategorySlot = useMemo(() => {
        return categorySlots?.length > 1;
    }, [categorySlots])
    
    const categories = useMemo(() => {
        if (!selectedCategorySlot) return [];

        return categorySlots.find(s => s.id === selectedCategorySlot.id)?.categories ?? [];
    },[categorySetLink, selectedCategorySlot])


    const selectDays = useCallback((days) => {
        setSelectedDays(d => {
            const daysToAdd = days.filter(day => !d.includes(day));
            return [
                ...d,
                ...daysToAdd,
            ];
        })
    }, [setSelectedDays]);

    const unselectDays = useCallback((days) => {
        setSelectedDays(d => {
           return d.filter(day => !days.includes(day))
        })
    }, [setSelectedDays])

    
    const toggleDay = useCallback(day => {
        if( selectedDays.includes(day)) unselectDays([day]);
        else {
            selectDays([day])
        }
    },[selectedDays,selectDays,unselectDays]);

    const isWeekEndSelected = useMemo(() => {
        return weekend.every(d => selectedDays.includes(d));
    },[selectedDays])

    const isWeekSelected = useMemo(() => {
        return weekdays.every(d => selectedDays.includes(d));
    },[selectedDays])

    const handleSubmit = async () => {
        try {
            notify.hide()
            setIsLoading(true)
            await saveMarkupSetup({
                categorySetLinkId: categorySetLink.id,
                categorySlotId: selectedCategorySlot?.id || null,
                categoryId: selectedCategory?.id || null,
                daysOfWeek: selectedDays,
                markup,
                hours
            })

            setIsLoading(false)
            if(displayCategorySlot){
                setSelectedCategorySlot(null)
            }
            setSelectedCategory(null)
            setSelectedDays([])
            setMarkup('')

            setIsLoading(false)

            onAdd()

            notify.show(t('global.registerSuccess'), 'success');
        } catch (e) {
            if (e.message === 'markup_setup_already_exists') {
                notify.show(t('markup.markup_setup_already_exists'), 'error');
            } else {
                notify.show(t('global.anErrorOccurred'), 'error');
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(!hours && displayHours){
            setDisplayHours(false);
        }
    },[hours])

    return (
        <Form>
            <Header>{t('markup.add_markup')}</Header>
            <Segment>

                <Form.Group widths="equal">
                  
                    {displayCategorySlot && (<Form.Field>
                        <UltyDropdown
                            label={t('category_slot.category_slot')}
                            loading={isLoading}
                            placeholder={t('markup.add.all_slots')}
                            search
                            multiple={false}
                            clearable
                            options={categorySlots.map(categorySlot => ({
                                key: categorySlot.id,
                                value: categorySlot.id,
                                text: categorySlot.name,
                            }))}
                            value={selectedCategorySlot?.id || ''}
                            onChange={(_e, dp) => {
                                setSelectedCategorySlot(categorySlots.find(categorySlot => categorySlot.id === dp.value))
                            }}
                        />
                    </Form.Field>)}
                    <Form.Field>
                        <UltyDropdown
                            label={t('category.category')}
                            loading={isLoading}
                            multiple={false}
                            placeholder={t('markup.add.all_categories')}
                            search
                            clearable
                            options={categories.map(category => ({
                                key: category.id,
                                value: category.id,
                                text: category.name,
                            }))}
                            value={selectedCategory?.id || ''}
                            onChange={(_e, dp) => {
                                setSelectedCategory(categories.find(category => category.id === dp.value))
                            }}
                        />
                    </Form.Field>
                    <Form.Field>

                    <UltyInputText
                        type="number"
                        name="markup"
                        value={markup}
                        min="-99"
                        max="99"
                        onChange={e => setMarkup(e.target.value)}
                        label={t('markup.markup')}
                        placeholder='%'
                    />

                    </Form.Field>
                </Form.Group>

                <div css={css`
                  display: flex;
                  column-gap: 1rem;
                `}>
                    
                </div>

                    
                <Form.Field>
                    <label>{t('days.days_of_week')}</label>
                    <Form.Group vertical="false" grouped css={css`
                
                    margin-top:0.7em!important

                    .mx {
                        margin-left: 15px
                    }
                `}>
                        <Checkbox className='mx' label={t("markup.add.weekdays")} checked={isWeekSelected} onChange={(_event, {checked}) => {
                            if(checked) selectDays(weekdays);
                            else unselectDays(weekdays)
                        }}></Checkbox>
                        <Checkbox className='mx' css={marginLeft} label={t("markup.add.weekend")}  checked={isWeekEndSelected}  onChange={(_event, {checked}) => {
                            if(checked) selectDays(weekend);
                            else unselectDays(weekend)
                        }}>  </Checkbox>
                    </Form.Group>
                    {DAYS.map(day => (
                    <Button
                    size="medium"
                    compact
                        key={day.value}
                        css={css`margin-right: 1.5rem`}
                        primary={selectedDays.includes(day.value)}
                        onClick={(_e) => {
                         toggleDay(day.value)
                        }}
                    >{t(day.text)}</Button>
                ))}
                        
                
                </Form.Field>
                
                <Form.Field>
                <Form.Field>
                            <Checkbox
                                toggle
                                checked={!!displayHours}
                                label={t('markup.add.select_hours')}
                                onChange={(evt, {checked}) => {
                                    setDisplayHours(checked);
                                    setHours(checked ? [{start_time:'00:00',end_time:'23:59'}] : null )
                                }}
                            />
                        </Form.Field>
                </Form.Field>
                {displayHours && (hours || []).map(({start_time,end_time},index) => {
                    const previousVal = index === 0 ? null : hours[index - 1];

                    return (
                    <>
                    <HourSelector key={`${index}-${start_time}-${end_time}`} 
                    canDelete={index !== 0}
                    minValue={previousVal?.end_time}
                    currentFrom={start_time} currentTo={end_time} onUpdate={((val) => {
                        setHours(hours => {
                            if(!val) {
                                hours.splice(index,1)
                            } 
                            else {
                                const {start_time,end_time} = val;
                                hours[index] = {start_time,end_time};
                            }
                            return [...hours]

                        })
                    })}/>
                    <AddAnotherSlot currentHours={hours} slotIndex={index} onAdd={() => setHours(h => {
                        const lastValue = h[h.length -1];
                        const start_time = available_hours(lastValue?.end_time)[0]; 
                        const end_time = available_hours(start_time)[0]; 
                        return [
                            ...h,
                            {start_time,end_time}
                        ]
                    })}/>
                    </>
                )})}


                <div style={{ textAlign: 'right' }}>
                    <Button color="teal" type="submit" loading={isLoading} disabled={isLoading || markup === ''} onClick={handleSubmit}>{t('global.add')}</Button>
                </div>
            </Segment>
        </Form>
    )
}

export default MarkupSetupAdd
