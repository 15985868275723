import {useState} from 'react';

const useUltyModalWrapper =  () => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalSettings, setModalSettings] = useState(null);

    const handleUltyModalWrapper = (isNowOpen, nowSettings) => {
        const wasOpen = isOpen;
        const previousCloseCallback = modalSettings?.onClose;
        setIsOpen(isNowOpen);
        setModalSettings(nowSettings);
        if(wasOpen) {
            previousCloseCallback?.();
        }
    };

    return { isOpen, handleUltyModalWrapper, modalSettings };
};

export default useUltyModalWrapper;