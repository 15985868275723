import React, { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { UltyModalWrapperContext } from './UltyModalWrapperContext';
import { Modal } from 'semantic-ui-react';


const UltyModalWrapper = () => {
    const { isOpen, handleUltyModalWrapper, modalSettings} = useContext(UltyModalWrapperContext);

    const handleModalCloseRef = useRef(modalSettings?.onClose);

    useEffect(() => {
        handleModalCloseRef.current = modalSettings?.onClose;
    }, [modalSettings?.onClose]);


    useEffect(() => {
        try {
            window.Android && window.Android.isModalShowing(isOpen ? "true" : "false");
        } catch(e) {}
    }, [isOpen]);

    return createPortal (
         <Modal
             header={modalSettings?.title || modalSettings?.header}
             content= {modalSettings?.component && <div className="content">{modalSettings?.component}</div>}
             {...modalSettings}
             open={isOpen}
             onClose={() => {
                handleUltyModalWrapper(false, null);
             }}
             closeIcon
             centered={typeof modalSettings?.centered !== 'undefined' ? modalSettings.centered : true}
             size={(modalSettings && modalSettings.size) || null}
         />
          ,
        document.querySelector('#ulty-modal-wrapper-root')
    );
};

export default UltyModalWrapper;
