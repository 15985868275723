import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid } from 'semantic-ui-react';
import {notify} from 'react-notify-toast';
import {getItemElements, saveProductElements} from '../../../services/Product/Product.service';
import { useTranslation } from 'react-i18next';
import ItemElements from '../../Items/ItemElements/ItemElements';
import itemElementsReducer from '../../Items/ItemElements/ItemElementsReducer';


const initialState = {
  itemElements: [],
};

const ProductDetailsModifiers = ({ product, canEdit }) => {
  const [t] = useTranslation();

  const [loading, setIsLoading] = useState(false);
  const [state, dispatch] = useReducer(itemElementsReducer, initialState);

  useEffect(() => {
    loadProductElements(product.id);
  }, [product]);

  const loadProductElements = async (itemId) => {
    setIsLoading(true);
    const productElements = await getItemElements(itemId);
    dispatch({ type: 'SET_ITEM_ELEMENTS', payload: productElements });
    setIsLoading(false);
  };

  const handleSaveItemElements = async () => {
    setIsLoading(true);
    try {
      await saveProductElements(product.id, {
        itemElements: state.itemElements.map((item, idx) => ({
          ...item,
          position: idx,
          items: item.items.map((item, idx) => ({
            ...item,
            object: {
              ...item.object,
              position: idx
            }
          }))
        }))
      });
      notify.show(t('global.registerSuccess'), 'success');
    } catch {
      notify.show(t('global.anErrorOccurred'), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Form
      loading={loading}
    >
        <ItemElements
            itemTypeContext="PRODUCT"
            itemElements={state.itemElements}
            onChangeItemElementField={canEdit ? (itemElementId, field, value) => dispatch({ type: 'SET_ITEM_ELEMENT_FIELD', payload: { itemElementId, field, value } }) : null}
            onChangeItemElementItems={canEdit ? (itemElementId, items) => dispatch({ type: 'SET_ITEM_ELEMENT_ITEMS', payload: { itemElementId, items } }) : null}
            onChangeItemElementPosition={(itemElementId, value) => dispatch({ type: 'SET_ITEM_ELEMENT_POSITION', payload: { itemElementId, to: value } })}
            onAdd={canEdit ? (itemElement) => dispatch({ type: 'ADD_ITEM_ELEMENT', payload: {
                id: `new-${state.itemElements.length + 1}`, ...itemElement
              }}) : null}
            onDelete={canEdit ? (itemElementId) => dispatch({ type: 'REMOVE_ITEM_ELEMENT', payload: itemElementId }) : null}
        />

      { canEdit && (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Button
                type="submit"
                color="teal"
                onClick={handleSaveItemElements}
                loading={loading}
                disabled={loading}
              >
                {t('global.save')}
              </Button>
            </div>

          </Grid.Column>
        </Grid.Row>
      </Grid>
      )}
    </Form>
  );
}

ProductDetailsModifiers.propTypes = {
  product: PropTypes.object.isRequired,
  canEdit: PropTypes.bool
}

export default ProductDetailsModifiers;
