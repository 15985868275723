import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'billing';

export const getBillingInfos = async (providerId) => {
    return await call(METHODS.GET, ROUTE_PREFIX, providerId);
}

export const setupIntentForBilling = async () => {
    return await call(METHODS.PUT, ROUTE_PREFIX, `setup`, {});
}

export const validateSetupIntentForBilling = async (intent) => {
    return await call(METHODS.PUT, ROUTE_PREFIX, `validate`, intent);
}
