import { call, METHODS } from '../http-client';

const ROUTE_PREFIX = 'menu';

export const canSaveMenu = (menu) => {
    return (!!menu.object.name && !!menu.basePrice);
}

export const deleteMenu = async (menuId) => {
    try {
        return await call(METHODS.DELETE, ROUTE_PREFIX, `${menuId}`,);
    } catch (e) {
        console.error(`deleteMenu error: ${e.message}`);
        throw e;
    }
}

export const saveMenu = async (menu) => {
    try {
        if (menu.id === 0) {
            return await call(METHODS.POST, ROUTE_PREFIX, '', menu);
        } else {
            return await call(METHODS.PUT, ROUTE_PREFIX, '', menu);
        }
    } catch (e) {
        console.error(`saveMenu error: ${e.message}`);
        throw e;
    }
}

export const getMenus = async () => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, '');
    } catch (e) {
        console.error(`getMenus error: ${e.message}`);
        throw e;
    }
}

export const getMenu = async (menuId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${menuId}`);
    } catch (e) {
        console.error(`getMenu error: ${e.message}`);
        throw e;
    }
}
