import { useState } from 'react';

export default () => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalSettings, setModalSettings] = useState(null);

    const handleUltyModal = (isOpen, settings) => {
        setIsOpen(isOpen);
        setModalSettings(settings);
    };

    return { isOpen, handleUltyModal, modalSettings };
};
