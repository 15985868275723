/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React, {useMemo, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, Form, Grid, Input, Table, TextArea, Header, Ref, Dropdown} from 'semantic-ui-react';
import ItemElementItem from './ItemElementItem';
import { debounce } from 'lodash';
import ItemSelector from './ItemSelector';
import { ReactSortable } from "react-sortablejs";
import { useTranslation } from 'react-i18next';

const debounceDelay = 500;

const ItemElement = ({ itemElement, itemElementItemTypes, position, maxPosition, onChangeItemElementField, onChangeItemElementItems, onChangeItemElementPosition, canAddItem = true, onAdd, onDelete }) => {
    const [t] = useTranslation();

    const { id } = itemElement;

    const elementRef = useRef(null);

    const [name, setName] = useState(itemElement?.name || '');
    const [minSelection, setMinSelection] = useState(itemElement?.minSelection || 0);
    const [maxSelection, setMaxSelection] = useState(itemElement?.maxSelection || 1);
    const [repeatable, setRepeatable] = useState(itemElement?.repeatable || false);
    const [description, setDescription] = useState(itemElement?.description || '');
    const [items, setItems] = useState(itemElement?.items || []);

    const handleOnAddItem = async (itemToAdd) => {
        const newItems = [...(items || []), itemToAdd];
        setItems(newItems);
        debounceChangeMenuElementItems(newItems);
    };

    const handleOnExtraCostChange = (itemId, extraCost) => {
        const itemIdx = items.findIndex(i => i.id === itemId);
        const newItems = [...items];
        const updatedItem = newItems[itemIdx];
        newItems[itemIdx] = {
            ...updatedItem,
            object: {
                ...updatedItem.object,
                extraCost
            }
        };

        setItems(newItems);
        debounceChangeMenuElementItems(newItems);
    }

    const debounceChangeMenuElementItems = useMemo(() => (
        debounce((items) => onChangeItemElementItems(items), debounceDelay)
    ), [onChangeItemElementItems]);

    const handleOnDeleteItem = (itemId) => {
        const newItems = items.filter(i => i.id !== itemId);
        setItems(newItems);
        debounceChangeMenuElementItems(newItems);
    };

    const handleAdd = () => {
        onAdd({ name, description, minSelection, maxSelection, repeatable, items: [] });
        setName('');
        setMinSelection(0);
        setMaxSelection(1);
        setRepeatable(false);
        setDescription('');
    }

    const executeScroll = () => {
        const y = elementRef.current.getBoundingClientRect().top + window.pageYOffset - 80;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    const handleOnReorderItem = (newItems) => {
        setItems(newItems);
        if (!!onChangeItemElementItems) {
            onChangeItemElementItems(newItems);
        }
    };

    const debounceChangeMenuElementField = useMemo(() => (
        debounce((name, field) => onChangeItemElementField(name, field), debounceDelay)
    ), [onChangeItemElementField]);

    const handleChangeName = (e, {name, value}) => {
        setName(value);
        debounceChangeMenuElementField(name, value);
    }

    const handleChangePosition = (e, { value }) => {
        onChangeItemElementPosition(value);
        setTimeout(function() { executeScroll() }, 500);
    }

    const handleChangeDescription = (e, {name, value}) => {
        setDescription(value);
        debounceChangeMenuElementField(name, value);
    }

    const handleChangeMinSelection = (e, {name, value}) => {
        const minSelection = parseInt(value);
        setMinSelection(minSelection);
        debounceChangeMenuElementField(name, minSelection);
    }

    const handleChangeMaxSelection = (e, {name, value}) => {
        const maxSelection = parseInt(value);
        setMaxSelection(maxSelection);
        debounceChangeMenuElementField(name, maxSelection);
    }

    const handleChangeRepeatable = (e, {name, checked}) => {
        setRepeatable(checked);
        debounceChangeMenuElementField(name, checked);
    }

    return (
        <Ref innerRef={elementRef}>
            <>
                <Grid>
                    <Grid.Column tablet={8} mobile={16} computer={8}>
                        <Header as='h3'>{name}</Header>
                    </Grid.Column>
                    <Grid.Column tablet={8} mobile={16} computer={8}>
                        {onDelete &&
                            <span css={css`
                                display: flex;
                                justify-content: flex-end;
                            `}>
                                <Button
                                    icon='times'
                                    color='red'
                                    onClick={() => onDelete(id)}
                                />
                            </span>
                        }
                    </Grid.Column>

                    {onDelete &&
                        <Grid.Row columns='one'>
                            <Grid.Column>
                                <Form.Field
                                    control={Dropdown}
                                    selection
                                    onChange={handleChangePosition}
                                    value={position}
                                    disabled={!onChangeItemElementField}
                                    options={typeof maxPosition !== 'undefined' && maxPosition !== null ? [...Array(maxPosition).keys()].map(i => ({
                                        key: i,
                                        value: i,
                                        text: t('item_elements.position_n', { n: `${i+1}` })
                                    })) : []}
                                    name="position"
                                    label={t('item_elements.position')}
                                    fluid
                                    placeholder={t('item_elements.position')}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    }

                    <Grid.Row columns='one'>
                        <Grid.Column>
                            <Form.Field
                                control={Input}
                                onChange={handleChangeName}
                                value={name}
                                disabled={!onChangeItemElementField}
                                name="name"
                                label={t('item_elements.name')}
                                fluid
                                placeholder={t('item_elements.name')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column tablet={5} mobile={8} computer={5}>
                            <Form.Field
                                control={Input}
                                type="number"
                                min={0}
                                max={maxSelection}
                                onChange={handleChangeMinSelection}
                                value={minSelection}
                                disabled={!onChangeItemElementField}
                                name="minSelection"
                                label={t('item_elements.min_selection')}
                                placeholder={t('item_elements.min_selection')}
                            />
                        </Grid.Column>

                        <Grid.Column tablet={5} mobile={8} computer={5}>
                            <Form.Field
                                control={Input}
                                type="number"
                                min={0}
                                onChange={handleChangeMaxSelection}
                                value={maxSelection}
                                disabled={!onChangeItemElementField}
                                name="maxSelection"
                                label={t('item_elements.max_selection')}
                                placeholder={t('item_elements.max_selection')}
                            />
                        </Grid.Column>

                        <Grid.Column tablet={6} mobile={16} computer={6}>
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Checkbox
                                    name='repeatable'
                                    label={t('item_elements.repeatable')}
                                    toggle
                                    checked={repeatable}
                                    disabled={!onChangeItemElementField}
                                    onChange={handleChangeRepeatable}
                                />
                            </Form.Field>
                        </Grid.Column>

                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Form.Field
                                control={TextArea}
                                onChange={handleChangeDescription}
                                value={description}
                                disabled={!onChangeItemElementField}
                                name="description"
                                label={t('item_elements.description')}
                                placeholder={t('item_elements.description')}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {canAddItem &&
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}/>
                            <Table.HeaderCell width={11}>{t('item_elements.products')}</Table.HeaderCell>
                            <Table.HeaderCell width={3}>{t('item_elements.extra_cost')}</Table.HeaderCell>
                            <Table.HeaderCell width={1} />
                        </Table.Row>
                    </Table.Header>

                    <ReactSortable
                        tag="tbody"
                        group={`elements-${id}`}
                        animation={200}
                        delayOnTouchStart={true}
                        delay={2}
                        handle=".handle"
                        list={items.map(x => ({ ...x, chosen: true }))} // solution
                        setList={handleOnReorderItem}
                    >
                        {items && items.map(i => (
                            <Table.Row key={i.id}>
                                <ItemElementItem
                                    item={i}
                                    extraCost={i.object.extraCost}
                                    onExtraCostChange={onChangeItemElementItems ? (itemId, extraCost) => handleOnExtraCostChange(itemId, extraCost) : null}
                                    onDelete={onChangeItemElementItems ? (id) => handleOnDeleteItem(id) : null}
                                />
                            </Table.Row>
                        ))}
                    </ReactSortable>

                    {onChangeItemElementItems &&
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'>
                                <ItemSelector
                                    filters={{
                                        types: itemElementItemTypes
                                    }}
                                    excludedItemIds={(items || []).map(i => i.id)}
                                    onItemAdd={handleOnAddItem}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>}
                </Table>
                }

                {onAdd &&
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                <Button
                                    color='teal'
                                    onClick={handleAdd}
                                    disabled={!name}
                                >
                                    {t('item_elements.add')}
                                </Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                }
            </>
        </Ref>
    )
}

ItemElement.propTypes = {
    itemElement: PropTypes.object.isRequired,
    itemElementItemTypes: PropTypes.array,
    onChangeItemElementField: PropTypes.func,
    onChangeItemElementItems: PropTypes.func,
    onChangeItemElementPosition: PropTypes.func,
    position: PropTypes.number,
    maxPosition: PropTypes.number,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
}

export default ItemElement;
