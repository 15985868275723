import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'upload';

export const uploadImage = async (image, withConversion, onUploadProgress) => {
    try {
        const options = {
            headers: { 'Content-Type': 'multipart/form-data' },
            timeout: 0,
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onUploadProgress(percentCompleted);
            }
        };

        const bodyFormData = new FormData();
        bodyFormData.append('image', image);
        bodyFormData.append('withConversion', JSON.stringify(withConversion));

        return await call(METHODS.POST, ROUTE_PREFIX, `image`, bodyFormData, options);
    } catch (e) {
        console.error(`uploadImage error: ${e}`);
        throw e;
    }
}
