/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { UltyDropdown } from '@ulty/ulty-ui'
import { HourPicker } from './HourPicker'
import { WeekdayPicker } from './WeekdayPicker'
import { MonthdayPicker } from './MonthdayPicker'
import { useTranslation } from 'react-i18next'
import useUser from '../../../Shared/UserProvider/useUser'
import { Form, Icon } from 'semantic-ui-react'

const weeklyMonthlyStyles = {
    display: 'flex',
    columnGap: '1rem',
    flexWrap: 'wrap'
}

const columnStyles = {
    flexDirection: 'column'
}

const frequencies = ['daily', 'weekly', 'monthly']

const cronObjectToCronString = (cronObject) => {
    const { minute, hour, day, month, weekday } = cronObject
    return `${minute} ${hour} ${day} ${month} ${weekday}`
}

const cronStringToCronObject = (cronString) => {
    const [minute, hour, day, month, weekday] =  cronString.split(' ')

    return {
        minute,
        hour,
        day,
        month,
        weekday
    }
}

const cronExpressionToFrequency = (cronExpression) => {
    const {
        day,
        weekday
    } = cronStringToCronObject(cronExpression)

    if (day !== '*') {
        return 'monthly'
    }

    if (weekday !== '*') {
        return 'weekly'
    }

    return 'daily'
}

const initialDaily = {
    minute: '0',
    hour: '10',
    day: '*',
    month: '*',
    weekday: '*'
}

const initialWeekly = {
    ...initialDaily,
    day: '*',
    month: '*',
    weekday: '1'
}

const initialMonthly = {
    ...initialDaily,
    day: '1',
    month: '*',
    weekday: '*'
}

const initialCronObject = {
    'daily': initialDaily,
    'weekly': initialWeekly,
    'monthly': initialMonthly,
}


const FrequencyPicker = ({ cronExpression, onChange}) => {
    const [t] = useTranslation()
    const cronObject = cronStringToCronObject(cronExpression)
    const frequency = cronExpressionToFrequency(cronExpression)
    const {user} = useUser()

    const onHourChange = ({hour, minute}) => {
        const newValue = {
            ...cronObject,
            hour,
            minute
        }
        onChange(cronObjectToCronString(newValue))
    }

    const onWeekdayChange = ({ weekday }) => {
        const newValue = {
            ...cronObject,
            weekday,
        }
        onChange(cronObjectToCronString(newValue))
    }

    const onMonthdayChange = ({day}) => {
        const newValue = {
            ...cronObject,
            day,
        }
        onChange(cronObjectToCronString(newValue))
    }

    const styles = user.provider.type === 'COMPANY' ?
      { ...weeklyMonthlyStyles, ...columnStyles } :
      { ...weeklyMonthlyStyles }

    return (
      <div css={css`
          display: flex;
          column-gap: 1rem;
          flex-wrap: wrap;
      `}>
          <Form.Field>
              <label>
                  <Icon name="calendar"/>
                  {t('reports.performance_configuration.frequency_picker.label')}
              </label>
              <UltyDropdown
                loading={false}
                options={frequencies.map(frequency => ({
                    text: t(`reports.performance_configuration.frequency_picker.${frequency}`),
                    key: frequency,
                    value: frequency,
                }))}
                onChange={(e, {value}) => {
                    onChange(cronObjectToCronString(initialCronObject[value]))
                }}
                value={frequency}
                multiple={false}
              />
          </Form.Field>

          {frequency === 'daily' &&
            <HourPicker hour={+cronObject.hour} minute={+cronObject.minute} onChange={onHourChange}/>
          }

          {frequency === 'weekly' &&
            <div css={styles}>
                <WeekdayPicker weekday={cronObject.weekday} onChange={onWeekdayChange}/>
                <HourPicker hour={+cronObject.hour} minute={+cronObject.minute} onChange={onHourChange}/>
            </div>
          }

          {frequency === 'monthly' &&
            <div css={styles}>
                <MonthdayPicker day={+cronObject.day} onChange={onMonthdayChange}/>
                <HourPicker hour={+cronObject.hour} minute={+cronObject.minute} onChange={onHourChange}/>
            </div>
          }
      </div>
    )
}

export { FrequencyPicker }
