/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {useEffect, useState} from 'react';
import { Form, Grid, Input, Table } from 'semantic-ui-react'
import {getMerchant} from '../../../helpers/api';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { MOBILE_THRESHOLD } from '../../../constants';

const ModalExtraFields = ({ extraFields, handleChange }) => {
  const [t] = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
  const [isLoading, setIsLoading] = useState(false);
  const [merchant, setMerchant] =  useState(null);

  useEffect(() => {
    setIsLoading(true);
    getMerchant().then(merchant => {
      setMerchant(merchant.data);
    });
    setIsLoading(false);
  }, []);

  return (
    <React.Fragment>
      {merchant && merchant.extraFields && merchant.extraFields.length > 0 && (
        <Grid.Column width={16}>
          <Form.Field css={css`margin-top: 20px;`}>
            <label>{t('product_details.additional')}:</label>
          </Form.Field>
          <Table unstackable compact css={css`
            margin-left: ${isMobile ? '-1rem' : 0} !important;
          `}>
            <Table.Body>
              {merchant.extraFields.map(f => {
                return (
                  <Table.Row key={f.key} disabled={isLoading}>
                    <Table.Cell>{f.label}</Table.Cell>
                    <Table.Cell>
                      <Form.Field
                        control={Input}
                        name={`extra_${f.key}`}
                        value={(extraFields||{})[`extra_${f.key}`]}
                        onChange={handleChange}
                        placeholder={f.label}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
      )}
    </React.Fragment>
  )
}

export default ModalExtraFields;
