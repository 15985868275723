import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'roles';

export const getRoles = async () => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, '');
    } catch (e) {
        console.error(`getRole error: ${e}`);
        throw e;
    }
}
