/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Input, Form, Grid } from 'semantic-ui-react'
import { notify } from 'react-notify-toast';

import { useTranslation } from 'react-i18next';
import { saveCategorySlot } from '../../services/Category/Category.service';

const CategoryAddSlot = ({ setId, afterSubmit }) => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true)

    if (name === '' || !name) {
      notify.show(t('category_slot.add_name_mandatory'), 'error');
      setNameError(true);
      setLoading(false);
      return;
    }

    try {
      const result = await saveCategorySlot(setId, {
        name,
      });

      afterSubmit(result.id);
      notify.show(t('global.registerSuccess'), 'success');
    } catch (e) {
      console.error(e);
      if (e.message === 'name_already_exists') {
        notify.show(`${t(`category_slot.${e.message}`)}`, 'error');
      } else {
        notify.show(t('global.anErrorOccurred'), 'error');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form>
      <Grid css={css`
                .column { padding-top: .5rem !important; padding-bottom: .5rem !important; }
              `}>
        <Grid.Column width={16}>
          <Form.Field
            control={Input}
            name="name"
            value={name}
            onChange={(evt, { value }) => {
              setNameError(false);
              setName(value);
            }}
            error={nameError || false}
            label={t('category_slot.add_name')}
            placeholder={t('category_slot.add_name')}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <Button type="submit" color="teal" loading={loading} disabled={loading} onClick={handleSubmit}>{t('global.save')}</Button>
          </div>
        </Grid.Column>
      </Grid>
    </Form>
  )
}

CategoryAddSlot.propTypes = {
  setId: PropTypes.string.isRequired,
  afterSubmit: PropTypes.func.isRequired,
};

export default withRouter(CategoryAddSlot);
