/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {Statistic, Card} from 'semantic-ui-react';
import Chart from "react-apexcharts";
import useUser from '../Shared/UserProvider/useUser';
import {UltyInputText, UltyMoney} from '@ulty/ulty-ui';
import {unitFormat} from '../../helpers/money';
import {customTooltip} from './apexChartUtils';
import HeaderChartComparison from './HeaderChartComparison';
import {useTranslation} from 'react-i18next';
import {saveFile} from '../../services/File/File.service';
import {parse} from 'json2csv';
import slugify from 'slugify';


const HeaderChart = ({ loading, data, label, total, type, color, textColor, dataComparison, totalComparison }) => {
    const {user} = useUser();
    const [t] = useTranslation();

    const formatValueFromType = (value) => {
        switch (type) {
            case 'CURRENCY':
                return unitFormat(value, user.getCompanyCurrency())
            case 'NUMERIC':
            default:
                return value;
        }
    }

    return (
        <Card fluid>
            <Card.Content textAlign="center">
                <div>
                    <Statistic color="teal" css={css`max-width: 100%; width: 100%;`}>
                        <Statistic.Value css={css`color: ${textColor || '#00b5ad'}; min-height: 30px;`}>
                            {loading ? '-':
                                (
                                    <>
                                        {type === 'NUMERIC' &&
                                        <div css={css`
                                            font-size: 2em !important;  
                                            & > div > p { font-size: 2rem; color: ${textColor || '#00b5ad'}; }
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;`}>
                                            <UltyInputText
                                                readonly
                                                value={total || '-'}
                                            />

                                            {dataComparison && <HeaderChartComparison total={total} totalComparison={totalComparison} type={type} /> }
                                        </div>
                                        }

                                        {type === 'CURRENCY' &&
                                        <div css={css`
                                        font-size: 2em !important;  
                                        & > div > p { font-size: 2rem; color: ${textColor || '#00b5ad'}; }
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;`}>
                                        <UltyMoney
                                            css={css`font-size: 2em !important; & > p { font-size: 2rem; color: ${textColor || '#00b5ad'}; }`}
                                            readonly
                                            placeholder={+total ? null : '-'}
                                            amount={+total}
                                            currencyCode={user.getCompanyCurrency()}
                                            locale={user.getCompanyLocale()}
                                        />

                                        {dataComparison && <HeaderChartComparison total={total} totalComparison={totalComparison} type={type} /> }
                                        </div>
                                        }
                                    </>
                                )
                            }
                        </Statistic.Value>
                        <Statistic.Label>{label}</Statistic.Label>
                    </Statistic>
                </div>

                <Chart
                    css={css`
                      .apexcharts-menu-item.exportCSV {
                        display: none;
                      }
                    `}
                    options={{
                        chart: {
                            toolbar:{
                                tools: {
                                    customIcons: [{
                                        icon: `<i class="large icons" style="margin-left: 1rem; font-size: 18px">
                                                <i aria-hidden="true" class="file excel outline icon"></i>
                                                <i aria-hidden="true" class="download corner icon"></i>
                                            </i>`,
                                        title: t('home.download_all_datas'),
                                        class: 'custom-icon',
                                        click: function (_chart, options) {
                                            const firstSet = options.config.series[0]
                                            const csv = parse(data.map(d => ({
                                                category: d.x,
                                                [firstSet.name]: formatValueFromType(d.y)
                                            })), {fields: ['category', firstSet.name]})
        
                                            saveFile(slugify(firstSet.name), csv, 'text/csv')
                                        },
                                    }],
                                    download:  false
                                }
                            }
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true,
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            categories: data.sort((a, b) => b.y - a.y).slice(0, 15).sort((a, b) => a.y - b.y).map(d => d.x).reverse(),
                            labels: {
                                show: true,
                                rotateAlways: true,
                                formatter: function (value) {
                                    let val = Math.abs(value);
            
                                    if (val >= 10 ** 3 && val < 10 ** 6) {
                                        val = (val / 1000).toFixed(0) + 'k';
                                    } else if (val >= 10 ** 6) {
                                        val = (val / 1000000).toFixed(0) + 'M';
                                    }
            
                                    return val
                                }
                            }
                        },
                        tooltip: {
                            shared: true,
                            intersect: false,
                            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                                return customTooltip({ series, seriesIndex, dataPointIndex, w, currencyCode: user.getCompanyCurrency(), locale: user.getCompanyLocale() });
                            }
                        },
                        colors: color ? [color] : (type === 'NUMERIC' ? ['#45b29d'] : ['#334d5c']),
                    }}
                    series={[{
                        name: label,
                        data: data.sort((a, b) => b.y - a.y).slice(0, 15).sort((a, b) => a.y - b.y).map(d => (formatValueFromType(d.y))).reverse(),
                        dataType: type
                    }]}
                    type="bar"
                    height={250}
                />
            </Card.Content>
        </Card>
    )
}

export default HeaderChart
