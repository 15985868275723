/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icon } from 'semantic-ui-react';
import {UltyInputText, UltyMoney} from '@ulty/ulty-ui';
import useUser from '../Shared/UserProvider/useUser';

const HeaderChartComparison = ({
  total,
  totalComparison,
  type,
}) => {
  const {user} = useUser();

  const diff = total - totalComparison;
  const percent = totalComparison ? ((total - totalComparison) / totalComparison * 100.0) : 'N/A';

  return (
    <div css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: .8rem;
        padding: .6rem 1rem;
    `}>
      <div css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          & > div > p { font-size: 1rem; color: ${diff < 0 ? '#db2828' : '#21ba45'}; }; }
      `}>
        <Icon color={diff < 0 ? 'red' : 'green'} name={`caret ${diff < 0 ? "down" : "up"}`} size="mini" css={css`font-size: 1.6rem !important; margin-right: .4rem !important;`} />
        <UltyInputText
          readonly
          value={isNaN(percent) ? 'N/A' : `${percent >= 0 ? '+' : ''}${percent.toFixed(2)}%` || '-'}
        />
      </div>
      <div css={css`
          & > div > p { font-size: 1rem; color: ${diff < 0 ? '#db2828' : '#21ba45'}; }; }
      `}>
        { isNaN(percent) ? (
          <></>
        ) : (
          <>
          { type === 'NUMERIC' ?
        <UltyInputText
          readonly
          value={`${diff >= 0 ? '+' : ''}${diff}` || '-'}
        /> :
        <UltyMoney
          css={css`font-size: 1rem !important; & > p { font-size: 1rem; color: ${diff < 0 ? '#db2828' : '#21ba45'}; }`}
          readonly
          placeholder={+diff ? null : '-'}
          amount={+diff}
          prefix={diff >= 0 ? '+' : ''}
          currencyCode={user.getCompanyCurrency()}
          locale={user.getCompanyLocale()}
        />}
          </>
        )}
        
      </div>
    </div>
  );
}

export default HeaderChartComparison;