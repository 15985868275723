import { useState } from "react"
import { createContainer } from "unstated-next"

const ProductStore = () => {
  let [products, setProducts] = useState([])
  let [categories, setCategories] = useState([])

  let [l1, setL1] = useState(null)
  let [l2, setL2] = useState(null)
  let [unavailable, setUnavailable] = useState(false)

  let [platforms, setPlatforms] = useState([])
  let [merchant, setMerchant] = useState({})

  let [itemsPerPage, setItemsPerPage] = useState(30)

  return { 
    products, setProducts,
    categories, setCategories,
    l1, setL1,
    l2, setL2,
    unavailable, setUnavailable,
    platforms, setPlatforms,
    itemsPerPage, setItemsPerPage,
    merchant, setMerchant,
  }
}

export default createContainer(ProductStore)