/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {useTranslation} from 'react-i18next'
import {Header, Segment, Table} from 'semantic-ui-react'
import ItemSelector from '../../Items/ItemElements/ItemSelector'
import {UltyMoney} from '@ulty/ulty-ui'
import {getWeeklyMarkupPricesOfItem} from '../../../services/Category/Category.service'
import {useEffect, useMemo, useState} from 'react'
import useUser from '../../Shared/UserProvider/useUser'
import {isDayNumberToday} from '../../../services/date.service'
import PropTypes from 'prop-types';




const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const selectable = css`
cursor: pointer;
`

const highlightStyle = css`
background: rgb(236 244 249)!important;
font-weight: bold;
`

const MarkupPriceChecker = ({ markupSetupContext,markups,onMarkupHighlight,currentMarkupSetupId }) => {
    const [t] = useTranslation()
    const {user} = useUser()

    const [item, setItem] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [itemPrice, setItemPrice] = useState('')
    const [weekParts, setWeekParts] = useState([])

    const [highlight, setHighlight] = useState(currentMarkupSetupId);

    const itemFilters = useMemo(() => (
        {
            types: ['PRODUCT', 'MENU', 'EXTRA'],
            ...(markupSetupContext?.categorySetLinkId ? {
                categorySet: markupSetupContext.categorySetId || undefined,
                categorySlot: markupSetupContext.categorySlotId || undefined,
                category: markupSetupContext.categoryId || undefined,
            }: {})
        }
    ),[markupSetupContext])

    useEffect( () => {
        (async function reloadPriceWrapper() {
            if(item){
                await reloadPrice(item);
            }
        })()
    },[item,markups])

    const getWeekPartOfADay = (day) => {
        return weekParts.find(weekPart => weekPart.daysOfWeek.includes(day))
    }

    async function reloadPrice(item){
        setIsLoading(true)
        try {
            const weeklyMarkupPricesOfItem = await getWeeklyMarkupPricesOfItem(markupSetupContext?.categorySetLinkId, item.id,currentMarkupSetupId ?? undefined)
            setItemPrice(weeklyMarkupPricesOfItem.itemPrice)
            setWeekParts(weeklyMarkupPricesOfItem.weekParts)
        }

        catch(e){

        }
        finally{
            setIsLoading(false)
        }
    }

    async function handleSelectedItemChange(selectedItem) {
        try {
            setIsLoading(true)
            setItem(selectedItem)

            if (!selectedItem) {
                setItemPrice('')
                setWeekParts([])
                return
            }
            await reloadPrice();
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    const canHighlight = (Boolean(!currentMarkupSetupId));

    const getCssStyles = (setupId) => ([
        ...(canHighlight ? [selectable] : []),
        ...withHighlightWhenNeeded(setupId)
    ]);

    const handleHighLight = (setupId) => {
        //If an highlight was provided at init, do nothing
        if(currentMarkupSetupId) return;

        if( highlight === setupId){
            setupId = null;
        }
        setHighlight(setupId);
        onMarkupHighlight?.(setupId);
    }

    const isSetupHighlight = (setupId) => {
        return (highlight && setupId === highlight);
    }

    const withHighlightWhenNeeded = (setupId) => {
        if(isSetupHighlight(setupId)) return [highlightStyle];
        else return [];
    }



    return (
        <>
                <div css={css`
                  display: flex;
                `}>

                </div>

                {<Table compact unstackable basic='very' >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <ItemSelector

                                        placeholder={t('markup.price_checker.choose_a_product')}
                                        filters={itemFilters}
                                        onItemChange={async (selectedItem) => {
                                            await handleSelectedItemChange(selectedItem)
                                        }}
                                        selectFirstAtLoad
                                        clearable={false}
                                    />


                                </Table.HeaderCell>
                                {DAYS_OF_WEEK.map(day => {
                                    const weekPart = getWeekPartOfADay(day)
                                    return (
                                    <Table.HeaderCell
                                        css={getCssStyles(weekPart?.setupId)}
                                        onClick={() => handleHighLight(weekPart?.setupId)}
                                        key={day}>
                                        {t(`days.${day}`)}
                                    </Table.HeaderCell>
                                )}
                                )}
                            </Table.Row>
                        </Table.Header>

                        { item && <Table.Body>
                            <Table.Row>
                                <Table.Cell>{t('markup.price_checker.item_price')}</Table.Cell>
                                {DAYS_OF_WEEK.map((day, index) => {
                                        const weekPart = getWeekPartOfADay(day)
                                        return (
                                        <Table.Cell
                                            css={getCssStyles(weekPart?.setupId)}
                                            onClick={() => handleHighLight(weekPart?.setupId)}
                                            key={`item-price-${day}`}
                                            >
                                           <UltyMoney
                                            css={css`margin: 0`}
                                            readonly
                                            currencyCode={user.getCompanyCurrency()}
                                            locale={user.getCompanyLocale()}
                                            amount={itemPrice || 0}
                                        />
                                        </Table.Cell>
                                        )
                                    }
                                )}
                            </Table.Row>
                            <Table.Row >
                                <Table.Cell>{t('markup.markup')}</Table.Cell>
                                {DAYS_OF_WEEK.map((day, index) => {
                                        const weekPart = getWeekPartOfADay(day)
                                        return (
                                        <Table.Cell
                                            css={getCssStyles(weekPart?.setupId)}
                                            onClick={() => handleHighLight(weekPart?.setupId)}
                                            key={`markup-${day}`}
                                            >
                                            <span
                                            >{weekPart?.markup} %
                                            </span>
                                        </Table.Cell>
                                        )
                                    }
                                )}
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>{t('markup.price_checker.markup_price')}</Table.Cell>
                                {DAYS_OF_WEEK.map((day, index) => {
                                        const weekPart = getWeekPartOfADay(day)
                                        return <Table.Cell
                                                css={getCssStyles(weekPart?.setupId)}
                                                onClick={() => handleHighLight(weekPart?.setupId)}
                                                key={`markup-price-${day}`}
                                                >
                                            <UltyMoney
                                                css={css`
                                                  margin: 0;
                                                `}
                                                readonly
                                                currencyCode={user.getCompanyCurrency()}
                                                locale={user.getCompanyLocale()}
                                                amount={weekPart?.itemMarkupPrice || 0}
                                            />
                                        </Table.Cell>
                                    }
                                )}
                            </Table.Row>

                        </Table.Body>
                        }
                    </Table>
                }
        </>
    )
}

MarkupPriceChecker.propTypes = {
    markupSetupContext: PropTypes.oneOf([PropTypes.shape({
        categorySetLinkId: PropTypes.string.isRequired,
        categorySetId: PropTypes.string,
        categorySlotId: PropTypes.string,
        categoryId: PropTypes.string
    })]),
    markups:PropTypes.any,
    onMarkupHighlight:PropTypes.func,
    currentMarkupSetupId:PropTypes.string
}

export default MarkupPriceChecker
