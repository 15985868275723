/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState, useReducer } from 'react';
import { Table, Form, Segment, Button, Icon } from 'semantic-ui-react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { getDetailedMetrics } from '../../services/Metrics/Metrics.service';
import { useTranslation } from 'react-i18next';
import useBreadcrumb from '../NavBar/useBreadcrumb';
import AdvancedFilters from './AdvancedFilters';
import useUser from '../Shared/UserProvider/useUser';
import _ from 'lodash';
import { UltyMoney } from '@ulty/ulty-ui';
import { CSVLink } from 'react-csv';
import { unitFormat } from '../../helpers/money';
import { getCancellationSource } from '../../services/http-client';


const reducer = (state, action) => {
  switch (action.type) {
    case 'STATE_UPDATE':
      let newdata = _.sortBy(action.data, [state.column])
      if (state.direction === 'descending') {
        newdata = newdata.reverse()
      }

      return {
        ...state,
        data: newdata
      }

    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: _.sortBy(state.data, [action.column]).reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
      }
    default:
      throw new Error()
  }
}

const Detailed = ({ handleFiltersChange, defaultFilters }) => {
  const [t] = useTranslation();
  const { setPaths } = useBreadcrumb();
  const [loading, setLoading] = useState(false);

  const [state, dispatch] = useReducer(reducer, {
    column: null,
    data: [],
    direction: null,
  });
  const { column, data, direction } = state;

  const { user } = useUser();

  const loadMetrics = useCallback(async (cancellationSource) => {
    setLoading(true);

    const dateRange = {
      startDate: moment(defaultFilters.dateRange.startDate).utc(),
      endDate: moment(defaultFilters.dateRange.endDate).utc()
    };

    const data = await getDetailedMetrics({
      dateRange: {
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString()
      },
      merchantId: defaultFilters.merchantId,
      brandIds: defaultFilters.brandIds,
      platformIds: defaultFilters.platformIds,
    },cancellationSource);

    if(cancellationSource?.isCancelled) return;

    dispatch({ type: 'STATE_UPDATE', data })

    setLoading(false);
  }, [defaultFilters.dateRange.startDate, defaultFilters.dateRange.endDate, defaultFilters.merchantId, defaultFilters.brandIds, defaultFilters.platformIds, defaultFilters.dateComparison?.startDate, defaultFilters.dateComparison?.endDate]);

  useEffect(() => {
    setPaths([{
      text: t('breadcrumb.home'),
      link: false,
      path: '/'
    }]);
  }, [setPaths, t]);

  useEffect(() => {
    const cancellationSource = getCancellationSource();
    loadMetrics(cancellationSource);

    return () => cancellationSource.cancel();
  }, [loadMetrics]);

  return (
    <React.Fragment>
      <div css={css`
          display: flex;
          justify-content: space-between;

          .csv-btn {
            display: flex;
            align-items: center;
          }
        `}>
        <AdvancedFilters
          defaultFilters={{
            ...defaultFilters,
            dateRange: {
              startDate: moment(defaultFilters.dateRange.startDate).utc(),
              endDate: moment(defaultFilters.dateRange.endDate).utc()
            },
            dateComparison: {
              startDate: defaultFilters.dateComparison.startDate && moment(defaultFilters.dateComparison.startDate).utc(),
              endDate: defaultFilters.dateComparison.endDate && moment(defaultFilters.dateComparison.endDate).utc()
            }
          }}
          onFiltersChange={handleFiltersChange}>
          <AdvancedFilters.DateRangeFilter />
          <Form css={css` display: flex;`}>
            <Form.Group widths="equal">
              {user.provider.type === 'COMPANY' && <AdvancedFilters.PosFilter />}
              <AdvancedFilters.BrandFilter />
              <AdvancedFilters.PlatformFilter />
            </Form.Group>
          </Form>
        </AdvancedFilters>
        <CSVLink
          className="csv-btn"
          enclosingCharacter={'"'}
          separator=";"
          data={[
            [
              t('home.detailed.pos'),
              t('home.detailed.brand'),
              t('home.detailed.platform'),
              t('home.detailed.ca'),
              t('home.detailed.orders_count'),
              t('home.detailed.note'),
              t('home.detailed.ca_missed'),
              t('home.detailed.expired'),
              t('home.detailed.denied'),
              t('home.detailed.cancelled'),
            ],
          ].concat(data.map((el) => [
            el.posname.replace(/"/g, '""'),
            el.brandname.replace(/"/g, '""'),
            el.platformname.replace(/"/g, '""'),
            unitFormat(el.revenuesmtd, user.getCompanyCurrency()),
            el.ordersmtd,
            el.rating,
            unitFormat(el.revenuesmissedmtd, user.getCompanyCurrency()),
            el.expiredmtd,
            el.deniedmtd,
            el.cancelledmtd,
          ]))}
          filename="export-detailed.csv"
        >
          <Button icon>
            <Icon name="cloud download" />
          </Button>
        </CSVLink>
      </div>
      <Segment>
        <div css={css`label { font-weight: bold; }`}>
          <label>{t('home.detailed.legend')}</label>
        </div>
        <div>
          {t('home.detailed.orders_count')}: {t('home.detailed.orders_count_explained')}
        </div>
        <div>
          {t('home.detailed.expired')}: {t('home.detailed.expired_explained')}
        </div>
        <div>
          {t('home.detailed.denied')}: {t('home.detailed.denied_explained')}
        </div>
        <div>
          {t('home.detailed.cancelled')}: {t('home.detailed.cancelled_explained')}
        </div>
      </Segment>
      <Segment loading={loading} basic css={css`padding: 0 !important;`}>
        <Table color="teal" sortable fixed selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left" singleLine
                sorted={column === 'posname' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'posname' })}
              >
                {t('home.detailed.pos')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left"
                width="2"
                sorted={column === 'brandname' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'brandname' })}
              >
                {t('home.detailed.brand')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left"
                width="2"
                sorted={column === 'platformname' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'platformname' })}
              >
                {t('home.detailed.platform')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left"
                width="2"
                sorted={column === 'revenuesmtd' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'revenuesmtd' })}
              >
                {t('home.detailed.ca')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left"
                width="1"
                sorted={column === 'ordersmtd' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'ordersmtd' })}
              >
                {t('home.detailed.orders_count')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left"
                width="1"
                sorted={column === 'rating' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'rating' })}
              >
                {t('home.detailed.note')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left"
                width="2"
                sorted={column === 'revenuesmissedmtd' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'revenuesmissedmtd' })}
              >
                {t('home.detailed.ca_missed')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left"
                width="1"
                sorted={column === 'expiredmtd' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'expiredmtd' })}
              >
                {t('home.detailed.expired')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left"
                width="1"
                sorted={column === 'deniedmtd' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'deniedmtd' })}
              >
                {t('home.detailed.denied')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left"
                width="1"
                sorted={column === 'cancelledmtd' ? direction : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'cancelledmtd' })}
              >
                {t('home.detailed.cancelled')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {(data || []).map(dd => {
              return (
                <Table.Row key={dd.pdmid}>
                  <Table.Cell singleLine>
                    {dd.posname}
                  </Table.Cell>
                  <Table.Cell>
                    {dd.brandname}
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    {dd.platformname}
                  </Table.Cell>
                  <Table.Cell positive textAlign="right">
                    <UltyMoney
                      readonly
                      currencyCode={user.getCompanyCurrency()}
                      locale={user.getCompanyLocale()}
                      amount={Math.round(dd.revenuesmtd)}
                    />
                  </Table.Cell>
                  <Table.Cell positive textAlign="right">{`${parseInt(dd.ordersmtd)}`}</Table.Cell>
                  <Table.Cell positive textAlign="right">{`${dd.rating ? parseFloat(dd.rating) : ''}`}</Table.Cell>
                  <Table.Cell negative textAlign="right">
                    <UltyMoney
                      readonly
                      currencyCode={user.getCompanyCurrency()}
                      locale={user.getCompanyLocale()}
                      amount={Math.round(dd.revenuesmissedmtd)}
                    />
                  </Table.Cell>
                  <Table.Cell negative textAlign="right">{`${parseInt(dd.expiredmtd)}`}</Table.Cell>
                  <Table.Cell negative textAlign="right">{`${parseInt(dd.deniedmtd)}`}</Table.Cell>
                  <Table.Cell negative textAlign="right">{`${parseInt(dd.cancelledmtd)}`}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Segment>
    </React.Fragment>
  )
}

export default Detailed;
