/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CategoryModifiedModal = ({ afterSubmit }) => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <Grid css={css`
        .column { padding-top: .5rem !important; padding-bottom: .5rem !important; }
      `}>
        <Grid.Column width={16}>
          {t('categories.confirm_continue')}
        </Grid.Column>
        <Grid.Column width={16} textAlign="right">
          <Button basic color="teal" onClick={() => afterSubmit(false)}>{t('global.dont_continue')}</Button>
          <Button color="teal" onClick={() => afterSubmit(true)}>{t('global.continue')}</Button>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

CategoryModifiedModal.propTypes = {
  afterSubmit: PropTypes.func.isRequired
}

export default CategoryModifiedModal;
