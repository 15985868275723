/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import { UltyModalWrapperContext } from '../../../Shared/UltyModalWrapper/UltyModalWrapperContext';

import {
    Button,
    Grid,
    Icon,
    Image,
    Header,
    Label,
    Divider,
    Statistic,
    Message,
    Segment,
} from 'semantic-ui-react';
import Barcode  from 'react-barcode';

import { UltyMoney } from '@ulty/ulty-ui'
import useUser from '../../../Shared/UserProvider/useUser';
import PropTypes from 'prop-types';


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  flex-direction: column;
`;

const ItemDisplay = ({item}) => {
    const [t] = useTranslation();
    const {user} = useUser();

    const currency = user.getCompanyCurrency();
    const locale = user.getCompanyLocale();

    const image = item?.image || item?.images?.[0]?.url;

    return  <Grid>
        <Label color='yellow' attached='top' icon="barcode" content={t('order.pickup.scanned_label')}></Label>
        <Grid.Row>
    <Grid.Column tablet={8} css={css`padding-bottom: 0;`}>
        <Image centered src={image} />
    </Grid.Column>
    <Grid.Column tablet={8} css={css`
      display: flex !important;
      flex-direction: column;
      padding-bottom: 0;
      label {
        font-weight: bold;
      }
    `}>
        <div css={css`padding-top:10px`}>
        <h4>{item.object?.name||item.name}</h4>
        </div>
                <Divider horizontal></Divider>
                <div css={css`
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  align-items: center;
                  height: 100%;
                  max-height: 95px;
                  padding-bottom: 10px;

                  .statistic { margin: 0; flex: 1; }
                `}>
                    <Statistic size='mini'>
                        <Statistic.Label css={css`font-size: .8em !important;`}>{t('order.pickup.wrong_item_details.price')}</Statistic.Label>
                        <Statistic.Value css={css`font-size: 1.8rem !important`}>
                            <UltyMoney
                                readonly
                                amount={item.unitPrice}
                                currencyCode={currency}
                                locale={locale}
                            />
                        </Statistic.Value>
                    </Statistic>
                </div>

    </Grid.Column>
    </Grid.Row>
    <Grid.Row>
    <div css={css`
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  align-items: center;
                  justify-content: space-around;
                  height: 100%;
                  max-height: 95px;

                  .statistic { margin: 0; flex: 1; }
                `}>
                    { item.object?.barcode && (
                        <div css={css`flex: 2; text-align: center;`}>
                            <Barcode value={item.object.barcode} format="EAN13" displayValue={true} fontSize={15} height={60} width={1.2} />
                        </div>
                    )}
                </div>
    </Grid.Row>
    </Grid>
}

const OrderPickupMoreItems = ({ orderItem,currentQuantity, expectedQuantity, onSubmit }) => {
    const [t] = useTranslation();
    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);

    useEffect(() => {
        if (currentQuantity === expectedQuantity) {
            handleUltyModalWrapper(false, null);
        }
    }, [currentQuantity, expectedQuantity, handleUltyModalWrapper]);

    return (
        <>
            <Container>
                <Message
                    warning
                    icon="warning sign"
                    header={t('order.pickup.remaining_item')}
                />
                <Header as='h1'>{currentQuantity} / {expectedQuantity}</Header>
            </Container>
            <Segment textAlign='center'>
            <ItemDisplay item={orderItem} />
            </Segment>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Button color='yellow'
                        onClick={() => {
                            onSubmit({
                                type: 'UNAVAILABLE',
                            });
                            handleUltyModalWrapper(false, null);
                        }}
                >
                    <Icon name='times'/>
                    {t('order.pickup.unavailable')}
                </Button>

                <Button color='teal'
                        onClick={() => {
                            onSubmit({
                                type: 'VALIDATE',
                            });
                            handleUltyModalWrapper(false, null);
                        }}
                >
                    <Icon name='checkmark'/>
                    {t('order.pickup.validate_multi_items')}
                </Button>
            </div>
        </>
    )
}

OrderPickupMoreItems.propTypes = {
    currentQuantity: PropTypes.number.isRequired,
    expectedQuantity: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default OrderPickupMoreItems;
