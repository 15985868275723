import styled from '@emotion/styled';
import {UltyInputStep} from '@ulty/ulty-ui';
import {Button, Icon} from 'semantic-ui-react';
import {useContext, useState} from 'react';
import {UltyModalWrapperContext} from '../../../Shared/UltyModalWrapper/UltyModalWrapperContext';
import {useTranslation} from 'react-i18next';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 2rem;
`;

const Title = styled.h3`
  margin-top: 2rem;
`;

const DefinePickupTime = ({ onSubmit }) => {
    const [t] = useTranslation();
    const [delayPickupTimeMinutes, setDelayPickupTimeMinutes] = useState(30);
    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);

    return (
        <>
            <Title>{t('order.pickup_time.define_pickup_time_delay')}</Title>
            <Container>
                <UltyInputStep
                    value={delayPickupTimeMinutes}
                    step={10}
                    min={0}
                    max={50}
                    onChange={(value) => setDelayPickupTimeMinutes(value)}
                />
                {t('order.pickup_time.units')}
            </Container>

            <div style={{textAlign: 'right', marginTop: '10px'}}>
                <Button color='red'
                        onClick={() => handleUltyModalWrapper(false, null)}
                >
                    <Icon name='remove'/>
                    {t('global.cancel')}
                </Button>
                <Button color='teal'
                        onClick={() => {
                            onSubmit(delayPickupTimeMinutes);
                            handleUltyModalWrapper(false, null);
                        }}
                >
                    <Icon name='checkmark'/>
                    {t('global.validate')}
                </Button>
            </div>
        </>
    )
}

export default DefinePickupTime;
