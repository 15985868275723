import ExtraEdit from './ExtraEdit';
import { Route, Routes } from 'react-router-dom-v5-compat'


const Extras = () => {
    return (
        <Routes>
            <Route path="/extras/edit" element={<ExtraEdit />}>
                <Route path=":id" element={<ExtraEdit />} />
            </Route>
        </Routes>
    )
}

export default Extras
