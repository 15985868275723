import useLocalStorage from 'beautiful-react-hooks/useLocalStorage'
import { useCallback, useEffect, useMemo } from 'react';


const usePrintedCache = () => {
    const [cache,setCache] =  useLocalStorage('ulty-printed_cache', []);

    const map = useMemo(() => {
        return new Map(cache)
    },[cache]);

    const exists = useCallback((orderId) => {
        return map.has(orderId);
    },[map]);

    const clearOld = useCallback(() => {
        const toDelete = new Set();
        for(const [orderId,printDate] of map.entries()){
            const relativePrintTime =(new Date().getTime() - new Date(printDate).getTime()) / 1000;
            if(relativePrintTime > (3600 * 24)) {// One day
                toDelete.add(orderId); 
            } 
        }

        setCache(c => c.filter(([orderId]) => !toDelete.has(orderId)));
    },[map]) 

    const addIfNotExists = useCallback((orderId) => {
        if(map.has(orderId)) return false;
        setCache(c => [...c,[orderId,new Date()]]);
        return true;
    },[map]);

    useEffect(() => {
        clearOld()
    },[])


    return {exists, addIfNotExists, clearOld}

}

export {usePrintedCache}