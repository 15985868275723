import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { getPickingProofUrl } from '../../../../services/Order/Order.service'

const PickingProofEventItemType = ({ orderId, event }) => {
    const [t] = useTranslation()

    const getPickingProofUrlMutation = useMutation({
        mutationFn: ({ pickingProofId }) => getPickingProofUrl(orderId, pickingProofId),
        onSuccess: (data) => {
            window.open(data)
        }
    })

    return (
      <>
          {t('order.picking_proof.picking_proof_added')}
          <Button
            style={{marginLeft: '1rem'}}
            basic
            compact
            icon="download"
            loading={getPickingProofUrlMutation.isPending}
            onClick={() => getPickingProofUrlMutation.mutate({pickingProofId: event.context.split(',')[1]})}>
          </Button>
      </>
    )
}

export { PickingProofEventItemType }
