/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {moneyFromFloat} from '../../helpers/money';
import moment from 'moment';
import {UltyInputText, UltyMoney} from '@ulty/ulty-ui';
import { createRoot } from 'react-dom/client'
import { flushSync } from 'react-dom'

export const customTooltip = ({ t, series, dataPointIndex, w, currencyCode, locale, isComparison, range, disclaimer = null }) => {
    function getTitle(value) {
        if (w.config.xaxis.type === 'category') {
            return w.config.xaxis.categories[dataPointIndex];
        }

        if (w.config.xaxis.type === 'datetime') {
            const dateValue = moment(value)
            switch(w.config.xaxis.bucket) {
                default:
                    return dateValue.format('Do MMM');
                case 'WEEK':
                    return `${t('week')}${dateValue.format('w YYYY')}`;
                case 'MONTH':
                    return dateValue.format('MMM YYYY');
            }
        }

        return '';
    }

    function getHtmlValue(series, seriesIndex, dataType, style = 'font-weight: 600') {
        const value = series?.[seriesIndex]?.[dataPointIndex] || 0;
        const container = document.createElement('span');
        const root = createRoot(container)

        switch (dataType) {
            case 'CURRENCY':
                const money = moneyFromFloat(value, currencyCode);
                flushSync(() => {
                    root.render(
                      <UltyMoney
                        css={css`${style}`}
                        readonly
                        currencyCode={currencyCode}
                        locale={locale}
                        amount={money.amount}
                      />
                    )
                })
                return container.innerHTML;
            case 'NUMERIC':
            default:
                flushSync(() => {
                    root.render(
                        <UltyInputText
                            css={css`${style}`}
                            readonly
                            value={value}
                        />
                    )
                })
                return container.innerHTML;
        }
    }

    let title = getTitle();

    if (isComparison) {
        return isComparison(getTitle, w, dataPointIndex, getHtmlValue, disclaimer)
    }

    const htmlSeriesGroups = w.config.series.filter(serie => serie.data.length > 0).map((serie, index) => {
        const markerColor = w.config.colors[index];

        title = getTitle(serie.data[dataPointIndex]?.[0]);

        return `
        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: ${index}; display: flex">
            <span class="apexcharts-tooltip-marker" style="background-color: ${markerColor}"></span>
            <div class="apexcharts-tooltip-text">
                <div class ="apexcharts-tooltip-y-group">
                    <span class="apexcharts-tooltip-text-y-label">${serie.name}: </span>        
                    <span class="apexcharts-tooltip-text-y-value">${getHtmlValue(series, index, serie.dataType)}</span>        
                </div>
            </div>
        </div>
        `;
    }).join('');

    return (`
        <div class="apexcharts-tooltip-title">${title}</div>
        ${htmlSeriesGroups}
        ${disclaimer ? `<div style="order: ${htmlSeriesGroups.length + 1}; display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start; padding: .6rem;"><i aria-hidden="true" class="info circle icon" style="font-size: 1rem !important;"></i><p>${disclaimer}</p></div>` : ''}
    `);
}
