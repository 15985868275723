const formatToMaxLength = (s,maxLength) => {
    if (typeof s !== 'string') return ''
    const reduced = s.split(' ').reduce((curr, word) => {
        if (curr.len + word.length < maxLength) {
            curr.words.push(word);
            curr.len += word.length;
        }
        return curr;
     },{words:[],len:0})
 return reduced.words.join(" ") + '…';
}

export { formatToMaxLength}