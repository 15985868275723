import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'brands';

export const getBrands = async () => {
    return await call(METHODS.GET, ROUTE_PREFIX);
}

export const patchBrand = async (id, patch) => {
    return await call(METHODS.PATCH, ROUTE_PREFIX, `${id}`, patch);
}

export const addBrand = async (brand) => {
    return await call(METHODS.POST, ROUTE_PREFIX, ``, brand);
}