/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Fragment, useState } from 'react';
import { Statistic, Card, Dropdown } from 'semantic-ui-react';
import Chart from "react-apexcharts";
import useUser from '../Shared/UserProvider/useUser';
import {UltyMoney} from '@ulty/ulty-ui';
import {unitFormat} from '../../helpers/money';
import {customTooltip} from './apexChartUtils';
import { useTranslation } from 'react-i18next';


const ChartFailure = ({ type, data }) => {
    const {user} = useUser();
    const [t] = useTranslation();

    const getValue = (d) => {
        switch(type) {
            case 'EXPIRED_NB':
                return d.nbrrate;
            case 'EXPIRED':
                return d.rate;
            case 'CA':
                return d.y;
            case 'VOLUME':
                return d.nbr;
            default:
                return null;
        }
    }

    const getSecondValue = (d) => {
        switch(type) {
            case 'EXPIRED_NB':
                return d.y;
            case 'EXPIRED':
                return d.y;
            case 'CA':
                return d.nbr;
            case 'VOLUME':
                return d.y;
            default:
                return null;
        }
    }

    const getColor = () => {
        switch(type) {
            case 'EXPIRED_NB':
            case 'EXPIRED':
                return ['#420c09', '#45b29d'];
            case 'CA':
            default:
                return ['#420c09'];
        }
    }

    const dataSorted = data.sort((a, b) => {
        const diff = getValue(b) - getValue(a);
        if (diff === 0) {
            return getSecondValue(b) - getSecondValue(a);
        }
        return diff;
    }).slice(0, 15).sort((a, b) => {
        const diff = getValue(a) - getValue(b);
        if (diff === 0) {
            return getSecondValue(a) - getSecondValue(b);
        }
        return diff;
    });

    return (
        type === 'EXPIRED' || type === 'EXPIRED_NB' ? <Chart
            key={type}
            options={{
                chart: {
                    id: 'failure-0',
                    stacked: true,
                    stackType: '100%'
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: dataSorted.map(d => d.x).reverse(),
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return customTooltip({ series, seriesIndex, dataPointIndex, w, currencyCode: user.getCompanyCurrency(), locale: user.getCompanyLocale() });
                    }
                },
                colors: getColor(),
            }}
            series={[...(type === 'EXPIRED' ? [{
                name: t('home.missed_revenues'),
                data: dataSorted.map(d => unitFormat(d.y, user.getCompanyCurrency())).reverse(),
                dataType: 'CURRENCY'
            }, {
                name: t('home.acquired_revenues'),
                data: dataSorted.map(d => unitFormat(d.ca, user.getCompanyCurrency())).reverse(),
                dataType: 'CURRENCY'
            }] : [{
                name: t('home.missed_orders'),
                data: dataSorted.map(d => d.nbr).reverse(),
                dataType: 'NUMERIC'
            }, {
                name: t('home.acquired_orders'),
                data: dataSorted.map(d => d.nbrca).reverse(),
                dataType: 'NUMERIC'
            }])]}
            type="bar"
            height={250}
        /> : <Chart
        key={type}
        options={{
            chart: {
                id: 'failure-1',
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: dataSorted.map(d => d.x).reverse(),
            },
            tooltip: {
                shared: true,
                intersect: false,
                custom: function({ series, seriesIndex, dataPointIndex, w }) {
                    return customTooltip({ series, seriesIndex, dataPointIndex, w, currencyCode: user.getCompanyCurrency(), locale: user.getCompanyLocale() });
                }
            },
            colors: getColor(),
        }}
        series={[{
            name: t(type === 'CA' ? 'home.missed_revenues' : 'home.missed_orders'),
            data: dataSorted.map(d => {
                switch (type) {
                    case 'CA':
                        return unitFormat(getValue(d), user.getCompanyCurrency())
                    case 'VOLUME':
                        return getValue(d);
                    default:
                        return d.y;
                }
            }).reverse(),
            dataType: type === 'CA' ? 'CURRENCY' : 'NUMERIC'
        }]}
        type="bar"
        height={250}
    />
    )
}

const FailureHeaderChart = ({ loading, data, total, textColor }) => {
    const {user} = useUser();
    const [t] = useTranslation();
    const [type, setType] = useState("EXPIRED_NB");

    return (
        <Card fluid css={css`
            .ui.dropdown {
                position: absolute;
                right: 40px;
                z-index: 100;
                top: 2px;
            }
        `}>
            <Card.Content textAlign="center">
                <div>
                    <Statistic color="teal" css={css`max-width: 100%; width: 100%;`}>
                        <Statistic.Value css={css`color: ${textColor || '#00b5ad'}; min-height: 30px;`}>
                            {loading ? '-':
                                (
                                    <Fragment>
                                        <div css={css`
                                            font-size: 2em !important;  
                                            & > div > p { font-size: 2rem; color: ${textColor || '#00b5ad'}; }
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;`}>
                                        <UltyMoney
                                            css={css`font-size: 2em !important; & > p { font-size: 2rem; color: ${textColor || '#00b5ad'}; }`}
                                            readonly
                                            placeholder={+total ? null : '-'}
                                            amount={+total}
                                            currencyCode={user.getCompanyCurrency()}
                                            locale={user.getCompanyLocale()}
                                        />

                                        </div>
                                    </Fragment>
                                )
                            }
                        </Statistic.Value>
                        <Statistic.Label>{t('home.missed_revenues')}</Statistic.Label>
                    </Statistic>
                </div>

                <div css={css`
                    position: relative;
                `}>
                    <Dropdown
                        options={[{
                            text: t('home.failure.percent_order'),
                            value: 'EXPIRED_NB',
                            key: 'EXPIRED_NB'
                        }, {
                            text: t('home.failure.percent'),
                            value: 'EXPIRED',
                            key: 'EXPIRED'
                        }, {
                            text: t('home.failure.ca'),
                            value: 'CA',
                            key: 'CA'
                        }, {
                            text: t('home.failure.volume'),
                            value: 'VOLUME',
                            key: 'VOLUME'
                        }]}
                        value={type}
                        onChange={(evt, { value }) => setType(value)}
                    />

                    <ChartFailure type={type} data={data} />
                </div>
            </Card.Content>
        </Card>
    )
}

export default FailureHeaderChart;
