import { useState } from "react"
import { createContainer } from "unstated-next"
import { AUTH_TOKEN } from "../../constants"

const UserStore = () => {
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)

  const logout = () => {
    setUser({});
    localStorage.removeItem(AUTH_TOKEN);
  }

  return { 
    user, setUser,
    loading, setLoading,
    logout,
  }
}

export default createContainer(UserStore)