/** @jsxImportSource @emotion/react */
import { UltyInputText } from '@ulty/ulty-ui'
import { Button } from 'semantic-ui-react'
import { recipientStyles } from './recipientStyles'

const RecipientRemover = ({recipient, onRemove}) => {
    return (
      <div css={recipientStyles}>
          <UltyInputText
            readonly
            value={recipient}
          />
          <Button
            icon='minus'
            size='mini'
            onClick={() => onRemove(recipient)}
          />
      </div>
    )
}

export { RecipientRemover }
