/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React, { useEffect, useState } from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import OrderStore from './Orders/OrderStore';
import useUser from './Shared/UserProvider/useUser';
import { reopenMerchant } from '../services/PointOfSale/PointOfSale.service';
import { notify } from 'react-notify-toast';
import { isProviderAPos } from '../services/Provider/Provider.service';

const PlatformError = () => {
  const [t] = useTranslation();
  const {user} = useUser();
  const store = OrderStore.useContainer();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const reopen = async () => {
    setLoading(true);

    try {
      await reopenMerchant();
    } catch(e) {
      notify.show(`${t(`global.anErrorOccurred`)}`, 'error');
    }
    setShow(false);
    setLoading(false);
  }

  useEffect(() => {
    if (store.inError && isProviderAPos(user?.provider)) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [store.inError, user])

  return (
    <React.Fragment>
      { show && (
        <div css={css` 
          width: 100%; 
          height: 100%; 
          position: fixed; 
          top: 0; 
          left: 0; 
          background-color: #000; 
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 99999999;

          background-color: #ad0303;
          animation-name: color;
          animation-duration: 3s;
          animation-iteration-count: infinite;
          
          @keyframes color {
            0% {
              background-color: #ad0303;
            }
            50% {
              background-color: #571d1d;
            }
            100 {
              background-color: #ad0303;
            }
          }

          h2 {
            color: #fff !important;
          }
        `}>
          <Header as='h2' icon>
            <Icon name='warning sign' />
            {t('platform.error_header')}
          </Header>
          <div css={css`
            margin-top: 4em;
            margin-bottom: 4em;
          `}>
            <Button color="black" disabled={loading} loading={loading} onClick={reopen}>{t('platform.error_reopen')}</Button>
          </div>
          <div css={css`
            #cancel-reopen-btn {
              font-weight: bold;
              color: white !important;
              box-shadow: 0 0 0 1px #fff inset !important;
            }
          `}>
            <Button color="grey" id="cancel-reopen-btn" basic disabled={loading} loading={loading} onClick={() => setShow(false)}>{t('platform.do_not_reopen')}</Button>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

PlatformError.propTypes = {};

export default PlatformError;
