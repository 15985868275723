import {UltyDropdown} from '@ulty/ulty-ui';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import useFilters from '../useFilters';
import {getBrands} from '../../../../services/Brand/Brand.service';

const BrandFilter = () => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [brands, setBrands] = useState([]);
    const { filters, setFilters, debounceFiltersChange } = useFilters();

    useEffect(() => {
        (async function loadBrands() {
            setIsLoading(true);
            setBrands((await getBrands()) || []);
            setIsLoading(false);
        })();
    }, []);

    const handleSelectedBrandsChange = (selectedBrandIds) => {
        const newFilters = {
            ...filters,
            brandIds: selectedBrandIds
        };

        setFilters(newFilters);
        debounceFiltersChange(newFilters);
    }

    return (
        <UltyDropdown
            label={t('global.brands')}
            options={brands
                .map(brand => ({
                    text: brand.name,
                    key: brand.id,
                    value: brand.id
                }))}
            loading={isLoading}
            search
            multiple
            value={filters.brandIds}
            placeholder=' '
            noResultsMessage={t('ulty_dropdown.no_result_message')}
            onChange={(e, dp) => handleSelectedBrandsChange(dp.value)}
        />
    )
}

export default BrandFilter;
