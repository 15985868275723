/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {Card, Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const ExtraCardContentExtra = ({ inventory, linkTo }) => {
    return (
        <Card.Content extra css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        `}>
            <span>
                <Icon name='shop' /> {inventory.quantity}
            </span>

            {linkTo &&
            <Link as="a" to={linkTo} css={css`
                        cursor: pointer;
                        z-index: 900;
                    `}>
                <Icon name="eye" />
            </Link>}
        </Card.Content>
    )
}

ExtraCardContentExtra.propTypes = {
    inventory: PropTypes.object,
    linkTo: PropTypes.string,
    color: PropTypes.string
}

export default ExtraCardContentExtra;
