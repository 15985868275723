import React, { useEffect, useState, useCallback,useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUB_KEY } from '../../../constants';
import AdminBillingForm from './AdminBillingForm';
import { getBillingInfos } from '../../../services/Billing/Billing.service';
import { useTranslation } from 'react-i18next';
import { Message, Icon, Grid, Header, Segment } from 'semantic-ui-react';
import { notify } from 'react-notify-toast';
import {UltyInputText} from '@ulty/ulty-ui';
import AdminBillingInvoices from './AdminBillingInvoices';
import useUser from '../../Shared/UserProvider/useUser';
import slugify from 'slugify';



const stripeFactory = () => {
    let stripePromise = null;
    return () => {
        if(!stripePromise){
            stripePromise = loadStripe(STRIPE_PUB_KEY);
        }
        return stripePromise;
    } 
}

const stripeLoader = stripeFactory();

const AdminBilling = () => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(true);
    const [billingDetails, setBillingDetails] = useState({});
    const { user } = useUser();

    const loadBilling = useCallback(async () => {
            setLoading(true);

            try {
                setBillingDetails(await getBillingInfos(user?.provider?.id));
            } catch (e) {
                notify.show(t('global.anErrorOccurred', 'error'));
            }

            setLoading(false);
        }, [t, user.provider.id]);

    useEffect(() => {
        loadBilling();
    }, [loadBilling]);

    const stripePromise = useMemo(stripeLoader,[]);

    return (
        <Segment>
            {loading && (
                <Message icon>
                    <Icon name='circle notched' loading />
                    <Message.Content>
                        <Message.Header>{t('global.loading')}</Message.Header>
                        {t('administration.billing.loading')}
                    </Message.Content>
                </Message>
            )}

            {!loading && !billingDetails?.id && (
                <Message icon>
                    <Icon name='question' />
                    <Message.Content>
                        {t('administration.billing.no_billing_details')}
                    </Message.Content>
                </Message>
            )}

            {billingDetails?.id && (
                <>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header className="form-header" as='h3' content={t('administration.billing.infos_header')} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <UltyInputText
                                    readonly={true}
                                    label={`${t('administration.billing.name')}:`}
                                    value={billingDetails.name}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <UltyInputText
                                    readonly={true}
                                    label={`${t('administration.billing.address')}:`}
                                    value={billingDetails.address}
                                />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <UltyInputText
                                    readonly={true}
                                    label={`${t('administration.billing.postalCode')}:`}
                                    value={billingDetails.postalCode}
                                />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <UltyInputText
                                    readonly={true}
                                    label={`${t('administration.billing.city')}:`}
                                    value={billingDetails.city}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <UltyInputText
                                    readonly={true}
                                    label={`${t('administration.billing.siret')}:`}
                                    value={billingDetails.siret}
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <UltyInputText
                                    readonly={true}
                                    label={`${t('administration.billing.vatNumber')}:`}
                                    value={billingDetails.vatNumber}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        { billingDetails.mandateUrl && (
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <UltyInputText
                                        readonly={true}
                                        label={`${t('administration.billing.mandate')}:`}
                                        value={(<a href={billingDetails.mandateUrl} target="_blank" rel="noopener noreferrer">{t('administration.billing.mandate_download')}</a>)}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        { billingDetails.billingNote && (
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    { billingDetails.billingNote.split(/\n/g).map((note, idx) => (
                                        <UltyInputText
                                            key={`${slugify(note)}-${idx}`}
                                            readonly={true}
                                            label={idx === 0 ? t('administration.billing.conditions') : ''}
                                            value={(<span>&bull;&nbsp;&nbsp;{note}</span>)}
                                        />
                                    ))}
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Elements stripe={stripePromise}>
                                    <AdminBillingForm billingDetails={billingDetails} reload={loadBilling} />
                                </Elements>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header className="form-header" as='h3' content={t('administration.billing.invoices_header')} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <AdminBillingInvoices invoices={billingDetails.invoices||[]} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            )}
        </Segment>
    );
}

export default AdminBilling;
