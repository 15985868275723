import {call, METHODS} from '../http-client';

const ROUTE_PREFIX = 'extras';

export const getExtra = async (itemId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${itemId}`);
    } catch (e) {
        console.error(`getExtra error: ${e}`);
        throw e;
    }
}

export const saveExtra = async (extra) => {
    try {
        if (extra.id === 0) {
            return await call(METHODS.POST, ROUTE_PREFIX, '', extra);
        } else {
            return await call(METHODS.PUT, ROUTE_PREFIX, `${extra.id}`, extra);
        }
    } catch (e) {
        console.error(`saveExtra error: ${e}`);
        throw e;
    }
}
