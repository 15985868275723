import { Checkbox, Form, Icon } from 'semantic-ui-react'
import { UltyDropdown } from '@ulty/ulty-ui'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { getMerchantsOfCompany } from '../../../../services/Company/Company.service'
import useUser from '../../../Shared/UserProvider/useUser'

const POSPicker = ({ posIds, onChange }) => {
    const [t] = useTranslation()
    const {user} = useUser()

    const {data: pointOfSales} = useQuery({
        queryKey: ['companies', user.companyId, 'pointofsales'],
        queryFn: () => getMerchantsOfCompany(user.companyId),
    })

    const areAllSelected = (posIds) => {
        if (!pointOfSales) {
            return false
        }

        return pointOfSales.length === posIds.length
    }

    return (
      <Form.Field>
          <label>
              <Icon name="map marker alternate"/>
              {t('reports.performance_configuration.POSPicker.point_of_sales')}
          </label>

          <Checkbox
            label={t('reports.performance_configuration.POSPicker.select_all')}
            disabled={!pointOfSales}
            checked={areAllSelected(posIds)}
            onChange={() => {
              onChange(areAllSelected(posIds) ? [] : pointOfSales.map(pos => pos.id))
          }} />

          <UltyDropdown
            loading={!pointOfSales}
            options={pointOfSales ?
              pointOfSales.map(pos => ({
                  text: pos.name,
                  key: pos.id,
                  value: pos.id,
              })) : []}
            multiple
            onChange={(e, {value}) => {
                onChange(value)
            }}
            value={posIds}
            noResultsMessage={t('ulty_dropdown.no_result_message')}
          />
      </Form.Field>
    )
}

export { POSPicker }
