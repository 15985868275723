/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useCallback, useMemo } from "react";
import { Button } from 'semantic-ui-react'
import { usePrinter } from "../../services/printer.service";
import { pulsing } from "../../../components/Shared/css/pulsing";
import { useTranslation } from 'react-i18next';

const TogglePrintOnNewOrder = ({}) => {
    const [t] = useTranslation()
    const {printerInfo,printingOptions,setPrintingOptions} = usePrinter();

    const handleToggle = useCallback(() => {
        setPrintingOptions(p => ({
            ...p,
            printOnNewOrder: !p.printOnNewOrder
        }));
    },[])

    const appliedClasses = useMemo(() => {
        if(!printingOptions.printOnNewOrder) return "";
        if(printerInfo.isConnected) {
            return "pulse ok"
        } else {
            return "pulse ko"
        }
    }, [printerInfo.isConnected, printingOptions.printOnNewOrder]);

    const appliedColor = useMemo(() => {
        if(!printingOptions.printOnNewOrder) return undefined;
        if(printerInfo.isConnected) {
            return "green"
        } else {
            return "orange"
        }
    }, [printerInfo.isConnected, printingOptions.printOnNewOrder]);

    return (<div css={css`${pulsing}`}>
        { printerInfo.isDeviceCompatible && (
        <Button className={appliedClasses}
            compact 
            icon="print" 
            color={appliedColor}
            onClick={handleToggle} content={t('printer.admin.printOnNewOrder_label')}/>
        )}
    </div>)
}

export {TogglePrintOnNewOrder}