import { createContext, useContext, useEffect, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { AUTH_TOKEN, SOCKET_URL } from '../constants'
import { useTranslation } from 'react-i18next'
import useUser from '../components/Shared/UserProvider/useUser'
import { isProviderAPos } from './Provider/Provider.service'

const SocketClientContext = createContext(undefined)

const SocketClientProvider = ({ children }) => {
    const [t] = useTranslation()
    const {user} = useUser()

    const [lastMessageId, setLastMessageId] = useState(null)

    const getConnectionInfos = () => {
        try {
            return `${window.navigator.connection?.type || 'unknown'}/${window.navigator.connection?.effectiveType || 'unknown'}/${window.navigator.connection?.downlink || 'unknown'}`
        } catch {
        }

        return 'unknown'
    }

    const [socketUrl, setSocketUrl] = useState(SOCKET_URL)
    const {
        sendJsonMessage,
        lastJsonMessage,
        readyState,
    } = useWebSocket(socketUrl, {
        reconnectAttempts: Infinity,
        reconnectInterval: 5000,
        shouldReconnect: (closeEvent) => {
            return !!localStorage.getItem(AUTH_TOKEN) && isProviderAPos(user?.provider)
        },

        queryParams: {
            token: localStorage.getItem(AUTH_TOKEN),
            version: process.env.REACT_APP_VERSION,
            appVersion: (window.Android?.getVersion()) || '',
            appUid: (window.Android?.getFid()) || '',
            connection: encodeURIComponent(getConnectionInfos()),
        },
    })

    const [listeners, setListeners] = useState([])

    useEffect(() => {
        if (lastJsonMessage && lastJsonMessage.event && lastJsonMessage.id !== lastMessageId) {
            setLastMessageId(lastJsonMessage.id);

            const event = lastJsonMessage.event;
            const [listener] = listeners.filter(l => l.event === event);
            if (listener) {
                listener.func(lastJsonMessage.content);
            }
        }
    }, [lastJsonMessage, listeners]);

    useEffect(() => {
        if(window.Android && window.Android.setIsSocketOpen){
            /* 2023.11.27 : ULTY-324 - HACK
            Some device seems to not be able to have notification when websocket is down
            probably because of battery management issues.
            Since Android Application (1.11.1 and 1.10.3-zebra) only trigger notification when App is NOT foreground (handle in Android)
            or Websocket are DOWN. We expect both a issue in "being foreground" categorization AND current websock state not being properly
            returned.

            This HACK ensures that Android notifications are triggered for every orders (because websocket status is set to close)
            This might cause double ringing issues, while the user doesn't click on stop
            */
            window.Android.setIsSocketOpen(false);
        }
    },[readyState])

    const on = (event, func) => {
        setListeners(prev => {
            const _state = prev.map(p => p).filter(p => p.event !== event)
            _state.push({
                event: event,
                func,
            })
            return _state
        })
    }

    const off = (event) => {
        setListeners(prev => prev.map(p => p).filter(p => p.event !== event))
    }

    const emit = (msg, content = {}) => {
        if (sendJsonMessage && readyState === ReadyState.OPEN) {
            sendJsonMessage({
                event: msg,
                content,
            })
        }
    }

    const disconnect = async () => {
        await new Promise((resolve) => {
            setListeners([])
            setSocketUrl(null)
            setTimeout(() => resolve(), 100)
        })
    }

    const connectionStatus = {
        [ReadyState.CONNECTING]: t('socket.connecting'),
        [ReadyState.OPEN]: t('socket.open'),
        [ReadyState.CLOSING]: t('socket.closing'),
        [ReadyState.CLOSED]: t('socket.closed'),
        [ReadyState.UNINSTANTIATED]: t('socket.uninstantiated'),
    }[readyState]


    return (
      <SocketClientContext.Provider value={{
          status: readyState,
          connectionStatus,
          on,
          off,
          emit,
          disconnect,
      }}>
          {children}
      </SocketClientContext.Provider>
    )
}

const useSocket = () => {
    const context = useContext(SocketClientContext)
    if (context === undefined) {
        throw new Error(`useSocket must be used within a SocketClientProvider`)
    }
    return context
}

export { SocketClientProvider, SocketClientContext, useSocket }

