import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Icon, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import ProductDetailsPlatformItem from './ProductDetailsPlatformItem';
import {getPosItemSyncStates} from '../../../services/Item/Item.service';

const ProductDetailsSynchronisation = ({ product }) => {
  const [t] = useTranslation();
    const [syncStates, setSyncStates] = useState([]);

    useEffect(() => {
        (async function loadOffDays() {
            setSyncStates(await getPosItemSyncStates(product.id));
        })();
    }, [product.id]);


  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('product_details.digitalMerchant')}</Table.HeaderCell>
            <Table.HeaderCell>{t('product_details.platform')}</Table.HeaderCell>
            <Table.HeaderCell>{t('product_details.price')}</Table.HeaderCell>
            <Table.HeaderCell>{t('product_details.stock')}</Table.HeaderCell>
            <Table.HeaderCell>{t('product_details.sync_state')}</Table.HeaderCell>
            <Table.HeaderCell>{t('product_details.sync_date')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {syncStates && syncStates.map(syncState =>
              <ProductDetailsPlatformItem key={`${syncState.platform.name}-${syncState.platform.id}`} pf={syncState} />)
          }

          {syncStates && syncStates.length === 0 && (
            <Table.Row warning>
              <Table.Cell textAlign="center" colSpan={6}>
                <Icon name='attention' />
                {t('product_details.unpublished')}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}

ProductDetailsSynchronisation.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductDetailsSynchronisation;
