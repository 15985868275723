/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useSocket } from '../../services/socket'

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { ReadyState } from 'react-use-websocket';
import { useTranslation } from 'react-i18next';

const SyncDot = () => {
  const [t] = useTranslation();
  const { status, connectionStatus } = useSocket()

  return (
    <div css={css`
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      width: 20px;
      padding: .92857143em 1.14285714em;

      .bubble {
        display: block; 
        position: absolute;
        cursor: pointer; 
      }
      
      .bubble:hover:after {
        background-color: red
      }
      
      .bubble:after {
        content: "";
        background-color: red;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        display: block;
        top: 1px;
        left: 1px;
      }
      
      .bubble .bubble-outer-dot {
        margin: 1px;
        display: block;
        text-align: center;
        opacity: 1;
        background-color: rgba(255, 0, 0, 0.4);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        -webkit-animation: bubble-pulse 1.5s linear infinite;
        -moz-animation: bubble-pulse 1.5s linear infinite;
        -o-animation: bubble-pulse 1.5s linear infinite;
        animation: bubble-pulse 1.5s linear infinite
      }
      
      .bubble .bubble-inner-dot {
        display: block;
        text-align: center;
        opacity: 1;
        background-color: rgba(255, 0, 0, 0.4);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        -webkit-animation: bubble-pulse 1.5s linear infinite;
        -moz-animation: bubble-pulse 1.5s linear infinite;
        -o-animation: bubble-pulse 1.5s linear infinite;
        animation: bubble-pulse 1.5s linear infinite
      }
      
      .bubble .bubble-inner-dot:after {
        content: "";
        display: block;
        text-align: center;
        opacity: 1;
        background-color: rgba(255, 0, 0, 0.4);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        -webkit-animation: bubble-pulse 1.5s linear infinite;
        -moz-animation: bubble-pulse 1.5s linear infinite;
        -o-animation: bubble-pulse 1.5s linear infinite;
        animation: bubble-pulse 1.5s linear infinite
      }

      .status-ok {
        &.bubble:hover:after {
          background-color: green
        }
        
        &.bubble:after {
          background-color: green;
        }
        
        &.bubble .bubble-outer-dot {
          background-color: rgba(0, 255, 0, 0.4);
        }
        
        &.bubble .bubble-inner-dot {
          background-color: rgba(0, 255, 0, 0.4);
        }
        
        &.bubble .bubble-inner-dot:after {
          background-color: rgba(0, 255, 0, 0.4);
        }
      }

      .status-connecting {
        &.bubble:hover:after {
          background-color: orange
        }
        
        &.bubble:after {
          background-color: orange;
        }
        
        &.bubble .bubble-outer-dot {
          background-color: rgba(0, 255, 0, 0.4);
        }
        
        &.bubble .bubble-inner-dot {
          background-color: rgba(0, 255, 0, 0.4);
        }
        
        &.bubble .bubble-inner-dot:after {
          background-color: rgba(0, 255, 0, 0.4);
        }
      }
      
      @-webkit-keyframes bubble-pulse {
        0% {
          transform: scale(1);
          opacity: .75
        }
        25% {
          transform: scale(1);
          opacity: .75
        }
        100% {
          transform: scale(2.5);
          opacity: 0
        }
      }
      
      @keyframes bubble-pulse {
        0% {
          transform: scale(1);
          opacity: .75
        }
        25% {
          transform: scale(1);
          opacity: .75
        }
        100% {
          transform: scale(2.5);
          opacity: 0
        }
      }
      
      @-moz-keyframes bubble-pulse {
        0% {
          transform: scale(1);
          opacity: .75
        }
        25% {
          transform: scale(1);
          opacity: .75
        }
        100% {
          transform: scale(2.5);
          opacity: 0
        }
      }
      
      @-o-keyframes bubble-pulse {
        0% {
          transform: scale(1);
          opacity: .75
        }
        25% {
          transform: scale(1);
          opacity: .75
        }
        100% {
          transform: scale(2.5);
          opacity: 0
        }
      }
      
    `}>
      <Tippy content={`${t('navbar.sync_state')} ${connectionStatus}`}>
        <div className={`bubble ${status === ReadyState.OPEN ? 'status-ok' : (status === ReadyState.CONNECTING ? 'status-connecting' : '')}`}>
          <span className="bubble-outer-dot">
            <span className="bubble-inner-dot" />
          </span>
        </div>
      </Tippy>
    </div>
  )
}

export default SyncDot
