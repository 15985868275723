import { Placeholder, PlaceholderLine, PlaceholderParagraph, Segment } from 'semantic-ui-react'
import React from 'react'

const PerformanceReportConfigurationPlaceholder = () => {
    return (
      <Segment>
          <Placeholder>
              <PlaceholderParagraph>
                  <PlaceholderLine />
                  <PlaceholderLine />
              </PlaceholderParagraph>
          </Placeholder>
      </Segment>
    )
}

export { PerformanceReportConfigurationPlaceholder }
