import mime from 'mime';


export const base64ToFile = async (base64Data, fileName, mimeType) => {
    const res = await fetch(base64Data);
    const blob = await res.blob();
    const extension = mime.getExtension(mimeType);
    return new File([blob], `item-image.${extension}`, { type: mimeType });
}

export const saveFile = (name, content, mimeType) => {
    const blob = new Blob([content], { type: mimeType });
    downloadFile(`${name}.${mime.getExtension(mimeType)}`, blob);
}

const downloadFile = (name, content) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.download = name;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
