import React, { useEffect } from 'react'

import { AUTH_TOKEN } from '../../constants';
import { checkUser as checkUserService } from '../../services/User/User.service';
import Store from './store';
import { Dimmer, Transition } from 'semantic-ui-react';

import { ClockLoader } from 'react-spinners'
import useUser from '../Shared/UserProvider/useUser';

const EnsureLogin = ({ location, history, children }) => {
  const store = Store.useContainer();
  const {user, setUser} = useUser();

  const checkUser = async () => {
    let token = localStorage.getItem(AUTH_TOKEN);
    if (!token) {
      history.push('/auth/login');
      return;
    }

    if (typeof user.id === 'undefined' || !user.id) {
      try {
        const res = await checkUserService();
        if(res?.user){
          setUser(res.user);
          store.setLoading(false);
  
          return;

        }
      } catch (e) { }

      localStorage.removeItem(AUTH_TOKEN);

      store.setLoading(false);
      history.push('/auth/login');
    }

    store.setLoading(false)
  }

  useEffect(() => {
    if (location.pathname.indexOf('/auth/') !== 0) {
      checkUser()
    } else {
      store.setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Dimmer.Dimmable as="div" dimmed={store.loading} style={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
       }}>
        <Transition animation="fade" visible={store.loading} duration={500}>
          <React.Fragment>
            {!store.loading && (
              <>{children}</>
            )}
            <Dimmer active={store.loading}>
              <ClockLoader
                sizeUnit={"px"}
                height={50}
                width={4}
                radius={50}
                color={'#00b5ad'}
                loading={store.loading}
              />
            </Dimmer>
          </React.Fragment>
        </Transition>
      </Dimmer.Dimmable>
    </React.Fragment>
  )
}

export default EnsureLogin
