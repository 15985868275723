/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {useCallback, useEffect, useState} from 'react';
import {Card, Divider, Form, Grid, Segment, Header, Button, Icon} from 'semantic-ui-react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {getMetricsOrders, getMetricsToday, getMetricsTop} from '../../services/Metrics/Metrics.service';
import HeaderChart from './HeaderChart';
import DownloadMetrics from './DownloadMetrics';
import DataTable from './DataTable';
import TopProducts from './TopProducts';
import HourChart from './HourChart';
import DailyChart from './DailyChart';
import { useTranslation } from 'react-i18next';
import useBreadcrumb from '../NavBar/useBreadcrumb';
import AdvancedFilters from './AdvancedFilters';
import useUser from '../Shared/UserProvider/useUser';
import moment from 'moment';
import PosBrandHeaderChart from './PosBrandHeaderChart';
import CurrentDayChart from './CurrentDayChart';
import { getCancellationSource } from '../../services/http-client';

const Revenues = ({ handleFiltersChange, defaultFilters }) => {
  const [t] = useTranslation();
  const { setPaths } = useBreadcrumb();
  const [loading, setLoading] = useState(true);

  const [dataNbOrders, setDataNbOrders] = useState([]);
  const [dataAvgOrders, setDataAvgOrders] = useState([]);
  const [dataRevenues, setDataRevenues] = useState([]);
  const [dataDaily, setDataDaily] = useState([]);
  const [dataBrands, setDataBrands] = useState([]);
  const [dataPlatforms, setDataPlatforms] = useState([]);
  const [dataHour, setDataHour] = useState([]);
  const [dataCurrentDay, setDataCurrentDay] = useState([]);
  const [comparison, setComparison] = useState({});

  const [dataTop, setDataTop] = useState([]);
  const [loadingDataTop, setLoadingDataTop] = useState(true);
  
  const [localFilters, setLocalFilters] = useState({
    merchant: defaultFilters.merchant,
    brands: defaultFilters.brands,
    platforms: defaultFilters.platforms,
    dateRange: {
      startDate: moment(defaultFilters.dateRange.startDate).utc(),
      endDate: moment(defaultFilters.dateRange.endDate).utc()
    },
    dateComparison: {
      startDate: defaultFilters.dateComparison.startDate && moment(defaultFilters.dateComparison.startDate).utc(),
      endDate: defaultFilters.dateComparison.endDate && moment(defaultFilters.dateComparison.endDate).utc()
    }
  });

  const localHandleFiltersChange = (filters) => {
    setLocalFilters(prev => ({
      ...prev,
      ...filters,
    }));
  }

  useEffect(() => {
    handleFiltersChange(localFilters);
  }, [localFilters, handleFiltersChange]);

  const {user} = useUser();

  const loadTopMetrics = useCallback(async (filters,cancellationSource) => {

    setLoadingDataTop(true);
    const topData = await getMetricsTop({...filters},cancellationSource)
    
    if(cancellationSource?.isCancelled) return;

    setDataTop({
      topCA: topData.topCA,
      top: topData.top,
    });

    setLoadingDataTop(false);


  })

  const loadMetrics = useCallback(async (cancellationSource) => {
    setLoading(true);

    const dateRange = {
      startDate: moment(defaultFilters.dateRange.startDate).utc(),
      endDate: moment(defaultFilters.dateRange.endDate).utc()
    };

    const dateComparison = {
      startDate: defaultFilters.dateComparison.startDate ? moment(defaultFilters.dateComparison.startDate).utc() : null,
      endDate: defaultFilters.dateComparison.endDate ? moment(defaultFilters.dateComparison.endDate).utc() : null
    };

    const baseFilters = {
      dateRange:{
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString()
      },
      merchantId: defaultFilters.merchantId,
      brandIds: defaultFilters.brandIds,
      platformIds: defaultFilters.platformIds,
  };

    const dateComparisonParam = {
      startDate: dateComparison.startDate&&dateComparison.startDate.toISOString(),
      endDate: dateComparison.endDate&&dateComparison.endDate.toISOString()
    }

    const orderPromise = getMetricsOrders({
     ...baseFilters
    },cancellationSource);

    const todayPromise = getMetricsToday({
      ...baseFilters
     },cancellationSource);

    const [orderData,todayData] = await Promise.all([orderPromise,todayPromise]);

    if(cancellationSource?.isCancelled) return;

    setComparison(null)
    setDataAvgOrders(orderData.avgOrders)
    setDataNbOrders(orderData.nbOrders)
    setDataRevenues(orderData.revenues)
    setDataBrands(orderData.brands)
    setDataPlatforms(orderData.platforms)
    setDataDaily(orderData.byDate)
    setDataHour(orderData.byHour);
   
    setDataCurrentDay(todayData.todayArray || []);

    setLoading(false);

    
    if(dateComparisonParam.startDate && dateComparison.endDate){
      const sd = baseFilters.dateRange.startDate,
      sdc = dateComparison.startDate;

      getMetricsOrders({
        ...baseFilters,
        dateRange:{...dateComparisonParam}
      },cancellationSource).then((comparisonData) =>{

        if(cancellationSource?.isCancelled) return;

        const range =  moment(sd).diff(moment(sdc), 'days');
        setComparison({range,...comparisonData})
      })
    } 

    loadTopMetrics({...baseFilters},cancellationSource);

  }, [defaultFilters.dateRange.startDate, defaultFilters.dateRange.endDate, defaultFilters.merchantId, defaultFilters.brandIds, defaultFilters.platformIds, defaultFilters.dateComparison?.startDate, defaultFilters.dateComparison?.endDate]);

  useEffect(() => {
    setPaths([{
      text: t('breadcrumb.home'),
      link: false,
      path: '/'
    }]);
  }, [setPaths, t]);

  useEffect(() => {
    const cancellationSource = getCancellationSource();
    loadMetrics(cancellationSource);

    return () => cancellationSource.cancel();
  }, [loadMetrics]);

  const getAvgTotal = (dataRevenues, dataNbOrders) => {
    const rev = dataRevenues.reduce((total, dataRevenue) => total + dataRevenue.y, 0);
    const orders = dataNbOrders.reduce((total, dataNbOrder) => total + dataNbOrder.y, 0);
    return orders === 0 ? 0 : Math.round(rev / orders);
  }

  const getTotal = () => {
    return +dataRevenues.reduce((total, dataRevenue) => total + dataRevenue.y, 0);
  }

  const chartContainerStyle = css`
    padding-left: 0 !important;
    padding-right: 0 !important;
  `;

  return (
    <React.Fragment>
      <Segment>
        <div css={css`
          display: flex;
          justify-content: space-between;
        `}>
          <AdvancedFilters
            defaultFilters={{
              merchant: defaultFilters.merchant,
              brands: defaultFilters.brands,
              platforms: defaultFilters.platforms,
            }}
            onFiltersChange={localHandleFiltersChange}>
            <Form css={css` display: flex;`}>
              <Form.Group widths="equal">
                {user.provider.type === 'COMPANY' && <AdvancedFilters.PosFilter />}
                <AdvancedFilters.BrandFilter />
                <AdvancedFilters.PlatformFilter />
              </Form.Group>
            </Form>
          </AdvancedFilters>
        </div>
      </Segment>
      <Segment loading={loading} color="teal" >
        <div css={css`
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justofy-content: flex-start;
        `}>
          <Header css={css`flex: 1;`} as='h4' dividing>
            {t('home.header_today')}
          </Header>
          <div css={css`
            margin-left: 1em;
          `}>
            <Button 
              size="mini"
              color="teal"
              icon
              loading={loading}
              disabled={loading}
              onClick={() => loadMetrics()}
            >
              <Icon name="refresh" />
            </Button>
          </div>
        </div>
        <Grid css={chartContainerStyle}>
          <Grid.Column width={16}>
            <CurrentDayChart loading={loading} data={dataCurrentDay} />
          </Grid.Column>
        </Grid>
      </Segment>

      <Divider />

      <Segment loading={loading} color='teal' >
        <Header as='h4' dividing>
        {t('home.header_history')}
        </Header>
        <div css={css`
          display: flex;
          justify-content: space-between;
          margin-bottom: 2rem;
        `}>
          <AdvancedFilters
            defaultFilters={{
              dateRange: {
                startDate: moment(defaultFilters.dateRange.startDate).utc(),
                endDate: moment(defaultFilters.dateRange.endDate).utc()
              },
              dateComparison: {
                startDate: defaultFilters.dateComparison.startDate && moment(defaultFilters.dateComparison.startDate).utc(),
                endDate: defaultFilters.dateComparison.endDate && moment(defaultFilters.dateComparison.endDate).utc()
              }
            }}
            onFiltersChange={localHandleFiltersChange}>
            <AdvancedFilters.DateRangeFilter includeComparaison />
          </AdvancedFilters>
          <DownloadMetrics
            css={css`
                  align-self: center;
                  margin-left: 1rem !important;
                `}
            dateRange={{
              startDate: moment(defaultFilters.dateRange.startDate).utc(),
              endDate: moment(defaultFilters.dateRange.endDate).utc()
            }}
            merchantId={defaultFilters.merchantId}
            brands={defaultFilters.brandIds}
            platformIds={defaultFilters.platformIds}
            type={"REVENUES"}
          />
        </div>

        <Card.Group centered itemsPerRow={3} stackable>
         
              <PosBrandHeaderChart
                type="CURRENCY"
                label={t('home.revenues')}
                data={dataRevenues}
                dataBrands={dataBrands?.map(br => ({
                  x: br.name,
                  y: parseInt(br.revenues || '0')
                })) ?? []}
                dataPlatforms={dataPlatforms?.map(p => ({
                  x: p.name,
                  y: parseInt(p.revenues || '0')
                })) ?? []}
                total={getTotal()}
                dataComparison={!!defaultFilters.dateComparison.startDate}
                totalComparison={+(comparison?.revenues || []).reduce((total, dataRevenue) => total + dataRevenue.y, 0)}
                loading={loading}
              />
              <PosBrandHeaderChart
                type="NUMERIC"
                label={t('home.number_order')}
                data={dataNbOrders}
                dataBrands={dataBrands?.map(br => ({
                  x: br.name,
                  y: parseInt(br.nborder || '0')
                })) ?? []}
                dataPlatforms={dataPlatforms?.map(p => ({
                  x: p.name,
                  y: parseInt(p.nborder || '0')
                })) ?? []}
                total={dataNbOrders.reduce((total, dataNbOrder) => total + dataNbOrder.y, 0)}
                dataComparison={!!defaultFilters.dateComparison.startDate}
                totalComparison={(comparison?.nbOrders || []).reduce((total, dataNbOrder) => total + dataNbOrder.y, 0)}
                loading={loading}
              />
              <PosBrandHeaderChart
                type="CURRENCY"
                label={t('home.average_order')}
                data={dataAvgOrders}
                dataBrands={dataBrands?.map(br => ({
                  x: br.name,
                  y: parseInt(br.avgorder || '0')
                })) ?? []}
                dataPlatforms={dataPlatforms?.map(p => ({
                  x: p.name,
                  y: parseInt(p.avgorder || '0')
                })) ?? []}
                total={getAvgTotal(dataRevenues, dataNbOrders)}
                dataComparison={!!defaultFilters.dateComparison.startDate}
                totalComparison={getAvgTotal((comparison?.revenues || []), (comparison?.nbOrders || []))}
                loading={loading}
              />
        </Card.Group>

        <Grid css={chartContainerStyle}>
          <Grid.Column width={16}>
            <DailyChart loading={loading} data={dataDaily} dataComparison={comparison ? (comparison?.byDate || []) : null} range={comparison?.range} />
          </Grid.Column>
        </Grid>

        <Grid css={chartContainerStyle}>
          <Grid.Column computer={16} mobile={16}>
            <HourChart loading={loading} data={dataHour} />
          </Grid.Column>
        </Grid>

        <Grid css={chartContainerStyle}>
          <Grid.Column computer={10} mobile={16} tablet={16}>
            <DataTable dataDaily={dataDaily} filters={defaultFilters} />
          </Grid.Column>
          <Grid.Column computer={6} mobile={16} tablet={16} css={css` padding-left: 0 !important; `}>
            <Segment loading={loadingDataTop}>
              <TopProducts dataTop={dataTop} filters={defaultFilters} />
            </Segment>
          </Grid.Column>
        </Grid>
      </Segment>
    </React.Fragment>
  )
}

export default Revenues;
