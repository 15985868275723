/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {Fragment, useRef, useState} from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import './ImageEditor.css'
import {Button, Form} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import {notify} from 'react-notify-toast';
import PropTypes from 'prop-types';
import {UltyDropdown} from '@ulty/ulty-ui';


export const ImageEditor = ({ initialImage, onImageChange, afterSubmit }) => {
    const [t] = useTranslation();
    const [imageData, setImageData] = useState(initialImage || '');
    const [fileType, setFileType] = useState('');
    const [selectedAspectRatio, setSelectedAspectRatio] = useState(16/9);
    const [selectedDragMode, setSelectedDragMode] = useState('crop');
    const cropperRef = useRef(null);
    const [cropper, setCropper] = useState();
    const [selectedPlatformPreview, setSelectedPlatformPreview] = useState('ratio11');
    const [dataUrl, setDataUrl] = useState('');

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        if (files && files[0] && !files[0].type.match(/image-*/)) {
            notify.show(t('imageEditor.onlyImageFiles'), 'error');
            return;
        }

        setFileType(files[0].type);

        const reader = new FileReader();
        reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            setImageData(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = async () => {
        if (typeof cropper !== "undefined") {
            try {
                const data = cropper.getCroppedCanvas().toDataURL();
                onImageChange({ data, type: fileType });
                afterSubmit();
            } catch(e) {
                console.log(e);
            }
        }
    };

    const getCustomPreview = () => {
        try {
            const data = cropperRef.current.cropper.getCroppedCanvas()
                .toDataURL();
            setDataUrl(data);
        } catch (e) {
            console.log(e);
        }
    }

    const fileInputRef = useRef(null);

    const setAspectRatio = (ratio) => {
        const setFreeAspectRatio = () => {
            setSelectedAspectRatio(NaN);
            cropper.setAspectRatio(NaN);
        }

        if(selectedAspectRatio === ratio)  {
            setFreeAspectRatio();
        } else {
            setSelectedAspectRatio(ratio);
            cropper.setAspectRatio(ratio);
        }

        getCustomPreview();
    }

    const getCssFromRatio = (ratio) => {
        switch (ratio) {
            case 'ratio169':
                return 'height: 200px; aspect-ratio: 16/9';
            case 'ratio11':
            case 'ratio125':
            default:
                return 'width: 300px; height: 300px; aspect-ratio: 1/1;';
        }
    }

    const handlePlatformPreviewChange = (e, {value}) => {
        getCustomPreview();
        setSelectedPlatformPreview(value);
    };

    const handleCropEnd = () => {
        getCustomPreview();
    };

    const handleZoom = (e) => {
        if (!e.detail.originalEvent) {
            return;
        }

        const isMouseUp = () => e.detail.originalEvent.deltaY > 0;
        isMouseUp() ? handleZoomOut() : handleZoomIn();
    }

    const handleZoomIn = () => {
        cropperRef.current.cropper.zoom(.1);
        getCustomPreview();
    };

    const handleZoomOut = () => {
        cropperRef.current.cropper.zoom(-.1);
        getCustomPreview();
    };

    return (
        <Form>
            <div css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 1rem;
            `}>
                <div>
                    <input
                        ref={fileInputRef}
                        type="file"
                        hidden
                        onChange={onChange}
                        accept="image/*"
                    />
                    <Button
                        icon='upload'
                        title={t('imageEditor.uploadFile')}
                        onClick={() => fileInputRef.current.click()}
                    />
                </div>

                <div css={css`
                  display: flex;
                  margin: 0 1rem;
                  justify-content: center;
                `}>
                    <Button
                        {...(selectedDragMode === 'move' ? {color: 'teal'} : {})}
                        icon='move'
                        disabled={!imageData}
                        title={t('imageEditor.moveMode')}
                        onClick={() => {
                            setSelectedAspectRatio(NaN);
                            setSelectedDragMode('move');
                            cropper.setDragMode('move');
                            cropper.clear();
                        }}
                    />

                    <Button
                        {...(selectedDragMode === 'crop' ? {color: 'teal'} : {})}
                        icon='crop'
                        disabled={!imageData}
                        title={t('imageEditor.cropMode')}
                        onClick={() => {
                            setSelectedDragMode('crop');
                            cropper.setDragMode('crop');
                            setAspectRatio(16/9);
                            cropper.crop();
                        }}
                    />

                    <Button
                        icon='zoom-in'
                        disabled={!imageData}
                        title={t('imageEditor.zoomIn')}
                        onClick={handleZoomIn}
                    />

                    <Button
                        icon='zoom-out'
                        disabled={!imageData}
                        title={t('imageEditor.zoomOut')}
                        onClick={handleZoomOut}
                    />

                    <Button
                        icon='undo'
                        disabled={!imageData}
                        title={t('imageEditor.rotate90CounterClockwise')}
                        onClick={() => {
                            cropper.rotate(-90);
                            getCustomPreview();
                        }}
                    />

                    <Button
                        icon='redo'
                        disabled={!imageData}
                        title={t('imageEditor.rotate90Clockwise')}
                        onClick={() => {
                            cropper.rotate(90);
                            getCustomPreview();
                        }}
                    />

                    <div css={css`
                      margin: 0 1rem;
                    `}>
                        <Button
                            title={t('imageEditor.aspectRatio169')}
                            disabled={!imageData}
                            {...(selectedAspectRatio === 16/9 ? {color: 'teal'} : {})}
                            onClick={() => setAspectRatio(16/9)}
                        >16:9</Button>

                        <Button
                            title={t('imageEditor.aspectRatio43')}
                            disabled={!imageData}
                            {...(selectedAspectRatio === 4/3 ? {color: 'teal'} : {})}
                            onClick={() => setAspectRatio(4/3)}
                        >4:3</Button>

                        <Button
                            title={t('imageEditor.aspectRatio1')}
                            disabled={!imageData}
                            {...(selectedAspectRatio === 1 ? {color: 'teal'} : {})}
                            onClick={() => setAspectRatio(1)}
                        >1:1</Button>
                    </div>
                </div>

                <Button
                    title={t('global.validate')}
                    icon='check'
                    color='teal'
                    disabled={!imageData}
                    onClick={getCropData}
                />
            </div>

            <div className="layout">
                <Cropper
                    className="cropper"
                    ref={cropperRef}
                    style={{ height: '80vh', maxWidth: '90%' }}
                    zoomTo={0}
                    initialAspectRatio={16/9}
                    aspectRatio={16/9}
                    preview=".img-preview"
                    src={imageData}
                    viewMode={0}
                    guides={true}
                    minCropBoxHeight={50}
                    minCropBoxWidth={50}
                    modal={true}
                    background={true}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => { setCropper(instance); }}
                    ready={getCustomPreview}
                    cropend={handleCropEnd}
                    zoom={handleZoom}
                />

                {imageData &&
                    <Fragment>
                        <div className="preview">
                            <div className="img-preview"/>
                        </div>

                        <div className="platform-preview">
                            <UltyDropdown
                                css={css`width: 280px;`}
                                label={`${t('imageEditor.platform_preview')}:`}
                                clearable
                                options={[
                                    {key: 1, value: 'ratio11', text: t('imageEditor.platform_preview_catalog')},
                                    {key: 2, value: 'ratio169', text: t('imageEditor.platform_preview_detail')},
                                    {key: 3, value: 'ratio125', text: t('imageEditor.platform_preview_detail_app_mobile')}
                                ]}
                                onChange={handlePlatformPreviewChange}
                                value={selectedPlatformPreview}
                            />

                            <div css={css`
                      margin: auto;
                      border: 1px solid grey;
                      overflow: hidden;
                      ${getCssFromRatio(selectedPlatformPreview)}
                    `}>
                                {selectedPlatformPreview &&
                                <img
                                    css={css`
                              ${getCssFromRatio(selectedPlatformPreview)}
                              ${selectedPlatformPreview === 'ratio125' && 'transform: scale(1.25);'}
                            `}
                                    src={dataUrl || ''}
                                    alt="platform preview"
                                />
                                }
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
        </Form>
    );
};

ImageEditor.propTypes = {
    initialImage: PropTypes.string,
    onImageChange: PropTypes.func,
    afterSubmit: PropTypes.func,
}

export default ImageEditor;
