import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Divider, Grid, Header, List, Segment} from 'semantic-ui-react';
import ItemElement from './ItemElement';
import {useTranslation} from 'react-i18next';

const initialNewItemElement = {
    id: 0,
    name: '',
    description: '',
    minSelection: 0,
    maxSelection: 1,
    repeatable: false
};

const ItemElements = ({ itemTypeContext, itemElements, onChangeItemElementField, onChangeItemElementItems, onChangeItemElementPosition, onAdd, onDelete }) => {
    const [t] = useTranslation();
    const [newItemElement, setNewItemElement] = useState(initialNewItemElement);
    const newItemElementHeader = itemTypeContext === 'MENU' ?
        t('menu_edit.add_menu_element') :
        t('product_details.modifiers.add_product_element');

    const itemElementItemTypes = () => {
        if (itemTypeContext === 'MENU') {
            return ['PRODUCT'];
        }
        if (itemTypeContext === 'PRODUCT') {
            return ['EXTRA']
        }
        return ['EXTRA', 'MENU', 'PRODUCT']
    }

    return (
        <>
            <List>
                {itemElements?.map((itemElement, idx) =>
                    <List.Item key={itemElement.id}>
                        <List.Content>
                            <Segment>
                                <ItemElement
                                    itemElement={itemElement}
                                    position={idx}
                                    maxPosition={itemElements.length}
                                    itemElementItemTypes={itemElementItemTypes()}
                                    onChangeItemElementField={onChangeItemElementField ? (field, value) => onChangeItemElementField(itemElement.id, field, value) : null}
                                    onChangeItemElementItems={onChangeItemElementItems ? (items) => onChangeItemElementItems(itemElement.id, items) : null}
                                    onChangeItemElementPosition={onChangeItemElementPosition ? (value) => onChangeItemElementPosition(itemElement.id, value) : null}
                                    onDelete={onDelete ? () => onDelete(itemElement.id) : null}
                                />
                            </Segment>
                        </List.Content>
                    </List.Item>
                )}
            </List>

            {onAdd && <>
                <Divider />

                <Segment>
                    <Header as='h3'>{newItemElementHeader}</Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <ItemElement
                                    itemElement={newItemElement}
                                    itemElementItemTypes={itemElementItemTypes()}
                                    canAddItem={false}
                                    onAdd={(itemElement) => onAdd(itemElement)}
                                    onChangeItemElementField={(field, value) => setNewItemElement({ ...newItemElement, [field]: value }) }
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </>}
        </>
    )
}

ItemElements.propTypes = {
    itemTypeContext: PropTypes.string.isRequired,
    itemElements: PropTypes.array,
    onChangeItemElementField: PropTypes.func,
    onChangeItemElementItems: PropTypes.func,
    onChangeItemElementPosition: PropTypes.func,
    onDelete: PropTypes.func,
}

export default ItemElements;
