/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {useEffect, useState, Fragment, useCallback } from 'react';
import {Dropdown, Icon, Loader, Menu, Tab} from 'semantic-ui-react';
import CategoryEdit from './CategoryEdit';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import { getCategories, updateCategoryPosition } from '../../services/Category/Category.service';
import useUser from '../Shared/UserProvider/useUser';
import { UltyModalWrapperContext } from '../Shared/UltyModalWrapper/UltyModalWrapperContext';
import CategoryModal from './CategoryModal';
import CategoryModifiedModal from './CategoryModifiedModal';
import useModified from './useModified';

const menuItemStyles = css`
        color: teal;
        &:hover {
            color: grey;
        `;
const loaderTabStyles = css``;

const CategoryMenuItemContent = ({ category, position, maxPosition, isLoading, onCategoryPositionChange, isParent }) => {
    const handleCategoryPositionChange = async (e, category, position) => {
        e.stopPropagation();
        onCategoryPositionChange(category, position);
    }

    return (
        <div css={css`
            display: flex;
            align-items: center;
        `}>
            <span css={css`flex: 1;`}>{category.name}</span>

            <div css={css`min-height: 21px;`}>
                {isParent && (
                    <Icon name="ban" />
                )}

                {!isParent && isLoading && (
                    <Loader active size = 'tiny' inline = 'centered' css = { loaderTabStyles } />
                )}

                {!isParent && !isLoading && (
                    <Fragment>
                        <Dropdown scrolling options={[...Array(maxPosition).keys()].map(p => ({
                            text: `${p + 1}`,
                            value: p
                        }))} value={position} onChange={(evt, { value }) => {
                            handleCategoryPositionChange(evt, category, value);
                        }} />
                    </Fragment>
                )}

            </div>
        </div>
    );
}

CategoryMenuItemContent.propTypes = {
    category: PropTypes.object.isRequired,
    position: PropTypes.number.isRequired,
    maxPosition: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    onCategoryPositionChange: PropTypes.func.isRequired
};

const CategoryList = ({ setId, slot }) => {
    const {user, canCreateCategories, canExtendCategories} = useUser();
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [canAdd, setCanAdd] = useState(true);
    const [panes, setPanes] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const { modified, setModified } = useModified();

    const { handleUltyModalWrapper } = React.useContext(UltyModalWrapperContext);
    const defineImportModal = () => {
        const modalSettings = {
            title: t('category_list.import_selection'),
            component: <CategoryModal
                currentSet={setId}
                currentSlot={slot.id}
                afterSubmit={() => {
                    loadCategories();
                    handleUltyModalWrapper(false, null);
                }}
            />
        };
        handleUltyModalWrapper(true, modalSettings);
    };

    const defineConfirmModal = (callback) => {
        const modalSettings = {
            title: t('categories.confirm_exit'),
            component: <CategoryModifiedModal
                afterSubmit={(result) => {
                    handleUltyModalWrapper(false, null);

                    if (result) {
                        setModified(false);
                        callback();
                    }
                }}
            />
        };
        handleUltyModalWrapper(true, modalSettings);
    };

    useEffect(() => {
        loadCategories();
    }, [slot]);

    const handleSaveCategory = async () => {
        await loadCategories(false);
        setCanAdd(true);
    }

    const handleSaveCategoryCallback = useCallback(handleSaveCategory, [activeIndex, canAdd, setId, slot]);

    const handleDeleteCategory = async () => {
        setActiveIndex(activeIndex === 0 ? 0 : activeIndex - 1);
        await loadCategories(false);
        setCanAdd(true);
    }

    const handleDeleteCategoryCallback = useCallback(handleDeleteCategory, [activeIndex, canAdd, setId, slot]);

    const handleCategoryPositionChange = async (category, position) => {
        const callback = async () => {
            setIsLoading(true);
            await updateCategoryPosition(setId, slot.id, {
                ...category,
                position,
            });
            await loadCategories(false);
            setIsLoading(false);
        };

        if (modified) {
            defineConfirmModal(callback);
        } else {
            callback();
        }

    }

    const handleCategoryPositionChangeCallback = useCallback(handleCategoryPositionChange, [setId, slot, activeIndex, canAdd]);

    useEffect(() => {
        const panes = categories.map(c => ({
            menuItem: (
                <Menu.Item key={c.id}>
                    <CategoryMenuItemContent
                        category={c}
                        position={categories.filter(c => c.slot.providerId === user.provider.id).map(c => c.id).indexOf(c.id)}
                        maxPosition={categories.filter(c => c.slot.providerId === user.provider.id).length}
                        isLoading={isLoading}
                        onCategoryPositionChange={handleCategoryPositionChangeCallback}
                        isParent={c.slot.providerId !== user.provider.id}
                    />
                </Menu.Item>
            ),
            render: () => <Tab.Pane>
                <CategoryEdit
                    setId={setId}
                    slotId={slot.id}
                    category={c}
                    onSaveCategory={handleSaveCategoryCallback}
                    onDeleteCategory={handleDeleteCategoryCallback}
                    isParent={c.slot.providerId !== user.provider.id}
                />
            </Tab.Pane>
        }));

        if (canExtendCategories() || canCreateCategories()) {
            if (canAdd) {
                panes.push({
                    menuItem: (
                        <Menu.Item key={'add-category-tab'}>
                            <div css={css`
                                display: flex;
                                align-items: center;
                            `}>
                                <span css={css`flex: 1;`}>{t('category_list.new_category')}</span>

                                <div css={css`min-height: 21px;`}>
                                    {isLoading
                                    ? <Loader active size='tiny' inline='centered' css={loaderTabStyles} />
                                    : <Icon name={'plus square'} size={'large'} css={menuItemStyles} />}
                                </div>
                            </div>
                        </Menu.Item>
                    ),
                    render: () => null
                });
            } else {
                panes.push({
                    menuItem: t('category_list.new_category'),
                    render: () => <Tab.Pane>
                        <CategoryEdit
                            setId={setId}
                            slotId={slot.id}
                            onSaveCategory={handleSaveCategoryCallback}
                            onDeleteCategory={handleDeleteCategoryCallback}
                            isParent={false}
                        />
                    </Tab.Pane>
                });
            }

            panes.push({
                menuItem: (
                    <Menu.Item key={'import-category-tab'} onClick={defineImportModal}>
                        <div css={css`
                            display: flex;
                            align-items: center;
                        `}>
                            <span css={css`flex: 1;`}>{t('category_list.import_category')}</span>

                            <div css={css`min-height: 21px;`}>
                                {isLoading
                                ? <Loader active size='tiny' inline='centered' css={loaderTabStyles} />
                                : <Icon name={'upload'} size={'large'} css={menuItemStyles} />}
                            </div>
                        </div>
                    </Menu.Item>
                ),
                render: () => null
            });
        }
        setPanes(panes);

    }, [categories,
        handleCategoryPositionChangeCallback,
        handleDeleteCategoryCallback,
        handleSaveCategoryCallback,
        isLoading,
        setId,
        slot,
        canAdd
    ]);

    const loadCategories = async () => {
        setIsLoading(true);
        setCategories(await getCategories(setId, slot.id));
        setIsLoading(false);
    }

    const handleCategoryTabChange = (e, data) => {
        const callback = () => {
            const itemKey = data.panes[data.activeIndex].menuItem?.key;
            if (itemKey === 'import-category-tab') {
                return;
            }

            setActiveIndex(data.activeIndex);

            if (itemKey === 'add-category-tab') {
                setCanAdd(false);
            }
        };

        if (modified) {
            defineConfirmModal(callback);
        } else {
            callback();
        }
    }

    return (
        <Tab activeIndex={activeIndex} panes={panes} onTabChange={handleCategoryTabChange} menu={{ fluid: true, vertical: true, tabular: true }} grid={{ paneWidth: 11, tabWidth: 5 }} />
    )
}

export default CategoryList;
