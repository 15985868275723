import styled from '@emotion/styled';
import {Button, Icon, Message} from 'semantic-ui-react';
import {useContext} from 'react';
import { UltyModalWrapperContext } from '../../../Shared/UltyModalWrapper/UltyModalWrapperContext';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
`;

const OrderPickupIncomplete = ({ items, onSubmit }) => {
    const [t] = useTranslation();
    const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);

    return (
        <>
            <Container>
                <Message
                    error
                    icon="ban"
                    header={t('order.pickup.incomplete_order')}
                    list={items.map(i => t('order.pickup.incomplete', {
                        item: i.item?.object?.name,
                        expected: i.expected,
                        found: i.found
                    }))}
                />
            </Container>

            <div style={{textAlign: 'center'}}>
                <Button fluid color='red'
                        onClick={() => {
                            handleUltyModalWrapper(false, null);
                        }}
                >
                    <Icon name='times'/>
                    {t('order.pickup.cancel')}
                </Button>
            </div>

            <div style={{textAlign: 'center', marginTop: '2em'}}>
                <Button fluid color='teal'
                        onClick={() => {
                            onSubmit();
                            handleUltyModalWrapper(false, null);
                        }}
                >
                    <Icon name='checkmark'/>
                    {t('order.pickup.validate')}
                </Button>
            </div>
        </>
    )
}


OrderPickupIncomplete.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        object: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
      expected: PropTypes.number,
      found: PropTypes.number,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OrderPickupIncomplete;
