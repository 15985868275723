/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {useEffect, useState, useCallback} from 'react';
import { Form, Header, Button, Input, Dropdown, Checkbox, Grid, Divider } from 'semantic-ui-react'
import { notify } from 'react-notify-toast';
import {updateCategorySlot} from '../../services/Category/Category.service';
import { UltyModalWrapperContext } from '../Shared/UltyModalWrapper/UltyModalWrapperContext';
import CategoryRemoveSlot from './CategoryRemoveSlot';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useUser from '../Shared/UserProvider/useUser';
import DayTimePeriods from '../Admin/AdminHours/DayTimePeriods';
import {getTimePeriodsOfADayFromAvailabilities} from '../../services/TimePeriod/timePeriod.service';

const CategorySlot = ({ setId, slot, nbSlots, reload }) => {
    const [t] = useTranslation();
    const {user} = useUser();

    const DAYS = [
        { value: 'monday', text: t('days.monday') },
        { value: 'tuesday', text: t('days.tuesday') },
        { value: 'wednesday', text: t('days.wednesday') },
        { value: 'thursday', text: t('days.thursday') },
        { value: 'friday', text: t('days.friday') },
        { value: 'saturday', text: t('days.saturday') },
        { value: 'sunday', text: t('days.sunday') },
    ];

    const [name, setName] = useState(slot?.name||'');
    const [position, setPosition] = useState(slot?.position||0);
    const [availabilities, setAvailabilities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [useMerchantHours, setUseMerchantHours] = useState(true);

    useEffect(() => {
        if (slot) {
            setAvailabilities(slot.hours);
            setUseMerchantHours(slot.hours.length === 0);
            setName(slot.name);
            setPosition(slot.position);
        } else {
            setAvailabilities([]);
            setUseMerchantHours(true);
            setName('');
            setPosition(0);
        }
    }, [setId, slot]);

    const { handleUltyModalWrapper } = React.useContext(UltyModalWrapperContext);
    const defineRemoveModal = () => {
        const modalSettings = {
            title: t('category_slot.delete_slot_header'),
            component: <CategoryRemoveSlot
                set={setId}
                slot={slot}
                afterSubmit={() => {
                    reload();

                    handleUltyModalWrapper(false, null);
                }}
            />
        };
        handleUltyModalWrapper(true, modalSettings);
    };

    const handleSubmit = async () => {
        setLoading(true);

        let hours = [];

        let isError = [];
        availabilities.forEach((a) => {
            let times = [];
            a.time_periods.forEach(h => {
                if (h.start_time === '' && h.end_time === '') {
                    return;
                }

                if (h.start_time === '' || h.end_time === '' || !h.start_time.match(/[0-9][0-9]:[0-9][0-9]/) || !h.end_time.match(/[0-9][0-9]:[0-9][0-9]/)) {
                    isError.push(a.day_of_week);
                    return;
                }

                times.push(h);
            });

            a.time_periods = times;

            hours.push(a);
        });

        if (isError.length > 0) {
            setErrors(isError);
            notify.show(t('category_slot.hours_errors'), 'error');
            setLoading(false);

            return;
        }

        try {
            await updateCategorySlot(setId, slot.id, {
                name,
                position,
                hours: useMerchantHours ? [] : hours
            });
            notify.show(t('global.registerSuccess'), 'success')
        } catch (e) {
            notify.show(t('global.anErrorOccurred'), 'error')
        }

        setLoading(false)
    }

    const isReadOnly = useCallback(() => {
        return !!slot?.parentId||slot?.provider?.id!==user.provider.id;
    }, [slot, user]);

    const handleTimePeriodChange = (day, timePeriodIndex, timePeriod) => {
        const { start_time, end_time } = timePeriod;
        const availabilityToUpdate = availabilities.find(availability => availability.day_of_week === day);
        if (availabilityToUpdate) {
            availabilityToUpdate.time_periods[timePeriodIndex] = { start_time, end_time };
            setAvailabilities(availabilities.map(a => a));
        } else {
            setAvailabilities(prev => [...prev, { day_of_week: day, time_periods: [{ start_time, end_time }] }])
        }
    }

    const handleOnError = (day, timePeriodIndex, error) => {
        const prefix = `${day}-${timePeriodIndex}-`;
        const errorCode = `${prefix}${error}`;

        if (error) {
            setErrors(prev => [...prev, errorCode]);
        } else {
            setErrors(prev => prev.filter(err => !err.includes(prefix)));
        }
    }

    return (
        <>
            <Form.Field
                control={Input}
                name="name"
                value={name}
                onChange={(_evt, dt) => setName(dt.value)}
                label={t('category_slot.name')}
                placeholder={t('category_slot.name')}
                readOnly={isReadOnly()}
            />

            <Form.Field
                control={Dropdown}
                name="position"
                inline
                scrolling
                selection
                value={position}
                onChange={(_evt, dt) => setPosition(dt.value)}
                label={t('category_slot.position')}
                placeholder={t('category_slot.position')}
                style={{
                    minWidth: '80px'
                }}
                options={[...Array(nbSlots).keys()].map(i => ({
                    value: i,
                    key: i,
                    text: `${i}`
                }))}
                disabled={isReadOnly()}
            />

            <Header>{t('category_slot.hours')}</Header>

            <Form.Field>
                <Checkbox disabled={isReadOnly()} toggle label={t('category_slot.use_merchant_hours')} checked={useMerchantHours} onChange={(evt, { checked }) => setUseMerchantHours(checked)} />
            </Form.Field>

            { !useMerchantHours && (
                <>
                    {DAYS.map(day =>
                        <DayTimePeriods
                            key={day.text}
                            label={day.text}
                            timePeriods={getTimePeriodsOfADayFromAvailabilities(day, availabilities)}
                            onChange={(timePeriodIndex, timePeriod) => handleTimePeriodChange(day.value, timePeriodIndex, timePeriod)}
                            onError={(timePeriodIndex, error) => handleOnError(day.value, timePeriodIndex, error)}
                            readonly={isReadOnly()}
                        />
                    )}
                </>
            )}

            <Divider/>

            <Grid css={css`margin-top: 10px;`}>
                <Grid.Column width={8} textAlign="left">
                    <Button color="red" loading={loading} disabled={loading || isReadOnly()} onClick={() => {
                        defineRemoveModal();
                    }}>{t('category_slot.delete_slot')}</Button>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                    <Button color="teal" type="submit" loading={loading} disabled={loading || errors.length > 0 || isReadOnly()} onClick={handleSubmit}>{t('global.save')}</Button>
                </Grid.Column>
            </Grid>
        </>
    )
}

CategorySlot.propTypes = {
    setId: PropTypes.string.isRequired,
    slot: PropTypes.object.isRequired,
    nbSlots: PropTypes.number,
    reload: PropTypes.func.isRequired
}

export default CategorySlot;
