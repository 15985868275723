/** @jsxImportSource @emotion/react */
import { UltyInputText } from '@ulty/ulty-ui'
import { Button } from 'semantic-ui-react'
import { useState } from 'react'
import { recipientStyles } from './recipientStyles'

const RecipientAdder = ({onAdd}) => {
    const [value, setValue] = useState('')

    const canAdd = value !== '' && value.includes('@')

    return (
      <div css={recipientStyles}>
          <UltyInputText
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <Button
            icon='add'
            size='mini'
            onClick={() => {
                onAdd(value)
                setValue('')
            }}
            disabled={!canAdd}
          />
      </div>
    )
}

export { RecipientAdder }
