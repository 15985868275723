import React, { createContext } from 'react';
import useUltyModal from './useUltyModal';
import UltyModal from "./UltyModal";

let UltyModalContext;
const { Provider } = (UltyModalContext = createContext(null));

const UltyModalProvider = ({ children }) => {
    const { isOpen, handleUltyModal, modalSettings } = useUltyModal();
    return (
        <Provider value={{ isOpen, handleUltyModal, modalSettings }}>
            <UltyModal />
            {children}
        </Provider>
    );
};

export { UltyModalContext, UltyModalProvider };