/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import PosDayOffItem from './PosDayOffItem';
import React, {useEffect, useState} from 'react';
import {Button, Divider, Header, Icon} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {notify} from 'react-notify-toast';
import {getPosOffDays, setPosOffDays} from '../../../../services/PointOfSale/PointOfSale.service';
import {Prompt} from 'react-router-dom';

const itemStyles = css`
  display: flex;
  column-gap: 1rem;
`;

const PosDaysOff = ({ pos }) => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isModified, setIsModified] = useState(false);

    const initialPosDayOffToAdd = {
        from: null,
        to: null,
        errors: []
    };

    const [posDayOffToAdd, setPosDayOffToAdd] = useState(initialPosDayOffToAdd);

    const [posDaysOff, setPosDaysOff] = useState([]);

    useEffect(() => {
        (async function loadOffDays() {
            setIsLoading(true);
            setPosDaysOff(await getPosOffDays(pos.id));
            setIsLoading(false);
        })();
    }, [pos.id]);

    const canAddPosDayOff = () => {
        return !posDayOffToAdd.errors?.from
            && !posDayOffToAdd.errors?.to
            && moment(posDayOffToAdd.from).isValid()
            && moment(posDayOffToAdd.to).isValid();
    }

    const handleAddPosDayOff = () => {
        setIsModified(true);
        setPosDaysOff(prev => [posDayOffToAdd, ...prev]);
        setPosDayOffToAdd(initialPosDayOffToAdd);
    }

    const handleRemovePosDayOff = (posDayOffIndex) => {
        setIsModified(true);
        const newArray = [...posDaysOff];
        newArray.splice(posDayOffIndex, 1);
        setPosDaysOff(newArray);
    }

    const handlePosDaysOffChange = (posDayOff) => {
        setPosDayOffToAdd(prev => ({
            ...prev,
            ...posDayOff
        }));
    }

    const handleOnPosDayOffToAddError = (fieldName, reason) => {
        setPosDayOffToAdd(prev => ({
            ...prev,
            errors: {
                ...prev.errors,
                [fieldName]: reason
            }
        }));
    }

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const settedOffDays = await setPosOffDays(pos.id, posDaysOff.map(offDay => ({
                from: offDay.from,
                to: offDay.to,
            })));

            setPosDaysOff(settedOffDays);
            setIsModified(false);

            notify.show(t('global.registerSuccess'), 'success');
        } catch (e) {
            notify.show(t('global.anErrorOccurred'), 'error');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Prompt
                when={isModified}
                message={t('categories.confirm_continue')}
            />

            <span css={itemStyles}>
                <PosDayOffItem
                    from={posDayOffToAdd.from}
                    to={posDayOffToAdd.to}
                    onChange={handlePosDaysOffChange}
                    onError={handleOnPosDayOffToAddError}
                />
                <Button icon color="teal" disabled={!canAddPosDayOff()} onClick={handleAddPosDayOff}>
                    <Icon name="add" />
                </Button>
            </span>

            <Divider />

            <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                `}
            >
                {posDaysOff.length === 0 &&
                    <Header
                        css={css`
                          display: flex;
                          justify-content: center;
                        `}
                        icon
                    >
                        <Icon color='teal' name='calendar times outline' />
                        {t('administration.pos_days_off.no_days_off')}
                    </Header>
                }

                {posDaysOff.map((posDayOff, index) =>
                    <span key={`posDaysOff-${index}`} css={itemStyles}>
                        <PosDayOffItem readonly from={posDayOff.from} to={posDayOff.to} />
                        <Button icon onClick={() => handleRemovePosDayOff(index)}>
                            <Icon name="minus"  />
                        </Button>
                    </span>
                )}
            </div>

            <div style={{ marginTop: '1rem', textAlign: 'right' }}>
                <Button color="teal" type="submit" loading={isLoading} disabled={isLoading} onClick={handleSubmit}>{t('global.save')}</Button>
            </div>
        </>
    )
}

export default PosDaysOff;
