import React, { useState } from "react";

const CategoryModifiedContext = React.createContext();

export const CategoryModifiedProvider = ({ children }) => {
  const [modified, setModified] = useState(false);

  return (
    <CategoryModifiedContext.Provider
      value={{ modified, setModified }}
    >
      {children}
    </CategoryModifiedContext.Provider>
  );
};

export default CategoryModifiedContext;