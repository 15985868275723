import React, {createContext, useState} from 'react';
import rules from '../../../rbac-rules';
import { isProviderAPos } from '../../../services/Provider/Provider.service';

const UserContext = createContext({});
const UserProvider = ({ children,baseUser={} }) =>  {
    const [user, setUser] = useState(baseUser);

    user.getUserCompany = () => {
        return user.provider.type === 'COMPANY' ? user.provider.object : user.provider.object.company;
    }

    user.getUserPos = () => {
        return  isProviderAPos(user.provider) ? user.provider.object : null;
    }

    user.getCompanyCurrency = () => {
        return user.provider.type === 'COMPANY' ?
            user.provider.object.country.currencyCode :
            user.provider.object.company.country.currencyCode;
    }

    user.getCompanyLocale = () => {
        return user.provider.type === 'COMPANY' ?
            user.provider.object.country.locale :
            user.provider.object.company.country.locale;
    }

    user.getCompanyCountry = () => {
        return user.provider.type === 'COMPANY' ?
            user.provider.object.country.code :
            user.provider.object.company.country.code;
    }

    user.isAdmin = () => {
        return user.provider?.role.name === 'POWER_USER';
    }

    const canCreateCategories = () => {
        if (user.provider.type === 'COMPANY') {
            return true;
        }

        const [provider] = user.providers.filter(p => p.id === user.provider.id);
        return provider?.object?.canCreateCategories;
    }

    const canExtendCategories = () => {
        if (user.provider.type === 'COMPANY') {
            return true;
        }

        const [provider] = user.providers.filter(p => p.id === user.provider.id);
        return provider?.object?.canExtendCategories;
    }

    const checkStaticPermission = (role, action, data) => {
        if (user.provider?.role.name === 'POWER_USER') {
            return true;
        }

        if (!role) {
            return false
        }

        const permissions = rules[role.toLowerCase()]
        if (!permissions) {
            return false
        }

        const staticPermissions = permissions.static
        if (staticPermissions && staticPermissions.includes(action)) {
            return true
        }

        const dynamicPermissions = permissions.dynamic
        if (dynamicPermissions) {
            const permissionCondition = dynamicPermissions[action]
            if (!permissionCondition) {
                return false
            }

            return permissionCondition(data)
        }

        return false
    }


    const can = (action, resource) => {
        if (user.provider?.role.name === 'POWER_USER') {
            return true;
        }

        const staticCheck = (action === 'STATIC' && resource) ? checkStaticPermission(user.provider?.role.name, resource) : true;
        const roleCheck = (action !== 'STATIC' && resource) ? user.provider?.role.permissions.some(permission =>
            permission.action === action && permission.resource === resource) : true;

        return staticCheck && roleCheck;
    }

    const landingRoute = (roleName = null) => {
        switch (roleName || user.provider?.role.name) {
            case 'ORDER_PREPARER':
                return '/orders/current';
            default:
                return '/analytics';
        }
    }

    return (
        <UserContext.Provider value={{user, setUser, can, landingRoute, canCreateCategories, canExtendCategories }}>
            {children}
        </UserContext.Provider>
    )
}

export { UserProvider, UserContext };
