import {UltyDropdown} from '@ulty/ulty-ui';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import useFilters from '../useFilters';
import {getPlatforms} from '../../../../services/Platform/Platform.service';

const PlatformFilter = () => {
    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [platforms, setPlatforms] = useState([]);
    const { filters, setFilters, debounceFiltersChange } = useFilters();

    useEffect(() => {
        (async function loadPlatforms() {
            setIsLoading(true);
            setPlatforms((await getPlatforms()) || []);
            setIsLoading(false);
        })();
    }, []);

    const handleSelectedPlatformsChange = (selectedPlatforms) => {
        const newFilters = {
            ...filters,
            platformIds: selectedPlatforms
        };

        setFilters(newFilters);
        debounceFiltersChange(newFilters);
    }

    return (
        <UltyDropdown
            label={t('global.platforms')}
            multiple
            loading={isLoading}
            options={platforms.map(item => ({
                text: item.name,
                key: item.id,
                value: item.id
            }))}
            placeholder=' '
            value={filters.platformIds}
            onChange={(e, dp) => handleSelectedPlatformsChange(dp.value)}
        />
    )
}

export default PlatformFilter;
