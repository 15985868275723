import { Header } from 'semantic-ui-react'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { PerformanceReportConfigurationCreate } from './edit/PerformanceReportConfigurationCreate'
import { PerformanceReportConfigurationUpdate } from './edit/PerformanceReportConfigurationUpdate'
import useUser from '../Shared/UserProvider/useUser'
import { getPerformanceReportConfigurations } from '../../services/Provider/Provider.service'
import { PerformanceReportConfigurationPlaceholder } from './PerformanceReportConfigurationPlaceholder'
import { PerformanceReportConfigurationHeader } from './PerformanceReportConfigurationHeader'
import { useTranslation } from 'react-i18next'

const PerformanceReportConfigurationList = () => {
    const [t] = useTranslation()
    const {user} = useUser()
    const {data: performanceReportConfigurations, isLoading} = useQuery({
        queryKey: ['providers', user.provider.id, 'performanceReportConfigurations'],
        queryFn: () => getPerformanceReportConfigurations(user.provider.id),
    })

    return (
      <>
          <PerformanceReportConfigurationHeader />

          <PerformanceReportConfigurationCreate />

          {performanceReportConfigurations?.length > 0 && <Header>{t('reports.performance_configuration.your_reports')}</Header>}

          {isLoading ? <PerformanceReportConfigurationPlaceholder />
          : performanceReportConfigurations.map(performanceReportConfiguration => (
              <PerformanceReportConfigurationUpdate
                key={performanceReportConfiguration.id}
                performanceReportConfiguration={performanceReportConfiguration}
              />
            ))
          }
      </>
    )
}

export { PerformanceReportConfigurationList }
