export const AUTH_TOKEN = 'auth-token';
export const API_URL = 'https://api-v2.ulty.fr';
export const SOCKET_URL = 'wss://api-v2.ulty.fr';

export const RECAPTCHA_KEY = '6LcD5rYZAAAAAAG7XHTe1_z-YDXi7AjGoneqC2QB';

export const PASSWORD_REGEX = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");

export const STRIPE_PUB_KEY = 'pk_live_51InonGHqBpNIbzOT7oPD1yIdTOSECTwTA4Vzn7S6yQELSVF7XZxgRQesPoml0BpTKytYOb09efGZcXVN4x1RJnrw00y33cyrEh';

export const MOBILE_THRESHOLD = 576;
export const MOBILE_HIGH_THRESHOLD = 768;
export const TABLET_THRESHOLD = 991;