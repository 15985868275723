import {useContext} from 'react';
import BreadcrumbContext from './BreadcrumbContext';

export default () => {
  const context = useContext(BreadcrumbContext)
    if (context === undefined) {
        throw new Error(`useBreadcrumb must be used within a Breadcrumb provider`)
    }
    return context
}
