import {
    FormField,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import { useQuery } from '@tanstack/react-query'
import { getRoles } from '../../../../services/Role/role.service'
import { useTranslation } from 'react-i18next'
import { getMerchantsOfCompany } from '../../../../services/Company/Company.service'
import useUser from '../../../Shared/UserProvider/useUser'
import { useMemo, useCallback } from 'react'

const doesProviderHaveRole = (providersRoleMap, posId, roleName) => {
    return providersRoleMap.has(posId) && providersRoleMap.get(posId) === roleName
}

const buildMatrix = (providersRole, pointOfSales, roles) => {
    const providersRoleMap = new Map(providersRole.map(pr => [pr.object.id, pr.role.name]))
    return pointOfSales.map(pos => ({
        pos: {
            id: pos.id,
            name: pos.name,
            roles: roles.map(role => ({
                ...role,
                value: `${pos.id}#${role.id}`,
                checked: doesProviderHaveRole(providersRoleMap, pos.id, role.name),
            })),
        },
    }))
}

const RolesAccesses = ({ providersRole, upsertRoleForPos, removeProviderUser }) => {
    const [t] = useTranslation()
    const {user: currentUser} = useUser()
    const userCompanyId = currentUser.provider.type === 'COMPANY' ?
      currentUser.provider.object.id :
      currentUser.provider.object.company.id

    const { data: roles } = useQuery({
        queryFn: getRoles,
        queryKey: ['roles'],
    })

    const { data: pointOfSales } = useQuery({
        queryFn: () => getMerchantsOfCompany(userCompanyId),
        queryKey: ['companies', userCompanyId, 'pointofsales'],
    })

    const matrix = useMemo(() => {
        if (!pointOfSales || !roles) return []
        return buildMatrix(providersRole, pointOfSales, roles)
    }, [providersRole, pointOfSales, roles])

    const handleChange = useCallback((e, { value }) => {
        const [posId, roleId] = value.split('#')
        const updatedPosIndex = matrix.findIndex(({ pos }) => pos.id === posId)
        const updatedRoleIndex = matrix[updatedPosIndex].pos.roles.findIndex(role => role.id === roleId)

        const actualValue = matrix[updatedPosIndex].pos.roles[updatedRoleIndex].checked
        const newValue = !actualValue

        const pos = pointOfSales.find(pos => pos.id === posId)
        const role = roles.find(role => role.id === roleId)

        if (newValue) {
            upsertRoleForPos({pos, role})
        } else {
            removeProviderUser({pos, role})
        }
    }, [matrix, pointOfSales, roles, upsertRoleForPos, removeProviderUser])

    if (!pointOfSales || !roles) {
        return null
    }

    return (
      <Table definition celled striped>
          <TableHeader>
              <TableRow>
                  <TableHeaderCell width={8} />
                  {roles.map(role => (
                    <TableHeaderCell
                      key={role.id}
                      width={4}
                    >
                        {t(`role.${role.name}`)}
                    </TableHeaderCell>
                  ))}
              </TableRow>
          </TableHeader>

          <TableBody>
              {matrix.map(({ pos }) => (
                <TableRow key={pos.id}>
                    <TableCell>{pos.name}</TableCell>
                    {pos.roles.map((role) => (
                      <TableCell key={role.id} textAlign='center'>
                          <FormField>
                              <Radio
                                toggle
                                name={role.value}
                                value={role.value}
                                checked={role.checked}
                                onChange={handleChange}
                              />
                          </FormField>
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
      </Table>
    )
}

export { RolesAccesses }
