/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {Checkbox, Loader} from 'semantic-ui-react';
import PropTypes from 'prop-types';


const UltyIndeterminateCheckboxGroup = ({parentLabel, options, loading, onChange}) => {
    const handleChangeParentOption = (checked) => {
        const newOptions = [...options.map(option => ({
            ...option,
            checked
        }))];
        const checkedValues = newOptions.filter(option => option.checked).map(option => option.value);
        onChange({options: newOptions, checkedValues});
    }

    const handleChangeSubOption = (index, checked) => {
        const newOptions = [...options];
        newOptions[index].checked = checked;
        const checkedValues = newOptions.filter(option => option.checked).map(option => option.value);
        onChange({options: newOptions, checkedValues});
    }

    return (
        <div
            css={css`
              display: flex;
              flex-direction: column;
              row-gap: 1rem;
            `}
        >
            <Checkbox
                css={css`
                  font-weight: bold;
                `}
                disabled={loading}
                label={parentLabel}
                checked={options.every(option => option.checked)}
                indeterminate={options.some(option => option.checked) && !options.every(option => option.checked)}
                onChange={(e, {checked}) => handleChangeParentOption(checked)}
            />

            {loading &&
            <Loader
                css={css`
                  &:before {
                    border: .2em solid rgba(0,0,0,.1) !important;
                  }
                  &:after {
                    border-color: #767676 transparent transparent !important
                  }
                `}
                active
                inline
            />
            }

            {options.map((option, index) =>
                <Checkbox
                    css={css`
                      margin-left: 1rem;
                    `}
                    disabled={loading}
                    key={`ulty-indeterminate-checkbox-group-${parentLabel}-${index}`}
                    label={option.label}
                    checked={option.checked}
                    onChange={(e, {checked}) => handleChangeSubOption(index, checked)}
                />
            )}
        </div>
    )
}

UltyIndeterminateCheckboxGroup.propTypes = {
    parentLabel: PropTypes.string,
    options: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
}

export default UltyIndeterminateCheckboxGroup;
