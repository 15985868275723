/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {useQuerySearchParams} from '../../../services/Filter/filter.service';
import {Icon, Label} from 'semantic-ui-react';
import {Fragment} from 'react';


const UltyFilterBar = ({filterAttributes, formatFilterDataFn, onFilterRemoval, ...props}) => {
    const query = useQuerySearchParams();

    return (
        <div
          className={props.className}
            css={css`
              display: flex;
              row-gap: .4rem;
              flex-wrap: wrap;
            `}
        >
            {Object.keys(filterAttributes).map(filterAttribute => {
                const value = query.get(filterAttribute);
                if(value === null ){
                    return <Fragment key={filterAttribute} />;
                }

                const formattedFilterData = formatFilterDataFn(filterAttribute, value);
                if(!formattedFilterData ){
                    return <Fragment key={filterAttribute} />;
                }

                return (
                    <Label
                        css={css`
                          cursor: pointer;
                        `}
                        key={filterAttribute}
                        color='teal'
                    >
                        {formattedFilterData}
                        { onFilterRemoval && (
                            <Icon name='delete' onClick={() => {
                                onFilterRemoval(filterAttribute);
                            }}/>
                        )}
                    </Label>
                )
            })}
        </div>
    )
}

export default UltyFilterBar;
