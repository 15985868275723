/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Card, Header, Icon, Segment } from 'semantic-ui-react'
import UltyLoadingPlaceholderCardGroup from '../../Shared/UltyLoadingCardPlaceholder/UltyLoadingPlaceholderCardGroup'
import ItemCard from '../../Items/ItemCard'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const cardGroupStyle = css`
    flex: 1;
    justify-content: center;
`

const ItemListSegment = ({isLoading, items, onSelectItem, selectedItems, ...props}) => {
    const [t] = useTranslation()
    const isMobile = useMediaQuery(`(max-width: 768px)`)
    const isTablet = useMediaQuery(`(max-width: 992px)`)
    const itemsPerRow = isMobile ? 1 : (isTablet ? 2 : 4)

    return (
      <Segment basic css={css` display: flex !important;
          width: 100%;
          padding: 0 !important;
          align-items: flex-start !important;  `}>
          {isLoading ? (
            <UltyLoadingPlaceholderCardGroup css={cardGroupStyle} itemsPerRow={itemsPerRow} cardQuantity={20}/>
          ) : (
            <Card.Group
              itemsPerRow={itemsPerRow}
              css={cardGroupStyle}
              className={props.className}
            >
                {items.map(i =>
                  <ItemCard
                    key={i.id}
                    item={i}
                    isSelected={selectedItems.map(item => item.id).includes(i.id)}
                    onSelect={onSelectItem}
                  />)}

                {items.length === 0 && (
                  <Segment placeholder>
                      <Header icon>
                          <Icon name="warehouse"/>
                          {t('product_list.no_product_found')}
                      </Header>
                  </Segment>
                )}
            </Card.Group>
          )}
      </Segment>
    )
}

ItemListSegment.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
    })).isRequired,
    onSelectItem: PropTypes.func,
    selectedItems: PropTypes.array,
    className: PropTypes.string,
}

export { ItemListSegment }
