import { Table } from 'semantic-ui-react'
import formatDate from '../../../helpers/formatDate'
import { useTranslation } from 'react-i18next'
import { EventItemType } from './EventItemType/EventItemType'

const EventItem = ({ orderId, event }) => {
    const [t] = useTranslation()

    return (
      <Table.Row key={event.id}>
          <Table.Cell>{formatDate(event.createdAt)}</Table.Cell>
          <Table.Cell><EventItemType orderId={orderId} event={event} /></Table.Cell>
          <Table.Cell>
              {event.user ?
                `${event.user.firstname} ${event.user.lastname} (${event.user.email})` :
                t('global.ulty')}
          </Table.Cell>
      </Table.Row>
    )
}

export { EventItem }
