/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FeatureInfo } from './FeatureInfo'
import { Header } from 'semantic-ui-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const inlineStyles = css`
          display: flex;
          column-gap: .5rem;
      `

const localStorageIsVisibleName = 'performance-report-configuration-feature-info-is-visible'

const PerformanceReportConfigurationHeader = () => {
    const [t] = useTranslation()
    const localStorageValue = localStorage.getItem(localStorageIsVisibleName)
    const visible = localStorageValue !== null ? JSON.parse(localStorageValue) : true
    const [isVisible, setIsVisible] = useState(visible)

    return (
      <div css={!isVisible ? inlineStyles : undefined}>
          <Header>{t('reports.performance_configuration.create_a_report')}</Header>
          <FeatureInfo isVisible={isVisible} setIsVisible={(newValue) => {
              setIsVisible(newValue)
              localStorage.setItem(localStorageIsVisibleName, newValue)
          }} />
      </div>
    )
}

export { PerformanceReportConfigurationHeader }
