import {
    Button, Header,
} from 'semantic-ui-react'
import { useCamera } from '../../../camera/useCamera'
import { useMutation } from '@tanstack/react-query'
import { postPickingProof } from '../../../services/Order/Order.service'
import { useTranslation } from 'react-i18next'
import { useId } from 'react'

const AskToAddPickingProof = ({orderId, afterSubmit, isFirstAsk}) => {
    const [t] = useTranslation()

    const postPickingProofMutation = useMutation({
        mutationFn: ({orderId, pickingProof}) => postPickingProof(orderId, pickingProof),
    })

    const cameraId = useId()
    const {takePhoto} = useCamera((image) => {
        postPickingProofMutation.mutate({orderId: orderId, pickingProof: image})
    }, cameraId)

    return (
      <div>
          {isFirstAsk &&
              <Header>
                  {t('order.picking_proof.add_picking_proof_hint')}
                  <br />
                  {t('order.picking_proof.do_you_want_to_take_a_photo')}
              </Header>
          }

          {!isFirstAsk &&
            <Header>
                {t('order.picking_proof.photo_successfully_taken')}
                <br />
                {t('order.picking_proof.take_another_picking_proof')}
            </Header>
          }

          <div style={{textAlign: 'right'}}>
              <Button
                fluid
                size='massive'
                positive
                onClick={() => {
                    takePhoto(cameraId)
                    afterSubmit('CLOSE_AND_ASK_AGAIN')
                }}>
                  {t('global.yes')}
              </Button>

              <Button
                fluid
                size='massive'
                onClick={() => {
                    afterSubmit('CLOSE_AND_CONTINUE')
                }}>
                  {t('global.no')}
              </Button>
          </div>
      </div>
    )
}

export { AskToAddPickingProof }
