import { UltyDropdown, UltyInputText } from '@ulty/ulty-ui'
import { css } from '@emotion/react'
import { debounce } from 'lodash'
import { Form } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string';
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useQuerySearchParams } from '../../../services/Filter/filter.service'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { MOBILE_THRESHOLD } from '../../../constants'

const ProviderSearch = ({ providers }) => {
    const [t] = useTranslation()
    const history = useHistory()
    const { search } = useLocation()
    const queryParams = useQuerySearchParams()
    const { providerId } = useParams()
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

    const handleProviderSearchChange = (e, { value }) => {
        const provider = providers.find(provider => provider.id === value)
        if (provider) {
            history.push({
                pathname: `/settings/${provider.id}/users`,
                search: queryParams.toString(),
            })
        }
    }

    if (isMobile) {
        return (
          <UltyDropdown
            loading={false}
            options={providers ? providers.map(provider => ({
                text: provider.object.name,
                key: provider.id,
                value: provider.id,
            }))  : []}
            multiple={false}
            search
            onChange={handleProviderSearchChange}
            value={providerId}
            noResultsMessage={t('ulty_dropdown.no_result_message')}
          />
        )
    }

    return (
      <Form>
          <UltyInputText
            css={css`
                width: fit-content;
            `}
            placeholder={t('administration.users.search_provider_placeholder')}
            icon="filter"
            value={queryParams.get('pos-search') || ''}
            autoFocus
            onChange={debounce(e => {
                const searchValue = e.target.value
                const parsedQuery = queryString.parse(search)
                const searchQuery = queryString.stringify({
                    ...parsedQuery,
                    'pos-search': searchValue || undefined,
                })

                history.push({ search: searchQuery })
            }, 400)}
          />
      </Form>
    )
}

export { ProviderSearch }
