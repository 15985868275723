/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {UltyDateTimePicker} from '@ulty/ulty-ui';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { MOBILE_THRESHOLD } from '../../../../constants';

const PosDayOffItem = ({ from, to, readonly, onChange, onError }) => {
    const [t] = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

    const handleOnStartDateChange = (date) => {
        if (date && (date.isAfter(to) || !to)) {
            to = date.endOf('days');
        }

        onChange({
            from: date,
            to
        });
    }

    const handleOnEndDateChange = (date) => {
        onChange({
            from,
            to: date
        });
    }

    const handleOnError = (fieldName, reason) => {
        if (!onError) {
            return;
        }
        onError(fieldName, reason);
    }

    return (
        <span
            css={css`
              display: flex;
              column-gap: 1rem;
              ${isMobile ? 'gap: 1rem;' : ''}
              flex-direction: ${isMobile ? 'column' : 'row'};
            `}
        >
            <UltyDateTimePicker
                readonly={readonly}
                label={t('administration.pos_days_off.from')}
                dateTime={from}
                minDateTime={readonly ? undefined : moment().utc()}
                onChange={handleOnStartDateChange}
                onError={(reason) => handleOnError('from', reason)}
            />

            <UltyDateTimePicker
                readonly={readonly}
                label={t('administration.pos_days_off.to')}
                dateTime={to}
                minDateTime={(readonly || !from) ? undefined : from}
                onChange={handleOnEndDateChange}
                onError={(reason) => handleOnError('to', reason)}
            />
        </span>
    )
}

export default PosDayOffItem;
