/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, {useState, useEffect, useContext} from 'react';
import { Segment, Image, Header, Grid, Icon, Popup, Button, Tab} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ProductDetailsSynchronisation from './ProductDetailsSynchronisation';
import ProductDetailsModifiers from './ProductDetailsModifiers';
import {UltyModalContext} from '../../Shared/UltyModal/UltyModalContext';
import {archive, isItemProvidedByUserProvider} from '../../../services/Item/Item.service';
import {notify} from 'react-notify-toast';
import {getProduct} from '../../../services/Product/Product.service';
import useUser from '../../Shared/UserProvider/useUser';
import {Link} from 'react-router-dom';
import {UltyInputText, UltyMoney} from '@ulty/ulty-ui';
import ItemAvailabilitySetter from '../../Items/ItemAvailabilitySetter';
import useBreadcrumb from '../../NavBar/useBreadcrumb';
import ItemCategories from '../../Items/ItemCategories';
import { isProviderAPos } from '../../../services/Provider/Provider.service';
import { ProductPrices } from '../ProductPrices/ProductPrices';

const ProductDetails = ({ match, history }) => {
    const [t] = useTranslation();
    const {user, can}=useUser();
    const {setPaths} = useBreadcrumb();

    const { id } = match.params;

    const [canEditItem, setCanEditItem] = useState(true);
    const [canDeleteItem, setCanDeleteItem] = useState(true);
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState({
        basePrice: 0,
        taxRate: 0,
        providerIds: [],
        inventory: {
            quantity: 0,
            sellPrice: '',
            unavailableUntil: null,
            externalId: ''
        },
        extraFields: [],
        images: [],
        object: {
            barcode: '',
            name: '',
            description: '',
            overrides: {}
        }
    });
    const { handleUltyModal } = useContext(UltyModalContext);

    const loadProduct = async () => {
        setLoading(true);

        try {
            const p = await getProduct(id);
            setProduct(p);
            setCanEditItem(isItemProvidedByUserProvider(p, user) && can('UPDATE', 'product'));
            setCanDeleteItem(isItemProvidedByUserProvider(p, user) && can('UPDATE', 'product'));
        } catch (e) {
            notify.show(`${t(`global.${e.message}`)}`, 'error');
            navigateToProductsList();
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/'
        }, {
            text: t('breadcrumb.catalog'),
            link: true,
            path: '/products'
        }, {
            text: product?.object?.name || t('global.loading'),
            link: false,
            path: '/products'
        }]);
    }, [product]);

    useEffect(() => {
        loadProduct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const OverridableField = ({ label, value, overrideValue, style }) => {
        if (overrideValue && value !== overrideValue) {
            return (
                <div style={(style || {})}><label>{label} : </label> {value} <Popup
                    trigger={<Icon name="warning circle" color="yellow" />}
                    content={t('item_edit.override')}
                /></div>
            )
        }

        return (
            <div style={(style || {})}><label>{label} : </label> {value}</div>
        )
    }

    const handleDeleteItem = async () => {
        const modalSettings = {
            title: `${t('product_details.deleteConfirm.title')} ${product?.object?.name} ?`,
            content: <>
                <p>{t('product_details.deleteConfirm.message')}</p>
            </>,
            onValidate: async () => {
                await archive(product.id, { archived: true });
                handleUltyModal(false);
                navigateToProductsList();
            }
        };
        handleUltyModal(true, modalSettings);
    }

    const navigateToProductsList = () => {
        history.push('/products');
    }

    return (
        <>
            <Segment loading={loading}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Header>{t('product_details.product')}</Header>
                        </Grid.Column>
                        <Grid.Column width={8} textAlign="right">
                            <Button css={css` margin-left: 10px !important;`} icon='pencil' as={Link} to={`/products/edit/${product.id}`} />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={16} textAlign="right">
                            {product?.id && product?.inventory && isProviderAPos(user.provider) && (
                                <ItemAvailabilitySetter itemId={product.id} />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
                <Grid>
                    <Grid.Column tablet={4} mobile={16} computer={4}>
                        <Image src={product.images.find(image => image.isDefault)?.url} css={css` max-height: 250px; margin: auto; `} />
                    </Grid.Column>
                    <Grid.Column tablet={12} mobile={16} computer={12} css={css`
                      display: flex !important;
                      flex-direction: column;

                      label {
                        font-weight: bold;
                      }
                    `}>
                        <UltyInputText
                            readonly={true}
                            label={`${t('product_details.ean')}:`}
                            value={<span><label><Icon name="barcode" /></label> {product.object.barcode}</span>}
                        />

                        {product.inventory?.externalId &&
                            <UltyInputText
                                readonly={true}
                                label={`${t('product_details.sku')}:`}
                                value={<span><label><Icon name="barcode" /></label> {product.inventory.externalId}</span>}
                            />}

                        <OverridableField label={t('item_edit.name')} value={product.object.name} overrideValue={''} />
                        <OverridableField label={t('item_edit.description')} value={product.object.description} overrideValue={''} />
                       
                       <ProductPrices product={product} />

                        <UltyInputText
                            readonly
                            label={`${t('item_edit.taxRate')} :`}
                            value={`${product.taxRate} %`}
                        />

                        <div css={css`
                          text-align: right;
                          padding-top: 20px;
                        `}>
                            {canDeleteItem &&
                                <Button
                                    type="button"
                                    color="red"
                                    size="mini"
                                    onClick={handleDeleteItem}
                                    loading={loading}
                                >
                                    <Icon name={'delete'} />
                                    {t('global.delete')}
                                </Button>}

                        </div>
                    </Grid.Column>
                </Grid>
            </Segment>

            <Tab menu={{ secondary: true, pointing: true }} panes={[
                (product.id && {
                    menuItem: t('item_categories.header'),
                    render: () => <Tab.Pane attached={false} loading={loading}><ItemCategories itemId={product.id} canEdit={can('UPDATE', 'product')} /></Tab.Pane>,
                }),
                {
                    menuItem: t('product_details.synchronisation'),
                    render: () => <Tab.Pane attached={false} loading={loading}><ProductDetailsSynchronisation product={product} /></Tab.Pane>,
                },
                {
                    menuItem: t('product_details.modifiers.header'),
                    render: () => <ProductDetailsModifiers product={product} canEdit={canEditItem} />,
                },
            ]} />
        </>
    )
}

export default ProductDetails
