/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, Icon, Segment, Table} from 'semantic-ui-react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {getDeploymentMetrics} from '../../services/Metrics/Metrics.service';
import { useTranslation } from 'react-i18next';
import useBreadcrumb from '../NavBar/useBreadcrumb';
import AdvancedFilters from './AdvancedFilters';
import useUser from '../Shared/UserProvider/useUser';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { getCancellationSource } from '../../services/http-client';


const Deployments = ({ handleFiltersChange, defaultFilters }) => {
  const [t] = useTranslation();
  const { setPaths } = useBreadcrumb();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({});

  const {user} = useUser();

  const loadMetrics = useCallback(async (cancellationSource) => {
    setLoading(true);
    const data = await getDeploymentMetrics({
      merchantId: defaultFilters.merchantId,
      brandIds: defaultFilters.brandIds,
      platformIds: defaultFilters.platformIds,
    }, cancellationSource );

    if(cancellationSource?.isCancelled) return;

    setData(data);
    setLoading(false);
  }, [defaultFilters.merchantId, defaultFilters.brandIds, defaultFilters.platformIds]);

  useEffect(() => {
    setPaths([{
      text: t('breadcrumb.home'),
      link: false,
      path: '/'
    }]);
  }, [setPaths, t]);

  useEffect(() => {
    const cancellationSource = getCancellationSource();
    loadMetrics(cancellationSource);

    return () => cancellationSource.cancel();
  }, [loadMetrics]);

  const [localFilters, setLocalFilters] = useState({
    merchant: defaultFilters.merchant,
    brands: defaultFilters.brands,
    platforms: defaultFilters.platforms,
    dateRange: {
      startDate: moment(defaultFilters.dateRange.startDate).utc(),
      endDate: moment(defaultFilters.dateRange.endDate).utc()
    },
    dateComparison: {
      startDate: defaultFilters.dateComparison.startDate && moment(defaultFilters.dateComparison.startDate).utc(),
      endDate: defaultFilters.dateComparison.endDate && moment(defaultFilters.dateComparison.endDate).utc()
    }
  });

  const localHandleFiltersChange = (filters) => {
    setLocalFilters(prev => ({
      ...prev,
      ...filters,
    }));
  }

  useEffect(() => {
    handleFiltersChange(localFilters);
  }, [localFilters, handleFiltersChange]);

  return (
      <React.Fragment>
        <div css={css`
          display: flex;
          justify-content: space-between;

          .csv-btn {
            display: flex;
            align-items: center;
          }
        `}>
          <AdvancedFilters
            defaultFilters={{
              merchant: defaultFilters.merchant,
              brands: defaultFilters.brands,
              platforms: defaultFilters.platforms,
            }}
            onFiltersChange={localHandleFiltersChange}>
            <Form css={css` display: flex;`}>
              <Form.Group widths="equal">
                {user.provider.type === 'COMPANY' && <AdvancedFilters.PosFilter />}
                <AdvancedFilters.BrandFilter />
                <AdvancedFilters.PlatformFilter />
              </Form.Group>
            </Form>
          </AdvancedFilters>
          <CSVLink
            className="csv-btn"
            enclosingCharacter={'"'}
            separator=";"
            data={[["Store"].concat((data?.brands||[]).flatMap((brand) => {
              return (data?.platforms||[]).map(platform => `${brand.name} - ${platform.name}`);
            }))].concat((data?.results||[]).map(res => {
              return res.map((r, i) => {
                  if (i === 0) {
                    return r;
                  }
                  return r ? 'Y' : 'N';
                });
              }))}
            filename="export-deployments.csv"
          >
            <Button icon>
              <Icon name="cloud download" />
            </Button>
          </CSVLink>
        </div>
        <Segment loading={loading} basic css={css`
          width: 100%;
          overflow: auto;
        `}>
          <Table celled striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                { (data?.brands||[]).map(brand => (
                  <Table.HeaderCell textAlign="center" colSpan={`${(data?.platforms||[]).length}`}>{brand.name}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                { (data?.brands||[]).flatMap(() => {
                  const cols = (data?.platforms||[]).map(platform => (
                    <Table.HeaderCell singleLine textAlign="center">{platform.name}</Table.HeaderCell>
                  ));
                  return cols;
                })}
              </Table.Row>
            </Table.Header>

          <Table.Body>
            { (data?.results||[]).map(res => (
              <Table.Row>
                { res.map((r, i) => {
                  if (i === 0) {
                    return (
                      <Table.Cell singleLine>{r}</Table.Cell>
                    )
                  }

                  return (
                    <Table.Cell textAlign="center">{r ? (
                      <Icon name="check circle" color="green" />
                    ) : (
                      <Icon name="times circle" color="red" />
                    )}</Table.Cell>
                  );
                })}
              </Table.Row>
            ))}

          </Table.Body>
          </Table>
        </Segment>
      </React.Fragment>
  )
}

export default Deployments;
