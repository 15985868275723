/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';

import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {Icon, Message, Image} from 'semantic-ui-react';
import { MOBILE_THRESHOLD } from '../../../constants';

import formatDate from '../../../helpers/formatDate'
import { getOrderDeliveryInfo } from '../../../services/Order/Order.service'

const OrderDelivery = ({ orderId }) => {
  const [t] = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);

  const [deliveryInfo,setDeliveryInfo] = useState(null);

  useEffect(() => {
      (async function loadOrderDeliveryInfo() {
          setDeliveryInfo(await getOrderDeliveryInfo(orderId))
      })()
  }, [orderId]);

  const locationLink = useMemo(() => {
    if(!deliveryInfo?.location) return undefined;
    const {latitude,longitude} = deliveryInfo.location;
    const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroidDevice = Boolean(window.Android);
    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    const appleMapsLink = `https://maps.apple.com/?q=${latitude},${longitude}`;

    if(isAndroidDevice && window.Android.handleGeoLinks) return `geo:${latitude},${longitude}?q=${latitude},${longitude}`
    if(isAppleDevice) return appleMapsLink;
    return googleMapsLink
  },[deliveryInfo?.location?.latitude,deliveryInfo?.location?.longitude])


  return (
    <>
    {deliveryInfo?.location && <Message icon compact css={css`
      img {
        width: 60px !important;
      }
    `}>
      <Message.Content css={css`
        display: flex !important;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
      `}>
        <div css={css`
          display: flex;
          flex-direction: ${isMobile ? 'column' : 'row'};
          flex: 1;
        `}>
          <div css={css`
            flex: 1;
          `}>
            <Message.Header css={css`
              text-align: center;
            `}>{t("order.deliveryInfo.title")}</Message.Header>
            <div css={css`
              text-align: center;
              font-size: 1.14285714em;
            `}>
              <address css={css`
              white-space: pre-wrap
              `}>
              <a target='_blank' accessKey='m' href={locationLink}>
                <Icon name="map marker alternate" /><br/>
                <span>{deliveryInfo?.fullAddress && deliveryInfo.fullAddress}</span>
                </a>
              </address>
            </div>
            <div css={css`
              font-weight: bold;
              text-align: center;
              font-size: 1em;
              white-space: pre-wrap
            `}>
              {deliveryInfo.otherInformation && <em>"{deliveryInfo.otherInformation}"</em>}
            </div>
            <div css={css`
              font-weight: bold;
              text-align: center;
              font-size: 1em;
            `}>
              {deliveryInfo.expectedTime && <em> <Icon name="clock outline"/> {formatDate(deliveryInfo.expectedTime)}</em>}
            </div>
          </div>

        </div>
      </Message.Content>
    </Message>
}
    </>

  );
};

OrderDelivery.propTypes = {
  orderId: PropTypes.string.isRequired,
}

export default OrderDelivery;
