import {useContext} from 'react';
import CategoryModifiedContext from './CategoryModifiedContext';

export default () => {
  const context = useContext(CategoryModifiedContext)
    if (context === undefined) {
        throw new Error(`useModified must be used within a Category provider`)
    }
    return context
}
