/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {Icon} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import useUser from '../../Shared/UserProvider/useUser';
import { UltyMoney } from '@ulty/ulty-ui';

const crossedOutStyle = css`text-decoration: line-through; color: red !important;`;


const ProductQuantity = ({quantity,isUncountable,unavailableUntil}) => {
    const isUnavailable = Boolean(unavailableUntil);
    const isStockUnknown = quantity === null;

    if(isUncountable)  return "∞"
    if(isStockUnknown && isUnavailable) return "0";
    if(isStockUnknown) return "N/C"
    
    return quantity;
}

const ProductCardContentExtra = ({ item, basePrice, inventory, linkTo, color }) => {
    const {user} = useUser();

    return (
        <div
            css={css`
              display: flex;
              flex-grow: 1;
              justify-content: space-evenly;
              align-items: center;
              color: ${color};
              column-gap: 1rem;
            `}>
            <UltyMoney
                css={css`
                  margin: 0;
                  ${inventory?.sellPrice && crossedOutStyle}
                `}
                readonly={true}
                currencyCode={user.getCompanyCurrency()}
                locale={user.getCompanyLocale()}
                amount={basePrice}
            />

            {inventory?.sellPrice && (
                <UltyMoney
                    css={css`margin: 0;`}
                    readonly={true}
                    currencyCode={user.getCompanyCurrency()}
                    locale={user.getCompanyLocale()}
                    amount={inventory.sellPrice}
                />
            )}

            {inventory && (<span>
                <Icon name='warehouse' /> <ProductQuantity isUncountable={item?.isUncountable} unavailableUntil={inventory?.unavailableUntil} quantity={inventory?.quantity} />
            </span>)
            }

            {linkTo &&
            <Link as="a" to={linkTo} css={css`
              cursor: pointer;
              z-index: 900;
            `}>
                <Icon name="eye" />
            </Link>}
        </div>
    )
}

ProductCardContentExtra.propTypes = {
    basePrice: PropTypes.number.isRequired,
    item: PropTypes.object,
    inventory: PropTypes.object,
    linkTo: PropTypes.string,
    color: PropTypes.string,
}

export default ProductCardContentExtra;
