import React, { useState } from 'react';

import { Dropdown, Form, Radio, Grid, Button, Message } from 'semantic-ui-react';

import { notify } from 'react-notify-toast';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { getCategorySets, getCategorySlots, getCategories, importCategories } from '../../services/Category/Category.service';

const CategoryModal = ({ currentSet, currentSlot, afterSubmit }) => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);

  const [allCategories, setAllCategories] = useState(false);
  const [allSetCategories, setAllSetCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [sets, setSets] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedSet, setSelectedSet] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const loadCategories = async () => {
    setLoading(true);

    setCategories(await getCategories(selectedSet, selectedSlot));

    setLoading(false);
  }

  const loadSets = async () => {
    setLoading(true);

    const sets = await getCategorySets();
    setSets(sets);

    setLoading(false);
  }

  const loadSlots = async () => {
    setLoading(true);

    const slots = await getCategorySlots(selectedSet);
    setSlots(slots);

    setLoading(false);
  }

  useEffect(() => {
    loadSets();
  }, []);

  useEffect(() => {
    if (selectedSet) {
      loadSlots();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSet]);

  useEffect(() => {
    if (selectedSet && selectedSlot) {
      loadCategories();
      setSelectedCategory(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSet, selectedSlot])

  const handleSubmit = async () => {
    setLoading(true);

    const result = await importCategories(
      currentSet, currentSlot,
      selectedSet, selectedSlot,
      allCategories, selectedCategory, allSetCategories
    );


    if (result && result.code === 'ERROR') {
      notify.show(t('global.anErrorOccurred'), 'error');
      return;
    }

    await afterSubmit();
  }

  return (
    <React.Fragment>
      <Form>
        <Grid>
          <Grid.Column width={16}>
            <Form.Field>
              <label>{t('categories.set')}</label>
              <Dropdown
                selection
                disabled={loading}
                loading={loading}
                value={selectedSet}
                name="sets"
                onChange={(evt, { value }) => setSelectedSet(value)}
                options={sets.map(dm => ({
                  text: dm.name,
                  value: dm.id,
                  key: dm.id
                }))}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                toggle
                disabled={loading}
                checked={allSetCategories}
                onChange={(evt, { checked }) => setAllSetCategories(checked)}
                label={t('category_import.all_set_categories')}
              />
            </Form.Field>
            { !allSetCategories ? (
              <React.Fragment>
                <Form.Field>
                  <label>{t('categories.slot')}</label>
                  <Dropdown
                    selection
                    disabled={loading}
                    loading={loading}
                    value={selectedSlot}
                    name="platforms"
                    onChange={(evt, { value }) => setSelectedSlot(value)}
                    options={slots.filter(s => s.id !== currentSlot).map(dm => ({
                      text: dm.name,
                      value: dm.id,
                      key: dm.id
                    }))}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    toggle
                    disabled={loading}
                    checked={allCategories}
                    onChange={(evt, { checked }) => setAllCategories(checked)}
                    label={t('category_import.all_categories')}
                  />
                </Form.Field>
                {!allCategories ? (
                  <Form.Field>
                    <label>{t('category_list.categories')}</label>
                    <Dropdown
                      selection
                      name="categories"
                      loading={loading}
                      value={selectedCategory}
                      onChange={(evt, { value }) => setSelectedCategory(value)}
                      options={categories.map(dm => ({
                        text: dm.name,
                        value: dm.id,
                        key: dm.id
                      }))}
                    />
                  </Form.Field>
                ) : (
                  <Message color="yellow">
                    {t('category_import.warning_all_categories')}
                  </Message>
                )}
              </React.Fragment>
            ) : (
              <Message color="yellow">
                {t('category_import.warning_all_categories')}
              </Message>
            )}
          </Grid.Column>
          <Grid.Column width={16}>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Button type="submit" color="teal" loading={loading} disabled={loading||!selectedSet||(!allSetCategories&&!selectedSlot)||(!allSetCategories&&!allCategories&&!selectedCategory)} onClick={handleSubmit}>{t('global.save')}</Button>
            </div>
          </Grid.Column>
        </Grid>
      </Form>
    </React.Fragment>
  );
}

CategoryModal.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  currentSet: PropTypes.string.isRequired,
  currentSlot: PropTypes.string.isRequired
}

export default CategoryModal;
