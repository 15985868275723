/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import formatDate from '../../../helpers/formatDate'
import { Table, Image, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { UltyMoney } from '@ulty/ulty-ui';
import useUser from '../../Shared/UserProvider/useUser';

const ProductDetailsPlatformItem = ({ pf }) => {
    const [t] = useTranslation();
    const {user} = useUser();

    return (
        <Table.Row>
            <Table.Cell textAlign="left">
                { pf.brand.name }
            </Table.Cell>
            <Table.Cell>
                <div css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  img {
                    max-width: 40px !important;
                    height: auto !important;
                  }

                  &>.platform-label {
                    padding-left: 20px;
                    font-weight: bold;
                  }
                `}>
                    <Image wrapped src={pf.platform.logo} />
                    <div className="platform-label">{pf.platform.name}</div>
                </div>
            </Table.Cell>
            <Table.Cell textAlign="center">
                {pf.price ?
                    <UltyMoney
                        readonly
                        currencyCode={user.getCompanyCurrency()}
                        locale={user.getCompanyLocale()}
                        amount={pf.price}
                    />
                    : "-"}
            </Table.Cell>
            <Table.Cell textAlign="center">{typeof pf.stock === 'undefined' ? "-" : (parseInt(pf.stock) === 0 ? "0" : "> 0")}</Table.Cell>
            <Table.Cell textAlign="center">
                {(
                    <React.Fragment>
                        {pf.syncState === 'OK' && (
                            <React.Fragment>
                                <Icon name="check circle outline" color="green" /> {t('item_sync.synchronized')}
                            </React.Fragment>
                        )}
                        {pf.syncState === 'FAILED' && (
                            <React.Fragment>
                                <Icon name="warning circle" color="red" /> {t('item_sync.error')}
                            </React.Fragment>
                        )}
                        {pf.syncState === 'FAILED-STOCKS' && (
                            <React.Fragment>
                                <Icon name="warning circle" color="red" /> {t('item_sync.stock_error')}
                            </React.Fragment>
                        )}
                        {pf.syncState === 'WAITING' && (
                            <React.Fragment>
                                <Icon name="refresh" color="teal" /> {t('item_sync.in_progress')}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Table.Cell>
            <Table.Cell textAlign="center">{formatDate(pf.syncDate)}</Table.Cell>
        </Table.Row>
    )
}

export default ProductDetailsPlatformItem
