import { useTranslation } from "react-i18next";
import useUser from "../UserProvider/useUser";
import { exportPosAvailabilitiesOfACompany } from "../../../services/Company/Company.service";
import { notify } from "react-notify-toast";
import { Button, Icon } from "semantic-ui-react";
import { saveFile } from "../../../services/File/File.service";

export const ExportAllCompanyOpeningButton = ({}) => {

    const { user } = useUser();
    const [t] = useTranslation();

    const handleExportPosAvailabilitiesOfACompany = async () => {
        try {
            const { data } = await exportPosAvailabilitiesOfACompany(user.companyId)
            saveFile(t('administration.hours.export_pos_hours_file'), data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        } catch (e) {
            notify.show(t('global.anErrorOccurred'), 'error');
        }
    }

    return (
        <Button size="tiny"
            onClick={handleExportPosAvailabilitiesOfACompany}
                        >
            <Icon name="download cloud" />
              {t('administration.hours.export_pos_hours')}
        </Button>
    )
}